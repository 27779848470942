import { forwardRef, useCallback, useState } from 'react';

import { Button } from '../button';
import { Flex } from '../flex';
import { InputVerification } from '../input-verification';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import {
  DialogClose,
  ResendButton,
  VerifyEmailFormClose,
  VerifyEmailFormDescription,
  VerifyEmailFormFooter,
  VerifyEmailFormHeader,
  VerifyEmailFormIconButton,
  VerifyEmailFormLogo,
  VerifyEmailFormMainContent,
  VerifyEmailFormSVGIcon,
  VerifyEmailFormTitle,
} from './_Base';

export const defaultVerifyEmailForm = {};

export interface VerifyEmailFormProps extends BaseProps {
  /** Email to verify */
  email: string;
  /** If submit button is disabled */
  disabled?: boolean;
  /** If form is submitting */
  submitting?: boolean;
  /** Event handler called when form submit */
  onSubmit?: (code: string) => void | Promise<any>;
  /** Event handler called when resend code */
  onResend?: (email: string) => void | Promise<any>;
}

export const VerifyEmailForm = forwardRef<HTMLInputElement, VerifyEmailFormProps>((props, ref) => {
  const [code, setCode] = useState('');
  const { email, disabled, submitting, onSubmit, onResend, ...restProps } = props;

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setCode(event.target.value ?? '');
  }, []);

  const handleSubmit = useCallback(() => {
    if (disabled || submitting) {
      return;
    }

    if (typeof onSubmit === 'function') {
      onSubmit(code);
    }
  }, [code, disabled, onSubmit, submitting]);

  const handleResend = useCallback(() => {
    if (typeof onResend === 'function') {
      onResend(email);
      setCode('');
    }
  }, [email, onResend]);

  return (
    <VerifyEmailFormMainContent size={36} direction="vertical" inline={false} {...restProps}>
      <VerifyEmailFormHeader size={20} direction="vertical" inline={false}>
        <VerifyEmailFormTitle>Please check your email.</VerifyEmailFormTitle>
        <VerifyEmailFormDescription>
          We’ve sent a verification code to <strong>{email}</strong>
        </VerifyEmailFormDescription>
        <VerifyEmailFormClose asChild>
          <VerifyEmailFormIconButton type="button">
            <VerifyEmailFormSVGIcon name="close" />
          </VerifyEmailFormIconButton>
        </VerifyEmailFormClose>
      </VerifyEmailFormHeader>
      <Space size={14} direction="vertical" inline={false}>
        <InputVerification
          autoFocus
          ref={ref}
          id="verifyEmail"
          name="code"
          size="xl"
          value={code}
          onChange={handleChange}
          onPressEnter={handleSubmit}
        />
        <div>
          Didn&apos;t receive a code?{' '}
          <ResendButton onClick={handleResend}>Click to resend</ResendButton>
        </div>
      </Space>
      <VerifyEmailFormFooter>
        <Flex align="center" justify="between">
          <VerifyEmailFormLogo />
          <Space size={14}>
            <DialogClose asChild>
              <Button size="large" height={44} variant="transparent">
                Cancel
              </Button>
            </DialogClose>
            <Button
              height={44}
              size="large"
              color="primary"
              type="button"
              loading={submitting}
              disabled={disabled || submitting || !/^\d{6}$/.test(code)}
              onClick={handleSubmit}
            >
              {submitting ? 'Verifying...' : 'Verify'}
            </Button>
          </Space>
        </Flex>
      </VerifyEmailFormFooter>
    </VerifyEmailFormMainContent>
  );
});

VerifyEmailForm.displayName = 'VerifyEmailForm';
