/**
 * Flatten object keys and values to a single depth object
 *
 * @param obj - Original object
 * @param parent - Parent key prefix
 * @param res - Initial object for start
 * @returns Flatten object
 */
export function flattenObject(
  obj: Record<string, any>,
  parent = '',
  res: Record<string, any> = {},
): Record<string, any> {
  for (const key of Object.keys(obj)) {
    const propName = parent ? `${parent}.${key}` : key;

    if (typeof obj[key] === 'object') {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }

  return res;
}
