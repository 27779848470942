import { styled } from '../design-tokens';

export const Container = styled('div', {
  position: 'relative',
  width: 'min-content',
});

export const Input = styled('input', {
  'position': 'absolute',
  'border': '0 none transparent',
  'backgroundColor': 'transparent',
  'caretColor': 'transparent',
  'color': 'transparent',
  'inset': 0,
  'outline': 'none',

  '&::selection': {
    backgroundColor: 'transparent',
  },

  '&:read-only': {
    cursor: 'default',
  },

  '&:disabled': {
    cursor: 'not-allowed',
  },
});

export const InputChars = styled('div', {
  display: 'flex',
  gap: '8px',

  variants: {
    size: {
      sm: {
        height: '36px',
      },

      md: {
        height: '42px',
      },

      lg: {
        height: '52px',
      },

      xl: {
        'height': '52px',
        '@sm': {
          height: '70px',
        },
      },
    },
  },
});

export const InputChar = styled('button', {
  display: 'inline-flex',
  width: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  border: 'none',
  appearance: 'none',
  backgroundColor: '$bg',
  boxShadow: 'inset 0 0 1px $colors$fgTertiary',
  color: '$fg',
  fontFamily: 'inherit',
  fontWeight: '$medium',
  outline: 'none',
  textAlign: 'center',
  transition: 'all 150ms cubic-bezier(0.645, 0.045, 0.355, 1)',

  variants: {
    size: {
      sm: {
        width: '28px',
        padding: '8px 10px',
        fontSize: '14px',
        lineHeight: '20px',
        borderRadius: '4px',
      },

      md: {
        width: '34px',
        padding: '10px 12px',
        fontSize: '16px',
        lineHeight: '22px',
        borderRadius: '6px',
      },

      lg: {
        width: '40px',
        padding: '16px 14px',
        fontSize: '18px',
        lineHeight: '22px',
        borderRadius: '8px',
      },

      xl: {
        'width': '40px',
        'padding': '16px 14px',
        'fontSize': '18px',
        'lineHeight': '22px',
        'borderRadius': '8px',

        '@sm': {
          width: '50px',
          padding: '20px 14px',
          fontSize: '22px',
          lineHeight: '20px',
        },
      },
    },

    disabled: {
      true: {
        backgroundColor: '$bgGroup',
        color: '$fgSecondary',
      },
    },

    selected: {
      true: {
        boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
      },
    },

    inactive: {
      true: {
        backgroundColor: '$bgGroup',
        color: '$fgSecondary',
      },
    },

    ghost: {
      true: {
        backgroundColor: '$bgGroup',
        boxShadow: 'none',
      },
    },
  },

  compoundVariants: [
    {
      ghost: true,
      selected: true,
      css: {
        backgroundColor: '$bgBlue',
      },
    },
    {
      ghost: true,
      inactive: true,
      css: {
        backgroundColor: '$bgGroup',
        color: '$fgSecondary',
      },
    },
  ],
});
