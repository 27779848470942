import { forwardRef } from 'react';

import type { BasePropsWithChildren, FontSize, FontWeight } from '../interfaces';
import { LabelContainer, StyledLabel } from './_Base';

export const defaultLabelProps = {
  size: 'medium' as FontSize,
  weight: 'semibold' as FontWeight,
};

export interface LabelProps extends BasePropsWithChildren {
  /** HTML for attribute */
  htmlFor?: string;
  /** Show asterisk or not */
  asterisk?: boolean;
  /** Show colon or not */
  colon?: boolean;
  /** Extra entry of label */
  extra?: React.ReactNode;
  /** Size of label */
  size?: FontSize;
  /** Weight of label */
  weight?: FontWeight;
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const {
    htmlFor,
    size = defaultLabelProps.size,
    asterisk,
    colon,
    extra,
    children,
    weight = defaultLabelProps.weight,
    ...restProps
  } = props;

  if (!children) {
    return null;
  }

  return (
    <LabelContainer {...restProps}>
      <StyledLabel
        ref={ref}
        as="label"
        htmlFor={htmlFor}
        asterisk={asterisk}
        size={typeof size === 'number' ? undefined : size}
        weight={weight}
        css={{
          fontSize: typeof size === 'number' ? size : undefined,
        }}
      >
        {children}
      </StyledLabel>
      {typeof extra === 'string' ? <span>{extra}</span> : extra}
    </LabelContainer>
  );
});

Label.displayName = 'Label';
