import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';
import { Flex } from '../flex';

export const AlertContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  position: 'relative',
  padding: '24px',
  borderRadius: '6px',

  variants: {
    color: {
      light: {
        border: '1px solid $colors$fgFaint',
        backgroundColor: '$bgGroup',
        color: '$fg',
      },

      dark: {
        border: 'none',
        backgroundColor: '$fg',
        color: '$bg',
      },
    },

    compact: {
      true: {
        gap: '12px',
      },
    },
  },

  defaultVariants: {
    color: 'light',
  },
});

export const AlertHeader = styled('header', {
  'fontSize': '18px',
  'fontWeight': '$semibold',
  'lineHeight': '25px',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    closable: {
      true: {
        paddingRight: '24px',
      },
    },
  },
});

export const AlertContentContainer = styled(Flex, {
  'gap': '14px',
  'alignItems': 'center',

  '@xs': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  '@sm': {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },

  '@xl': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  'variants': {
    isVertical: {
      true: {
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'flex-start',
      },
    },
  },
});

export const AlertContent = styled('div', {
  'fontSize': '14px',
  'fontWeight': '$normal',
  'lineHeight': '21px',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    closable: {
      true: {
        paddingRight: '24px',
      },
    },
  },
});

export const AlertFooter = styled('div', {
  'flexShrink': 0,

  '&:empty': {
    display: 'none',
  },
});

export const CloseButton = styled('button', {
  'all': 'unset',
  'position': 'absolute',
  'right': '24px',
  'top': '24px',
  'width': '25px',
  'height': '25px',
  'padding': '4px',
  'boxSizing': 'border-box',
  'cursor': 'pointer',
  'opacity': '0.7',
  'transition': 'opacity 100ms',

  '&:hover': {
    opacity: 1,
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const CloseIcon = styled(SVGIcon, {
  width: '17px',
  height: '17px',
});
