import { type SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import type { CSS } from '../design-tokens';
import type { BaseProps } from '../interfaces';
import { AvatarFallback, AvatarImage, AvatarRoot, StyledSVGIcon } from './_Base';

export type AvatarVariant = 'square' | 'rounded' | 'circle';
export type AvatarSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xlarge';
export type AvatarBackground = 'transparent' | 'blue' | 'white' | 'lightGrey';

export const defaultAvatarProps = {
  variant: 'circle' as AvatarVariant,
  size: 'medium' as AvatarSize,
  bordered: false,
  pointer: false,
  backgroundColor: 'white' as AvatarBackground,
};

export interface AvatarProps extends BaseProps {
  /** Image src */
  src?: string;
  /** Responsive image src set */
  srcSet?: string;
  /** Alternative information for image  */
  alt?: string;
  /** Name of svg icon */
  icon?: SVGIconName;
  /** Variant shape of avatar */
  variant?: AvatarVariant;
  /** Size of avatar */
  size?: AvatarSize;
  /** Font size of avatar */
  fontSize?: AvatarSize;
  /** Color of icon */
  iconColor?: 'white';
  /** Style of icon */
  iconStyle?: CSS;
  /** background color */
  backgroundColor?: AvatarBackground;
  /** If there is border of avatar */
  bordered?: boolean;
  /** If avatar cursor to pointer */
  pointer?: boolean;
  /** Avatar fallback string */
  children?: string | null;
  /** Click handler */
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>((props, ref) => {
  const {
    src,
    srcSet,
    alt,
    icon,
    variant = defaultAvatarProps.variant,
    size = defaultAvatarProps.size,
    fontSize,
    pointer = defaultAvatarProps.pointer,
    bordered = defaultAvatarProps.bordered,
    children,
    iconColor,
    iconStyle,
    backgroundColor = defaultAvatarProps.backgroundColor,
    onClick,
    ...restProps
  } = props;

  return (
    <AvatarRoot
      ref={ref}
      variant={variant}
      size={size}
      bordered={bordered}
      pointer={pointer}
      onClick={onClick}
      {...restProps}
    >
      <AvatarImage src={src} srcSet={srcSet} alt={alt} />
      <AvatarFallback
        size={fontSize || size}
        delayMs={600}
        background={backgroundColor}
        color={iconColor}
      >
        {icon ? (
          <StyledSVGIcon name={icon} size={size} color={iconColor} css={iconStyle} />
        ) : (
          children?.slice(0, 2)?.toUpperCase() ?? null
        )}
      </AvatarFallback>
    </AvatarRoot>
  );
});

Avatar.displayName = 'Avatar';
