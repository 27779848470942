import { SVGIcon } from '@paid-ui/icons';

import { Button } from '../button';
import { styled } from '../design-tokens';
import { Flex } from '../flex';

export const PaymentContainer = styled('div', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'cursor': 'pointer',
  'position': 'relative',
  'display': 'flex',
  'flexDirection': 'column',
  'width': '100%',
  'padding': '20px 30px',
  'gap': '14px',
  'borderBottom': '1px solid $colors$fgFaint',
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: '$lightGrey',
  },
});

export const TopGrid = styled('div', {
  'display': 'grid',
  'alignItems': 'center',
  'gap': '6px 12px',
  'width': '100%',
  'gridTemplateAreas': '"stageName stageName" "percentage amount"',
  'gridTemplateColumns': '2fr 1fr',

  '@md': {
    gridTemplateAreas: '"stageName percentage amount"',
    gridTemplateColumns: '1fr 50px 200px',
  },

  'variants': {
    secondary: {
      true: {
        'gridTemplateAreas':
          '"stageName stageName stageName" "percentage percentage amount" "empty linkingButton linkingButton"',
        'gridTemplateColumns': '0 22px 1fr',

        '@md': {
          gridTemplateAreas:
            '"stageName percentage amount amount" "empty linkingButton linkingButton linkingButton"',
          gridTemplateColumns: 'minmax(0, 1fr) 50px 200px 22px',
        },
      },
    },
  },
});

export const StageName = styled('span', {
  gridArea: 'stageName',
  fontSize: '18px',
  fontWeight: '$medium',
  lineHeight: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px',
});

export const Percentage = styled('span', {
  gridArea: 'percentage',
  fontSize: '18px',
  fontWeight: '$normal',
  lineHeight: '25px',
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const PaymentAmount = styled('span', {
  gridArea: 'amount',
  fontSize: '18px',
  fontWeight: '$normal',
  lineHeight: '25px',
  textAlign: 'right',

  variants: {
    empty: {
      true: {
        color: '$darkGrey',
        fontWeight: '$normal',
      },
    },
    bold: {
      true: {
        fontWeight: '$medium',
      },
    },
  },
});

export const Empty = styled('span', {
  gridArea: 'empty',
});

export const LinkingStatus = styled('span', {
  'fontSize': '14px',
  'fontWeight': '$normal',
  'lineHeight': '14px',
  'textAlign': 'right',
  'display': 'none',
  'color': '$darkGrey',
  '&:empty': {
    display: 'none',
  },
  '@sm': {
    display: 'unset',
  },
  'variants': {
    secured: {
      true: {
        color: '$blue',
      },
    },
  },
});

export const LinkingButton = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'gridArea': 'linkingButton',
  'display': 'inline-flex',
  'alignItems': 'center',
  'gap': '8px',
  'color': '$label',
  'padding': '2px',
  '&:empty': {
    display: 'none',
  },
  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const LinkingIcon = styled(SVGIcon, {
  width: '15px',
  height: '15px',
  flexShrink: 0,
  color: '$darkGrey',
  variants: {
    blue: {
      true: {
        color: '$blue',
      },
    },
  },
});

export const BottomGrid = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '6px',
  'width': '100%',

  '@md': {
    gap: '12px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const BottomLeft = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  flexShrink: 0,
  minHeight: '32px',
});

export const BottomRight = styled('div', {
  'display': 'flex',
  'alignItems': 'center',
  'gap': '12px',
  'minHeight': '32px',
  '&:empty': {
    display: 'none',
  },
});

export const ClaimAmount = styled('span', {
  color: '$darkGrey',
  fontSize: '16px',
  fontWeight: '$medium',
  lineHeight: '22px',
});

export const Variation = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  fontWeight: '$normal',
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const VariationIcon = styled(SVGIcon, {
  width: '18px',
  height: '18px',
});

export const VariationCount = styled('span', {
  'fontSize': '18px',
  'display': 'none',
  '@sm': {
    display: 'unset',
  },
});

export const ClaimStatusContainer = styled(Flex, {
  flexDirection: 'unset',
  alignItems: 'center',
  gap: '12px',
  flexShrink: 0,
  width: 'auto',
});

export const ClaimStatusWrap = styled(Flex, {
  'alignItems': 'center',
  'gap': '12px',
  'flexShrink': 0,
  'width': 'auto',
  '@sm': {
    display: 'contents',
  },
});

export const ClaimStatusDot = styled('span', {
  width: '8px',
  height: '8px',
  backgroundColor: '$hoverBg',
  border: '1px solid $colors$darkGrey',
  boxSizing: 'border-box',
  borderRadius: '50%',
  flexShrink: 0,
  variants: {
    color: {
      green: {
        backgroundColor: '$payInGreen',
        border: 'unset',
        boxShadow: '0 0 0 4px rgba(0, 188, 116, 0.25)',
      },
      pink: {
        background: '$pink',
        border: 'unset',
        boxShadow: '0 0 0 4px rgba(255, 0, 115, 0.25)',
      },
      blue: {
        background: '$blue',
        border: 'unset',
        boxShadow: '0 0 0 4px rgba(26, 125, 255, 0.25)',
      },
      white: {
        background: '$bg',
      },
    },
  },
});

export const ClaimStatusText = styled('span', {
  'flexShrink': 0,
  'fontSize': '14px',
  'fontWeight': '$medium',
  'lineHeight': '14px',

  '&:empty': {
    visibility: 'hidden',
  },

  'variants': {
    color: {
      default: {
        color: '$fg',
      },

      primary: {
        color: '$blue',
      },

      success: {
        color: '$payInGreen',
      },

      danger: {
        color: '$pink',
      },
    },
  },

  'defaultVariants': {
    color: 'default',
  },
});
export const OffPlatformText = styled('span', {
  'color': '$darkGrey',
  'flexShrink': 0,
  'fontSize': '14px',
  'fontWeight': '$medium',
  'lineHeight': '21px',

  '&:empty': {
    visibility: 'hidden',
  },
});

export const ArrowIcon = styled(SVGIcon, {
  width: '16px',
  height: '16px',
  color: '$pink',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ActionButton = styled(Button, {
  marginLeft: 'auto',
  flexShrink: 0,
});

export const DateIcon = styled(SVGIcon, {
  size: '24px',
  transform: 'scale(0.6)',
  color: '$darkGrey',
  cursor: 'pointer',
});

export const DescriptionLabel = styled('span', {
  fontSize: '14px',
  color: '$darkGrey',
});

export const DescriptionContainer = styled(Flex, {
  'alignItems': 'center',
  'gap': '4px',
  'flexShrink': 0,
  'marginLeft': 0,
  'width': 'auto',
  '@sm': {
    marginLeft: '6px',
  },
});

export const ClaimActionContainer = styled(Flex, {
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const AutoWidthContainer = styled('div', {
  width: 'auto',
});
