import { formatBSB } from '@paid-ui/utils';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import {
  CheckboxIcon,
  DropIcon,
  PrimaryText,
  SecondaryText,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectPlaceholder,
  SelectRoot,
  SelectTitle,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from './_Base';

export type AccountSelectSize = 'small' | 'large';

export const defaultAccountSelectProps = {
  size: 'large' as AccountSelectSize,
  placeholder: 'Not available',
};

export interface AccountSelectItemProps {
  firstName: string;
  lastName: string;
  bsb: string;
  number: string;
}

export interface AccountSelectProps extends BaseProps {
  label?: string;
  value: string;
  /** Change handler */
  onChange: (value: string) => void;
  placeholder?: string;
  list?: AccountSelectItemProps[];
  /** Size */
  size?: AccountSelectSize;
  /** If select disabled */
  disabled?: boolean;
}

export const AccountSelect = forwardRef<HTMLButtonElement, AccountSelectProps>((props, ref) => {
  const {
    label,
    value,
    size = defaultAccountSelectProps.size,
    disabled,
    list,
    placeholder = defaultAccountSelectProps.placeholder,
    onChange,
    ...restProps
  } = props;

  return (
    <Space size={14} direction="vertical" inline={false}>
      <SelectRoot value={value} onValueChange={onChange}>
        <SelectTitle>{label}</SelectTitle>
        <SelectTrigger name={label} ref={ref} disabled={disabled} {...restProps}>
          {list?.length === 0 ? (
            <SelectPlaceholder>{placeholder}</SelectPlaceholder>
          ) : (
            <>
              <SelectValue />
              <SelectIcon>
                <DropIcon name="drop" />
              </SelectIcon>
            </>
          )}
        </SelectTrigger>
        <SelectContent>
          {list && list.length > 0 ? (
            <SelectViewport>
              {list.map((account) => (
                <SelectItem key={account.number} value={account.number}>
                  <SelectItemText>
                    <PrimaryText>{`${account.firstName} ${account.lastName}`}</PrimaryText>
                    <SecondaryText>
                      ({formatBSB(account.bsb)} {account.number})
                    </SecondaryText>
                  </SelectItemText>
                  <SelectItemIndicator>
                    <CheckboxIcon name="checkbox" />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            </SelectViewport>
          ) : null}
        </SelectContent>
      </SelectRoot>
    </Space>
  );
});

AccountSelect.displayName = 'AccountSelect';
