import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { forwardRef, startTransition, useCallback, useEffect, useMemo, useState } from 'react';

import type { BaseProps, Themes } from '../interfaces';
import { Space } from '../space';
import {
  Arrow,
  ArrowIcon,
  CarouselContainer,
  CarouselItem,
  CarouselItems,
  CarouselViewport,
  Indicator,
  Indicators,
} from './_Base';

export const defaultCarouselProps = {
  theme: 'dark' as Themes,
  autoplay: true,
  showIndicator: true,
  showArrows: true,
  delay: 4000,
};

export interface CarouselProps<T = Record<string, any>> extends BaseProps {
  /** Theme of carousel */
  theme?: Themes;
  /** Autoplay slide or not */
  autoplay?: boolean;
  /** Show indicator or not */
  showIndicator?: boolean;
  /** Show arrow or not */
  showArrows?: boolean;
  /** Delay between transitions in milliseconds */
  delay?: number;
  /** Slide items for carousel */
  items: T[];
  /** Item Render function */
  renderItem: (item: T, index: number) => React.ReactNode;
}

const Carousel = forwardRef(
  <T extends Record<string, any>>(props: CarouselProps<T>, ref: React.Ref<HTMLDivElement>) => {
    const {
      theme = defaultCarouselProps.theme,
      autoplay = defaultCarouselProps.autoplay,
      showIndicator = defaultCarouselProps.showIndicator,
      showArrows: showArrowsProp = defaultCarouselProps.showArrows,
      delay = defaultCarouselProps.delay,
      items,
      renderItem,
      ...restProps
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

    const showArrows = useMemo(() => {
      return showArrowsProp && items.length > 1;
    }, [items.length, showArrowsProp]);

    const [viewportRef, embla] = useEmblaCarousel(
      {
        loop: false,
      },
      autoplay ? [Autoplay({ delay })] : [],
    );

    const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla?.scrollNext(), [embla]);
    const scrollTo = useCallback((index: number) => embla?.scrollTo(index), [embla]);

    const handleSelect = useCallback(() => {
      if (!embla) {
        return;
      }

      startTransition(() => {
        setSelectedIndex(embla.selectedScrollSnap());
        setPrevButtonEnabled(embla.canScrollPrev());
        setNextButtonEnabled(embla.canScrollNext());
      });
    }, [embla, setSelectedIndex]);

    useEffect(() => {
      if (!embla) {
        return;
      }

      handleSelect();
      embla.on('select', handleSelect);
    }, [embla, handleSelect]);

    if (items.length === 0) {
      return null;
    }

    return (
      <CarouselContainer theme={theme} showArrows={showArrows} ref={ref} {...restProps}>
        <Arrow hidden={!showArrows} enabled={prevButtonEnabled} onClick={scrollPrev}>
          <ArrowIcon name="previous-2" theme={theme} />
        </Arrow>

        <Space size={24} direction="vertical" inline={false} fullHeight>
          <CarouselViewport ref={viewportRef}>
            <CarouselItems>
              {items.map((item, index) => (
                <CarouselItem key={index}>{renderItem(item, index)}</CarouselItem>
              ))}
            </CarouselItems>
          </CarouselViewport>
          <Indicators
            hidden={!showIndicator || items.length === 1}
            css={{
              gridTemplateColumns: `repeat(${items.length}, minmax(48px, 1fr))`,
            }}
          >
            {items.map((_, index) => (
              <Indicator
                key={index}
                selected={selectedIndex === index}
                onClick={() => scrollTo(index)}
              />
            ))}
          </Indicators>
        </Space>

        <Arrow hidden={!showArrows} enabled={nextButtonEnabled} onClick={scrollNext}>
          <ArrowIcon name="next-2" theme={theme} />
        </Arrow>
      </CarouselContainer>
    );
  },
);

Carousel.displayName = 'Carousel';

export default Carousel as <T extends Record<string, any>>(
  props: CarouselProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => JSX.Element;
