import { globalCss } from './stitches.config';

export const globalStyles = globalCss({
  '@font-face': {
    fontFamily: 'Emoji',
    src: 'local("Apple Color Emoji"), local("Segoe UI Emoji"), local("Segoe UI Symbol"), local("Noto Color Emoji")',
    unicodeRange: 'U+1F000-1F644, U+203C-3299',
  },

  '*, *::before, *::after': {
    boxSizing: 'border-box',
    borderWidth: 0,
    borderStyle: 'solid',
  },

  '*:focus-visible': {
    outline: 'none',
  },

  'body': {
    margin: 0,
    fontFamily: '$sans',
    color: '$fg',
    backgroundColor: '$bg',
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
  },

  'blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre': {
    margin: 0,
  },

  'h1, h2, h3, h4, h5, h6': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  'ol, ul': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  'img, svg, video, canvas, audio, iframe, embed, object': {
    display: 'block',
    verticalAlign: 'middle',
  },

  'img, video': {
    maxWidth: '100%',
    height: 'auto',
  },

  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
    fontFeatureSettings: 'inherit',
    fontVariationSettings: 'inherit',
    fontSize: '100%',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
    margin: 0,
    padding: 0,
  },

  "button, [type='button'], [type='reset'], [type='submit']": {
    'appearance': 'button',
    '-webkitAppearance': 'button',
  },

  '#__next': {
    'display': 'flex',
    'flexDirection': 'column',
    'minHeight': '100vh',
    'width': '100%',

    '@supports (-webkit-touch-callout: none)': {
      minHeight: '-webkit-fill-available',
    },
  },

  '.gmnoprint.gm-style-cc': {
    display: 'none',
  },

  '.gmnoprint > .gm-style-cc': {
    display: 'none',
  },

  '.gm-style > div > div > a > div > img': {
    display: 'none',
  },

  '.gm-style-cc': {
    display: 'none',
  },
});
