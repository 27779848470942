import { Formik, type FormikHelpers, type FormikProps, type FormikValues } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { forwardRef, useCallback } from 'react';
import * as yup from 'yup';

import { Button } from '../button';
import { Flex } from '../flex';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { Textarea } from '../textarea';
import {
  DialogClose,
  HelpFormClose,
  HelpFormDescription,
  HelpFormFooter,
  HelpFormHeader,
  HelpFormIconButton,
  HelpFormLogo,
  HelpFormMainContent,
  HelpFormSVGIcon,
  HelpFormTitle,
} from './_Base';

export const defaultHelpFormProps = {};

export interface HelpFormProps extends BaseProps {
  /** Reference of form instance */
  formRef?: React.MutableRefObject<FormikProps<FormikValues>>;
  /** If submit button is disabled */
  disabled?: boolean;
  /** If form is submitting */
  submitting?: boolean;
  /** Event handler called when form submit */
  onSubmit?: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => void | Promise<any>;
}

export const HelpForm = forwardRef<HTMLDivElement, HelpFormProps>((props, ref) => {
  const { formRef, disabled, submitting, onSubmit, ...restProps } = props;

  const handleSubmit = useCallback(
    (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
      if (formRef?.current && !isEmpty(formRef.current.errors)) {
        console.log(formRef.current.errors);
        return;
      }

      if (typeof onSubmit === 'function') {
        onSubmit(values, formikHelpers);
      }
    },
    [formRef, onSubmit],
  );

  return (
    <Formik
      ref={ref}
      innerRef={formRef}
      onSubmit={handleSubmit}
      initialValues={{
        content: '',
      }}
      validationSchema={yup.object({
        content: yup.string().required('Please input your question'),
      })}
      {...restProps}
    >
      {(formik) => (
        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
          <HelpFormMainContent size={36} direction="vertical" inline={false}>
            <HelpFormHeader size={20} direction="vertical" inline={false}>
              <HelpFormTitle>Got a question?</HelpFormTitle>
              <HelpFormDescription>Send us a message and we’ll help you out.</HelpFormDescription>
              <HelpFormClose asChild>
                <HelpFormIconButton type="button">
                  <HelpFormSVGIcon name="close" />
                </HelpFormIconButton>
              </HelpFormClose>
            </HelpFormHeader>
            <Textarea
              name="content"
              value={formik.values.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your question here"
              rows={4}
              block
            />
            <HelpFormFooter>
              <Flex align="center" justify="between">
                <HelpFormLogo />
                <Space size={14}>
                  <DialogClose asChild>
                    <Button size="large" height={44} variant="transparent">
                      Cancel
                    </Button>
                  </DialogClose>
                  <Button
                    height={44}
                    size="large"
                    color="primary"
                    type="submit"
                    loading={submitting}
                    disabled={disabled || submitting}
                  >
                    {submitting ? 'Sending...' : 'Send'}
                  </Button>
                </Space>
              </Flex>
            </HelpFormFooter>
          </HelpFormMainContent>
        </form>
      )}
    </Formik>
  );
});

HelpForm.displayName = 'HelpForm';
