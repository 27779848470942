import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { styled } from '../design-tokens';

export const StyledNotificationButton = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'flex',
  'width': '100%',
  'gap': '12px',
  'padding': '10px',
  'cursor': 'pointer',
  'borderRadius': '3px',
  'backgroundColor': '$bg',
  'transition': 'backgroundColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: '$bgGroup',
    },
  },

  'variants': {
    skeleton: {
      true: {
        'cursor': 'default',

        '@hover': {
          '&:hover': {
            backgroundColor: '$bg',
          },
        },
      },
    },

    unread: {
      true: {
        opacity: 1,
      },

      false: {
        opacity: 0.62,
      },
    },
  },

  'defaultVariants': {
    unread: true,
  },
});

export const StyledNotificationMenuItem = styled(DropdownMenuPrimitive.DropdownMenuItem, {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'flex',
  'width': '100%',
  'gap': '12px',
  'padding': '10px',
  'cursor': 'pointer',
  'borderRadius': '3px',
  'backgroundColor': '$bg',
  'transition': 'backgroundColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: '$bgGroup',
    },
  },

  'variants': {
    unread: {
      true: {
        opacity: 1,
      },

      false: {
        opacity: 0.62,
      },
    },
  },

  'defaultVariants': {
    unread: true,
  },
});

export const UnreadIndicator = styled('span', {
  'position': 'relative',
  'flexShrink': 0,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'size': '25px',

  '&::before': {
    borderRadius: '3px',
    content: '',
    size: '6px',
  },

  'variants': {
    enable: {
      true: {
        '&::before': {
          backgroundColor: '$pink',
        },
      },
    },
  },
});

export const NotificationContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

export const NotificationContent = styled('div', {
  'flex': 1,
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '4px',
  'color': '$fg',

  '@sm': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const NotificationTitle = styled('div', {
  'fontSize': '18px',
  'fontWeight': '$bold',
  'lineHeight': '25px',

  '&:empty': {
    display: 'none',
  },
});

export const NotificationParagraph = styled('div', {
  'fontSize': '14px',
  'fontWeight': '$normal',
  'lineHeight': '21px',

  '&:empty': {
    display: 'none',
  },
});

export const NotificationTimestamp = styled('span', {
  'flexShrink': 0,
  'color': '$fgSecondary',
  'fontSize': '14px',
  'fontWeight': '$normal',
  'lineHeight': '21px',

  '&:empty': {
    display: 'none',
  },
});
