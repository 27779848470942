import { createStitches, type CSS as BaseCSS, type PropertyValue } from '@stitches/react';

import { breakpoints } from './breakpoints';

export type { VariantProps } from '@stitches/react';

export const { config, createTheme, css, getCssText, globalCss, keyframes, styled, theme } =
  createStitches({
    theme: {
      colors: {
        bg: '#ffffff', // Default background color
        bgGroup: '#f4f6f9', // Group background color
        bgBlue: '#ebf4ff', // Blue background color
        bgOverlay: 'rgb(0 16 38 / 45%)', // Overlay background color

        fg: '#001026', // Default foreground color
        fgSecondary: '#66707d', // Secondary foreground color
        fgTertiary: '#a6acb3', // Tertiary foreground color
        fgFaint: '#dadfe5', // Faint foreground color

        label: '#001026',

        blue: '#1a7dff', // Brand blue color,
        green: '#00db8f', // Brand green color
        pink: '#ff0073', // Brand pink color
        cyan: '#00f3ff', // Brand cyan color

        greyBlue: '#3C519E',

        hoverBg: '#f2f2f2', // Hover status of bg
        hoverBlue: '#0561da', // Hover status of blue
        hoverPink: '#e80068', // Hover status of pink

        lightBlue: '#76b1ff', // Light blue color
        darkBlue: '#334051', // Dark blue color
        lightGrey: '#f9f9f9', // Light grey
        midGrey: '#e0e0e0', // Mid grey
        tertiaryGrey: '#a6acb3', // Tertiary grey
        darkGrey: '#5a636e', // Dark grey
        greyHover: '#f2f2f2', // Grey hover
        payInGreen: '#00bc74', // Pay in green
        darkGreen: '#00bc74', // Dark green
        lightTint: '#ebf4ff',
      },
      fonts: {
        sans: 'Barlow, system-ui, Segoe UI, Rototo, Emoji, Helvetica, Arial, sans-serif',
        serif: 'Georgia, Cambria, "Times New Roman", Times, serif',
        mono: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
      },
      fontWeights: {
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
      },
      radii: {
        sm: '4px',
        md: '6px',
        lg: '8px',
        xl: '16px',
        panel: '0 16px 16px',
        round: '50%',
        pill: '9999px',
      },
      shadows: {
        sm: '0 5px 10px rgb(0 0 0 / 12%)',
        md: '0 8px 30px rgb(0 0 0 / 12%)',
        lg: '0 30px 60px rgb(0 0 0 / 12%)',
        hover: '0 30px 60px rgb(0 0 0 / 12%)',
        sticky: '0 12px 10px -10px rgb(0 0 0 / 12%)',
        button: '0 2px 0 rgb(5 145 255 / 10%)',
        kbd: 'inset 0 0.5px rgb(255 255 255 / 10%), inset 0 1px 5px #f8f9fa, 0 0 0 0.5px #c1c8cd, 0 2px 1px -1px #c1c8cd, 0 1px #c1c8cd',
      },
      zIndices: {
        tabs: 50,
        navbar: 100,
        drawer: 200,
        overlay: 300,
        dialog: 400,
        overlay2: 500,
        dialog2: 600,
        dropdown: 700,
        tooltip: 999,
        toast: 999,
      },
    },

    media: {
      xs: `(min-width: ${breakpoints.xs}px)`,
      sm: `(min-width: ${breakpoints.sm}px)`,
      md: `(min-width: ${breakpoints.md}px)`,
      lg: `(min-width: ${breakpoints.lg}px)`,
      xl: `(min-width: ${breakpoints.xl}px)`,
      hover: '(any-hover: hover)',
      motion: '(prefers-reduced-motion)',
      dark: '(prefers-color-scheme: dark)',
      light: '(prefers-color-scheme: light)',
    },

    utils: {
      p: (value: PropertyValue<'padding'>) => ({
        padding: value,
      }),
      pt: (value: PropertyValue<'paddingTop'>) => ({
        paddingTop: value,
      }),
      pr: (value: PropertyValue<'paddingRight'>) => ({
        paddingRight: value,
      }),
      pb: (value: PropertyValue<'paddingBottom'>) => ({
        paddingBottom: value,
      }),
      pl: (value: PropertyValue<'paddingLeft'>) => ({
        paddingLeft: value,
      }),
      px: (value: PropertyValue<'paddingLeft'>) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: PropertyValue<'paddingTop'>) => ({
        paddingTop: value,
        paddingBottom: value,
      }),

      m: (value: PropertyValue<'margin'>) => ({
        margin: value,
      }),
      mt: (value: PropertyValue<'marginTop'>) => ({
        marginTop: value,
      }),
      mr: (value: PropertyValue<'marginRight'>) => ({
        marginRight: value,
      }),
      mb: (value: PropertyValue<'marginBottom'>) => ({
        marginBottom: value,
      }),
      ml: (value: PropertyValue<'marginLeft'>) => ({
        marginLeft: value,
      }),
      mx: (value: PropertyValue<'marginLeft'>) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: PropertyValue<'marginTop'>) => ({
        marginTop: value,
        marginBottom: value,
      }),

      size: (value: PropertyValue<'width'>) => ({
        width: value,
        height: value,
      }),

      ellipsis: (value: PropertyValue<'width'>) => ({
        width: value,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }),

      radius: (value: PropertyValue<'borderRadius'>) => ({
        borderTopLeftRadius: 0,
        borderTopRightRadius: value,
        borderBottomLeftRadius: value,
        borderBottomRightRadius: value,
      }),

      lineClamp: (value: PropertyValue<'lineClamp'>) => ({
        display: '-webkit-box',
        webkitLineClamp: value,
        webkitBoxOrient: 'vertical',
        overflow: 'hidden',
        whiteSpace: 'initial',
        wordBreak: 'break-word',
      }),
    },
  });

export type CSS = BaseCSS<typeof config>;
