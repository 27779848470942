import { blue } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledLoadingIndicator = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  color: '$blue',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },

      column: {
        flexDirection: 'column',
      },
    },

    size: {
      small: {
        gap: '8px',
        fontSize: '16px',
      },

      medium: {
        gap: '12px',
        fontSize: '18px',
      },

      large: {
        gap: '16px',
        fontSize: '20px',
      },
    },
  },

  defaultVariants: {
    direction: 'row',
    size: 'medium',
  },
});

export const StyledLoadingIndicatorTitle = styled('span', {
  'fontWeight': '$medium',

  '@dark': {
    color: blue.blue6,
  },

  '&:empty': {
    display: 'none',
  },

  'variants': {
    size: {
      xs: {
        fontSize: '14px',
      },
      sm: {
        fontSize: '16px',
      },
      md: {
        fontSize: '24px',
      },
      lg: {
        fontSize: '32px',
      },
      xl: {
        fontSize: '48px',
      },
    },
  },

  'defaultVariants': {
    size: 'medium',
  },
});
