export const banks = {
  'Australia & New Zealand Banking Group': 'Australia & New Zealand Banking Group (ANZ)',
  'Commonwealth Bank': 'Commonwealth Bank (CBA)',
  'National Australia Bank': 'National Australia Bank (NAB)',
  'Westpac': 'Westpac',
  'AMP Limited': 'AMP Limited',
  'Australian Military Bank': 'Australian Military Bank',
  'Australian Mutual Bank': 'Australian Mutual Bank',
  'Australian Settlements Limited': 'Australian Settlements Limited',
  'Auswide Bank': 'Auswide Bank',
  'Bank Australia': 'Bank Australia',
  'Bank First': 'Bank First',
  'Bank of Melbourne': 'Bank of Melbourne',
  'Bank of Queensland': 'Bank of Queensland',
  'BankSA': 'BankSA',
  'BankVic': 'BankVic',
  'Bankwest': 'Bankwest',
  'Bendigo and Adelaide Bank': 'Bendigo and Adelaide Bank',
  'Beyond Bank Australia': 'Beyond Bank Australia',
  'Clean Energy Finance Corporation': 'Clean Energy Finance Corporation',
  'Defence Bank': 'Defence Bank',
  'Delphi Bank': 'Delphi Bank',
  'Gateway Bank': 'Gateway Bank',
  'G&C Mutual Bank': 'G&C Mutual Bank',
  'Greater Bank': 'Greater Bank',
  'Heritage Bank': 'Heritage Bank',
  'Hume Bank': 'Hume Bank',
  'IMB Bank': 'IMB Bank',
  'Macquarie Group': 'Macquarie Group',
  'ME Bank': 'ME Bank',
  'MyLife MyFinance': 'MyLife MyFinance',
  'MyState Limited': 'MyState Limited',
  'Newcastle Permanent Building Society': 'Newcastle Permanent Building Society',
  'P&N Bank': 'P&N Bank',
  'Police Bank': 'Police Bank',
  'QBank': 'QBank',
  'Qudos Bank': 'Qudos Bank',
  'RACQ Bank': 'RACQ Bank',
  'Regional Australia Bank': 'Regional Australia Bank',
  'Rural Bank': 'Rural Bank',
  'St.George Bank': 'St.George Bank',
  'Suncorp Bank': 'Suncorp Bank',
  'Teachers Mutual Bank': 'Teachers Mutual Bank',
  'Tyro Payments': 'Tyro Payments',
  'UBank': 'UBank',
  'Unity Bank': 'Unity Bank',
  'Up Money': 'Up Money',
  'Volt Bank': 'Volt Bank',
} as const;
