import { slate } from '@radix-ui/colors';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import {
  css,
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
  styled,
} from '../design-tokens';

export const DropdownMenuRoot = DropdownMenuPrimitive.Root;

export const DropdownMenuContent = styled(DropdownMenuPrimitive.Content, {
  'minWidth': '180px',
  'backgroundColor': '$bg',
  'borderRadius': '6px',
  'maxWidth': '540px',
  'maxHeight': 'calc(100vh - 74px)',
  'boxShadow': '0px 10px 38px -10px rgb(22 23 24 / 35%), 0px 10px 20px -15px rgb(22 23 24 / 20%)',
  'animationDuration': '400ms',
  'animationTimingFunction': 'cubic-bezier(0.16, 1, 0.3, 1)',
  'animationFillMode': 'forwards',
  'willChange': 'transform, opacity',
  'overflow': 'hidden',

  '&[data-state="open"]': {
    '&[data-side="top"]': {
      animationName: slideDownAndFade,
    },

    '&[data-side="right"]': {
      animationName: slideLeftAndFade,
    },

    '&[data-side="bottom"]': {
      animationName: slideUpAndFade,
    },

    '&[data-side="left"]': {
      animationName: slideRightAndFade,
    },
  },

  '@motion': {
    animation: 'none',
    willChange: 'unset',
  },
});

export const DropdownMenuTrigger = styled(DropdownMenuPrimitive.Trigger, {});

export const dropdownMenuItemStyles = css({
  'all': 'unset',
  'position': 'relative',
  'display': 'flex',
  'alignItems': 'center',
  'fontSize': '16px',
  'lineHeight': '22px',
  'padding': '0 20px',
  'color': '$fg',
  'backgroundColor': 'transparent',
  'userSelect': 'none',
  'cursor': 'default',
  'transition': 'all 100ms ease',

  '&:focus': {
    color: '$blue',
    backgroundColor: '$bgGroup',
  },

  '@hover': {
    '&:hover': {
      color: '$blue',
      backgroundColor: '$bgGroup',
    },
  },

  'variants': {
    size: {
      sm: {
        height: '44px',
      },
      md: {
        height: '54px',
      },
      lg: {
        height: '64px',
      },
    },
    danger: {
      true: {
        'color': '$pink',
        '&:focus': {
          color: '$pink',
        },
        '@hover': {
          '&:hover': {
            color: '$pink',
          },
        },
      },
    },
  },
});

export const DropdownMenuLink = styled('a', dropdownMenuItemStyles, {
  variants: {
    disabled: {
      true: {
        color: '$fgTertiary',
        pointerEvents: 'none',
      },
    },
  },
});

export const DropdownMenuItem = styled(DropdownMenuPrimitive.Item, dropdownMenuItemStyles, {
  '&[data-disabled]': {
    color: '$fgTertiary',
    pointerEvents: 'none',
  },
  'variants': {
    readonly: {
      true: {
        'color': '$fgTertiary',
        '&:hover': {
          color: '$fgTertiary',
          backgroundColor: '$bgGroup',
        },
      },
    },
  },
});

export const DropdownMenuSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$fgFaint',
});

export const DropdownMenuArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white',
});

export const RightSlot = styled('div', {
  'marginLeft': 'auto',
  'paddingLeft': '16px',
  'color': '$fgTertiary',

  ':focus > &': {
    color: 'white',
  },

  '[data-disabled] &': {
    color: '$fgTertiary',
  },

  '&:empty': {
    display: 'none',
  },
});
