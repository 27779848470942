import React, { forwardRef } from 'react';

import { type CSS } from '../design-tokens';
import type { BaseProps } from '../interfaces';
import { Skeleton } from '../skeleton';
import { Space } from '../space';
import { Container, Item, ItemLabel, ItemValue, SpaceItem, Subtitle } from './_Base';

export const defaultOverviewProps = {
  level: '1' as '0' | '1' | '2',
  data: [] as OverviewItem[],
  responsive: true,
};

export type OverviewItemAlign = 'left' | 'right';

export type OverviewItem = {
  label?: React.ReactNode;
  value?: React.ReactNode;
  align?: OverviewItemAlign;
  bold?: boolean;
  hidden?: boolean;
  extra?: React.ReactNode;
};

export interface OverviewProps extends BaseProps {
  label?: React.ReactNode;
  level?: '0' | '1' | '2';
  data?: OverviewItem[];
  align?: OverviewItemAlign;
  compact?: boolean;
  responsive?: boolean;
  hidden?: boolean;
  loading?: boolean;
  noAutoColon?: boolean;
  longLabel?: boolean;
  labelStyle?: CSS;
  containerStyle?: CSS;
  itemStyle?: CSS;
  itemCompact?: boolean;
  labelWidth?: number;
}

export const Overview = forwardRef<HTMLDivElement, OverviewProps>((props, ref) => {
  const {
    align,
    level = defaultOverviewProps.level,
    label,
    data = defaultOverviewProps.data,
    compact,
    responsive = defaultOverviewProps.responsive,
    hidden,
    loading,
    noAutoColon,
    longLabel,
    labelStyle,
    containerStyle,
    itemStyle,
    itemCompact,
    labelWidth,
    ...restProps
  } = props;

  if (loading) {
    return (
      <Space ref={ref} size={10} direction="vertical" inline={false} {...restProps}>
        <Subtitle level={level}>{label}</Subtitle>
        <Container compact={compact}>
          {data
            .filter((item) => !item.hidden)
            .map((item, index) => (
              <SpaceItem
                key={index}
                size={2}
                bordered={!compact}
                inline={false}
                direction="horizontal"
              >
                <Item responsive={responsive}>
                  <ItemLabel
                    bold={!compact}
                    responsive={responsive}
                    css={{
                      ...labelStyle,
                      minWidth: labelWidth ? `${labelWidth}px` : 'unset',
                    }}
                  >
                    {item.label}
                  </ItemLabel>
                  <Skeleton height={22} />
                </Item>
              </SpaceItem>
            ))}
        </Container>
      </Space>
    );
  }

  if (hidden || data.length === 0) {
    return null;
  }

  return (
    <Space ref={ref} size={10} direction="vertical" inline={false} {...restProps}>
      {typeof label === 'string' ? <Subtitle level={level}>{label}</Subtitle> : label}
      <Container compact={compact} css={containerStyle}>
        {data
          .filter((item) => !item.hidden)
          .map((item, index) => (
            <SpaceItem
              size={2}
              bordered={!compact}
              inline={false}
              direction="vertical"
              key={index}
              compact={itemCompact}
              css={itemStyle}
            >
              <Item responsive={responsive}>
                <ItemLabel
                  bold={!compact && item.bold !== false}
                  noAutoColon={noAutoColon}
                  responsive={responsive}
                  longLabel={longLabel}
                  css={{
                    ...labelStyle,
                    minWidth: labelWidth ? `${labelWidth}px` : 'unset',
                  }}
                  hidden={!item.label}
                >
                  {item.label}
                </ItemLabel>
                <ItemValue
                  align={align ?? item.align}
                  bold={item.bold || compact}
                  longLabel={longLabel}
                >
                  {item.value ?? '-'}
                </ItemValue>
              </Item>
              {item.extra}
            </SpaceItem>
          ))}
      </Container>
    </Space>
  );
});

Overview.displayName = 'Overview';
