import { Combobox } from '@headlessui/react';
import { CountryCode, defaultAddress } from '@paid-ui/constants';
import { appConfigManager } from '@paid-ui/models/app-config';
import type { Address } from '@paid-ui/types';
import { formatAddress } from '@paid-ui/utils';
import { slate } from '@radix-ui/colors';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import clsx from 'clsx';
import type { FormikErrors, FormikTouched } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useSnapshot } from 'valtio/react';

import { AddressInput } from '../address-input';
import { styled } from '../design-tokens';
import { FormControl } from '../form';
import { HelperText } from '../help-text';
import { Input } from '../input';
import type { BaseProps, FontSize } from '../interfaces';
import { useToast } from '../toast';
import { decodeAddress } from '../utils';
import { CheckIcon, CheckIconContainer, ComboboxOption, StyledSVGIcon } from './_Base';
import { GoogleMapsMarkerWidget } from './GoogleMapsMarkerWidget';

const MANUAL_ADDRESS_VALUE = 'Use address as entered';

export const defaultPlaceAutocompleteProps = {
  country: CountryCode.AU,
  preview: true,
};

export const MapFallback = styled(AspectRatio.Root, {
  width: '100%',
  borderRadius: '4px',
  color: slate.slate10,
  backgroundColor: slate.slate6,
  fontWeight: '$semibold',
  fontSize: '24px',
});

export interface PlaceAutocompleteProps extends BaseProps {
  name: string;
  value?: Address;
  country?: CountryCode;

  label?: React.ReactNode;
  placeholder?: string;
  touched?: boolean | FormikTouched<Address>;
  helperText?: React.ReactNode;
  errorMessage?: string | FormikErrors<Address>;
  errorMessagePlaceholder?: string;
  required?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  preview?: boolean;
  size?: FontSize;
  maxLength?: number;
  onChange?: (value?: Address) => void;
  onBlur?: (value?: Address) => void;
  onSelect?: (value?: Address) => void;
  onClear?: () => void;
}

export const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = (props) => {
  const {
    name,
    value,
    country = defaultPlaceAutocompleteProps.country,
    size,
    label,
    placeholder,
    helperText,
    touched,
    errorMessage,
    errorMessagePlaceholder,
    maxLength,

    required,
    readOnly,
    autoFocus,
    preview = defaultPlaceAutocompleteProps.preview,

    onChange,
    onBlur,
    onSelect,
    onClear,

    className,
    ...restProps
  } = props;

  const toast = useToast();
  const [showGoogleMaps, setShowGoogleMaps] = useState(false);
  const { mapId } = useSnapshot(appConfigManager);

  const hasError = useMemo(() => {
    return touched && !isEmpty(touched) && !!errorMessage;
  }, [errorMessage, touched]);

  const {
    ready,
    value: inputValue,
    setValue,
    suggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    defaultValue: formatAddress(value ?? defaultAddress),
    callbackName: 'initMap',
    requestOptions: {
      componentRestrictions: {
        country,
      },
    },
  });

  const getErrorMessage = useCallback(() => {
    if (errorMessagePlaceholder) {
      return errorMessagePlaceholder;
    }

    if (typeof errorMessage === 'string' || errorMessage === undefined) {
      return errorMessage;
    }

    if (isEmpty(errorMessage)) {
      return;
    }

    return Object.values(errorMessage)[0];
  }, [errorMessage, errorMessagePlaceholder]);

  const handlePlaceSearch = useCallback(
    (event: any) => {
      if (event.target.value) {
        setValue(event.target.value);
      } else {
        setValue('');

        if (preview) {
          setShowGoogleMaps(false);
        }

        if (typeof onChange === 'function') {
          onChange(defaultAddress);
        }

        if (typeof onClear === 'function') {
          onClear();
        }
      }
    },
    [onChange, onClear, preview, setValue],
  );

  const handlePlaceSelect = useCallback(
    async (address: string) => {
      if (address === MANUAL_ADDRESS_VALUE && typeof onChange === 'function') {
        let unitNumber = '';
        let streetNumber = '';
        let streetName = '';

        if (inputValue) {
          const matches = /^(?<streetNumber>[\d\s,-]+)?\s?(?<streetName>.+)?$/.exec(inputValue);

          if (matches?.groups) {
            const unitAndStreetNumber = matches.groups.streetNumber?.trim()?.split(/[\s,-]/);

            if (unitAndStreetNumber && unitAndStreetNumber.length > 1) {
              unitNumber = unitAndStreetNumber[0] ?? '';
              streetNumber = unitAndStreetNumber[1] ?? '';
            } else {
              streetNumber = matches.groups.streetNumber?.trim() ?? '';
            }

            streetName = matches.groups.streetName?.trim() ?? '';
          }
        }

        const oldValue = {
          ...defaultAddress,
        };

        if (unitNumber) {
          oldValue.unitNumber = unitNumber;
        }

        if (streetNumber) {
          oldValue.streetNumber = streetNumber;
        }

        if (streetName) {
          oldValue.streetName = streetName;
        }

        onChange({
          ...oldValue,
          manual: true,
        });

        return;
      }

      setValue(address);

      if (preview) {
        setShowGoogleMaps(true);
      }

      try {
        const decodedAddress = await decodeAddress(address);

        if (typeof onChange === 'function') {
          onChange(decodedAddress);
        }

        if (typeof onSelect === 'function') {
          onSelect(decodedAddress);
        }
      } catch (error) {
        toast.error(error instanceof Error ? error.message : 'Decode address failed.');
      }
    },
    [inputValue, onChange, onSelect, preview, setValue, toast],
  );

  const handleBlur = useCallback(
    (result: any) => {
      if (typeof onBlur === 'function') {
        onBlur(result);
      }
    },
    [onBlur],
  );

  const getClassName = useCallback(
    (cls: string) => {
      return size ? `${cls} ${cls}_${size}` : cls;
    },
    [size],
  );

  useEffect(() => {
    if (value?.manual || !window.google) {
      return;
    }

    if (value && value.country && value.state && value.suburb && value.streetName) {
      setValue(formatAddress(value));

      if (preview) {
        setShowGoogleMaps(true);
      }

      if (typeof onSelect === 'function') {
        onSelect(value);
      }
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (value?.manual || !window.google) {
    return (
      <AddressInput
        name={name}
        label={label}
        maxLength={maxLength}
        autoFocus={autoFocus}
        readOnly={readOnly}
        required={required}
        helperText={helperText}
        value={value}
        touched={touched}
        errorMessage={typeof errorMessage === 'string' ? undefined : errorMessage}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        {...restProps}
      />
    );
  }

  return (
    <ErrorBoundary
      fallbackRender={() => (
        <AddressInput
          name={name}
          label={label}
          autoFocus={autoFocus}
          readOnly={readOnly}
          required={required}
          helperText={helperText}
          value={value}
          touched={touched}
          errorMessage={typeof errorMessage === 'string' ? undefined : errorMessage}
          onChange={onChange}
          onBlur={onBlur}
          className={className}
          {...restProps}
        />
      )}
    >
      <Combobox
        as="div"
        value={inputValue}
        onChange={handlePlaceSelect}
        className={clsx('place-autocomplete', className)}
        {...restProps}
      >
        {label ? (
          <Combobox.Label
            htmlFor={name}
            className={clsx('place-autocomplete__label', {
              'place-autocomplete__label--required': required,
            })}
          >
            {label}
          </Combobox.Label>
        ) : null}
        <FormControl>
          <button
            type="button"
            tabIndex={-1}
            className={getClassName('place-autocomplete__location')}
          >
            <StyledSVGIcon name="map-pin" />
          </button>

          <Combobox.Input
            id={name}
            name={name}
            type="text"
            autoFocus={autoFocus}
            readOnly={readOnly}
            disabled={!ready}
            placeholder={placeholder}
            value={inputValue}
            onChange={handlePlaceSearch}
            onBlur={handleBlur}
            aria-invalid={hasError ? 'true' : 'false'}
            aria-describedby={hasError ? `${name}-error` : `${name}-description`}
            className={getClassName('place-autocomplete__input')}
            autoComplete="off"
          />

          <Input
            size={size}
            type="hidden"
            name={`${name}.unitNumber`}
            value={value?.streetName ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.streetNumber`}
            value={value?.streetNumber ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.streetName`}
            value={value?.streetName ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.suburb`}
            value={value?.suburb ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.postcode`}
            value={value?.postcode ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.state`}
            value={value?.state ?? ''}
            readOnly
          />
          <Input
            size={size}
            type="hidden"
            name={`${name}.country`}
            value={value?.country ?? 'Australia'}
            readOnly
          />

          <Combobox.Button tabIndex={-1} className={getClassName('place-autocomplete__drop')}>
            <StyledSVGIcon name="drop" />
          </Combobox.Button>

          {['OK', 'ZERO_RESULTS'].includes(suggestions.status) ? (
            <Combobox.Options className="place-autocomplete__options">
              {suggestions.data.map((suggestion) => (
                <Combobox.Option
                  key={suggestion.place_id}
                  value={suggestion.description}
                  className={({ active }) =>
                    clsx(
                      'place-autocomplete__option',
                      active ? 'place-autocomplete__option--active' : '',
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <ComboboxOption selected={selected}>{suggestion.description}</ComboboxOption>
                      <CheckIconContainer active={active} selected={selected}>
                        <CheckIcon name="drop" />
                      </CheckIconContainer>
                    </>
                  )}
                </Combobox.Option>
              ))}
              <Combobox.Option
                key="manual address"
                value={MANUAL_ADDRESS_VALUE}
                className={({ active }) =>
                  clsx(
                    'place-autocomplete__option',
                    active ? 'place-autocomplete__option--active' : '',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <ComboboxOption selected>{MANUAL_ADDRESS_VALUE}</ComboboxOption>
                    <CheckIconContainer active={active} selected={selected}>
                      <CheckIcon name="drop" />
                    </CheckIconContainer>
                  </>
                )}
              </Combobox.Option>
            </Combobox.Options>
          ) : null}
        </FormControl>

        {preview && showGoogleMaps && !value?.manual ? (
          <GoogleMapsMarkerWidget address={value} mapId={mapId} />
        ) : null}

        <HelperText
          error={hasError}
          id={hasError ? `${name}-error` : `${name}-description`}
          size={size}
        >
          {hasError ? getErrorMessage() : helperText}
        </HelperText>
      </Combobox>
    </ErrorBoundary>
  );
};

PlaceAutocomplete.displayName = 'PlaceAutocomplete';
