import { type ProductReferenceCode, type ProductType } from '@paid-ui/enums/product';
import { type TaxableAmount } from '@paid-ui/types/work-items';
import { proxy } from 'valtio';

export const whatsIncluded = [
  'Unlimited digital variations',
  'Unlimited digital claims',
  'Financial security',
  'Priority support',
  'Cashflow funding',
  'Automated admin',
];

export type Product = {
  id: string;
  type: ProductType;
  code: string;
  referenceCode: ProductReferenceCode;
  name: string;
  description: string;
  taxablePrice: TaxableAmount;
};

const initialState = {
  current: null as ProductReferenceCode | null,
  products: [] as Product[],
};

export const productManager = proxy(initialState);

export const resetProductManager = () => {
  Object.assign(productManager, initialState);
};

export const setCurrentProduct = (value?: ProductReferenceCode | null) => {
  productManager.current = value ?? null;
};

export const saveProducts = (value: Product[]) => {
  productManager.products = value;
};
