import { BusinessType } from '@paid-ui/enums/business';
import { ContractRole, ContractTemplate } from '@paid-ui/enums/contract';

export type ContractFormStep = HeadContractStep;

export enum ContractCreateMethod {
  NEW_BUILDING = 'NEW_BUILDING',
  ONBOARD_SIGNED = 'ONBOARD_SIGNED',
}

export enum ContractType {
  HEAD_CONTRACT = 'HEAD_CONTRACT',
  SUBCONTRACT = 'SUBCONTRACT',
  SUPPLY_CONTRACT = 'SUPPLY_CONTRACT',
}

export enum ContractInsuranceType {
  CONTRACT_BUILDING_INSURANCE = 'CONTRACT_BUILDING_INSURANCE',
  DEPOSIT_BUILDING_INSURANCE = 'DEPOSIT_BUILDING_INSURANCE',
}

/**
 * See details
 * https://paid-inc.atlassian.net/wiki/spaces/AD/pages/16711756/Contract
 */
export enum ContractState {
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
  EXECUTED = 'EXECUTED',
  ABANDONED = 'ABANDONED',
  ALL_PAID = 'ALL_PAID',
  TERMINATED = 'TERMINATED',
}

export enum BaseOnCompletionOf {
  THIS_CONTRACT = 'THIS_CONTRACT',
  PRIME_CONTRACT = 'PRIME_CONTRACT',
}

export enum InsurancePolicyType {
  JOB_SPECIFIC = 'JOB_SPECIFIC',
  ANNUAL_WARRANTY = 'ANNUAL_WARRANTY',
}

export const insurancePolicyTypes = {
  [InsurancePolicyType.JOB_SPECIFIC]: 'Job specific',
  [InsurancePolicyType.ANNUAL_WARRANTY]: 'Annual warranty',
};

export enum HeadContractStep {
  CREATE_METHOD,
  TYPE_SCOPE,
  DEMOLITION_INCLUSIONS,
  PROJECT_LOCATION,
  YOUR_PARTY,
  OTHER_PARTY,
  PAYMENTS,
  TERMS_MARGINS_DAMAGES,
  CONSTRUCTION_PERIOD,
  RATES_INSURANCE_FINANCE,
  REUSED_MATERIALS,
  SERVICES,
  PERMITS_AND_APPROVALS,
  PRIME_COST_ITEMS,
  PROVISIONAL_SUM_WORKS,
  BUILDER_SUPPLIED_SECOND_HAND_MATERIALS,
  OWNER_SUPPLIED_MATERIALS,
  WORKS_UNDERTAKEN_BY_OWNER,
  DOCUMENTED_ITEMS,
  SPECIAL_CONDITIONS,
  SPECIFICATIONS,
  DESIGN_DRAWINGS,
  ENGINEERING_DRAWINGS,
  FOUNDATION_REPORTS,
  ATTACHMENTS_NOTES,
  REVIEW,
}

export enum SubContractStep {
  WORKS,
  YOU,
  OTHER_PARTY,
  PROGRESS_CLAIM_SCHEDULE,
  CONSTRUCTION_PERIOD,
  LIABILITY,
  ATTACHMENTS_NOTES,
  REVIEW,
}

export enum SupplyContractStep {
  WORKS,
  YOU,
  OTHER_PARTY,
  PROGRESS_CLAIM_SCHEDULE,
  CONSTRUCTION_PERIOD,
  LIABILITY,
  ATTACHMENTS_NOTES,
  REVIEW,
}

export enum OnboardSignedContractStep {
  CREATE_METHOD,
  PROJECT_LOCATION,
  UPLOAD_CONTRACT,
  YOUR_PARTY,
  OTHER_PARTY,
  PAYMENTS,
  VARIATIONS,
  TERMS_MARGINS_DAMAGES,
  CONSTRUCTION_PERIOD,
  REVIEW,
}

export const contractTemplates = {
  [ContractTemplate.RBN]: 'Victorian Domestic Building Contract',
  [ContractTemplate.RBN_HIBC]: 'Victorian Home Improvements Building Contract',
  [ContractTemplate.QBCC]: 'Queensland Building and Construction Contract',
  [ContractTemplate.SUBCONTRACT]: 'Construction Subcontract',
  [ContractTemplate.SUPPLY_CONTRACT]: 'Construction Supply Contract',
  [ContractTemplate.OFF_PLATFORM]: 'Off Platform Construction Contract',
  [ContractTemplate.OFF_PLATFORM_SUBCONTRACT]: 'Off Platform Construction Subcontract',
  [ContractTemplate.OFF_PLATFORM_SUPPLY_CONTRACT]: 'Off Platform Construction Supply Contract',
  [ContractTemplate.SUPER_CONTRACT]: 'Super Contract',
};

export const contractTypes = {
  [ContractType.HEAD_CONTRACT]: 'Building / Head contract',
  [ContractType.SUBCONTRACT]: 'Subcontract',
  [ContractType.SUPPLY_CONTRACT]: 'Supply contract',
};

export type ContractStep = HeadContractStep | SubContractStep | SupplyContractStep;

export interface ContractFormStepper {
  index: ContractStep;
  title: string;
  description?: string;
  included: boolean;
}

const headTitle = 'Create building / head contract';

export const headContractSteps: ContractFormStepper[] = [
  {
    index: HeadContractStep.CREATE_METHOD,
    title: headTitle,
    description: 'New or existing signed contract',
    included: true,
  },
  {
    index: HeadContractStep.TYPE_SCOPE,
    title: headTitle,
    description: 'Template and scope',
    included: true,
  },
  {
    index: HeadContractStep.DEMOLITION_INCLUSIONS,
    title: headTitle,
    description: 'Demolition and inclusions',
    included: true,
  },
  {
    index: HeadContractStep.PROJECT_LOCATION,
    title: headTitle,
    description: 'Project address',
    included: true,
  },
  {
    index: HeadContractStep.YOUR_PARTY,
    title: headTitle,
    description: 'Your party',
    included: true,
  },
  {
    index: HeadContractStep.OTHER_PARTY,
    title: headTitle,
    description: 'Other party',
    included: true,
  },
  {
    index: HeadContractStep.PAYMENTS,
    title: headTitle,
    description: 'Progress payments',
    included: true,
  },
  {
    index: HeadContractStep.TERMS_MARGINS_DAMAGES,
    title: headTitle,
    description: 'Terms, margins and damages',
    included: true,
  },
  {
    index: HeadContractStep.CONSTRUCTION_PERIOD,
    title: headTitle,
    description: 'Construction period',
    included: true,
  },
  {
    index: HeadContractStep.RATES_INSURANCE_FINANCE,
    title: headTitle,
    description: 'Insurance',
    included: true,
  },
  {
    index: HeadContractStep.REUSED_MATERIALS,
    title: headTitle,
    description: 'Re-used materials',
    included: false,
  },
  {
    index: HeadContractStep.SERVICES,
    title: headTitle,
    description: 'Services',
    included: true,
  },
  {
    index: HeadContractStep.PERMITS_AND_APPROVALS,
    title: headTitle,
    description: 'Permits and approvals',
    included: true,
  },
  {
    index: HeadContractStep.PRIME_COST_ITEMS,
    title: headTitle,
    description: 'Prime cost items',
    included: false,
  },
  {
    index: HeadContractStep.PROVISIONAL_SUM_WORKS,
    title: headTitle,
    description: 'Provisional sum works',
    included: false,
  },
  {
    index: HeadContractStep.BUILDER_SUPPLIED_SECOND_HAND_MATERIALS,
    title: headTitle,
    description: 'Builder supplied second hand materials',
    included: false,
  },
  {
    index: HeadContractStep.OWNER_SUPPLIED_MATERIALS,
    title: headTitle,
    description: 'Property owner supplied materials',
    included: false,
  },
  {
    index: HeadContractStep.WORKS_UNDERTAKEN_BY_OWNER,
    title: headTitle,
    description: 'Works undertaken by property owner',
    included: false,
  },
  {
    index: HeadContractStep.DOCUMENTED_ITEMS,
    title: headTitle,
    description: 'Documented items not in contract',
    included: false,
  },
  {
    index: HeadContractStep.SPECIAL_CONDITIONS,
    title: headTitle,
    description: 'Special conditions',
    included: true,
  },
  {
    index: HeadContractStep.SPECIFICATIONS,
    title: headTitle,
    description: 'Specifications',
    included: true,
  },
  {
    index: HeadContractStep.DESIGN_DRAWINGS,
    title: headTitle,
    description: 'Design drawings',
    included: true,
  },
  {
    index: HeadContractStep.ENGINEERING_DRAWINGS,
    title: headTitle,
    description: 'Engineering drawings',
    included: true,
  },
  {
    index: HeadContractStep.FOUNDATION_REPORTS,
    title: headTitle,
    description: 'Foundation reports',
    included: true,
  },
  {
    index: HeadContractStep.ATTACHMENTS_NOTES,
    title: headTitle,
    description: 'Additional attachments and notes',
    included: true,
  },
  {
    index: HeadContractStep.REVIEW,
    title: headTitle,
    description: 'Review',
    included: true,
  },
];

export const subContractSteps: ContractFormStepper[] = [
  {
    index: SubContractStep.WORKS,
    title: 'New subcontract',
    description: 'Works and address',
    included: true,
  },
  {
    index: SubContractStep.YOU,
    title: 'New subcontract',
    description: 'You',
    included: true,
  },
  {
    index: SubContractStep.OTHER_PARTY,
    title: 'New subcontract',
    description: 'Other party',
    included: true,
  },
  {
    index: SubContractStep.PROGRESS_CLAIM_SCHEDULE,
    title: 'New subcontract',
    description: 'Payments',
    included: true,
  },
  {
    index: SubContractStep.CONSTRUCTION_PERIOD,
    title: 'New subcontract',
    description: 'Period and damages',
    included: true,
  },
  {
    index: SubContractStep.LIABILITY,
    title: 'New subcontract',
    description: 'Liability',
    included: true,
  },
  {
    index: SubContractStep.ATTACHMENTS_NOTES,
    title: 'New subcontract',
    description: 'Attachments',
    included: true,
  },
  {
    index: SubContractStep.REVIEW,
    title: 'New subcontract',
    description: 'Review',
    included: true,
  },
];

export const supplyContractSteps: ContractFormStepper[] = [
  {
    index: SupplyContractStep.WORKS,
    title: 'New supply contract',
    description: 'Works and address',
    included: true,
  },
  {
    index: SupplyContractStep.YOU,
    title: 'New supply contract',
    description: 'You',
    included: true,
  },
  {
    index: SupplyContractStep.OTHER_PARTY,
    title: 'New supply contract',
    description: 'Other party',
    included: true,
  },
  {
    index: SupplyContractStep.PROGRESS_CLAIM_SCHEDULE,
    title: 'New supply contract',
    description: 'Payments',
    included: true,
  },
  {
    index: SupplyContractStep.CONSTRUCTION_PERIOD,
    title: 'New supply contract',
    description: 'Period and damages',
    included: true,
  },
  {
    index: SupplyContractStep.LIABILITY,
    title: 'New supply contract',
    description: 'Liability',
    included: true,
  },
  {
    index: SupplyContractStep.ATTACHMENTS_NOTES,
    title: 'New supply contract',
    description: 'Attachments',
    included: true,
  },
  {
    index: SupplyContractStep.REVIEW,
    title: 'New supply contract',
    description: 'Review',
    included: true,
  },
];

export enum CompleteContractStep {
  CHALLENGE_ADDRESS,
  REVIEW,
}

export enum CompleteSecondaryContractStep {
  RELATED_PROJECT,
  YOUR_PARTY,
  REVIEW,
}

interface CompleteContractFormStepper {
  index: CompleteContractStep | CompleteSecondaryContractStep;
  title: string;
  description?: string;
  included: boolean;
}

export const completeContractSteps: CompleteContractFormStepper[] = [
  {
    index: CompleteContractStep.CHALLENGE_ADDRESS,
    title: 'Join contract',
    description: 'Verification',
    included: true,
  },
  {
    index: CompleteContractStep.REVIEW,
    title: 'Join contract',
    description: 'Review',
    included: true,
  },
];

export const completeSubcontractSteps: CompleteContractFormStepper[] = [
  {
    index: CompleteSecondaryContractStep.RELATED_PROJECT,
    title: 'Review subcontract',
    description: 'Related project',
    included: true,
  },
  {
    index: CompleteSecondaryContractStep.YOUR_PARTY,
    title: 'Review subcontract',
    description: 'Your party',
    included: true,
  },
  {
    index: CompleteSecondaryContractStep.REVIEW,
    title: 'Review subcontract',
    description: 'Agreement',
    included: true,
  },
];

export const completeSupplyContractSteps: CompleteContractFormStepper[] = [
  {
    index: CompleteSecondaryContractStep.RELATED_PROJECT,
    title: 'Review supply contract',
    description: 'Related project',
    included: true,
  },
  {
    index: CompleteSecondaryContractStep.YOUR_PARTY,
    title: 'Review supply contract',
    description: 'Your party',
    included: true,
  },
  {
    index: CompleteSecondaryContractStep.REVIEW,
    title: 'Review supply contract',
    description: 'Agreement',
    included: true,
  },
];

export enum ReviewContractStep {
  INVITEE_DETAILS,
  OTHER_DETAILS,
  SUBJECT_TO_LOAN,
  HAVE_LOAN_APPROVAL,
  OWNERSHIP_AND_FINANCE,
  NEED_LOAN_APPROVAL,
  REVIEW,
}

export enum ReviewSecondaryContractStep {
  YOUR_PARTY,
  REVIEW,
}

export enum ReviewOffPlatformContractStep {
  YOUR_PARTY,
  OVERVIEW,
  REVIEW_PROGRESS_PAYMENTS,
  REVIEW_VARIATIONS,
  REVIEW_TERMS_MARGINS_DAMAGES,
  REVIEW_CONSTRUCTION_PERIOD,
  SUBJECT_TO_LOAN,
  HAVE_LOAN_APPROVAL,
  NEED_LOAN_APPROVAL,
  AGREEMENT,
}

interface ReviewContractFormStepper {
  index: ReviewContractStep | ReviewSecondaryContractStep | ReviewOffPlatformContractStep;
  title: string;
  description?: string;
  included: boolean;
}

export const reviewContractSteps: ReviewContractFormStepper[] = [
  {
    index: ReviewContractStep.INVITEE_DETAILS,
    title: 'Review contract',
    description: 'Your party',
    included: true,
  },
  {
    index: ReviewContractStep.OTHER_DETAILS,
    title: 'Review contract',
    description: 'Other details in this contract',
    included: true,
  },
  {
    index: ReviewContractStep.SUBJECT_TO_LOAN,
    title: 'Review contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewContractStep.HAVE_LOAN_APPROVAL,
    title: 'Review contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewContractStep.OWNERSHIP_AND_FINANCE,
    title: 'Review contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewContractStep.NEED_LOAN_APPROVAL,
    title: 'Review contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewContractStep.REVIEW,
    title: 'Review contract',
    description: 'Review',
    included: true,
  },
];

export const reviewSubcontractSteps: ReviewContractFormStepper[] = [
  {
    index: ReviewSecondaryContractStep.YOUR_PARTY,
    title: 'Review subcontract',
    description: 'Your party',
    included: true,
  },
  {
    index: ReviewSecondaryContractStep.REVIEW,
    title: 'Review subcontract',
    description: 'Agreement',
    included: true,
  },
];

export const reviewSupplyContractSteps: ReviewContractFormStepper[] = [
  {
    index: ReviewSecondaryContractStep.YOUR_PARTY,
    title: 'Review supply contract',
    description: 'Your party',
    included: true,
  },
  {
    index: ReviewSecondaryContractStep.REVIEW,
    title: 'Review supply contract',
    description: 'Agreement',
    included: true,
  },
];

// not in use
export const reviewOffPlatformContractSteps: ReviewContractFormStepper[] = [
  {
    index: ReviewOffPlatformContractStep.YOUR_PARTY,
    title: 'Review signed contract',
    description: 'Your party',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.OVERVIEW,
    title: 'Review signed contract',
    description: 'Review',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.REVIEW_PROGRESS_PAYMENTS,
    title: 'Review signed contract',
    description: 'Progress payments and claims',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.REVIEW_VARIATIONS,
    title: 'Review signed contract',
    description: 'Variations and current contract price',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.REVIEW_TERMS_MARGINS_DAMAGES,
    title: 'Review signed contract',
    description: 'Terms, liquidated damages and defects liability',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.REVIEW_CONSTRUCTION_PERIOD,
    title: 'Review signed contract',
    description: 'Construction period and contract',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.SUBJECT_TO_LOAN,
    title: 'Review signed contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.HAVE_LOAN_APPROVAL,
    title: 'Review signed contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.NEED_LOAN_APPROVAL,
    title: 'Review signed contract',
    description: 'Finance',
    included: true,
  },
  {
    index: ReviewOffPlatformContractStep.AGREEMENT,
    title: 'Review signed contract',
    description: 'Approval or rejection',
    included: true,
  },
];

export const defaultToggleOptions = [
  {
    label: 'Yes',
    value: '1',
    icon: 'checkbox',
  },
  {
    label: 'No',
    value: '0',
    icon: 'close-circle',
  },
];

export const contractRoleMap = {
  [ContractRole.BUILDER]: 'Builder / Head contractor',
  [ContractRole.CONTRACTOR]: 'Contractor',
  [ContractRole.SUBCONTRACTOR]: 'Subcontractor',
  [ContractRole.SUPPLIER]: 'Supplier',
  [ContractRole.PRINCIPAL]: 'Property owner / Principal',
  [ContractRole.SUPERINTENDENT]: 'Superintendent',
  [ContractRole.QUANTITY_SURVEYOR]: 'Quantity surveyor',
  [ContractRole.ARCHITECT]: 'Architect',
} as Record<ContractRole, string>;

export const businessTypeMap = {
  [BusinessType.BUILDER]: 'Builder / Head contractor',
  [BusinessType.SUBCONTRACTOR]: 'Subcontractor',
  [BusinessType.SUPPLIER]: 'Supplier',
} as Record<BusinessType, string>;
