import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const TrailingUnit = styled('span', {
  color: slate.slate10,
  userSelect: 'none',
  variants: {
    fontSize: {
      small: {},
      medium: {},
      large: {
        fontSize: '22px',
      },
    },
  },
});
