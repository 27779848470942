export enum PriceVariationMethod {
  DECREASE = 'DECREASE',
  NONE = 'NONE',
  INCREASE = 'INCREASE',
}

export enum VariationStage {
  SUBMITTED = 'SUBMITTED',
  RESUBMITTED = 'RESUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ABANDONED = 'ABANDONED',
  COMPLETED = 'COMPLETED',
}

export enum VariationType {
  VARIATION = 'VARIATION',
  ADJUSTMENT_PRIME = 'ADJUSTMENT_PRIME',
  ADJUSTMENT_PROVISIONAL = 'ADJUSTMENT_PROVISIONAL',
}

export enum AdjustmentType {
  ADJUSTMENT_PRIME = 'ADJUSTMENT_PRIME',
  ADJUSTMENT_PROVISIONAL = 'ADJUSTMENT_PROVISIONAL',
}

export enum VariationSource {
  ON_PLATFORM = 'ON_PLATFORM',
  OFF_PLATFORM = 'OFF_PLATFORM',
}

export enum VariationActionType {
  CREATE = 'CREATE',
  AMEND = 'AMEND',
  REVIEW = 'REVIEW',
}

export enum DefaultVariationActionType {
  SECURE_VARIATION = 'SECURE_VARIATION',
  ABANDON_VARIATION = 'ABANDON_VARIATION',
}
