import type { SVGIconName } from '@paid-ui/icons';
import React, { forwardRef, useMemo } from 'react';

import type { BasePropsWithChildren, FontWeight } from '../interfaces';
import { StyledButton, StyledLinkButton, StyledSVGIcon } from './_Base';

export type ButtonSize = 'small' | 'medium' | 'large' | 'xlarge';
export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonVariant = 'solid' | 'outline' | 'light' | 'transparent';
export type ButtonColor = 'default' | 'primary' | 'success' | 'danger';

export const defaultButtonProps = {
  as: 'button' as 'a' | 'button',
  size: 'medium' as ButtonSize,
  type: 'button' as ButtonType,
  variant: 'solid' as ButtonVariant,
  color: 'default' as ButtonColor,
};

export interface ButtonProps extends BasePropsWithChildren {
  as?: 'a' | 'button';
  /** Size of button */
  size?: ButtonSize;
  /** HTML type of button */
  type?: ButtonType;
  /** Target form ID */
  formId?: string;
  /** Href of link */
  href?: string;
  /** Target of link */
  target?: React.HTMLAttributeAnchorTarget;
  /** Rel of link */
  rel?: string;
  /** Shape of button */
  variant?: ButtonVariant;
  /** Color of button */
  color?: ButtonColor;
  /** Start icon node of button */
  leadingIcon?: SVGIconName;
  /** End icon node of button */
  trailingIcon?: SVGIconName;
  /** If button animated */
  animated?: boolean;
  /** If button full width */
  block?: boolean;
  /** If button disabled */
  disabled?: boolean;
  /** If button hidden */
  hidden?: boolean;
  /** If button loading */
  loading?: boolean;
  /** User custom width */
  width?: number | string;
  /** User custom height */
  height?: number | string;
  /** Click handler */
  weight?: FontWeight;
  onClick?: React.MouseEventHandler;
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      as,
      size = defaultButtonProps.size,
      type = defaultButtonProps.type,
      formId,
      href,
      target,
      rel,
      variant = defaultButtonProps.variant,
      color = defaultButtonProps.color,
      leadingIcon,
      trailingIcon,
      animated,
      block,
      disabled,
      hidden,
      loading,
      width,
      height,
      weight,
      css,
      children,
      onClick,
      ...restProps
    } = props;

    const childrenNode = useMemo(() => {
      if (leadingIcon) {
        return (
          <>
            <StyledSVGIcon name={leadingIcon} size={size} />
            {children}
          </>
        );
      }

      if (trailingIcon) {
        return (
          <>
            {children}
            <StyledSVGIcon name={trailingIcon} size={size} />
          </>
        );
      }

      return children;
    }, [children, leadingIcon, size, trailingIcon]);

    if (hidden) {
      return null;
    }

    if (as === 'a') {
      return (
        <StyledLinkButton
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
          type={type}
          size={size}
          href={href}
          target={target}
          rel={rel}
          variant={variant}
          color={color}
          animated={animated}
          block={block}
          disabled={disabled || loading}
          weight={weight}
          css={{
            ...css,
            width,
            height,
          }}
          {...restProps}
        >
          {childrenNode}
        </StyledLinkButton>
      );
    }

    return (
      <StyledButton
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        type={type}
        form={formId}
        size={size}
        variant={variant}
        color={color}
        animated={animated}
        block={block}
        disabled={disabled || loading}
        onClick={onClick}
        weight={weight}
        css={{
          ...css,
          width,
          height,
        }}
        {...restProps}
      >
        {childrenNode}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';
