import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { Spinner } from '../spinner';
import { StyledLoadingIndicator, StyledLoadingIndicatorTitle } from './_Base';

export type LoadingIndicatorDirection = 'row' | 'column';
export interface LoadingIndicatorProps extends BaseProps {
  title?: string;
  direction?: LoadingIndicatorDirection;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
}

export const LoadingIndicator = forwardRef<HTMLDivElement, LoadingIndicatorProps>((props, ref) => {
  const { title = 'Loading...', direction = 'row', size = 'md', ...restProps } = props;

  return (
    <StyledLoadingIndicator ref={ref} direction={direction} {...restProps}>
      <Spinner size={size} />
      <StyledLoadingIndicatorTitle
        size={typeof size === 'number' ? undefined : size}
        css={{
          width: typeof size === 'number' ? `${size}px` : undefined,
          height: typeof size === 'number' ? `${size}px` : undefined,
        }}
      >
        {title}
      </StyledLoadingIndicatorTitle>
    </StyledLoadingIndicator>
  );
});

LoadingIndicator.displayName = 'LoadingIndicator';
