/**
 * Pause program for several seconds.
 *
 * @param seconds - Waiting time in seconds.
 * @returns Empty promise.
 */
export function sleep(seconds: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}
