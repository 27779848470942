import { styled } from '../design-tokens';

export const NotificationsLabel = styled('div', {
  width: '100%',
  fontSize: '16px',
  fontWeight: '$bold',
  lineHeight: '22px',
  color: '$fg',
  paddingBottom: '14px',
  borderBottom: '1px solid $colors$fgFaint',
  mt: '24px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const EmptyText = styled('p', {
  my: '16px',
  color: '$fgSecondary',
  textAlign: 'center',
});
