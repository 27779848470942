import { styled } from '../design-tokens';

export const Grid = styled('div', {
  display: 'grid',
  boxSizing: 'border-box',
  width: '100%',

  variants: {
    align: {
      start: {
        alignItems: 'start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    justify: {
      start: {
        justifyContent: 'start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'end',
      },
      between: {
        justifyContent: 'space-between',
      },
    },
    flow: {
      row: {
        gridAutoFlow: 'row',
      },
      column: {
        gridAutoFlow: 'column',
      },
      dense: {
        gridAutoFlow: 'dense',
      },
      rowDense: {
        gridAutoFlow: 'row dense',
      },
      columnDense: {
        gridAutoFlow: 'column dense',
      },
    },
    columns: {
      1: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      },
      2: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
      3: {
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      },
      4: {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      },
    },
    gap: {
      0: {
        gap: 0,
      },
      '0_5': {
        gap: '2px',
      },
      1: {
        gap: '4px',
      },
      '1_5': {
        gap: '6px',
      },
      2: {
        gap: '8px',
      },
      '2_5': {
        gap: '10px',
      },
      3: {
        gap: '12px',
      },
      '3_5': {
        gap: '14px',
      },
      4: {
        gap: '16px',
      },
      '4_5': {
        gap: '18px',
      },
      5: {
        gap: '20px',
      },
      6: {
        gap: '24px',
      },
      7: {
        gap: '28px',
      },
      8: {
        gap: '32px',
      },
      9: {
        gap: '36px',
      },
      10: {
        gap: '40px',
      },
    },
    gapX: {
      0: {
        columnGap: 0,
      },
      '0_5': {
        columnGap: '2px',
      },
      1: {
        columnGap: '4px',
      },
      '1_5': {
        columnGap: '6px',
      },
      2: {
        columnGap: '8px',
      },
      '2_5': {
        columnGap: '10px',
      },
      3: {
        columnGap: '12px',
      },
      4: {
        columnGap: '16px',
      },
      5: {
        columnGap: '20px',
      },
      6: {
        columnGap: '24px',
      },
      7: {
        columnGap: '28px',
      },
      8: {
        columnGap: '32px',
      },
      9: {
        columnGap: '36px',
      },
      10: {
        columnGap: '40px',
      },
    },
    gapY: {
      0: {
        rowGap: 0,
      },
      '0_5': {
        rowGap: '2px',
      },
      1: {
        rowGap: '4px',
      },
      '1_5': {
        rowGap: '6px',
      },
      2: {
        rowGap: '8px',
      },
      '2_5': {
        rowGap: '10px',
      },
      3: {
        rowGap: '12px',
      },
      4: {
        rowGap: '16px',
      },
      5: {
        rowGap: '20px',
      },
      6: {
        rowGap: '24px',
      },
      7: {
        rowGap: '28px',
      },
      8: {
        rowGap: '32px',
      },
      9: {
        rowGap: '36px',
      },
      10: {
        rowGap: '40px',
      },
    },

    fullHeight: {
      true: {
        height: '100%',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
