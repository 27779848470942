import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';

export const StyledActionButton = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'flex',
  'width': '100%',
  'gap': '12px',
  'padding': '10px',
  'cursor': 'pointer',
  'borderRadius': '3px',
  'backgroundColor': '$bg',
  'transition': 'backgroundColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: '$bgGroup',
    },
  },

  'variants': {
    skeleton: {
      true: {
        'cursor': 'default',

        '@hover': {
          '&:hover': {
            backgroundColor: '$bg',
          },
        },
      },
    },

    unread: {
      true: {
        opacity: 1,
      },

      false: {
        opacity: 0.62,
      },
    },
  },

  'defaultVariants': {
    unread: true,
  },
});

export const RequiringActionMenuItem = styled('div', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'flex',
  'width': '100%',
  'minWidth': '320px',
  'gap': '12px',
  'padding': '10px 16px',
  'cursor': 'pointer',
  'borderRadius': '3px',
  'backgroundColor': '$bg',
  'alignItems': 'baseline',
  'transition': 'backgroundColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: '$bgGroup',
    },
  },
});

export const ActionContainer = styled('div', {
  'flex': 1,
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '4px',
  '@sm': {
    flexDirection: 'unset',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export const ActionContent = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  color: '$fg',
});

export const ActionExtraContent = styled('div', {
  'flexShrink': 0,
  'display': 'flex',
  'flexDirection': 'row',
  'justifyContent': 'space-between',
  'gap': '8px',

  '@sm': {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
});

export const ActionTitle = styled('div', {
  'fontSize': '16px',
  'fontWeight': '$medium',
  'lineHeight': '22px',

  '&:empty': {
    display: 'none',
  },
});

export const ActionParagraph = styled('div', {
  'fontSize': '16px',
  'fontWeight': '$normal',
  'lineHeight': '22px',

  '&:empty': {
    display: 'none',
  },
});

export const ActionTimestamp = styled('span', {
  'width': '100%',
  'color': '$darkGrey',
  'fontSize': '14px',
  'fontWeight': '$normal',
  'lineHeight': '22px',

  '&:empty': {
    display: 'none',
  },
});

export const ActionIcon = styled(SVGIcon, {
  width: '16px',
  height: '14px',
  color: '$pink',
  lineHeight: '22px',
});

export const DismissButton = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'cursor': 'pointer',
  'color': '$lightBlue',
  'fontSize': '14px',
  'fontWeight': '$medium',
  'lineHeight': '22px',

  '&:disabled': {
    color: '$midGrey',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },

  '@hover': {
    '&:hover': {
      'color': '$blue',
      '&:disabled': {
        color: '$midGrey',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
  },
});
