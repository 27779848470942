import { ContractState, ContractType, contractTypes, defaultAddress } from '@paid-ui/constants';
import type { ProjectSummary } from '@paid-ui/types';
import { useRouter } from 'next/router';
import { forwardRef, useCallback, useMemo } from 'react';

import { Avatar } from '../avatar';
import { Button } from '../button';
import { CountIndicator } from '../count-indicator';
import { DropdownMenu } from '../dropdown-menu';
import { Highlight } from '../highlight';
import type { BaseProps } from '../interfaces';
import { PaymentsProgress } from '../payments-progress';
import { useToast } from '../toast';
import {
  ActionButtons,
  ActionIcon,
  ActionWrap,
  CardContent,
  CardHeader,
  CardTitle,
  CompletedOrRemainTime,
  ContractInfo,
  ContractTypeText,
  HeaderLabel,
  PaymentProgressContainer,
  StyledCard,
} from './_Base';

export const defaultProjectCardProps = {
  data: {
    id: '',
    projectName: '',
    projectAddress: defaultAddress,
    otherContracts: [],
  } as ProjectSummary,
};

export interface ProjectCardProps extends BaseProps {
  data?: ProjectSummary;
  highlight?: string;
  isEmpty?: boolean;
  hideActions?: boolean;
  requiringActions?: number;
  disableProjectCreation?: boolean;
}

export const ProjectCard = forwardRef<HTMLDivElement, ProjectCardProps>((props, ref) => {
  const toast = useToast();
  const router = useRouter();
  const {
    data = defaultProjectCardProps.data,
    highlight,
    isEmpty,
    hideActions,
    requiringActions = 0,
    disableProjectCreation,
    ...restProps
  } = props;

  const callbackRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [ref],
  );

  const numberOfContracts = useMemo(() => {
    const { mainContract, otherContracts = [] } = data;
    const numberOfOtherContracts = otherContracts.length;
    return mainContract ? numberOfOtherContracts + 1 : numberOfOtherContracts;
  }, [data]);

  const contractType: ContractType = useMemo(() => {
    const { mainContract, otherContracts = [] } = data;

    if (mainContract) {
      return mainContract.contractType;
    }

    if (otherContracts.length > 0) {
      return otherContracts[0]?.contractType ?? ContractType.SUBCONTRACT;
    }

    return ContractType.HEAD_CONTRACT;
  }, [data]);

  const isAllClaimsPaid = useMemo(() => {
    const otherContracts = data.otherContracts ?? [];
    const allContracts = data.mainContract
      ? [data.mainContract, ...otherContracts]
      : otherContracts;

    if (allContracts.length === 0) {
      return false;
    }

    return allContracts.every((contract) => contract.contractState === ContractState.ALL_PAID);
  }, [data]);

  const remainingDays = useMemo(() => {
    return data.mainContract?.remainingDays ? `${data.mainContract.remainingDays}d remain` : null;
  }, [data]);

  const hasContractExecuted = useMemo(() => {
    return data.mainContract?.contractState === ContractState.EXECUTED;
  }, [data]);

  const paidIndexList = useMemo(() => {
    return (
      data.mainContract?.progressPayments?.map((item, index) => {
        if (item.paid) {
          return index;
        }

        return -1;
      }) || []
    );
  }, [data.mainContract?.progressPayments]);

  const handleProjectCardClick = useCallback(() => {
    if (data.id) {
      router.push(`/projects/${data.id}`);
    }
  }, [data.id, router]);

  const handleNewVariationButtonClick = useCallback(
    (event: Event | React.MouseEvent) => {
      event.stopPropagation();
      router.push(`/projects/${data.id}?dialog=new-variation`);
    },
    [data.id, router],
  );

  const handleClaimPaymentButtonClick = useCallback(
    (event: Event | React.MouseEvent) => {
      event.stopPropagation();
      router.push(`/projects/${data.id}?dialog=new-claim`);
    },
    [data.id, router],
  );

  const handleNewProjectButtonClick = useCallback<React.MouseEventHandler>(
    (event) => {
      event.stopPropagation();

      if (disableProjectCreation) {
        toast.error('You need to provide construction services before creating project.');
        return;
      }

      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.set('dialog', 'new-project');
      urlSearchParams.sort();
      router.replace(
        [window.location.pathname, urlSearchParams.toString()].filter(Boolean).join('?'),
        undefined,
        {
          shallow: true,
          scroll: true,
        },
      );
    },
    [disableProjectCreation, router, toast],
  );

  const projectSuburb = useMemo(() => {
    if (data?.projectAddress) {
      return data?.projectAddress?.suburb;
    }
    if (data?.projectName) {
      const list = data.projectName.split(',')?.[1]?.split(' ');
      return list?.slice(-2, -1);
    }
    return '';
  }, [data?.projectAddress, data.projectName]);

  return (
    <StyledCard ref={callbackRef} onClick={handleProjectCardClick} {...restProps}>
      <CardHeader>
        {isEmpty ? (
          <Avatar
            icon="project"
            size="large"
            variant="rounded"
            backgroundColor="lightGrey"
            bordered={false}
            iconStyle={{
              color: '#001026',
              opacity: '0.2',
            }}
          />
        ) : (
          <HeaderLabel color="grey">{projectSuburb}</HeaderLabel>
        )}
        <CountIndicator
          color="primary"
          showIcon
          css={{
            position: 'absolute',
            top: '14px',
            right: '14px',
          }}
        >
          {requiringActions}
        </CountIndicator>
      </CardHeader>
      <CardContent isEmpty={isEmpty}>
        <CardTitle>
          {isEmpty ? (
            'Create your first project.'
          ) : (
            <Highlight highlight={highlight}>{data.projectName}</Highlight>
          )}
        </CardTitle>
        {numberOfContracts === 1 ? (
          <>
            <ContractInfo align="center" justify="between">
              <ContractTypeText>{contractTypes[contractType]}</ContractTypeText>
              <CompletedOrRemainTime completed={isAllClaimsPaid}>
                {isAllClaimsPaid ? 'All claims paid' : remainingDays}
              </CompletedOrRemainTime>
            </ContractInfo>
            <PaymentProgressContainer hidden={isAllClaimsPaid}>
              <PaymentsProgress
                total={data.mainContract?.progressPayments?.length ?? 0}
                paidIndexList={paidIndexList}
                showArrow={false}
              />
            </PaymentProgressContainer>
          </>
        ) : (
          <ContractInfo align="center" hidden={isEmpty || numberOfContracts === 0}>
            <ContractTypeText>{`${numberOfContracts} contracts`}</ContractTypeText>
            <CompletedOrRemainTime completed hidden={!isAllClaimsPaid}>
              All claims paid
            </CompletedOrRemainTime>
          </ContractInfo>
        )}
        {isEmpty ? (
          <Button
            block
            color="primary"
            size="large"
            weight="medium"
            onClick={handleNewProjectButtonClick}
            height={44}
          >
            New project
          </Button>
        ) : (
          <ActionButtons
            justify="start"
            gap={2}
            hidden={
              hideActions || numberOfContracts !== 1 || !hasContractExecuted || isAllClaimsPaid
            }
          >
            <DropdownMenu
              align="end"
              trigger={
                <ActionWrap>
                  <ActionIcon name="quick-actions" />
                </ActionWrap>
              }
              side="right"
              menu={[
                {
                  text: 'New variation',
                  onSelect: handleNewVariationButtonClick,
                },
                {
                  text: 'Claim payment',
                  onSelect: handleClaimPaymentButtonClick,
                },
              ]}
            />
          </ActionButtons>
        )}
      </CardContent>
    </StyledCard>
  );
});

ProjectCard.displayName = 'ProjectCard';
