import { styled } from '../design-tokens';

export const StyledSpace = styled('div', {
  'display': 'inline-flex',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    direction: {
      horizontal: {
        flexDirection: 'row',
      },

      vertical: {
        flexDirection: 'column',
      },
    },

    align: {
      start: {
        alignItems: 'start',
      },

      end: {
        alignItems: 'flex-end',
      },

      center: {
        alignItems: 'center',
      },

      baseline: {
        alignItems: 'baseline',
      },
    },

    inline: {
      true: {
        display: 'inline-flex',
      },

      false: {
        display: 'flex',
        width: '100%',
      },
    },

    fullHeight: {
      true: {
        height: '100%',
      },
    },

    reversed: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
  },

  'defaultvariants': {
    direction: 'horizontal',
    inline: true,
  },
});
