import { type Dispatch, forwardRef, useCallback } from 'react';

import { Button, type ButtonProps } from '../button';
import { Flex } from '../flex';
import type { BaseProps } from '../interfaces';
import { StyledReviewFormFooter } from './_Base';
import { type FormStepper } from './types';

export interface ReviewFormFooterProps extends BaseProps {
  submitButton?: ButtonProps;
  hidden?: boolean;

  currentStep?: number;
  steps?: FormStepper[];
  isSticky?: boolean;
  dispatch?: Dispatch<any>;
}

export const ReviewFormFooter = forwardRef<HTMLDivElement, ReviewFormFooterProps>((props, ref) => {
  const { currentStep, steps, isSticky, dispatch, hidden, submitButton, ...restProps } = props;

  const handleSubmit = useCallback<React.MouseEventHandler<Element>>(
    (event) => {
      const { loading, disabled, onClick } = submitButton ?? {};

      if (loading || disabled) {
        return;
      }

      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [submitButton],
  );

  if (hidden) {
    return null;
  }

  return (
    <StyledReviewFormFooter ref={ref} sticky {...restProps}>
      <Flex align="center" justify="end">
        <Button
          height={44}
          size="large"
          color="primary"
          loading={submitButton?.loading}
          disabled={submitButton?.disabled}
          hidden={submitButton?.hidden}
          onClick={handleSubmit}
        >
          {submitButton?.children}
        </Button>
      </Flex>
    </StyledReviewFormFooter>
  );
});

ReviewFormFooter.displayName = 'ReviewFormFooter';
