import { type RequiringAction } from '@paid-ui/types';
import { groupBy } from 'lodash';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const initialState = {
  requiringActions: [] as RequiringAction[],
  projectActions: {} as Record<string, RequiringAction[]>,
  numberOfProjectActions: {} as Record<string, number>,
  forceUpdate: 0,
};

export const requiringActionManager = proxy(initialState);

devtools(requiringActionManager, {
  name: 'Requiring Action',
  enabled: false,
});

export const resetRequiringActions = () => {
  Object.assign(requiringActionManager, initialState);
};

export const saveRequiringActions = (requiringActions: RequiringAction[]) => {
  const projectActions = groupBy(requiringActions, (action) => action.data?.projectId);
  requiringActionManager.requiringActions = requiringActions;
  requiringActionManager.projectActions = projectActions;
  requiringActionManager.numberOfProjectActions = Object.fromEntries(
    Object.entries(projectActions).map(([key, value]) => [key, value.length]),
  );
};

export const reloadRequiringActions = () => {
  const { forceUpdate } = requiringActionManager;
  requiringActionManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};
