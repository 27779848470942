import { SVGIcon } from '@paid-ui/icons';
import * as ToastPrimitive from '@radix-ui/react-toast';

import { styled, toastHide, toastSlideIn, toastSwipeOut, VIEWPORT_PADDING } from '../design-tokens';

export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 400,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: '$toast',
  outline: 'none',

  variants: {
    position: {
      topLeft: {
        top: '32px',
        left: 0,
      },

      topRight: {
        top: '32px',
        right: 0,
      },

      bottomLeft: {
        bottom: 0,
        left: 0,
      },

      bottomRight: {
        right: 0,
        bottom: 0,
      },
    },
  },

  defaultVariants: {
    position: 'topRight',
  },
});

export const ToastRoot = styled(ToastPrimitive.Root, {
  'display': 'grid',
  'gridTemplateAreas': '"title action" "content action"',
  'gridTemplateColumns': 'auto max-content',
  'columnGap': '16px',
  'padding': '16px',
  'borderRadius': '4px',
  'boxShadow':
    'hsl(206deg 22% 7% / 35%) 0px 10px 38px -10px, hsl(206deg 22% 7% / 20%) 0px 10px 20px -15px',

  '&[data-state="open"]': {
    animation: `${toastSlideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${toastHide} 100ms ease-in forwards`,
  },

  '&[data-swipe="move"]': {
    transform: 'translateX(var(--radix-toast-swipe-move-x))',
  },

  '&[data-swipe="cancel"]': {
    transform: 'translateX(0)',
    transition: 'transform 200ms ease-out',
  },

  '&[data-swipe="end"]': {
    animation: `${toastSwipeOut} 100ms ease-out forwards`,
  },

  '@motion': {
    '&[data-state="open"]': {
      animation: 'none',
    },

    '&[data-state="closed"]': {
      animation: 'none',
    },

    '&[data-swipe="move"]': {
      animation: 'none',
    },

    '&[data-swipe="cancel"]': {
      animation: 'none',
    },

    '&[data-swipe="end"]': {
      animation: 'none',
    },
  },

  'variants': {
    variant: {
      info: {
        backgroundColor: '$darkBlue',
      },

      success: {
        backgroundColor: '$payInGreen',
      },

      error: {
        backgroundColor: '$pink',
      },
    },
  },

  'defaultVariants': {
    variant: 'info',
  },
});

export const ToastTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  fontWeight: '$medium',
  marginBottom: '4px',
  color: 'white',
  fontSize: '16px',
  lineHeight: '18px',
});

export const ToastDescription = styled(ToastPrimitive.Description, {
  gridArea: 'content',
  color: 'rgb(255 255 255 / 80%)',
  fontSize: '16px',
  lineHeight: '18px',
});

export const ToastClose = styled(ToastPrimitive.Close, {
  all: 'unset',
  cursor: 'pointer',
  gridArea: 'action',
  size: '18px',
});

export const ToastActionIcon = styled(SVGIcon, {
  'size': '100%',
  'lineHeight': 1,
  'color': 'white',
  'opacity': 0.6,
  'transition': 'opacity 100ms',

  '@hover': {
    '&:hover': {
      opacity: 1,
    },
  },
});
