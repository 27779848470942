/**
 * See details
 * https://paid-inc.atlassian.net/wiki/spaces/AD/pages/16580758/Variation+and+Adjustment
 */

export enum VariationStage {
  SUBMITTED = 'SUBMITTED',
  RESUBMITTED = 'RESUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ABANDONED = 'ABANDONED',
  COMPLETED = 'COMPLETED',
}

export enum AdjustmentStage {
  SUBMITTED = 'SUBMITTED',
  RESUBMITTED = 'RESUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ABANDONED = 'ABANDONED',
  COMPLETED = 'COMPLETED',
}

export enum AdjustmentStatus {
  UNADJUSTED = 'UNADJUSTED',
  REQUESTED = 'REQUESTED',
  ADJUSTED = 'ADJUSTED',
}

export enum PriceVariationMethod {
  DECREASE = 'DECREASE',
  NONE = 'NONE',
  INCREASE = 'INCREASE',
}

export enum VariationType {
  VARIATION = 'VARIATION',
  ADJUSTMENT_PRIME = 'ADJUSTMENT_PRIME',
  ADJUSTMENT_PROVISIONAL = 'ADJUSTMENT_PROVISIONAL',
}

export enum AdjustmentType {
  ADJUSTMENT_PRIME = 'ADJUSTMENT_PRIME',
  ADJUSTMENT_PROVISIONAL = 'ADJUSTMENT_PROVISIONAL',
}

export enum VariationContractStep {
  TYPE_SCOPE,
  INITIATOR_PERMITS_DOCUMENTATION,
  DELAY_DETAILS,
  REVIEW,
}

export enum AdjustPrimeCostItemsStep {
  TYPE_SCOPE,
  PRIME_COST_ITEMS,
  REVIEW,
}

export enum AdjustProvisionalSumStep {
  TYPE_SCOPE,
  PROVISIONAL_SUM_WORKS,
  REVIEW,
}

export type VariationStep =
  | VariationContractStep
  | AdjustPrimeCostItemsStep
  | AdjustProvisionalSumStep;

export interface VariationContractFormStepper {
  index: VariationStep;
  title: string;
  description?: string;
  included: boolean;
}

export const variationContractSteps: VariationContractFormStepper[] = [
  {
    index: VariationContractStep.TYPE_SCOPE,
    title: 'New variation',
    description: 'Type and scope',
    included: true,
  },
  {
    index: VariationContractStep.INITIATOR_PERMITS_DOCUMENTATION,
    title: 'Request variation',
    description: 'Initiator, permits and supporting documentation',
    included: true,
  },
  {
    index: VariationContractStep.DELAY_DETAILS,
    title: 'Request variation',
    description: 'Delay and details',
    included: true,
  },
  {
    index: VariationContractStep.REVIEW,
    title: 'Request variation',
    description: 'Review',
    included: true,
  },
];

export const adjustPrimeCostItemsContractSteps: VariationContractFormStepper[] = [
  {
    index: AdjustPrimeCostItemsStep.TYPE_SCOPE,
    title: 'New variation',
    description: 'Type and scope',
    included: true,
  },
  {
    index: AdjustPrimeCostItemsStep.PRIME_COST_ITEMS,
    title: 'Adjust costs',
    description: 'Prime cost items',
    included: true,
  },
  {
    index: AdjustPrimeCostItemsStep.REVIEW,
    title: 'Adjust costs',
    description: 'Review',
    included: true,
  },
];

export const adjustProvisionalSumWorksContractSteps: VariationContractFormStepper[] = [
  {
    index: AdjustProvisionalSumStep.TYPE_SCOPE,
    title: 'New variation',
    description: 'Type and scope',
    included: true,
  },
  {
    index: AdjustProvisionalSumStep.PROVISIONAL_SUM_WORKS,
    title: 'Adjust costs',
    description: 'Provisional sum works',
    included: true,
  },
  {
    index: AdjustProvisionalSumStep.REVIEW,
    title: 'Adjust costs',
    description: 'Review',
    included: true,
  },
];

export const plusOrMinus = {
  [PriceVariationMethod.DECREASE]: -1,
  [PriceVariationMethod.NONE]: 0,
  [PriceVariationMethod.INCREASE]: 1,
};

export const variationTypeLabel = {
  [VariationType.VARIATION]: 'Variations',
  [VariationType.ADJUSTMENT_PRIME]: 'Prime cost adjustment',
  [VariationType.ADJUSTMENT_PROVISIONAL]: 'Provisional sum adjustment',
};
