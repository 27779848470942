import { SVGIcon } from '@paid-ui/icons';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { styled } from '../design-tokens';

export const StyledToggleGroupRoot = styled(ToggleGroupPrimitive.Root, {
  'padding': '6px',
  'display': 'grid',
  'gridAutoFlow': 'row',
  'alignItems': 'stretch',
  'gap': '10px',
  'backgroundColor': '$lightGrey',
  'borderRadius': '6px',
  'width': '100%',

  '@sm': {
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',
  },
});

export const StyledIcon = styled(SVGIcon, {
  width: '18px',
  height: '18px',
  color: '$tertiaryGrey',
  flexShrink: 0,
  transition: 'color 250ms ease',
});

export const StyledToggleGroupItem = styled(ToggleGroupPrimitive.Item, {
  'all': 'unset',
  'boxSizing': 'border-box',
  'padding': '10px',
  'color': '$darkGrey',
  'fontSize': '16px',
  'lineHeight': '22px',
  'textAlign': 'center',
  'backgroundColor': 'transparent',
  'border': '1px solid $colors$midGrey',
  'borderRadius': '6px',
  'cursor': 'pointer',
  'transition': 'all 250ms ease',

  '&[data-disabled]': {
    cursor: 'not-allowed',
  },

  '&[data-state=off]': {
    '@hover': {
      '&:hover': {
        color: '$fg',
        backgroundColor: '$bg',
        borderColor: '$tertiaryGrey',
      },
    },
    '&:focus': {
      color: '$fg',
      backgroundColor: '$bg',
      borderColor: '$tertiaryGrey',
    },
    '&[data-disabled]': {
      '@hover': {
        '&:hover': {
          color: '$darkGrey',
          backgroundColor: 'transparent',
          borderColor: '$midGrey',
        },
      },
      '&:focus': {
        color: '$darkGrey',
        backgroundColor: 'transparent',
        borderColor: '$midGrey',
      },
    },
  },

  '&[data-state=on]': {
    color: '$fg',
    backgroundColor: '$bg',
    borderColor: 'transparent',
    boxShadow:
      '0px 0.9975153207778931px 1.9285296201705933px 0px rgba(0, 0, 0, 0.07), 0px 3.3504464626312256px 6.477529525756836px 0px rgba(0, 0, 0, 0.04), 0px 15px 29px 0px rgba(0, 0, 0, 0.03)',
  },

  'variants': {
    iconColor: {
      blue: {
        '&[data-state=on]': {
          [`& ${StyledIcon}`]: {
            color: '$blue',
          },
        },
      },
      pink: {
        '&[data-state=on]': {
          [`& ${StyledIcon}`]: {
            color: '$pink',
          },
        },
      },
      green: {
        '&[data-state=on]': {
          [`& ${StyledIcon}`]: {
            color: '$darkGreen',
          },
        },
      },
    },
    hasIcon: {
      true: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        '& span': {
          'minWidth': '55px',
          '@sm': {
            minWidth: 'auto',
          },
        },
      },
    },
    hideIconWhenOff: {
      true: {
        '&[data-state=off]': {
          [`& ${StyledIcon}`]: {
            display: 'none',
          },
        },
      },
    },

    bordered: {
      false: {
        borderColor: 'transparent !important',
      },
    },
  },

  'defaultVariants': {
    iconColor: 'blue',
  },
});

export const StyledItemContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  height: '100%',
});
