import { SVGIcon } from '@paid-ui/icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { Button } from '../button';
import { styled } from '../design-tokens';
import { Logo } from '../logo';
import { Space } from '../space';

export const StepFormMainContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden auto',
  width: '100%',
  height: '100%',
});

export const StyledReviewFormHeader = styled('header', {
  'position': 'sticky',
  'top': 0,
  'width': '100%',
  'flexShrink': 0,
  'padding': '18px 20px',
  'borderBottom': '1px solid transparent',
  'backdropFilter': 'none',
  'backgroundColor': '$bg',
  'display': 'grid',
  'gap': '12px',
  'gridTemplateAreas': '"logo title"',
  'gridTemplateColumns': '18px minmax(0, 1fr)',
  'transition': 'all 250ms ease',
  'zIndex': 10,

  '&:empty': {
    display: 'none',
  },

  '@sm': {
    padding: '18px 30px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: '16px',
  },

  'variants': {
    sticky: {
      true: {
        'borderBottom': '1px solid $colors$midGrey',
        'backgroundColor': 'rgb(255 255 255 / 90%)',
        'backdropFilter': 'none',

        '@supports (backdrop-filter: none)': {
          backgroundColor: 'rgb(255 255 255 / 75%)',
          backdropFilter: 'blur(5px)',
        },
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StyledStepFormHeader = styled('header', {
  'position': 'sticky',
  'top': 0,
  'width': '100%',
  'flexShrink': 0,
  'padding': '18px 20px',
  'borderBottom': '1px solid transparent',
  'backdropFilter': 'none',
  'backgroundColor': '$bg',
  'display': 'grid',
  'gap': '12px',
  'gridTemplateAreas': '"logo title" "progress progress"',
  'gridTemplateColumns': '18px minmax(0, 1fr)',
  'gridTemplateRows': '46px 8px',
  'transition': 'all 250ms ease',
  'zIndex': 10,

  '&:empty': {
    display: 'none',
  },

  '@sm': {
    padding: '18px 30px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: '16px',
  },

  'variants': {
    sticky: {
      true: {
        'borderBottom': '1px solid $colors$fgTertiary',
        'backgroundColor': 'rgb(255 255 255 / 90%)',
        'backdropFilter': 'none',

        '@supports (backdrop-filter: none)': {
          backgroundColor: 'rgb(255 255 255 / 75%)',
          backdropFilter: 'blur(5px)',
        },
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StepFormLogo = styled(Logo, {
  gridArea: 'logo',
  marginTop: '3px',
  height: '25px',
});

export const StepFormTitleAndDescription = styled(Space, {
  'gridArea': 'title',
  'paddingRight': '90px',

  '@sm': {
    paddingRight: '80px',
  },
});

export const StepFormTitle = styled(DialogPrimitive.Title, {
  color: '$darkGrey',
  fontSize: '16px',
  fontWeight: '$normal',
  lineHeight: '22px',
  ellipsis: '100%',
  userSelect: 'none',
});

export const StepFormDescription = styled(DialogPrimitive.Description, {
  color: '$label',
  fontSize: '16px',
  fontWeight: '$medium',
  lineHeight: '22px',
  ellipsis: '100%',
  userSelect: 'none',
});

export const StepFormProgress = styled(Space, {
  gridArea: 'progress',
  width: '100%',
  height: '100%',
  zIndex: 11,
});

export const StepFormProgressItem = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'position': 'relative',
  'width': '100%',
  'height': '4px',
  'overflow': 'hidden',
  'transition': 'all 100ms',

  '&:first-of-type': {
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
  },

  '&:last-of-type': {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },

  '@hover': {
    '&:hover': {
      height: '8px',
      cursor: 'pointer',
    },

    '&:first-of-type': {
      '&:hover': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
    },

    '&:last-of-type': {
      '&:hover': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },
  },

  'variants': {
    readonly: {
      true: {
        cursor: 'unset !important',
      },
    },
    active: {
      true: {
        backgroundColor: '$blue',
      },

      false: {
        backgroundColor: '$fgFaint',
      },
    },
    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  'defaultVariants': {
    active: false,
  },
});

export const StepFormIconButton = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'padding': '6px',
  'size': '32px',
  'cursor': 'pointer',
  'position': 'absolute',
  'top': '18px',
  'right': '20px',

  '@sm': {
    top: '18px',
    right: '30px',
  },
});

export const StepFormSVGIcon = styled(SVGIcon, {
  'size': '20px',

  '@hover': {
    'color': '$fgSecondary',
    'transition': 'color 100ms',

    '&:hover': {
      color: '$blue',
    },
  },
});

export const StepFormClose = styled(DialogPrimitive.Close, {
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StepFormHelp = styled('a', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'padding': '6px',
  'size': '32px',
  'cursor': 'pointer',
  'position': 'absolute',
  'top': '18px',
  'right': '66px',

  '@sm': {
    top: '18px',
    right: '76px',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StyledReviewFormFooter = styled('footer', {
  'position': 'sticky',
  'bottom': 0,
  'width': '100%',
  'flexShrink': 0,
  'padding': '18px 16px',
  'borderTop': '1px solid transparent',
  'backgroundColor': '$bg',
  'backdropFilter': 'none',
  'transition': 'all 250ms ease',
  'zIndex': 10,
  'overflow': 'hidden',
  '&:empty': {
    display: 'none',
  },

  '@sm': {
    padding: '18px 26px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },

  'variants': {
    sticky: {
      true: {
        'borderTop': '1px solid $colors$midGrey',
        'backgroundColor': 'rgb(255 255 255 / 90%)',
        'backdropFilter': 'none',

        '@supports (backdrop-filter: none)': {
          backgroundColor: 'rgb(255 255 255 / 75%)',
          backdropFilter: 'blur(5px)',
        },
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StyledStepFormFooter = styled('footer', {
  'position': 'sticky',
  'bottom': 0,
  'width': '100%',
  'flexShrink': 0,
  'padding': '18px 16px',
  'borderTop': '1px solid transparent',
  'backgroundColor': '$bg',
  'backdropFilter': 'none',
  'transition': 'all 250ms ease',
  'zIndex': 10,
  'overflow': 'hidden',
  '&:empty': {
    display: 'none',
  },

  '@sm': {
    padding: '18px 26px',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
  },

  'variants': {
    sticky: {
      true: {
        'borderTop': '1px solid $colors$fgTertiary',
        'backgroundColor': 'rgb(255 255 255 / 90%)',
        'backdropFilter': 'none',

        '@supports (backdrop-filter: none)': {
          backgroundColor: 'rgb(255 255 255 / 75%)',
          backdropFilter: 'blur(5px)',
        },
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ReturnToReviewButton = styled(Button, {
  'display': 'none',
  'marginRight': 'auto !important',

  '@sm': {
    display: 'inline-flex',
  },
});

export const StepFormContentContainer = styled('div', {
  'flex': 1,
  'padding': '30px 20px',

  '@sm': {
    padding: '30px',
  },

  'variants': {
    noPadding: {
      true: {
        padding: 0,
      },
    },
  },
});
