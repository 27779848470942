import { forwardRef, useCallback, useRef } from 'react';

import { FormControl } from '../form';
import { HelperText } from '../help-text';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import { StyledTextarea } from './_Base';
import useAutosize from './useAutosize';

export type RowType = 1 | 2 | 3;

export interface TextareaProps extends BaseProps {
  name: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  autoRows?: RowType;
  size?: FontSize;
  label?: React.ReactNode;
  labelSize?: FontSize;
  labelWeight?: FontWeight;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  rows?: number;
  block?: boolean;
  ghost?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  hidden?: boolean;
  autosize?: boolean;
  maxLength?: number;
  multiLine?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const innerRef = useRef<HTMLTextAreaElement>(null);
  const {
    size,
    name,
    placeholder,
    value,
    defaultValue,
    label,
    labelSize,
    labelWeight,
    helperText,
    errorMessage,
    rows = 4,
    block,
    ghost,
    required,
    disabled,
    readOnly,
    autoFocus,
    multiLine = true,
    hidden,
    autosize = false,
    maxLength,
    autoRows = 3,
    onChange,
    onBlur,
    ...restProps
  } = props;

  const hasError = !!errorMessage;

  useAutosize(autosize, innerRef?.current, value || '');

  const handleFocus = useCallback(() => {
    if (autosize && innerRef?.current) {
      innerRef.current.style.height = '0px';
      const { scrollHeight } = innerRef.current;
      innerRef.current.style.height = `${scrollHeight}px`;
    }
  }, [autosize, innerRef]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>(
    (event) => {
      if (!multiLine) {
        const targetValue = event.target.value;
        event.target.value = targetValue.replaceAll('\n', ' ');
      }
      onChange?.(event);
    },
    [multiLine, onChange],
  );

  if (hidden) {
    return null;
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label ? (
        <Label htmlFor={name} asterisk={required} size={labelSize} weight={labelWeight}>
          {label}
        </Label>
      ) : null}

      <FormControl block={block}>
        <StyledTextarea
          ref={innerRef}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value ?? ''}
          defaultValue={defaultValue}
          rows={rows}
          block={block}
          ghost={ghost}
          maxLength={maxLength}
          autosize={autosize}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          invalid={hasError}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={handleFocus}
          autoRows={autoRows}
          size={typeof size === 'string' ? size : undefined}
          aria-invalid={hasError ? 'true' : 'false'}
          aria-describedby={hasError ? `${name}-error` : `${name}-description`}
        />
      </FormControl>

      <HelperText error={hasError} id={hasError ? `${name}-error` : `${name}-description`}>
        {hasError ? errorMessage : helperText}
      </HelperText>
    </Space>
  );
});

Textarea.displayName = 'Textarea';
