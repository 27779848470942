import { formatCurrency } from '@paid-ui/utils';
import { utc2Mel } from '@paid-ui/utils/datetime';
import isNull from 'lodash/isNull';
import { forwardRef, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { DescriptionLabel, DescriptionValue, StyledDescription } from './_Base';

export type ValueType = 'text' | 'money' | 'percent' | 'days' | 'date' | 'dateTime';

const defaultDescriptionProps = {
  span: 1 as 1 | 2,
};

export interface DescriptionProps extends BaseProps {
  label: React.ReactNode;
  value?: any;
  type?: ValueType;
  errorMessage?: string | Record<string, string>;
  span?: 1 | 2;
  renderValue?: (value: any) => React.ReactNode;
}

export const Description = forwardRef<HTMLDivElement, DescriptionProps>((props, ref) => {
  const {
    label,
    value,
    type,
    errorMessage,
    span = defaultDescriptionProps.span,
    renderValue,
    ...restProps
  } = props;

  const cleanedErrorMessage = useMemo(() => {
    if (!errorMessage) {
      return '';
    }

    if (typeof errorMessage === 'string') {
      return errorMessage;
    }

    const [firstError] = Object.entries(errorMessage);

    if (!firstError) {
      return '';
    }

    return firstError[1];
  }, [errorMessage]);

  const hasError = useMemo(() => {
    return !!cleanedErrorMessage;
  }, [cleanedErrorMessage]);

  const formattedValue = useMemo(() => {
    if (value === undefined || value === '' || isNull(value)) {
      return '-';
    }

    if (Array.isArray(value)) {
      return value;
    }

    switch (type) {
      case 'money': {
        if (typeof value === 'string' || typeof value === 'number') {
          return formatCurrency(typeof value === 'string' ? Number.parseInt(value, 10) : value);
        }
        break;
      }

      case 'percent': {
        if (typeof value === 'string' || typeof value === 'number') {
          return `${value}%`;
        }
        break;
      }

      case 'date': {
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
          return utc2Mel(value).format('DD/MM/YY');
        }
        break;
      }

      case 'dateTime': {
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
          return utc2Mel(value).format('DD/MM/YY HH:mm');
        }
        break;
      }

      case 'days': {
        if (typeof value === 'string' || typeof value === 'number') {
          return `${value} days`;
        }
        break;
      }

      default: {
        return value;
      }
    }
  }, [type, value]);

  return (
    <StyledDescription ref={ref} span={span} {...restProps}>
      <DescriptionLabel>{label}</DescriptionLabel>
      <DescriptionValue error={hasError}>
        {typeof renderValue === 'function' ? (
          renderValue(formattedValue)
        ) : (
          <span>{formattedValue as React.ReactNode}</span>
        )}
      </DescriptionValue>
    </StyledDescription>
  );
});

Description.displayName = 'Description';
