import type { RequiringAction as IRequiringAction } from '@paid-ui/types';
import { utc2Mel } from '@paid-ui/utils/datetime';
import sortBy from 'lodash/sortBy';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { RequiringAction, RequiringActionSkeleton } from '../requiring-action';
import { Separator } from '../separator';
import { Space } from '../space';
import { ActionContainer, EmptyText } from './_Base';

export interface RequiringActionsProps extends BaseProps {
  data: IRequiringAction[];
  loading?: boolean;
  disabled?: boolean;
  groupByDate?: boolean;
  emptyText?: string;
  onItemClick?: (data: IRequiringAction) => void;
  onItemDismiss?: (data: IRequiringAction) => void;
}

export const RequiringActions = forwardRef<HTMLDivElement, RequiringActionsProps>((props, ref) => {
  const {
    data,
    loading,
    disabled,
    groupByDate,
    emptyText = 'No requiring actions',
    onItemClick,
    onItemDismiss,
    ...restProps
  } = props;

  if (loading) {
    return (
      <Space ref={ref} direction="vertical" inline={false} {...restProps}>
        {Array.from({ length: 2 }).map((_, index) => (
          <RequiringActionSkeleton key={index} />
        ))}
      </Space>
    );
  }

  if (data.length === 0) {
    return <EmptyText>{emptyText}</EmptyText>;
  }

  if (groupByDate) {
    return (
      <Space ref={ref} direction="vertical" size={0} inline={false} {...restProps}>
        {sortBy(data, (v) => -utc2Mel(v.createdTimestamp).valueOf()).map((item, index) => (
          <ActionContainer key={item.id}>
            <Separator decorative gap={0} hidden={index === 0} />
            <RequiringAction
              data={item}
              disabled={disabled}
              onClick={onItemClick}
              onDismiss={onItemDismiss}
            />
          </ActionContainer>
        ))}
      </Space>
    );
  }

  return (
    <Space size={10} direction="vertical" inline={false} {...restProps}>
      {data.map((item, index) => (
        <ActionContainer key={item.id}>
          <Separator decorative gap={0} hidden={index === 0} />
          <RequiringAction
            data={item}
            disabled={disabled}
            onClick={onItemClick}
            onDismiss={onItemClick}
          />
        </ActionContainer>
      ))}
    </Space>
  );
});

RequiringActions.displayName = 'RequiringActions';
