export enum ProjectType {
  DOMESTIC_RESIDENTIAL = 'DOMESTIC_RESIDENTIAL',
  MULTI_RESIDENTIAL = 'MULTI_RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  INDUSTRIAL = 'INDUSTRIAL',
  CIVIL = 'CIVIL',
}

export enum ProjectRole {
  PRINCIPAL = 'PRINCIPAL',
  SUPERINTENDENT = 'SUPERINTENDENT',
  BUILDER = 'BUILDER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER',
}

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
