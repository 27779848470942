import { SVGIcon } from '@paid-ui/icons';
import { gray } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const FormControl = styled('div', {
  position: 'relative',
  borderRadius: '4px',

  variants: {
    block: {
      true: {
        width: '100%',
      },
      false: {
        width: 'auto',
      },
    },
  },

  defaultVariants: {
    block: false,
  },
});

export const IconButton = styled('button', {
  'all': 'unset',
  'position': 'absolute',
  'top': 0,
  'bottom': 0,
  'display': 'inline-flex',
  'alignItems': 'center',
  'userSelect': 'none',
  'padding': '12px',
  '&:empty': {
    display: 'none',
  },
});

export const LeadingIconButton = styled(IconButton, {
  left: 0,
  zIndex: 1,
  margin: '1px',
  variants: {
    bordered: {
      true: {
        backgroundColor: '$lightGrey',
        borderRight: '1px solid $colors$midGrey',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
    },
  },
});

export const TrailingIconButton = styled(IconButton, {
  right: 0,
  zIndex: 1,
  margin: '1px',
  variants: {
    bordered: {
      true: {
        backgroundColor: '$lightGrey',
        borderLeft: '1px solid $colors$midGrey',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },
  },
});

export const StyledSVGIcon = styled(SVGIcon, {
  size: '18px',
  color: gray.gray10,
  zIndex: 1,
});
