import { AddressState, defaultAddress } from '@paid-ui/constants';
import type { Address } from '@paid-ui/types';
import type { FormikErrors, FormikTouched } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { forwardRef, useCallback } from 'react';

import type { BaseProps } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import {
  AddressInputGrid,
  PostcodeInput,
  StateSelect,
  StreetNameInput,
  StreetNumberInput,
  SuburbInput,
  ToggleButton,
  UnitNumberInput,
} from './_Base';

export const defaultAddressInputProps = {};

export interface AddressInputProps extends BaseProps {
  maxLength?: number;
  name: string;
  value?: Address;
  label?: React.ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  touched?: boolean | FormikTouched<Address>;
  helperText?: React.ReactNode;
  errorMessage?: FormikErrors<Address>;
  onChange?: (value?: Address) => void;
  onBlur?: (value?: Address) => void;
}

export const AddressInput = forwardRef<HTMLDivElement, AddressInputProps>((props, ref) => {
  const {
    name,
    value,
    label,
    autoFocus,
    disabled,
    readOnly,
    required,
    touched,
    maxLength,
    helperText,
    errorMessage,
    onChange,
    onBlur,
    ...restProps
  } = props;

  const handleChange = useCallback(
    (field: keyof Address, _value: string) => {
      if (typeof onChange === 'function') {
        const oldValue = value ?? defaultAddress;

        onChange({
          ...oldValue,
          [field]: _value,
        });
      }
    },
    [onChange, value],
  );

  const handleBlur = useCallback(
    (field: keyof Address, _value: string) => {
      if (typeof onBlur === 'function') {
        const oldValue = value ?? defaultAddress;

        onBlur({
          ...oldValue,
          [field]: _value,
        });
      }
    },
    [onBlur, value],
  );

  const getTouched = useCallback(
    (field: keyof Address) => {
      if (typeof touched === 'boolean' || touched === undefined) {
        return touched;
      }

      if (isEmpty(touched)) {
        return false;
      }

      return touched[field];
    },
    [touched],
  );

  const handleManualAddressToggle = useCallback(() => {
    if (typeof onChange === 'function') {
      const oldValue = value ?? defaultAddress;

      onChange({
        ...oldValue,
        manual: false,
      });
    }
  }, [onChange, value]);

  return (
    <Space size={14} direction="vertical" inline={false} ref={ref} {...restProps}>
      {label ? (
        <Label
          htmlFor={`${name}.unitNumber`}
          size={16}
          weight="medium"
          asterisk={required}
          css={{
            flex: 1,
          }}
        >
          {label}
        </Label>
      ) : null}
      <AddressInputGrid>
        <UnitNumberInput
          block
          autoFocus={autoFocus}
          disabled={disabled}
          readOnly={readOnly}
          label="Unit Number"
          maxLength={maxLength}
          placeholder="Unit Number"
          name={`${name}.unitNumber`}
          value={value?.unitNumber}
          onChange={(event) => handleChange('unitNumber', event.target.value)}
          onBlur={(event) => handleBlur('unitNumber', event.target.value)}
          errorMessage={getTouched('unitNumber') ? errorMessage?.unitNumber : undefined}
          autoComplete="off"
        />
        <StreetNumberInput
          block
          required
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          label="Street/Lot Number"
          placeholder="Street/Lot Number"
          name={`${name}.streetNumber`}
          value={value?.streetNumber}
          onChange={(event) => handleChange('streetNumber', event.target.value)}
          onBlur={(event) => handleBlur('streetNumber', event.target.value)}
          errorMessage={getTouched('streetNumber') ? errorMessage?.streetNumber : undefined}
          autoComplete="off"
        />
        <StreetNameInput
          block
          required
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          label="Street Name"
          placeholder="Street Name"
          name={`${name}.streetName`}
          value={value?.streetName}
          onChange={(event) => handleChange('streetName', event.target.value)}
          onBlur={(event) => handleBlur('streetName', event.target.value)}
          errorMessage={getTouched('streetName') ? errorMessage?.streetName : undefined}
          autoComplete="off"
        />
        <SuburbInput
          block
          required
          maxLength={maxLength}
          disabled={disabled}
          readOnly={readOnly}
          label="Suburb/Town"
          placeholder="Suburb/Town"
          name={`${name}.suburb`}
          value={value?.suburb}
          onChange={(event) => handleChange('suburb', event.target.value)}
          onBlur={(event) => handleBlur('suburb', event.target.value)}
          errorMessage={getTouched('suburb') ? errorMessage?.suburb : undefined}
          autoComplete="off"
        />
        <StateSelect
          required
          disabled={disabled}
          readOnly={readOnly}
          label="State"
          name={`${name}.state`}
          value={value?.state ?? AddressState.VIC}
          onChange={(state) => handleChange('state', state)}
          onBlur={(event) => handleBlur('state', event.target.value)}
          options={Object.values(AddressState).map((state) => ({
            label: state,
            value: state,
          }))}
          errorMessage={getTouched('state') ? errorMessage?.state : undefined}
        />
        <PostcodeInput
          block
          required
          maxLength={4}
          disabled={disabled}
          readOnly={readOnly}
          label="Postcode"
          placeholder="Postcode"
          name={`${name}.postcode`}
          value={value?.postcode}
          onChange={(event) => handleChange('postcode', event.target.value)}
          onBlur={(event) => handleBlur('postcode', event.target.value)}
          errorMessage={getTouched('postcode') ? errorMessage?.postcode : undefined}
          autoComplete="off"
        />
        <ToggleButton onClick={handleManualAddressToggle} hidden={!window.google}>
          Switch to google maps
        </ToggleButton>
      </AddressInputGrid>
    </Space>
  );
});

AddressInput.displayName = 'AddressInput';
