import { breakpoints, styled } from '../design-tokens';

export const StyledContainer = styled('div', {
  flexShrink: 0,
  boxSizing: 'border-box',
  mx: '18px',

  variants: {
    maxWidth: {
      xs: {
        'maxWidth': breakpoints.xs,
        '@xs': {
          width: '100%',
          mx: 'auto',
        },
      },

      sm: {
        'maxWidth': breakpoints.sm,
        '@sm': {
          width: '100%',
          mx: 'auto',
        },
      },

      md: {
        'maxWidth': breakpoints.md,
        '@md': {
          width: '100%',
          mx: 'auto',
        },
      },

      lg: {
        'maxWidth': breakpoints.lg,
        '@lg': {
          width: '100%',
          mx: 'auto',
        },
      },
    },

    disableMargin: {
      true: {
        mx: 0,
      },
    },
  },

  defaultVariants: {
    maxWidth: 'lg',
    disableMargin: false,
  },
});
