import { useLocalStorageState } from 'ahooks';
import { forwardRef, useEffect, useState } from 'react';

import { Button } from '../button';
import { type BaseProps } from '../interfaces';
import { Progress } from '../progress';
import { Space } from '../space';
import { Tooltip } from '../tooltip';
import {
  BulletSpan,
  CheckIcon,
  ExpandButton,
  ExpandIcon,
  MinimizeIcon,
  QuickStartIcon,
  UserGuideBenefit,
  UserGuideBenefits,
  UserGuideContainer,
  UserGuideContent,
  UserGuideFab,
  UserGuideHeader,
  UserGuideItem,
  UserGuideItemContent,
  UserGuideItemDisableReason,
  UserGuideItemTitle,
  UserGuideItemTrigger,
  UserGuideItemVideo,
  UserGuideMinimizeButton,
  UserGuideSubtitle,
  UserGuideTitle,
  UserGuideWrapper,
} from './UserGuide.styled';

export type UserGuideAction = {
  key: string;
  title: string;
  actionText: string;
  benefits?: string[];
  video?:
    | string
    | {
        mp4?: string;
        webm?: string;
      };
  hidden?: boolean;
  completed?: boolean;
  disabled?: boolean;
  loading?: boolean;
  disableReason?: string;
  onClick?: () => void;
};

export interface UserGuideProps extends BaseProps {
  title: string;
  actions: UserGuideAction[];
  minimized?: boolean;
  loading?: boolean;
  hidden?: boolean;
  maxHeight?: string | number;
  setMinimized?: (minimized: boolean) => void;
}

export const UserGuide = forwardRef<HTMLDivElement, UserGuideProps>((props, ref) => {
  const {
    title,
    actions,
    minimized,
    loading,
    hidden,
    maxHeight = 'calc(100vh - 186px)',
    setMinimized,
    ...restProps
  } = props;
  const [minimizedState, setMinimizedState] = useLocalStorageState('user_guide_minimized', {
    defaultValue: minimized,
    serializer: (value) => (value ? '1' : '0'),
    deserializer: (value) => value === '1',
  });

  const [showTooltip, setShowTooltip] = useLocalStorageState('user_guide_tooltip', {
    defaultValue: true,
    serializer: (value) => (value ? '1' : '0'),
    deserializer: (value) => value === '1',
  });

  const [delayShow, setDelayShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayShow(true);
    }, 2000);
  }, []);

  const filteredActions = actions.filter((item) => !item.hidden);
  const completed = filteredActions.filter((item) => item.completed).length;
  const percentage = filteredActions.length > 0 ? (completed / filteredActions.length) * 100 : 0;

  const handleUserGuideOpen = () => {
    setMinimizedState(false);
    if (typeof setMinimized === 'function') {
      setMinimized(false);
    }
  };

  const handleUserGuideClose = () => {
    setMinimizedState(true);
    if (typeof setMinimized === 'function') {
      setMinimized(true);
    }
  };

  if (hidden || filteredActions.length === 0) {
    return null;
  }

  if (minimizedState) {
    return (
      <Tooltip title="Show guide">
        <UserGuideFab type="button" onClick={handleUserGuideOpen} className="hidden md:flex">
          <QuickStartIcon name="quick-start" />
        </UserGuideFab>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      open={showTooltip && delayShow}
      side="left"
      css={{
        zIndex: 300,
      }}
      disabled={!showTooltip}
      onEscapeKeyDown={() => setShowTooltip(false)}
      onPointerDownOutside={() => setShowTooltip(false)}
      title={
        <Space size={14} direction="vertical" inline={false}>
          <p>
            Use your {title.toLowerCase()} to learn more about the important steps to get you up and
            running on Paid.
          </p>
          <Button color="primary" onClick={() => setShowTooltip(false)}>
            OK
          </Button>
        </Space>
      }
    >
      <UserGuideContainer ref={ref} className="hidden md:block" {...restProps}>
        <UserGuideWrapper css={{ maxHeight }}>
          <UserGuideHeader>
            <UserGuideMinimizeButton type="button" onClick={handleUserGuideClose}>
              <MinimizeIcon name="minimize" />
            </UserGuideMinimizeButton>
            <UserGuideTitle>{title}</UserGuideTitle>
            <UserGuideSubtitle>
              Completed {completed} / {filteredActions.length}
            </UserGuideSubtitle>
            <Progress value={percentage} css={{ mt: 12 }} compact />
          </UserGuideHeader>
          <div>
            <UserGuideContent type="single" defaultValue={filteredActions.at(0)?.key} collapsible>
              {filteredActions.map((item) => (
                <UserGuideItem key={item.key} value={item.key}>
                  <UserGuideItemTrigger>
                    <CheckIcon name="checkbox" checked={item.completed} />
                    <UserGuideItemTitle>{item.title}</UserGuideItemTitle>
                    <ExpandButton>
                      <ExpandIcon name="drop" />
                    </ExpandButton>
                  </UserGuideItemTrigger>
                  <UserGuideItemContent>
                    {item.benefits && item.benefits.length > 0 ? (
                      <UserGuideBenefits>
                        {item.benefits.map((benefit, index) => (
                          <UserGuideBenefit key={index}>
                            <BulletSpan>·</BulletSpan>
                            <span>{benefit}</span>
                          </UserGuideBenefit>
                        ))}
                      </UserGuideBenefits>
                    ) : null}
                    {item.video ? (
                      typeof item.video === 'string' ? (
                        <UserGuideItemVideo src={item.video} autoPlay={false} controls />
                      ) : (
                        <UserGuideItemVideo autoPlay={false} controls>
                          {item.video.webm ? (
                            <source src={item.video.webm} type="video/webm" />
                          ) : null}
                          {item.video.mp4 ? <source src={item.video.mp4} type="video/mp4" /> : null}
                          <p>Sorry, your browser does not support HTML5 video.</p>
                        </UserGuideItemVideo>
                      )
                    ) : null}
                    {item.completed || loading ? null : (
                      <Space size={6} direction="vertical" inline={false}>
                        <Button
                          type="button"
                          size="large"
                          color="primary"
                          height={44}
                          disabled={item.disabled}
                          loading={item.loading}
                          onClick={item.onClick}
                          block
                        >
                          {item.actionText}
                        </Button>
                        {item.disableReason ? (
                          <UserGuideItemDisableReason>
                            {item.disableReason}
                          </UserGuideItemDisableReason>
                        ) : null}
                      </Space>
                    )}
                  </UserGuideItemContent>
                </UserGuideItem>
              ))}
            </UserGuideContent>
          </div>
        </UserGuideWrapper>
      </UserGuideContainer>
    </Tooltip>
  );
});

UserGuide.displayName = 'UserGuide';
