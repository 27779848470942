import { SVGIcon } from '@paid-ui/icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { styled } from '../design-tokens';

export const BasePreviewButton = styled('button', {
  'all': 'unset',
  'position': 'absolute',
  'display': 'block',
  'boxSizing': 'border-box',
  'padding': '10px',
  'borderRadius': '4px',
  'backgroundColor': '$bg',
  'transition': 'all 100ms',
  'userSelect': 'none',
  'cursor': 'pointer',

  '&:active': {
    backgroundColor: '$bgGroup',
    transform: 'scale(0.97)',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const PreviewZoomIn = styled(BasePreviewButton, {
  'top': '16px',
  'left': '16px',

  '@sm': {
    top: '36px',
    left: '36px',
  },
});

export const PreviewZoomOut = styled(BasePreviewButton, {
  'top': '74px',
  'left': '16px',

  '@sm': {
    top: '94px',
    left: '36px',
  },
});

export const PreviewPrev = styled(BasePreviewButton, {
  'top': 'calc(50vh - 52px)',
  'left': '16px',

  '@sm': {
    top: 'calc(50vh - 67px)',
    left: '36px',
  },
});

export const PreviewNext = styled(BasePreviewButton, {
  'top': 'calc(50vh - 52px)',
  'right': '16px',

  '@sm': {
    top: 'calc(50vh - 67px)',
    right: '36px',
  },
});

export const PreviewClose = styled(DialogPrimitive.Close, {
  'position': 'absolute',
  'top': '16px',
  'right': '16px',

  '@sm': {
    top: '36px',
    right: '36px',
  },
});

export const PreviewCloseButton = styled('button', {
  'all': 'unset',
  'padding': '10px',
  'boxSizing': 'border-box',
  'backgroundColor': '$bg',
  'borderRadius': '4px',
  'cursor': 'pointer',
  'transition': 'all 100ms',

  '&:active': {
    backgroundColor: '$bgGroup',
    transform: 'scale(0.97)',
  },
});

export const PreviewIcon = styled(SVGIcon, {
  size: '24px',
  color: '$fgSecondary',
});

export const PreviewContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

export const PreviewFooter = styled('div', {
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flex': '0 0 30px',
  'padding': '4px 8px',
  'width': '100%',
  'backgroundColor': '$bg',
  'gap': '16px',
  'textAlign': 'center',

  '@sm': {
    flex: '0 0 45px',
    padding: '12px 24px',
  },
});

export const ImageList = styled('ul', {
  all: 'unset',
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  scrollSnapType: 'x mandatory',
});

export const ImageItem = styled('li', {
  all: 'unset',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  scrollSnapAlign: 'start',
  color: '$blue',
});

export const Image = styled('img', {
  'display': 'block',
  'margin': 0,
  'maxWidth': '100vw',
  'maxHeight': 'calc(100vh - 30px)',
  'userSelect': 'none',

  '@sm': {
    maxHeight: 'calc(100vh - 45px)',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const Video = styled('video', {
  'display': 'block',
  'margin': 0,
  'maxWidth': '100vw',
  'maxHeight': 'calc(100vh - 30px)',
  'aspectRatio': '16 / 9',
  'userSelect': 'none',

  '@sm': {
    maxWidth: 'calc(100vw - 160px)',
    maxHeight: 'calc(100vh - 45px)',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const GeoIcon = styled(SVGIcon, {
  size: '18px',

  variants: {
    color: {
      pink: {
        color: '$pink',
      },

      amber: {
        color: '#ff9500',
      },

      green: {
        color: '$payInGreen',
      },
    },
  },

  defaultVariants: {
    color: 'pink',
  },
});

export const CalendarIcon = styled(SVGIcon, {
  size: '18px',
  color: '$fgSecondary',
});

export const Caption = styled('span', {
  fontSize: '16px',
  fontWeight: '$semibold',
  lineHeight: '22px',
});
