import * as Portal from '@radix-ui/react-portal';
import { forwardRef, useCallback, useEffect } from 'react';

import type { CSS } from '../design-tokens';
import type { BasePropsWithChildren, Themes } from '../interfaces';
import {
  DrawerBody,
  DrawerCloseButton,
  DrawerCloseIcon,
  DrawerContainer,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
  StyledDrawer,
} from './_Base';

export type Directions = 'top' | 'right' | 'bottom' | 'left';

export const defaultDrawerProps = {
  mask: true,
  zIndex: 1000,
  theme: 'light' as Themes,
  closable: true,
  placement: 'left' as Directions,
  maskClosable: true,
  hasCloseButton: true,
};

export interface DrawerProps extends BasePropsWithChildren {
  mask?: boolean;
  theme?: Themes;
  zIndex?: number;
  bodyStyle?: CSS;
  maskStyle?: CSS;
  headerStyle?: CSS;
  visible?: boolean;
  closable?: boolean;
  placement?: Directions;
  maskClosable?: boolean;
  extra?: React.ReactNode;
  width?: string | number;
  height?: string | number;
  footer?: React.ReactNode;
  hasCloseButton?: boolean;
  fixedBackground?: boolean;
  closeIcon?: React.ReactNode;
  title?: string | React.ReactNode;
  onClose?: () => void;
}

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props, ref) => {
  const {
    mask = defaultDrawerProps.mask,
    theme = defaultDrawerProps.theme,
    extra,
    title,
    width,
    height,
    zIndex = defaultDrawerProps.zIndex,
    footer,
    visible,
    closable = defaultDrawerProps.closable,
    children,
    maskStyle,
    placement = defaultDrawerProps.placement,
    headerStyle,
    maskClosable = defaultDrawerProps.maskClosable,
    hasCloseButton = defaultDrawerProps.hasCloseButton,
    fixedBackground,
    closeIcon,
    bodyStyle,
    css,
    onClose,
    ...restProps
  } = props;

  useEffect(() => {
    if (fixedBackground) {
      window.document.body.style.overflowY = visible ? 'hidden' : 'auto';
    }
  }, [visible, fixedBackground]);

  const handleClickMask = useCallback(() => {
    if (maskClosable && typeof onClose === 'function') {
      onClose();
    }
  }, [maskClosable, onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Portal.Root>
      <DrawerContainer visible={visible} ref={ref}>
        {mask && <DrawerOverlay onClick={handleClickMask} css={maskStyle} visible={visible} />}
        <StyledDrawer
          ref={ref}
          visible={visible}
          placement={placement}
          theme={theme}
          css={{
            width,
            height,
            zIndex,
            ...css,
          }}
          {...restProps}
        >
          <DrawerHeader css={headerStyle}>
            {typeof title === 'string' ? <DrawerTitle>{title}</DrawerTitle> : title}
            {hasCloseButton && (
              <DrawerCloseButton type="button" placement={placement} onClick={onClose}>
                <DrawerCloseIcon name="close" theme={theme} />
              </DrawerCloseButton>
            )}
            {extra}
          </DrawerHeader>
          <DrawerBody css={bodyStyle} placement={placement}>
            {children}
          </DrawerBody>
          <DrawerFooter>{footer}</DrawerFooter>
        </StyledDrawer>
      </DrawerContainer>
    </Portal.Root>
  );
});

Drawer.displayName = 'Drawer';
