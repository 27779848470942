import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledTextarea = styled('textarea', {
  'appearance': 'none',
  'position': 'relative',
  'boxSizing': 'border-box',
  'minHeight': '80px',
  'width': 'auto',
  'padding': '10px 12px',
  'borderWidth': '0',
  'borderRadius': '4px',
  'fontFamily': 'inherit',
  'fontSize': '16px',
  'lineHeight': 1.375,
  'letterSpacing': '0.025em',
  'color': '$fg',
  'backgroundColor': '$bg',
  'boxShadow': `inset 0 0 0 1px ${slate.slate7}`,
  'cursor': 'default',
  'outline': 'none',
  'resize': 'vertical',
  'fontVariantNumeric': 'tabular-nums',
  'webkitTapHighlightColor': 'transparent',

  '@sm': {
    fontSize: '14px',
  },

  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
    cursor: 'text',
  },

  '&::placeholder': {
    color: slate.slate9,
    lineHeight: 1.375,
  },

  '&:disabled': {
    'backgroundColor': slate.slate2,
    'color': slate.slate8,
    'cursor': 'not-allowed',
    'pointerEvents': 'none',
    'resize': 'none',

    '&::placeholder': {
      color: slate.slate7,
    },
  },

  '&:read-only': {
    'backgroundColor': slate.slate2,

    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${slate.slate7}`,
    },
  },

  'variants': {
    autosize: {
      true: {
        height: '86px',
        minHeight: '86px',
      },
    },

    autoRows: {
      1: {
        height: '45px',
        minHeight: '45px',
      },

      2: {
        height: '64px',
        minHeight: '64px',
      },

      3: {
        height: '86px',
        minHeight: '86px',
      },
    },

    block: {
      true: {
        width: '100%',
      },

      false: {
        width: 'auto',
      },
    },

    ghost: {
      true: {
        'backgroundColor': slate.slate2,
        'boxShadow': 'none',

        '&:focus': {
          backgroundColor: '$bg',
          boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
        },

        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
    },

    invalid: {
      true: {
        'boxShadow': `inset 0 0 0 1px $colors$pink`,

        '&:focus': {
          boxShadow: `inset 0 0 0 1px $colors$pink, 0 0 0 1px $colors$pink`,
        },
      },
    },

    size: {
      small: {
        fontSize: '14px',
      },

      medium: {
        fontSize: '16px',
      },

      large: {
        fontSize: '18px',
      },
    },
  },

  'defaultvariants': {
    block: false,
    ghost: false,
    invalid: false,
  },
});
