import { styled } from '../design-tokens';

export const StyledCard = styled('div', {
  'position': 'relative',
  'width': '100%',
  'maxWidth': '686px',
  'backgroundColor': '$bg',
  'border': '1px solid rgb(218 223 229)',
  'borderRadius': '8px',
  'overflow': 'hidden',
  'transition': 'all 100ms',
  'cursor': 'default',
  'willChange': 'transform, boxShadow',
  'boxShadow':
    '0.8px 0.8px 5.3px rgb(0 0 0 / 1%), 2.7px 2.7px 17.9px rgb(0 0 0 / 2%), 12px 12px 27px rgb(0 0 0 / 3%)',

  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
  },

  '@motion': {
    transition: 'none',
    willChange: 'none',
  },

  'variants': {
    interactive: {
      true: {
        'cursor': 'pointer',
        '@hover': {
          '&:hover': {
            boxShadow:
              '1px 1px 5.3px rgb(0 0 0 / 3%), 3.4px 3.4px 17.9px rgb(0 0 0 / 4%), 15px 15px 24px rgb(0 0 0 / 7%)',
            transform: 'translate(-3px, -3px)',
          },
        },
      },
    },

    padding: {
      true: {
        padding: '16px',
      },
    },
  },

  'defaultVariants': {
    interactive: true,
  },
});
