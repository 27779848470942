import {
  ClaimLinkStatus,
  ClearingState,
  InternalPaymentMethod,
  SubType,
  TransactionHistoryType,
} from '@paid-ui/constants';
import { SVGIcon } from '@paid-ui/icons';
import type { TransactionHistory } from '@paid-ui/types';
import { formatAddress, formatCurrency } from '@paid-ui/utils';
import { forwardRef, useCallback, useMemo } from 'react';

import { Button } from '../button';
import { Flex } from '../flex';
import { type BaseProps } from '../interfaces';
import { Separator } from '../separator';
import { Space } from '../space';
import {
  AutoPayment,
  DispenseIcon,
  EarlyReleaseDiscount,
  TransactionHistoryIcon,
  TransactionHistoryItemAddress,
  TransactionHistoryItemContainer,
  TransactionHistoryItemExtraArea,
  TransactionHistoryItemLinkedContractsTitle,
  TransactionHistoryItemLinkedContractsValueContainer,
  TransactionHistoryItemLinkedReserved,
  TransactionHistoryItemPayment,
  TransactionHistoryItemPaymentContainer,
  TransactionHistoryItemPaymentIcon,
  TransactionHistoryItemTitle,
  TransactionHistoryItemTitleContainer,
  TransactionHistoryItemUnreferencedArea,
} from './_Base';

const defaultTransactionHistoryItemProps = {
  type: TransactionHistoryType.UNKNOWN,
};

export interface TransactionHistoryItemProps extends TransactionHistory, BaseProps {}

export const TransactionHistoryItem = forwardRef<HTMLDivElement, TransactionHistoryItemProps>(
  (props, ref) => {
    const {
      credit,
      debit,
      description,
      transactionNumber,
      displayName,
      projectName,
      origin,
      type = defaultTransactionHistoryItemProps.type,
      subType,
      claimLinkStatus,
      clearingState,
      address,
      linkedContracts,
      transactionFee,
      transactionFeeType,
      transactionFeeOrigin,
      transactionFeeDescription,
      referenceTransactionId,
      date,
      alreadyPaidMatchedClaim,
      theOtherPartyName,
      toAccount,
      toBsb,
      internalPaymentMethod,
      earlyReleaseDiscount,
      onClick,
      onActionClick,
      ...restProps
    } = props;

    const amount = credit > 0 ? credit : debit > 0 ? debit : 0;

    const isAutoDispense =
      type === TransactionHistoryType.OUTBOUND &&
      internalPaymentMethod === InternalPaymentMethod.AUTO_DISPENSE;

    const isEarlyReleaseDispense =
      type === TransactionHistoryType.OUTBOUND &&
      internalPaymentMethod === InternalPaymentMethod.EARLY_RELEASE_DISPENSE;

    const isDispense = isAutoDispense || isEarlyReleaseDispense;

    const internalTransaction = type === TransactionHistoryType.INNER;
    const internalManualSecure = internalTransaction && subType === SubType.MANUAL_SECURE;
    const internalUnsecure = internalTransaction && subType === SubType.UNSECURE;
    const internalRetention = internalTransaction && subType === SubType.RETAIN;
    const internalReleaseRetention = internalTransaction && subType === SubType.RELEASE_RETENTION;

    const variationIcon = useMemo(() => {
      return credit > 0 ? (
        <TransactionHistoryItemPaymentIcon name="add" color="#00bc74" />
      ) : debit > 0 ? (
        <TransactionHistoryItemPaymentIcon name="minus" color="#a6acb3" />
      ) : null;
    }, [credit, debit]);

    const enrichedDescription = useMemo(() => {
      const description = [displayName, projectName || formatAddress(address, true)]
        .filter(Boolean)
        .join(' · ');

      if (isEarlyReleaseDispense) {
        return <span>{description}</span>;
      }

      if (isAutoDispense) {
        return (
          <>
            <span>{description}</span>
            <span> · </span>
            <AutoPayment>
              <DispenseIcon name="dispensed" />
              <span>Auto payment</span>
            </AutoPayment>
          </>
        );
      }

      if (internalManualSecure) {
        return (
          <Space size={6} align="center">
            <TransactionHistoryIcon name="secured" />
            <span>{formatCurrency(amount)} secured</span>
          </Space>
        );
      }

      if (internalUnsecure) {
        return (
          <Space size={6} align="center">
            <TransactionHistoryIcon name="dispensed" />
            <span>{formatCurrency(amount)} unsecured</span>
          </Space>
        );
      }

      if (internalRetention) {
        return (
          <Space size={6} align="center">
            <TransactionHistoryIcon name="secured" />
            <span>{formatCurrency(amount)} retained</span>
          </Space>
        );
      }

      if (internalReleaseRetention) {
        return (
          <Space size={6} align="center">
            <TransactionHistoryIcon name="dispensed" />
            <span>{formatCurrency(amount)} released (retention)</span>
          </Space>
        );
      }

      return description;
    }, [
      displayName,
      projectName,
      address,
      isEarlyReleaseDispense,
      isAutoDispense,
      amount,
      internalManualSecure,
      internalUnsecure,
      internalRetention,
      internalReleaseRetention,
    ]);

    const transactionComponent = useMemo(() => {
      if (transactionFee) {
        return (
          <Space size={8} direction="vertical" inline={false}>
            {earlyReleaseDiscount ? (
              <EarlyReleaseDiscount>
                <DispenseIcon name="dispensed" />
                <span>
                  {formatCurrency(earlyReleaseDiscount)} InstaPaid.EarlyRelease discount transferred
                  to available
                </span>
              </EarlyReleaseDiscount>
            ) : null}
            <Flex align="center" justify="between">
              <TransactionHistoryItemLinkedContractsTitle>
                {transactionFeeOrigin || 'Transaction fee'}
              </TransactionHistoryItemLinkedContractsTitle>
              <Flex align="center" justify="end" gap="1_5">
                <TransactionHistoryItemLinkedContractsTitle>
                  {formatCurrency(transactionFee)}
                </TransactionHistoryItemLinkedContractsTitle>
                <SVGIcon
                  name={transactionFeeType === TransactionHistoryType.REVERT ? 'credit' : 'debit'}
                  color="#A6ACB3"
                  size={18}
                />
              </Flex>
            </Flex>
          </Space>
        );
      }

      return null;
    }, [earlyReleaseDiscount, transactionFee, transactionFeeOrigin, transactionFeeType]);

    const handleActionClick = useCallback<React.MouseEventHandler>(
      (event) => {
        event.stopPropagation();
        if (typeof onActionClick === 'function') {
          onActionClick();
        }
      },
      [onActionClick],
    );

    const claimComponent = useMemo(() => {
      if (
        claimLinkStatus === ClaimLinkStatus.MATCH ||
        claimLinkStatus === ClaimLinkStatus.MANUAL_MATCH
      ) {
        return (
          <>
            <TransactionHistoryItemAddress>
              <Flex align="center" gap={2}>
                {type === TransactionHistoryType.OUTBOUND && !isDispense ? (
                  <TransactionHistoryIcon name="outbound" />
                ) : null}
                {enrichedDescription}
              </Flex>
            </TransactionHistoryItemAddress>
            <Separator
              gap={10}
              decorative
              hidden={!linkedContracts?.linkedNumber && !transactionFee}
              className="bg-grey-hover"
            />
            {linkedContracts || transactionFee ? (
              <TransactionHistoryItemExtraArea>
                {linkedContracts ? (
                  <TransactionHistoryItemLinkedReserved onClick={handleActionClick}>
                    <TransactionHistoryIcon name="inbound" />
                    <TransactionHistoryItemLinkedContractsTitle>
                      {formatCurrency(linkedContracts?.value || 0)} secured for{' '}
                      {linkedContracts?.linkedNumber} linked payments
                    </TransactionHistoryItemLinkedContractsTitle>
                  </TransactionHistoryItemLinkedReserved>
                ) : null}
                {transactionComponent}
              </TransactionHistoryItemExtraArea>
            ) : null}
          </>
        );
      }

      if (claimLinkStatus === ClaimLinkStatus.NO_MATCH) {
        return (
          <>
            <TransactionHistoryItemAddress>{description}</TransactionHistoryItemAddress>
            <Separator gap={10} decorative />
            <TransactionHistoryItemUnreferencedArea>
              <Flex align="center" gap={2}>
                <SVGIcon name="held" color="#ff0073" style={{ width: '18px', height: '19px' }} />
                <TransactionHistoryItemLinkedContractsTitle held>
                  Assign held funds
                </TransactionHistoryItemLinkedContractsTitle>
              </Flex>
              <TransactionHistoryItemLinkedContractsValueContainer>
                <Button
                  color="primary"
                  variant="solid"
                  disabled={clearingState !== ClearingState.CLEARED}
                  onClick={handleActionClick}
                  css={{
                    '&:disabled': {
                      backgroundColor: '$fgTertiary',
                      cursor: 'unset',
                    },
                  }}
                >
                  Assign funds
                </Button>
              </TransactionHistoryItemLinkedContractsValueContainer>
            </TransactionHistoryItemUnreferencedArea>
          </>
        );
      }

      if (claimLinkStatus === ClaimLinkStatus.NOT_REQUIRED && transactionFee) {
        return (
          <>
            <TransactionHistoryItemAddress>{description}</TransactionHistoryItemAddress>
            <Separator
              gap={10}
              decorative
              css={{
                backgroundColor: '#dadfe5',
              }}
            />
            <TransactionHistoryItemExtraArea>
              {transactionComponent}
            </TransactionHistoryItemExtraArea>
          </>
        );
      }

      if (type === TransactionHistoryType.FEE) {
        return <TransactionHistoryItemAddress>{enrichedDescription}</TransactionHistoryItemAddress>;
      }

      return <TransactionHistoryItemAddress>{description}</TransactionHistoryItemAddress>;
    }, [
      claimLinkStatus,
      transactionFee,
      type,
      description,
      isDispense,
      enrichedDescription,
      linkedContracts,
      handleActionClick,
      transactionComponent,
      clearingState,
    ]);

    const title = useMemo(() => {
      if (type === TransactionHistoryType.FEE) {
        return origin || 'Transaction fee';
      }

      if (internalTransaction) {
        return [displayName, theOtherPartyName, formatAddress(address, true)]
          .filter(Boolean)
          .join(' · ');
      }

      return theOtherPartyName ?? origin ?? 'Unknown';
    }, [type, internalTransaction, theOtherPartyName, origin, displayName, address]);

    if (internalTransaction) {
      return (
        <TransactionHistoryItemContainer clickable={false} ref={ref} {...restProps}>
          <TransactionHistoryItemTitleContainer bold={false}>
            <TransactionHistoryItemTitle>{title}</TransactionHistoryItemTitle>
          </TransactionHistoryItemTitleContainer>
          {claimComponent}
        </TransactionHistoryItemContainer>
      );
    }

    return (
      <TransactionHistoryItemContainer ref={ref} onClick={onClick} {...restProps}>
        <TransactionHistoryItemTitleContainer>
          <TransactionHistoryItemTitle>{title}</TransactionHistoryItemTitle>
          <TransactionHistoryItemPaymentContainer>
            <TransactionHistoryItemPayment
              revert={transactionFeeType === TransactionHistoryType.REVERT}
            >
              {formatCurrency(amount)}
            </TransactionHistoryItemPayment>
            {variationIcon}
          </TransactionHistoryItemPaymentContainer>
        </TransactionHistoryItemTitleContainer>
        {claimComponent}
      </TransactionHistoryItemContainer>
    );
  },
);

TransactionHistoryItem.displayName = 'TransactionHistoryItem';
