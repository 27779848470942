import { PriceVariationMethod } from '@paid-ui/constants';
import { formatCurrency } from '@paid-ui/utils';
import { forwardRef, useMemo } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StyledCurrency } from './_Base';

export type CurrencyAlign = 'left' | 'center' | 'right';
export type CurrencySize = 'small' | 'medium' | 'large' | 'xlarge';

export const defaultCurrencyProps = {
  children: 0 as number | string,
  align: 'left' as CurrencyAlign,
  size: 'medium' as CurrencySize,
  bold: true,
};

export interface CurrencyProps extends BasePropsWithChildren {
  children?: number | string;
  /** Price impact method */
  method?: PriceVariationMethod;
  /** Text align */
  align?: CurrencyAlign;
  /** Font size */
  size?: CurrencySize;
  /** Fixed width of field */
  width?: number | string;
  /** If font bold */
  bold?: boolean;
  /** If use color */
  color?: boolean;
  /** If inline block */
  inline?: boolean;
  /** If disabled or not */
  disabled?: boolean;
  // If abandoned or not
  abandoned?: boolean;
}

export const Currency = forwardRef<HTMLSpanElement, CurrencyProps>((props, ref) => {
  const {
    children = defaultCurrencyProps.children,
    method,
    align = defaultCurrencyProps.align,
    size = defaultCurrencyProps.size,
    width,
    bold = defaultCurrencyProps.bold,
    color: useColor,
    inline,
    disabled,
    css,
    ...restProps
  } = props;

  const cleanedValue = useMemo(() => {
    if (method === undefined) {
      return typeof children === 'string' ? Number.parseFloat(children) : children;
    }

    switch (method) {
      case PriceVariationMethod.INCREASE: {
        return typeof children === 'string'
          ? Math.abs(Number.parseFloat(children))
          : Math.abs(children);
      }

      case PriceVariationMethod.DECREASE: {
        return (
          -1 *
          (typeof children === 'string'
            ? Math.abs(Number.parseFloat(children))
            : Math.abs(children))
        );
      }

      default: {
        return 0;
      }
    }
  }, [method, children]);

  const color = useMemo(() => {
    if (disabled) {
      return 'secondary';
    }

    if (!useColor || cleanedValue === 0) {
      return 'default';
    }

    return cleanedValue < 0 ? 'danger' : 'success';
  }, [cleanedValue, disabled, useColor]);

  return (
    <StyledCurrency
      ref={ref}
      align={align}
      size={size}
      color={color}
      inline={inline}
      bold={bold}
      css={{
        ...css,
        width,
      }}
      {...restProps}
    >
      {formatCurrency(cleanedValue)}
    </StyledCurrency>
  );
});

Currency.displayName = 'Currency';
