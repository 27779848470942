import { styled } from '../design-tokens';

export const StyledProjectGrid = styled('div', {
  'display': 'grid',
  'width': '100%',
  'gridTemplateColumns': 'none',
  'gap': '16px',

  '@sm': {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gap: '14px',
  },

  '@lg': {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: '14px',
  },
});
