import { forwardRef, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { NotificationIcon, StyledCountIndicator } from './_Base';

export type IndicatorColor = 'primary' | 'secondary';

export const defaultCountIndicatorProps = {
  maxCount: 99,
  color: 'primary' as IndicatorColor,
};

export interface CountIndicatorProps extends BaseProps {
  color?: IndicatorColor;
  showIcon?: boolean;
  maxCount?: number;
  children?: number | string | null;
}

export const CountIndicator = forwardRef<HTMLSpanElement, CountIndicatorProps>((props, ref) => {
  const {
    color = defaultCountIndicatorProps.color,
    maxCount = defaultCountIndicatorProps.maxCount,
    children,
    showIcon,
    ...restProps
  } = props;

  const count = useMemo(() => {
    if (!children) {
      return null;
    }

    const numericCount = typeof children === 'string' ? Number.parseInt(children, 10) : children;

    if (numericCount <= 0 || Number.isNaN(numericCount)) {
      return null;
    }

    return numericCount > maxCount ? `${maxCount}+` : children;
  }, [children, maxCount]);

  return (
    <StyledCountIndicator color={color} ref={ref} {...restProps}>
      {showIcon && count ? <NotificationIcon name="notification" /> : null}
      {count}
    </StyledCountIndicator>
  );
});

CountIndicator.displayName = 'CountIndicator';
