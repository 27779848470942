export enum TransferStep {
  CHOOSE_WHO_TO_PAY,
  PAYEE,
  PAY_DETAILS,
  CHECK_DETAILS,
  VERIFICATION,
}

export interface AccountTransferStepper {
  index: TransferStep;
  title: string;
  description?: string;
  included: boolean;
}

export const accountTransferSteps: AccountTransferStepper[] = [
  {
    index: TransferStep.CHOOSE_WHO_TO_PAY,
    title: 'Transfer',
    description: 'Choose who to pay',
    included: true,
  },
  {
    index: TransferStep.PAYEE,
    title: 'Transfer',
    description: 'Payee',
    included: true,
  },
  {
    index: TransferStep.PAY_DETAILS,
    title: 'Transfer',
    description: 'Pay {name}',
    included: true,
  },
  {
    index: TransferStep.CHECK_DETAILS,
    title: 'Transfer',
    description: 'Check details',
    included: true,
  },
  {
    index: TransferStep.VERIFICATION,
    title: 'Transfer',
    description: 'Verification',
    included: true,
  },
];
