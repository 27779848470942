import { forwardRef } from 'react';

import type { BaseProps, Themes } from '../interfaces';
import { ChildContainer, MenuContainer } from './_Base';
import { MenuItem, type MenuItemProps } from './MenuItem';

export const defaultMenuProps = {};

export interface MenuProps extends BaseProps {
  items: MenuItemProps[];
  activeValue?: string;
  theme?: Themes;
}

export const Menu = forwardRef<HTMLDivElement, MenuProps>((props, ref) => {
  const { items, theme, activeValue, ...restProps } = props;
  return (
    <MenuContainer ref={ref} {...restProps} theme={theme}>
      {items.map((item) => (
        <ChildContainer key={item.title}>
          <MenuItem {...item} activeValue={activeValue} />
        </ChildContainer>
      ))}
    </MenuContainer>
  );
});

Menu.displayName = 'Menu';
