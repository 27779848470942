import { NotificationStatus, NotificationType } from '@paid-ui/constants';
import type { NotificationItem, RequiringAction } from '@paid-ui/types';
import groupBy from 'lodash/groupBy';
import { forwardRef, useCallback, useMemo, useState } from 'react';

import { Button } from '../button';
import { SCROLLBAR_SIZE } from '../design-tokens';
import { Flex } from '../flex';
import type { BaseProps } from '../interfaces';
import { Notifications } from '../notifications';
import { RequiringActions } from '../requiring-actions';
import { ScrollArea, ScrollAreaViewport, Scrollbar, ScrollbarThumb } from '../scroll-area';
import { Skeleton } from '../skeleton';
import { Switch } from '../switch';
import { Tabs, TabsContent, TabsList, TabTrigger } from '../tabs';
import { NotificationsPanelContainer } from './_Base';

export const defaultNotificationsPanelProps = {
  activeTab: NotificationType.INFO,
};

export interface NotificationsPanelProps extends BaseProps {
  data: NotificationItem[] | RequiringAction[];
  loading?: boolean;
  activeTab?: NotificationType;
  showActionsOnly?: boolean;
  onTabChange?: (value: string) => void;
  itemType?: 'button' | 'menu-item';
  onItemClick?: (data: NotificationItem | RequiringAction) => void;
  onMarkAllAsRead?: (type?: NotificationType) => void;
}

export const NotificationsPanel = forwardRef<HTMLDivElement, NotificationsPanelProps>(
  (props, ref) => {
    const {
      data,
      loading,
      showActionsOnly,
      activeTab = defaultNotificationsPanelProps.activeTab,
      onTabChange,
      itemType,
      onItemClick,
      onMarkAllAsRead,
      ...restProps
    } = props;
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);

    const { ACTION: actions = [], INFO: notifications = [] } = useMemo(() => {
      return groupBy(
        showUnreadOnly
          ? (data as NotificationItem[]).filter(
              (v) => v.status === NotificationStatus.UNREAD || v.status === NotificationStatus.NEW,
            )
          : data,
        (item: NotificationItem) => item.type,
      );
    }, [data, showUnreadOnly]);

    const handleMarkAllAsRead = useCallback(() => {
      if (typeof onMarkAllAsRead === 'function') {
        onMarkAllAsRead(activeTab);
      }
    }, [activeTab, onMarkAllAsRead]);

    const actionsTabComponent = useMemo(() => {
      return (
        <TabsContent
          value={NotificationType.ACTION}
          css={{
            overflow: 'hidden',
            paddingBottom: 0,
            height: 'calc(100vh - 200px)',
          }}
        >
          <ScrollArea rounded css={{ paddingRight: SCROLLBAR_SIZE }}>
            <ScrollAreaViewport>
              <RequiringActions
                data={data as RequiringAction[]}
                onItemClick={onItemClick}
                loading={loading}
                groupByDate
              />
            </ScrollAreaViewport>
            <Scrollbar orientation="vertical">
              <ScrollbarThumb />
            </Scrollbar>
          </ScrollArea>
        </TabsContent>
      );
    }, [data, loading, onItemClick]);

    if (showActionsOnly) {
      return (
        <NotificationsPanelContainer ref={ref} {...restProps}>
          <Tabs value={NotificationType.ACTION} sticky>
            <TabsList aria-label="Notifications" fullWidth>
              <TabTrigger value={NotificationType.ACTION} border>
                Requiring Action
              </TabTrigger>
            </TabsList>
            {actionsTabComponent}
          </Tabs>
        </NotificationsPanelContainer>
      );
    }

    return (
      <NotificationsPanelContainer ref={ref} {...restProps}>
        <Tabs value={activeTab} onValueChange={onTabChange} sticky>
          <TabsList aria-label="Notifications" fullWidth>
            <TabTrigger value={NotificationType.ACTION} border>
              Requiring Action
            </TabTrigger>
            <TabTrigger value={NotificationType.INFO} border>
              Notifications
            </TabTrigger>
          </TabsList>
          {actionsTabComponent}
          <TabsContent
            value={NotificationType.INFO}
            css={{
              overflow: 'hidden',
              paddingBottom: 0,
              height: 'calc(100vh - 200px)',
            }}
          >
            <ScrollArea rounded css={{ paddingRight: SCROLLBAR_SIZE }}>
              <ScrollAreaViewport>
                <Flex
                  align="center"
                  justify="between"
                  css={{
                    p: '5px',
                    backgroundColor: '$bg',
                  }}
                  gap="2"
                  wrap="wrap"
                  hidden={data.length === 0}
                >
                  {loading ? (
                    <Skeleton width={160} height={18} />
                  ) : (
                    <Switch
                      name="showUnreadOnly"
                      label="Only show unread"
                      value={showUnreadOnly}
                      onChange={setShowUnreadOnly}
                      reversed
                    />
                  )}
                  {loading ? (
                    <Skeleton width={100} height={18} />
                  ) : (
                    <Button
                      type="button"
                      color="default"
                      variant="transparent"
                      onClick={handleMarkAllAsRead}
                    >
                      Mark all as read
                    </Button>
                  )}
                </Flex>
                <Notifications
                  data={notifications as NotificationItem[]}
                  onClick={onItemClick}
                  emptyText="No notifications"
                  itemType={itemType}
                  loading={loading}
                  groupByDate
                />
              </ScrollAreaViewport>
              <Scrollbar orientation="vertical">
                <ScrollbarThumb />
              </Scrollbar>
            </ScrollArea>
          </TabsContent>
        </Tabs>
      </NotificationsPanelContainer>
    );
  },
);

NotificationsPanel.displayName = 'NotificationsPanel';
