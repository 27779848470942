import type { SVGIconName } from '@paid-ui/icons';
import { forwardRef, useCallback, useMemo } from 'react';

import type { CSS } from '../design-tokens';
import type { BaseProps } from '../interfaces';
import {
  MenuItemContainer,
  MenuItemListContainer,
  MenuItemListItem,
  MenuItemListItemLink,
  MenuItemSubtitle,
  MenuItemTitle,
  MenuItemTitleContainer,
  MenuItemTitleIcon,
  MenuItemTitleIconWithDefault,
  StyledMenuItemTitle,
} from './_Base';

export interface ChildrenListItemProps {
  label: string;
  value: string;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  hidden?: boolean;
  active?: boolean;
  disable?: boolean;
  style?: CSS;
  onClick?: (value: string) => void;
}

export interface MenuItemProps extends BaseProps {
  title: string;
  value?: string;
  active?: boolean;
  hidden?: boolean;
  disable?: boolean;
  subTitle?: string;
  activeValue?: string;
  titleIcon?: React.ReactNode;
  titleStyle?: CSS;
  childrenList?: ChildrenListItemProps[];
  titleIconName?: SVGIconName;
  titleIconStyle?: CSS;
  onClick?: (value?: string) => void;
}

const handleChildClick = (child: ChildrenListItemProps) => {
  if (child.disable) {
    return;
  }

  if (typeof child.onClick === 'function') {
    child.onClick(child.value);
  }
};

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>((props, ref) => {
  const {
    title,
    value,
    active,
    hidden,
    disable,
    onClick,
    subTitle,
    titleIcon,
    titleStyle,
    activeValue,
    childrenList,
    titleIconName,
    titleIconStyle,
    ...restProps
  } = props;

  const handleTitleClick = useCallback(() => {
    if (disable) {
      return;
    }

    if (typeof onClick === 'function') {
      onClick(value);
    }
  }, [disable, onClick, value]);

  const checkIsActive = useMemo(() => {
    return active || (!!activeValue && activeValue === value);
  }, [active, activeValue, value]);

  return (
    <MenuItemContainer ref={ref} {...restProps}>
      <MenuItemTitleContainer
        onClick={handleTitleClick}
        disable={disable}
        active={checkIsActive}
        hidden={hidden}
      >
        {titleIcon ? (
          <MenuItemTitleIconWithDefault>{titleIcon}</MenuItemTitleIconWithDefault>
        ) : null}
        {titleIconName ? <MenuItemTitleIcon name={titleIconName} css={titleIconStyle} /> : null}
        <StyledMenuItemTitle>
          <MenuItemTitle css={titleStyle}>{title}</MenuItemTitle>
          {subTitle ? <MenuItemSubtitle>{subTitle}</MenuItemSubtitle> : null}
        </StyledMenuItemTitle>
      </MenuItemTitleContainer>
      {childrenList && childrenList.length > 0 ? (
        <MenuItemListContainer>
          {childrenList
            .filter((child) => !child.hidden)
            .map((child) =>
              child.href ? (
                <MenuItemListItemLink
                  key={child.value}
                  css={child.style}
                  active={child.active || activeValue === child.value}
                  disable={child.disable}
                  href={child.href}
                  target={child.target}
                >
                  {child.label}
                </MenuItemListItemLink>
              ) : (
                <MenuItemListItem
                  key={child.value}
                  css={child.style}
                  active={child.active || activeValue === child.value}
                  disable={child.disable}
                  onClick={() => handleChildClick(child)}
                >
                  {child.label}
                </MenuItemListItem>
              ),
            )}
        </MenuItemListContainer>
      ) : null}
    </MenuItemContainer>
  );
});

MenuItem.displayName = 'MenuItem';
