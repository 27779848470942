import { jwtPattern } from '@paid-ui/regexps';
import type { NextApiHandler, NextApiRequest, NextApiResponse } from 'next';
import type { JsonValue } from 'type-fest';

export interface EnsureRequestOptions {
  methods?: string[];
  validateToken?: boolean;
}

/**
 * Ensure API handler has the legal methods and request params.
 *
 * @param handler - API handler
 * @param options - Supported request methods and whether to validate token
 * @returns API handler
 */
export function ensureRequest(
  handler: NextApiHandler,
  options: EnsureRequestOptions = {},
): NextApiHandler {
  return (req: NextApiRequest, res: NextApiResponse) => {
    const { methods, validateToken } = options;

    if (req.method === undefined || (methods && !methods.includes(req.method))) {
      return res.status(405).json({
        message: `Method '${req.method}' is not allowed.`,
      });
    }

    if (!validateToken || req.method === 'OPTIONS') {
      return handler(req, res);
    }

    if (req.headers.authorization && jwtPattern.test(req.headers.authorization)) {
      return handler(req, res);
    }

    return res.status(401).json({
      message: "You are unauthorized user, can't access this API",
    });
  };
}

/**
 * Run middleware
 *
 * @param req - API request
 * @param res - API response
 * @param fn - Middleware function
 * @returns Promise<unknown>
 */
export function runMiddleware(
  req: NextApiRequest,
  res: NextApiResponse,
  fn: (req: NextApiRequest, res: NextApiResponse, next: (result: unknown) => void) => void,
): Promise<unknown> {
  return new Promise((resolve, reject) => {
    fn(req, res, (result: unknown) => {
      if (result instanceof Error) {
        return reject(result);
      }

      return resolve(result);
    });
  });
}

/**
 * Ensure the query string value is safe for request.
 *
 * @param value - Any safe JSON value.
 * @returns Cleaned safe query string value.
 */
export function ensureQueryString(value: JsonValue | Date): string | null {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'boolean') {
    return value.toString();
  }

  if (typeof value === 'number') {
    return value.toString();
  }

  if (value instanceof Date) {
    return value.toISOString();
  }

  if (Array.isArray(value)) {
    const values = value.map((v) => ensureQueryString(v));
    return values.join(',');
  }

  return null;
}
