import { styled } from '../design-tokens';

export const EmptyText = styled('span', {
  color: '$fgSecondary',
});

export const LoadingText = styled('span', {
  color: '$blue',
  fontWeight: '$semibold',
});
