import { defaultAddress, PartyRole } from '@paid-ui/constants';
import { ContractRole, ContractType } from '@paid-ui/enums/contract';
import { PayType } from '@paid-ui/enums/party';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import type { ContractParticipantParty, UserDetail } from '@paid-ui/types';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { isEmpty, sortBy } from 'lodash';

const unspecifiedPartyName = 'Unspecified party';

/**
 * Get all participants' formatted to display for contract select
 *
 * @param participantParties - participant parties
 * @param profile - User profile
 * @returns Formatted to displays
 */
export function getPartiesNameToDisplay(
  participantParties?: ContractParticipantParty[],
  profile?: UserDetail,
) {
  if (!profile || !participantParties || participantParties.length === 0) {
    return unspecifiedPartyName;
  }

  const targetParticipant = participantParties.find(
    (party) => profile?.groupRelation?.group.id === party?.userGroup?.id,
  );
  const hasBusiness = !isEmpty(targetParticipant?.businessContractInfo);
  const firstUser = targetParticipant?.usersInfo?.find(
    (user) => user.id === targetParticipant?.nominee?.id,
  );
  const usersLength = targetParticipant?.usersInfo?.length ?? 1;
  const userName =
    usersLength > 1
      ? [(firstUser?.firstName, firstUser?.lastName)].join('')
      : [firstUser?.firstName, firstUser?.lastName, ` + ${usersLength - 1} other`].join('');

  switch (targetParticipant?.payType) {
    case PayType.PAYER: {
      return hasBusiness ? targetParticipant.displayName : userName;
    }

    case PayType.PAYEE: {
      return targetParticipant.displayName ?? userName;
    }

    default: {
      return unspecifiedPartyName;
    }
  }
}

/**
 * Format contract party to invitee
 *
 * @param party - Contract party
 * @returns Formatted invitee
 */
export function formatPartyToInvitee(party?: ContractParticipantParty | null) {
  const partyInfo = {
    firstName: party?.nominee?.firstName ?? '',
    lastName: party?.nominee?.lastName ?? '',
    email: party?.nominee?.email ?? '',
    confirmEmail: party?.nominee?.email ?? '',
  };
  if (!party) {
    return {
      businessId: '',
      businessName: '',
      type: PayType.PAYEE,
      address: defaultAddress,
      partyInfo,
    };
  }

  const business = party.userGroup?.business;

  if (business) {
    return {
      businessId: business?.id ?? '',
      businessName: business?.registeredName ?? '',
      type: party.payType ?? PayType.PAYEE,
      address: business?.registeredAddress ?? defaultAddress,
      partyInfo,
      displayName: party?.displayName,
    };
  }

  return {
    businessId: '',
    businessName: '',
    type: PayType.PAYEE,
    address: defaultAddress,
    partyInfo,
  };
}

/**
 * Get first inviteeParty from contract participant parties.
 *
 * @param parties - All contract participant parties
 * @returns First invitee or null
 */
export function getInviteeParty(parties: ContractParticipantParty[] = []) {
  return (
    parties.find(
      (participant) =>
        participant.invitationType === PartyRole.INVITEE &&
        [PayType.PAYEE, PayType.PAYER, PayType.INTERMEDIARY].includes(participant.payType),
    ) ?? null
  );
}

/**
 * Get first inviterParty from contract participant parties.
 *
 * @param parties All contract participant parties
 * @returns First invitee or null
 */
export function getInviterParty(parties: ContractParticipantParty[] = []) {
  return (
    parties.find(
      (participant) =>
        participant.invitationType === PartyRole.INVITER &&
        [PayType.PAYEE, PayType.PAYER, PayType.INTERMEDIARY].includes(participant.payType),
    ) ?? null
  );
}

/**
 * Get payee party from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayeeParty(participants: ContractParticipantParty[] = []) {
  return participants.find((participant) => participant.payType === PayType.PAYEE) ?? null;
}

/**
 * Get payer party from contract participants.
 *
 * @param participants All contract participants
 * @returns First payer or null
 */
export function getPayerParty(participants: ContractParticipantParty[] = []) {
  return participants.find((participant) => participant.payType === PayType.PAYER) ?? null;
}

/**
 * Get first superintendent from contract participants.
 *
 * @param participants All contract participants
 * @returns First superintendent or null
 */
export function getSuperintendentParty(participants: ContractParticipantParty[] = []) {
  return (
    participants.find((participant) => participant.role?.role === ContractRole.SUPERINTENDENT) ??
    null
  );
}

/**
 * Get first architect from contract participants.
 *
 * @param participants All contract participants
 * @returns First architect or null
 */
export function getArchitectParty(participants: ContractParticipantParty[] = []) {
  return (
    participants.find((participant) => participant.role?.role === ContractRole.ARCHITECT) ?? null
  );
}

/**
 * Get two contract parties based on participants data
 *
 * @param parties - All participant parties
 * @returns Current party and the other party
 */
export const getContractParticipantParties = (parties: ContractParticipantParty[] = []) => {
  const currentParty = parties.find((party) => party.isCurrentParty);

  if (!currentParty)
    return {
      theParty: null,
      currentParty: null,
      theOtherParty: null,
    };

  const currentPayType = currentParty.payType;
  const payeeParty = parties.find((party) => party.payType === PayType.PAYEE) ?? null;
  const payerParty = parties.find((party) => party.payType === PayType.PAYER) ?? null;
  const reviewerParty =
    parties.find(
      (party) =>
        party.payType !== PayType.PAYEE &&
        party.role?.actions.includes(ContractAction.CONTRACT_REVIEW),
    ) ?? payerParty;

  return {
    theParty: currentParty,
    // Alias to the party
    currentParty,
    theOtherParty: currentPayType === PayType.PAYEE ? reviewerParty : payeeParty,
  };
};

/**
 * Get last reviewer in contract participant parties.
 *
 * @param participantParties - All participant parties
 * @returns Last reviewer participant
 */
export function getLastReviewParty(participantParties: ContractParticipantParty[]) {
  return (
    sortBy(participantParties, (party) => {
      return party.snapshotStatus?.createdAt
        ? -utc2Mel(party.snapshotStatus.createdAt).valueOf()
        : 0;
    }).at(0) ?? null
  );
}

/**
 * Get contract role based on contract type and party.
 *
 * @param contractType Contract type
 * @param party Contract Party
 * @returns Contract role
 */
export function getPartyRole(contractType: ContractType, party: ContractParticipantParty | null) {
  if (!party) {
    return ContractRole.BUILDER;
  }
  if (contractType === ContractType.SUBCONTRACT) {
    if (party.payType === PayType.PAYER) {
      return ContractRole.BUILDER;
    }
    return ContractRole.SUBCONTRACTOR;
  }
  if (contractType === ContractType.SUPPLY_CONTRACT) {
    if (party.payType === PayType.PAYER) {
      return ContractRole.BUILDER;
    }
    return ContractRole.SUPPLIER;
  }
  if (party.payType === PayType.PAYER) {
    return ContractRole.PRINCIPAL;
  }
  if (party.payType === PayType.INTERMEDIARY) {
    return ContractRole.SUPERINTENDENT;
  }
  return ContractRole.BUILDER;
}
