import { forwardRef, useCallback, useState } from 'react';

import { type CSS } from '../design-tokens';
import type { BasePropsWithChildren } from '../interfaces';
import {
  ClampSpan,
  CollapseButton,
  PanelContainer,
  PanelContent,
  PanelHeader,
  PanelHeaderExtra,
  PanelHeaderTitle,
  StyledSVGIcon,
} from './_Base';

export const defaultPanelProps = {
  defaultExpanded: true,
};

export interface PanelProps extends BasePropsWithChildren {
  /** Title of panel */
  title?: React.ReactNode;
  /** Extra node of panel */
  extra?: React.ReactNode;
  /** If the panel collapsible */
  collapsible?: boolean;
  /** Default expanded state of panel */
  defaultExpanded?: boolean;
  /** Without border */
  noBorder?: boolean;
  /** Without padding inside */
  noPadding?: boolean;
  /** Compact mode panal */
  compact?: boolean;
  /** Hide panel or not */
  hidden?: boolean;
  /** Expanded state */
  value?: boolean;
  /** Header style */
  headerStyle?: CSS;
  titleStyle?: CSS;
  disabled?: boolean;
  /** Expanded state change handler */
  onChange?: (value: boolean) => void;
  /** Content style */
  contentStyle?: CSS;
  /** Extra content */
  extraContent?: React.ReactNode;
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const {
    title,
    extra,
    collapsible,
    defaultExpanded = defaultPanelProps.defaultExpanded,
    noBorder,
    noPadding,
    compact,
    hidden,
    value,
    disabled,
    onChange,
    children,
    titleStyle,
    headerStyle,
    contentStyle,
    extraContent,
    ...restProps
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleCollapse = useCallback(() => {
    if (!collapsible || disabled) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(!expanded);
    }
    setExpanded((prevState) => !prevState);
  }, [collapsible, disabled, expanded, onChange]);

  if (hidden) {
    return null;
  }

  return (
    <PanelContainer
      ref={ref}
      noBorder={noBorder}
      noPadding={noPadding}
      compact={compact}
      aria-expanded={expanded ? 'true' : 'false'}
      {...restProps}
    >
      <PanelHeader hidden={!title} css={headerStyle}>
        <PanelHeaderTitle compact={compact} css={titleStyle}>
          {collapsible ? (
            <CollapseButton
              type="button"
              onClick={handleCollapse}
              compact={compact}
              disabled={disabled}
            >
              <StyledSVGIcon name="collapse" expanded={expanded} />
              <ClampSpan disabled={disabled}>{title}</ClampSpan>
            </CollapseButton>
          ) : (
            title
          )}
        </PanelHeaderTitle>
        <PanelHeaderExtra>{extra}</PanelHeaderExtra>
      </PanelHeader>
      {expanded || !collapsible ? <PanelContent css={contentStyle}>{children}</PanelContent> : null}
      {extraContent}
    </PanelContainer>
  );
});

Panel.displayName = 'Panel';
