export enum SignatureType {
  INITIAL = 'INITIAL',
  FULL_NAME = 'FULL_NAME',
}

export enum UserSignatureStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EntityType {
  CONTRACT = 'CONTRACT',
}

export enum SigningStatus {
  PENDING = 'PENDING',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
}
