import { SVGIcon } from '@paid-ui/icons';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';

import { dialogContentShow, dialogOverlayShow, styled } from '../design-tokens';

export const AlertDialogRoot = AlertDialogPrimitive.Root;
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger;
export const AlertDialogPortal = AlertDialogPrimitive.Portal;
export const AlertDialogAction = AlertDialogPrimitive.Action;

export const AlertDialogOverlay = styled(AlertDialogPrimitive.Overlay, {
  'position': 'fixed',
  'inset': 0,
  'backgroundColor': '$bgOverlay',
  'animation': `${dialogOverlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  'zIndex': 700,

  '@motion': {
    animation: 'none',
  },

  'variants': {
    nested: {
      true: {
        zIndex: 800,
      },
    },
  },
});

export const AlertDialogContent = styled(AlertDialogPrimitive.Content, {
  'position': 'fixed',
  'top': '50%',
  'left': '50%',
  'transform': 'translate(-50%, -50%)',
  'width': '400px',
  'height': 'auto',
  'maxWidth': 'calc(100vw - 40px)',
  'maxHeight': 'calc(100vh - 20px)',
  'padding': '40px 30px',
  'zIndex': 701,
  'overflow': 'hidden',
  'backgroundColor': '$bg',
  'borderRadius': '0px 16px 16px',
  'outline': 'none',
  'animation': `${dialogContentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  '@motion': {
    animation: 'none',
  },

  '@sm': {
    padding: '40px 20px',
  },

  'variants': {
    nested: {
      true: {
        zIndex: 801,
      },
    },
  },
});

export const AlertDialogIcon = styled(SVGIcon, {
  margin: '0 auto 24px',
  width: '24px',
  height: '24px',
  color: '$fgSecondary',
});

export const AlertDialogTitle = styled(AlertDialogPrimitive.Title, {
  fontSize: '22px',
  fontWeight: '$semibold',
  lineHeight: '27px',
  color: '$fg',

  variants: {
    center: {
      true: {
        textAlign: 'center',
      },
    },
  },
});

export const AlertDialogDescription = styled(AlertDialogPrimitive.Description, {
  'margin': '14px auto 36px',
  'fontSize': '16px',
  'fontWeight': '$normal',
  'lineHeight': '22px',
  'color': '$fg',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    center: {
      true: {
        textAlign: 'center',
      },
    },
  },
});

export const DialogClose = styled(AlertDialogPrimitive.Cancel, {
  position: 'absolute',
  top: '20px',
  right: '20px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const DialogCloseButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  padding: '6px',
  size: '32px',
  cursor: 'pointer',
});

export const DialogCloseIcon = styled(SVGIcon, {
  'size': '20px',

  '@hover': {
    'color': '$fgSecondary',
    'transition': 'color 100ms',

    '&:hover': {
      color: '$blue',
    },
  },
});

export const ActionGroup = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'width': '100%',
  'gap': '10px',

  '@sm': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  'variants': {
    center: {
      true: {
        '@sm': {
          justifyContent: 'center',
        },
      },
    },
  },
});
