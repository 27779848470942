import { UploadStatus } from '@paid-ui/enums/attachment';
import { EvidenceCategory } from '@paid-ui/enums/evidence';
import { type Attachment } from '@paid-ui/schemas/zod/attachment';
import { type Evidence } from '@paid-ui/types';
import { formatGeolocationInfo, isVideo, preview } from '@paid-ui/utils';
import { nanoid } from 'nanoid';
import { forwardRef, useCallback, useMemo } from 'react';

import { AttachmentActionType } from '../attachment-list';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { Tooltip } from '../tooltip';
import {
  ActionButton,
  ActionIcon,
  GeoInfo,
  GeoInfoIcon,
  GeoInfoText,
  Image,
  ImageContainer,
  ImageGrid,
  PreviewGridLabel,
  Video,
  VideoIcon,
} from './_Base';

export const defaultPreviewGridProps = {
  action: AttachmentActionType.PREVIEW,
  emptyText: 'No attachments',
};

export interface PreviewGridProps extends BaseProps {
  data: Attachment[] | Evidence[];
  label?: React.ReactNode;
  bucketName: string;
  action?: AttachmentActionType;
  emptyText?: string;
  onRemove?: (index: number) => Promise<void> | void;
  onPreview?: (index: number) => void;
}

export const PreviewGrid = forwardRef<HTMLDivElement, PreviewGridProps>((props, ref) => {
  const {
    data,
    label,
    bucketName,
    action = defaultPreviewGridProps.action,
    emptyText = defaultPreviewGridProps.emptyText,
    onRemove,
    onPreview,
    ...restProps
  } = props;

  const dataWithId = useMemo(() => {
    return (data as Evidence[])
      .filter((v) => v.category !== EvidenceCategory.DOCUMENT)
      .map((v) => ({ ...v, id: v.id || nanoid() }));
  }, [data]);

  const handleEvidenceRemove = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: string) => {
      event.stopPropagation();

      if (id && typeof onRemove === 'function') {
        const index = dataWithId.findIndex((v) => v.id === id);

        if (index !== -1) {
          await onRemove(index);
        }
      }
    },
    [dataWithId, onRemove],
  );

  const handlePreview = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, file: Attachment | Evidence) => {
      event.preventDefault();

      if (file.status !== undefined && file.status !== UploadStatus.UPLOAD_SUCCESS) {
        return;
      }

      const index = dataWithId.findIndex((v) => v.id === file.id);

      if (index !== -1) {
        if (typeof onPreview === 'function') {
          onPreview(index);
        } else {
          await preview(file, bucketName);
        }
      }
    },
    [bucketName, dataWithId, onPreview],
  );

  return (
    <Space size={36} direction="vertical" inline={false}>
      <PreviewGridLabel>{label}</PreviewGridLabel>
      {dataWithId.length === 0 ? (
        <p>{emptyText}</p>
      ) : (
        <ImageGrid ref={ref} {...restProps}>
          {dataWithId.map((file) => (
            <ImageContainer onClick={(event) => handlePreview(event, file)} key={file.id}>
              <VideoIcon name="videos-2" hidden={!isVideo(file)} />
              <GeoInfo>
                <GeoInfoIcon name="location" color={formatGeolocationInfo(file).color} />
                {file.geolocation?.geolocatable ? (
                  <Tooltip title={formatGeolocationInfo(file).text} side="bottom">
                    <GeoInfoText>{formatGeolocationInfo(file).text}</GeoInfoText>
                  </Tooltip>
                ) : (
                  <GeoInfoText>{formatGeolocationInfo(file).text}</GeoInfoText>
                )}
              </GeoInfo>
              <ActionButton
                type="button"
                hidden={
                  action !== AttachmentActionType.REMOVE ||
                  (action === AttachmentActionType.REMOVE && file.status === UploadStatus.WAITING)
                }
                loading={file.status === UploadStatus.UPLOADING}
                onClick={(event) => handleEvidenceRemove(event, file.id)}
              >
                <ActionIcon
                  loading={file.status === UploadStatus.UPLOADING}
                  name={file.status === UploadStatus.UPLOADING ? 'loading' : 'remove'}
                />
              </ActionButton>
              {file.category === 'VIDEO' ? (
                <Video
                  src={file.tempFileAddress}
                  muted
                  autoPlay
                  loop
                  preload="auto"
                  controls={false}
                  controlsList="nofullscreen"
                />
              ) : (
                <Image src={file.tempFileAddress} alt={file.fileName} />
              )}
            </ImageContainer>
          ))}
        </ImageGrid>
      )}
    </Space>
  );
});

PreviewGrid.displayName = 'PreviewGrid';
