import { SVGIcon } from '@paid-ui/icons';
import { green, red, slate } from '@radix-ui/colors';

import { css, styled } from '../design-tokens';

export const StyledSVGIcon = styled(SVGIcon, {
  color: 'currentColor',

  variants: {
    size: {
      small: {
        size: '12.5px',
      },

      medium: {
        size: '14px',
      },

      large: {
        size: '16px',
      },

      xlarge: {
        size: '20px',
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});

const buttonStyles = css({
  '$$focusColor': '$colors$lightBlue',

  // Reset
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'inline-flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'flexShrink': 0,
  'cursor': 'pointer',
  'userSelect': 'none',
  'fontVariantNumeric': 'tabular-nums',

  '&::before': {
    boxSizing: 'border-box',
  },

  '&::after': {
    boxSizing: 'border-box',
  },

  // Custom base
  'width': 'auto',
  'borderRadius': '4px',
  'fontWeight': '$medium',
  'transition': 'all 100ms',

  '&:disabled': {
    border: 'none',
    backgroundColor: '$bgGroup',
    boxShadow: `inset 0 0 0 1px ${slate.slate7}`,
    color: slate.slate8,
    pointerEvents: 'none',
  },

  'variants': {
    weight: {
      normal: {
        fontWeight: '$normal',
      },

      medium: {
        fontWeight: '$medium',
      },

      semibold: {
        fontWeight: '$semibold',
      },
    },

    size: {
      small: {
        'columnGap': '4px',
        'height': '28px',
        'padding': '7px 6px',
        'fontSize': '12.5px',
        'lineHeight': '14px',

        '&:focus': {
          boxShadow: '0 0 0 1px $$focusColor',
        },
      },

      medium: {
        'columnGap': '5px',
        'height': '32px',
        'padding': '8px 7px',
        'fontSize': '14px',
        'lineHeight': '16px',

        '&:focus': {
          boxShadow: '0 0 0 2px $$focusColor',
        },
      },

      large: {
        'columnGap': '10px',
        'height': '36px',
        'padding': '10px 20px',
        'fontSize': '16px',
        'lineHeight': '18px',

        '&:focus': {
          boxShadow: '0 0 0 3px $$focusColor',
        },
      },

      xlarge: {
        'columnGap': '8px',
        'height': '54px',
        'padding': '16px 12px',
        'borderRadius': '6px',
        'fontSize': '20px',
        'lineHeight': '22px',

        '&:focus': {
          boxShadow: '0 0 0 4px $$focusColor',
        },
      },
    },

    variant: {
      solid: {
        'border': 'none',
        'backgroundColor': '$fgSecondary',
        'color': 'white',

        '@hover': {
          '&:hover': {
            border: 'none',
            backgroundColor: '$blue',
            color: 'white',
          },
        },
      },

      outline: {
        'border': '1px solid $colors$fgSecondary',
        'backgroundColor': 'transparent',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            border: '1px solid $colors$blue',
            backgroundColor: 'transparent',
            color: '$blue',
          },
        },
      },

      light: {
        'border': 'none',
        'backgroundColor': '$bgGroup',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            border: 'none',
            backgroundColor: '$bgBlue',
            color: '$blue',
          },
        },
      },

      transparent: {
        'border': 'none',
        'backgroundColor': 'transparent',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            border: 'none',
            backgroundColor: 'transparent',
            color: '$blue',
          },
        },
      },
    },

    color: {
      default: {
        'backgroundColor': '$fgSecondary',
        'color': 'white',

        '@hover': {
          '&:hover': {
            backgroundColor: '$blue',
            color: 'white',
          },
        },
      },

      primary: {
        'backgroundColor': '$blue',
        'color': 'white',

        '@hover': {
          '&:hover': {
            backgroundColor: '$hoverBlue',
            color: 'white',
          },
        },

        '&:disabled': {
          backgroundColor: '$fgTertiary',
          boxShadow: 'none',
          color: 'white',
        },
      },

      success: {
        'backgroundColor': '$green',
        'color': 'white',

        '@hover': {
          '&:hover': {
            backgroundColor: '$green',
            color: 'white',
          },
        },
      },

      danger: {
        'backgroundColor': '$pink',
        'color': 'white',

        '@hover': {
          '&:hover': {
            backgroundColor: '$hoverPink',
            color: 'white',
          },
        },
      },
    },

    animated: {
      true: {
        '&:active': {
          transform: 'scale(0.97)',
        },
      },
    },

    block: {
      true: {
        display: 'flex',
        width: '100%',
      },
    },
  },

  'compoundVariants': [
    {
      variant: 'solid',
      color: 'default',
      css: {
        'backgroundColor': '$fgSecondary',

        '@hover': {
          '&:hover': {
            backgroundColor: '$blue',
          },
        },
      },
    },
    {
      variant: 'solid',
      color: 'primary',
      css: {
        'backgroundColor': '$blue',

        '@hover': {
          '&:hover': {
            backgroundColor: '$hoverBlue',
          },
        },
      },
    },
    {
      variant: 'solid',
      color: 'success',
      css: {
        'backgroundColor': '$green',

        '@hover': {
          '&:hover': {
            backgroundColor: '$green',
          },
        },
      },
    },
    {
      variant: 'solid',
      color: 'danger',
      css: {
        'backgroundColor': '$pink',

        '@hover': {
          '&:hover': {
            backgroundColor: '$hoverPink',
          },
        },
      },
    },

    {
      variant: 'outline',
      color: 'default',
      css: {
        'borderColor': '$fgSecondary',
        'backgroundColor': 'transparent',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            borderColor: '$blue',
            color: '$blue',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    {
      variant: 'light',
      color: 'default',
      css: {
        'backgroundColor': '$bgGroup',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            backgroundColor: '$bgBlue',
            color: '$blue',
          },
        },
      },
    },
    {
      variant: 'transparent',
      color: 'default',
      css: {
        'backgroundColor': 'transparent',
        'color': '$fgSecondary',

        '@hover': {
          '&:hover': {
            backgroundColor: 'transparent',
            color: '$blue',
          },
        },
      },
    },

    {
      variant: 'outline',
      color: 'primary',
      css: {
        'borderColor': '$blue',
        'color': '$blue',
        'backgroundColor': 'transparent',

        '@hover': {
          '&:hover': {
            borderColor: '$blue',
            color: '$blue',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    {
      variant: 'light',
      color: 'primary',
      css: {
        'backgroundColor': '$bgBlue',
        'color': '$blue',

        '@hover': {
          '&:hover': {
            backgroundColor: '$bgBlue',
            color: '$blue',
          },
        },
      },
    },
    {
      variant: 'transparent',
      color: 'primary',
      css: {
        'backgroundColor': 'transparent',
        'color': '$blue',

        '@hover': {
          '&:hover': {
            backgroundColor: 'transparent',
            color: '$blue',
          },
        },
      },
    },

    {
      variant: 'outline',
      color: 'success',
      css: {
        'color': '$green',
        'borderColor': '$green',
        'backgroundColor': 'transparent',

        '@hover': {
          '&:hover': {
            color: '$green',
            borderColor: '$green',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    {
      variant: 'light',
      color: 'success',
      css: {
        'backgroundColor': green.green3,
        'color': '$green',

        '@hover': {
          '&:hover': {
            backgroundColor: green.green3,
            color: '$green',
          },
        },
      },
    },
    {
      variant: 'transparent',
      color: 'success',
      css: {
        'backgroundColor': 'transparent',
        'color': '$green',

        '@hover': {
          '&:hover': {
            backgroundColor: 'transparent',
            color: '$green',
          },
        },
      },
    },

    {
      variant: 'outline',
      color: 'danger',
      css: {
        'color': '$pink',
        'borderColor': '$pink',
        'backgroundColor': 'transparent',

        '@hover': {
          '&:hover': {
            color: '$pink',
            borderColor: '$pink',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    {
      variant: 'light',
      color: 'danger',
      css: {
        'backgroundColor': red.red3,
        'color': '$pink',

        '@hover': {
          '&:hover': {
            backgroundColor: red.red3,
            color: '$pink',
          },
        },
      },
    },
    {
      variant: 'transparent',
      color: 'danger',
      css: {
        'backgroundColor': 'transparent',
        'color': '$pink',

        '@hover': {
          '&:hover': {
            backgroundColor: 'transparent',
            color: '$pink',
          },
        },
      },
    },
  ],

  'defaultVariants': {
    color: 'default',
    size: 'medium',
    variant: 'solid',
  },
});

export const StyledButton = styled('button', buttonStyles);

export const StyledLinkButton = styled('a', buttonStyles, {
  variants: {
    disabled: {
      true: {
        border: 'none',
        backgroundColor: '$bgGroup',
        boxShadow: `inset 0 0 0 1px ${slate.slate7}`,
        color: slate.slate8,
        pointerEvents: 'none',
      },
    },
  },
});
