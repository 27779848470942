import * as ProgressPrimitive from '@radix-ui/react-progress';

import { styled } from '../design-tokens';

export const ProgressRoot = styled(ProgressPrimitive.Root, {
  position: 'relative',
  width: '100%',
  height: '6px',
  borderRadius: '8px',
  overflow: 'hidden',

  variants: {
    transparent: {
      true: {
        backgroundColor: 'transparent',
      },

      false: {
        backgroundColor: '$fgFaint',
      },
    },

    compact: {
      true: {
        height: '4px',
      },
      false: {},
    },
  },

  defaultVariants: {
    transparent: false,
  },
});

export const ProgressIndicator = styled(ProgressPrimitive.Indicator, {
  size: '100%',
  backgroundColor: '$blue',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',

  variants: {
    color: {
      white: {
        backgroundColor: 'white',
      },
      blue: {
        backgroundColor: '$blue',
      },

      pink: {
        backgroundColor: '$pink',
      },

      green: {
        backgroundColor: '$green',
      },

      grey: {
        backgroundColor: '$fgTertiary',
      },

      midGrey: {
        backgroundColor: '$midGrey',
      },

      darkGrey: {
        backgroundColor: '$darkGrey',
      },
    },

    rounded: {
      true: {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },

    hoverable: {
      true: {
        '&:hover': {
          backgroundColor: '$fg',
        },
      },
    },

    disabled: {
      true: {
        backgroundColor: '$fgFaint',
      },
    },
  },

  compoundVariants: [
    {
      color: 'blue',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'pink',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'green',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'grey',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
  ],

  defaultVariants: {
    color: 'blue',
    rounded: true,
  },
});

export const DefaultProgressLabel = styled('div', {
  'color': '$fg',
  'fontSize': '16px',
  'lineHeight': '22px',
  'fontWeight': '$normal',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const DefaultProgressHeader = styled('div', {
  'color': '$fg',
  'fontSize': '22px',
  'lineHeight': '27px',
  'fontWeight': '$semibold',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const DefaultProgressFooter = styled('div', {
  'color': '$fg',
  'fontSize': '16px',
  'lineHeight': '22px',
  'fontWeight': '$normal',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const RestProgressIndicator = styled('div', {
  height: '100%',

  variants: {
    color: {
      blue: {
        background: `linear-gradient(to right, ${'$blue'}, ${'$blue'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },

      pink: {
        background: `linear-gradient(to right, ${'$pink'}, ${'$pink'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },

      green: {
        background: `linear-gradient(to right, ${'$green'}, ${'$green'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },

      grey: {
        background: `linear-gradient(to right, ${'$fgTertiary'}, ${'$fgTertiary'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },

      midGrey: {
        background: `linear-gradient(to right, ${'$midGrey'}, ${'$midGrey'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },
      darkGrey: {
        background: `linear-gradient(to right, ${'$darkGrey'}, ${'$darkGrey'} 2px, transparent 2px, transparent)`,
        backgroundSize: '4px 100%',
      },
    },

    hoverable: {
      true: {
        '&:hover': {
          background: `linear-gradient(to right, $colors$fg, $colors$fg 2px, transparent 2px, transparent)`,
          backgroundSize: '4px 100%',
        },
      },
    },

    disabled: {
      true: {
        backgroundColor: '$fgFaint',
        backgroundSize: 'unset',
      },
    },
  },

  compoundVariants: [
    {
      color: 'blue',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
        backgroundSize: 'unset',
      },
    },
    {
      color: 'pink',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
        backgroundSize: 'unset',
      },
    },
    {
      color: 'green',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
        backgroundSize: 'unset',
      },
    },
    {
      color: 'grey',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
        backgroundSize: 'unset',
      },
    },
    {
      disabled: true,
      hoverable: true,
      css: {
        '&:hover': {
          backgroundColor: '$fgFaint',
          backgroundSize: 'unset',
        },
      },
    },
  ],
});
