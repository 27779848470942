import { styled } from '../design-tokens';

export const Flex = styled('div', {
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
      rowReverse: {
        flexDirection: 'row-reverse',
      },
      columnReverse: {
        flexDirection: 'column-reverse',
      },
    },
    align: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
      between: {
        justifyContent: 'space-between',
      },
    },
    wrap: {
      noWrap: {
        flexWrap: 'nowrap',
      },
      wrap: {
        flexWrap: 'wrap',
      },
      wrapReverse: {
        flexWrap: 'wrap-reverse',
      },
    },
    gap: {
      '0': {
        gap: 0,
      },
      '0_5': {
        gap: '2px',
      },
      '1': {
        gap: '4px',
      },
      '1_5': {
        gap: '6px',
      },
      '2': {
        gap: '8px',
      },
      '2_5': {
        gap: '10px',
      },
      '3': {
        gap: '12px',
      },
      '3_5': {
        gap: '14px',
      },
      '4': {
        gap: '16px',
      },
      '5': {
        gap: '20px',
      },
      '6': {
        gap: '24px',
      },
      '7': {
        gap: '28px',
      },
      '8': {
        gap: '32px',
      },
      '9': {
        gap: '36px',
      },
      '10': {
        gap: '40px',
      },
    },

    fullWidth: {
      true: {
        width: '100%',
      },
    },

    fullHeight: {
      true: {
        height: '100%',
      },
    },

    sticky: {
      true: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
