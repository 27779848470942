import {
  ClaimStatus,
  PartyType,
  PaymentSecuredStatus,
  PaymentStageName,
  paymentStageNames,
  SecuredType,
} from '@paid-ui/constants';
import type { LinkablePayment as NewLinkablePayment } from '@paid-ui/enums/payment';
import type {
  LinkablePayment,
  LinkedProgressPayment,
  PaymentStage,
  ProgressPaymentSummary,
  SecuredProgressPayment,
  SecuredVariation,
} from '@paid-ui/types';
import clamp from 'lodash/clamp';
import sumBy from 'lodash/sumBy';

import { nowMel, utc2Mel } from './datetime';

type PossiblePaymentStage =
  | PaymentStage
  | ProgressPaymentSummary
  | LinkablePayment
  | LinkedProgressPayment
  | PaymentStageName
  | NewLinkablePayment
  | string
  | null;

/**
 * Get formatted stage name of payment stage
 *
 * @param stage - Payment stage
 * @param customName - Custom payment name
 * @returns Formatted stage name
 */
export function getStageName(stage?: PossiblePaymentStage, customName?: string): string {
  if (!stage) {
    return 'Unknown';
  }

  if (typeof stage === 'string') {
    const baseStageName = paymentStageNames[stage as PaymentStageName];

    if (!baseStageName) {
      return stage;
    }

    if (!customName) {
      return baseStageName;
    }

    return [PaymentStageName.OTHER, PaymentStageName.UNKNOWN].includes(stage as PaymentStageName)
      ? customName
      : `${baseStageName}: ${customName}`;
  }

  const baseStageName = paymentStageNames[stage.stage];

  if (!baseStageName) {
    return stage.stage;
  }

  if (!stage.customName) {
    return baseStageName;
  }

  return [PaymentStageName.OTHER, PaymentStageName.UNKNOWN].includes(stage.stage)
    ? stage.customName
    : `${baseStageName}: ${stage.customName}`;
}

/**
 * Format progress payments.
 *
 * @param progressPayments - Progress payments of the contract.
 * @param disabled - Disable or not
 * @returns formatted progress payments
 */
export function formatProgressPayments(progressPayments: PaymentStage[], disabled = false) {
  const notStartedClaim = progressPayments.every((payment) => payment.claim === undefined);
  const depositStage = progressPayments.find(
    (payment) => payment.stage === PaymentStageName.DEPOSIT,
  );

  const restPayments = progressPayments.filter((payment) => depositStage?.id !== payment.id);

  const payments = depositStage
    ? ([depositStage, ...restPayments] as PaymentStage[])
    : progressPayments;

  if (disabled) {
    return payments.map((payment) => ({
      ...payment,
      disabled: true,
    }));
  }

  if (notStartedClaim && depositStage) {
    return payments.map((payment) => ({
      ...payment,
      disabled: payment.stage !== PaymentStageName.DEPOSIT,
    }));
  }

  const notAllPaidList = payments.filter(
    (payment) =>
      ![ClaimStatus.APPROVED, ClaimStatus.PAID].includes(payment.claim?.state as ClaimStatus),
  );

  const isFinalNotAllowed = notAllPaidList.length > 1;

  return payments.map((payment, index) => {
    if (index === payments.length - 1 && isFinalNotAllowed) {
      return { ...payment, disabled: true };
    }
    return payment;
  });
}

/**
 * Format progress payments with linked variations.
 *
 * @param progressPayments - Progress payments of the contract.
 * @param disabled - Disable or not
 * @returns formatted progress payments
 */
export function formatProgressPaymentsWithVariations(
  progressPayments: PaymentStage[],
  disabled = false,
) {
  const notStartedClaim = progressPayments.every((payment) => payment.claim === undefined);
  const depositStage = progressPayments.find(
    (payment) => payment.stage === PaymentStageName.DEPOSIT,
  );

  const restPayments = progressPayments.filter((payment) => depositStage?.id !== payment.id);

  const payments = depositStage
    ? ([depositStage, ...restPayments] as PaymentStage[])
    : progressPayments;

  if (disabled) {
    return payments.map((payment) => ({
      ...payment,
      disabled: true,
    }));
  }

  if (notStartedClaim && depositStage) {
    return payments.map((payment) => ({
      ...payment,
      disabled: payment.stage !== PaymentStageName.DEPOSIT,
    }));
  }

  const notAllPaidList = payments.filter(
    (payment) =>
      ![ClaimStatus.APPROVED, ClaimStatus.PAID].includes(payment.claim?.state as ClaimStatus),
  );

  const isFinalNotAllowed = notAllPaidList.length > 1;

  return payments.map((payment, index) => {
    if (index === payments.length - 1 && isFinalNotAllowed) {
      return {
        ...payment,
        disabled: true,
      };
    }
    return payment;
  });
}

/**
 * Get claim status text.
 *
 * @param claimStatus - Claim status of the progress payment.
 * @param options - Options of util
 * @param options.isPeriodic  - Is this periodic claim or not.
 * @param options.isHistory - Is for history usage.
 * @returns Formatted claim status text
 */
export function getClaimStatusText(
  claimStatus?: ClaimStatus,
  options?: {
    isPeriodic?: boolean;
    isHistory?: boolean;
  },
) {
  const { isPeriodic, isHistory } = options ?? {};

  switch (claimStatus) {
    case ClaimStatus.PROVISIONAL: {
      return isHistory ? 'Created' : 'Not yet submitted';
    }

    case ClaimStatus.SUBMITTED: {
      return isHistory ? 'Submitted' : isPeriodic ? 'Not yet assessed' : 'Submitted';
    }

    case ClaimStatus.RESUBMITTED: {
      return 'Resubmitted';
    }

    case ClaimStatus.REJECTED: {
      return 'Rejected';
    }

    case ClaimStatus.APPROVED: {
      return 'Approved';
    }

    case ClaimStatus.PAID: {
      return 'Paid';
    }

    default: {
      return '';
    }
  }
}

/**
 * Get extra claim status text for payment.
 *
 * @param payment - Payment object
 * @returns Extra claim status text
 */
export function getClaimStatusTextForApproved(payment: PaymentStage) {
  if (!payment.claim) {
    return '';
  }

  if (payment.claim.state !== ClaimStatus.APPROVED) {
    return '';
  }

  const { dueDate, amount = 0, paymentBreakdown } = payment.claim;

  if (dueDate && utc2Mel(dueDate).isBefore(nowMel()) && !utc2Mel(dueDate).isToday()) {
    return 'Overdue';
  }

  const {
    discount = 0,
    releasedUnpaid = 0,
    securedUnreleased = 0,
    totalHistoryPaid = 0,
  } = paymentBreakdown ?? {};
  const totalPaid = releasedUnpaid + totalHistoryPaid + discount;

  if (totalPaid >= amount) {
    return 'Paid';
  }

  if (totalPaid > 0 && totalPaid < amount) {
    return 'Part paid';
  }

  if (securedUnreleased + releasedUnpaid >= amount) {
    return 'Secured';
  }

  if (securedUnreleased > 0) {
    return 'Part secured';
  }

  if (
    payment.linked &&
    payment.linked?.length > 0 &&
    payment.secured !== PaymentSecuredStatus.TRANSFERRED &&
    payment.claim?.scheduledClaimPayments?.length === 0
  ) {
    return 'Not yet secured';
  }

  return 'Not yet paid';
}

/**
 * Get claim status text color for different party.
 *
 * @param payment - Payment stage object or claim status.
 * @param viewAs - Party type
 * @returns claim status text color
 */
export function getClaimStatusColorForParty(
  payment?: PaymentStage | ClaimStatus,
  viewAs?: PartyType,
) {
  if (!payment) {
    return 'default';
  }

  const claimStatus = typeof payment === 'string' ? payment : payment.claim?.state;

  if (!claimStatus) {
    return 'default';
  }

  switch (claimStatus) {
    case ClaimStatus.SUBMITTED:
    case ClaimStatus.RESUBMITTED: {
      return viewAs === PartyType.PAYER ? 'danger' : 'primary';
    }

    case ClaimStatus.REJECTED: {
      return viewAs === PartyType.PAYER ? 'primary' : 'danger';
    }

    case ClaimStatus.APPROVED: {
      return 'primary';
    }

    case ClaimStatus.PAID: {
      return 'success';
    }

    default: {
      return 'primary';
    }
  }
}

/**
 * Get last payment stage correction.
 *
 * @param payments - Progress payments of the contract.
 * @param contractPrice - Contract price
 * @returns Last payment stage correction
 */
export function getLastPaymentCorrection(payments: PaymentStage[] = [], contractPrice = 0): number {
  if (payments.length === 0) {
    return 0;
  }

  let correction = Math.round(contractPrice * 100) / 100;

  payments.forEach((payment) => {
    correction -= Math.round(payment.percentOfContractSum * contractPrice) / 100;
  });

  return correction;
}

/**
 *
 * Get paid percent of total claim
 *
 * @param payment - Payment object
 * @param excludeDiscount - Exclude discount or not
 * @returns Paid percentage
 */
export function getPaidPercent(payment: PaymentStage, excludeDiscount?: boolean) {
  if (!payment.claim?.amount) {
    return 0;
  }

  let totalPaid = 0;
  let discount = 0;

  if (payment?.claim?.paymentBreakdown) {
    discount = payment.claim.paymentBreakdown.discount;
    totalPaid =
      payment.claim.paymentBreakdown.totalHistoryPaid +
      payment.claim.paymentBreakdown.releasedUnpaid;
  } else {
    discount = sumBy(
      payment.claim.discounts ?? [],
      (discount) => discount.releaseDiscount + discount.releaseDiscountFee,
    );
    totalPaid = sumBy(payment.claim?.paymentHistory ?? [], (history) => history.amount ?? 0);
  }

  const totalAmount = excludeDiscount ? payment.claim.amount - discount : payment.claim.amount;
  return totalAmount ? clamp(Math.round((totalPaid / totalAmount) * 10_000) / 100, 0, 100) : 0;
}

/**
 * Get total secured amount of progress payment or variation.
 *
 * @param items - Secured items of progress payment or variation.
 * @param excludeReleased - Exclude released amount or not
 * @returns Total secured amount
 */
export function getTotalSecuredAmount(
  items: Array<SecuredProgressPayment | SecuredVariation> = [],
  excludeReleased = false,
): number {
  const securedAmount = excludeReleased
    ? sumBy(items, (item) => item.amount)
    : sumBy(items, (item) => item.amount * (item.securedType === SecuredType.SECURED ? 1 : -1));
  return Math.round(securedAmount * 100) / 100;
}

/**
 * Get total transferred amount of progress payment or variation.
 *
 * @param items - Secured items of progress payment or variation.
 * @returns Total transferred amount
 */
export function getTotalTransferredAmount(
  items: Array<SecuredProgressPayment | SecuredVariation> = [],
): number {
  return sumBy(
    items.filter((item) => item.securedType === SecuredType.RELEASED),
    (item) => item.amount,
  );
}

/**
 * Get total secured amount of progress payment
 *
 * @param payment - payment stage
 * @returns Total secured amount
 */
export function getTotalSecuredAmountForPayment(payment?: PaymentStage): number {
  if (!payment) {
    return 0;
  }
  if (payment?.claim?.paymentBreakdown) {
    return (
      (payment.claim.paymentBreakdown.securedUnreleased ?? 0) -
      (payment.claim.paymentBreakdown.overSecured ?? 0)
    );
  }
  return sumBy(
    payment.securedProgressPayments,
    (item) => item.amount * (item.securedType === SecuredType.SECURED ? 1 : -1),
  );
}

/**
 *  Get secured percent of total claim
 *
 * @param payment - Payment object
 * @returns Secured percentage
 */
export function getSecuredPercent(payment: PaymentStage) {
  if (!payment.claim?.amount) {
    return 0;
  }

  const totalSecured = getTotalSecuredAmountForPayment(payment);
  return clamp(Math.round((totalSecured / payment.claim.amount) * 10_000) / 100, 0, 100);
}

/**
 * Check if payment is fully secured.
 *
 * @param payment Payment to check
 * @returns Is fully secured or not
 */
export function isPaymentFullySecured(payment: PaymentStage) {
  if (!payment.claim) {
    return payment.secured === PaymentSecuredStatus.SECURED;
  }
  const { paymentBreakdown, amount } = payment.claim;
  return (
    (paymentBreakdown?.securedUnreleased ?? 0) + (paymentBreakdown?.releasedUnpaid ?? 0) >=
    (amount ?? 0)
  );
}
