import { styled } from '../design-tokens';

export const StyledDescription = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',

  variants: {
    span: {
      1: {
        gridColumn: 'span 1 / span 1',
      },

      2: {
        gridColumn: 'span 2 / span 2',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  defaultVariants: {
    span: 1,
  },
});

export const DescriptionsItems = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  width: '100%',
  gap: '36px',
});

export const DescriptionLabel = styled('span', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  columnGap: '6px',
  fontWeight: '$semibold',
  fontSize: '16px',
  lineHeight: '20px',
  color: '$blue',
});

export const DescriptionValue = styled('span', {
  display: 'block',
  width: '100%',
  fontWeight: '$medium',
  fontSize: '18px',
  lineHeight: '25px',

  variants: {
    error: {
      true: {
        color: '$pink',
      },

      false: {
        color: '$fg',
      },
    },
  },

  defaultVariants: {
    error: false,
  },
});
