import { SVGIcon } from '@paid-ui/icons';
import * as SelectPrimitive from '@radix-ui/react-select';

import { styled } from '../design-tokens';

export const SelectRoot = SelectPrimitive.Root;
export const SelectContent = SelectPrimitive.Content;
export const SelectItemIndicator = SelectPrimitive.ItemIndicator;

export const SelectTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '6px',
  backgroundColor: '$bg',
  border: '1px solid $colors$midGrey',
  borderRadius: '4px',
  width: '100%',

  variants: {
    size: {
      small: {
        padding: '20px',
      },

      large: {
        padding: '20px 40px',
      },
    },
  },

  defaultVariants: {
    size: 'large',
  },
});

export const PrimaryText = styled('div', {
  color: '$fg',
  fontSize: '18px',
  fontWeight: '$semibold',
  lineHeight: '25px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const SecondaryText = styled('div', {
  color: '$fg',
  fontSize: '18px',
  fontWeight: '$normal',
  lineHeight: '24px',
});

export const DropIcon = styled(SVGIcon, {
  color: '$fgSecondary',
  width: '24px',
  height: '24px',
});

export const CheckboxIcon = styled(SVGIcon, {
  color: '$payInGreen',
  width: '24px',
  height: '24px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const SelectViewport = styled(SelectPrimitive.Viewport, {
  width: '100%',
  backgroundColor: '$bg',
  border: '1px solid $colors$midGrey',
  boxShadow:
    '0px 15px 29px rgb(0 0 0 / 2.83%), 0px 3.35045px 6.47753px rgb(0 0 0 / 4.17%), 0px 0.997515px 1.92853px rgb(0 0 0 / 7%)',
  borderRadius: '4px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const SelectValue = styled(SelectPrimitive.Value, {
  color: '$pink',
});

export const SelectIcon = styled(SelectPrimitive.Icon, {
  flexShrink: 0,
});

export const SelectGroup = styled(SelectPrimitive.Group, {
  borderTop: '1px solid $colors$midGrey',
});

export const SelectItemText = styled(SelectPrimitive.ItemText, {
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
});

export const SelectItem = styled(SelectPrimitive.Item, {
  'all': 'unset',
  'position': 'relative',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'color': '$fg',
  'backgroundColor': '$bg',
  'userSelect': 'none',

  '&:focus': {
    backgroundColor: '$lightGrey',
    color: '$fg',
  },

  '&[data-highlighted]': {
    backgroundColor: '$lightGrey',
    color: '$fg',
  },

  'variants': {
    size: {
      small: {
        padding: '20px',
      },

      large: {
        padding: '20px 40px',
      },
    },
  },

  'defaultVariants': {
    size: 'large',
  },
});

export const SelectTitle = styled('div', {
  'color': '$fg',
  'fontSize': '16px',
  'fontWeight': '$medium',
  'lineHeight': '22px',

  '&:empty': {
    display: 'none',
  },
});
