import { useCallback } from 'react';
import type { FallbackProps } from 'react-error-boundary';

export const ErrorFallback: React.FC<FallbackProps> = (props) => {
  const { error, resetErrorBoundary } = props;

  const handleTryAgain = useCallback(
    (event?: React.MouseEvent) => {
      if (typeof resetErrorBoundary === 'function') {
        resetErrorBoundary(event);
      } else {
        window.location.reload();
      }
    },
    [resetErrorBoundary],
  );

  return (
    <div role="alert">
      <p>Something went wrong</p>
      <pre className="text-red-600">{error.message}</pre>
      <button type="button" onClick={handleTryAgain}>
        Try again
      </button>
    </div>
  );
};

ErrorFallback.displayName = 'ErrorFallback';
