import { NotificationStatus, NotificationType } from '@paid-ui/constants';
import type { NotificationItem } from '@paid-ui/types';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { type ForwardedRef, forwardRef, useCallback, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { Skeleton } from '../skeleton';
import {
  NotificationContainer,
  NotificationContent,
  NotificationParagraph,
  NotificationTimestamp,
  NotificationTitle,
  StyledNotificationButton,
  StyledNotificationMenuItem,
  UnreadIndicator,
} from './_Base';

export const defaultNotificationProps = {
  itemType: 'button' as 'button' | 'menu-item',
};

export interface NotificationProps extends BaseProps {
  data: NotificationItem;
  itemType?: 'button' | 'menu-item';
  onClick?: (data: NotificationItem) => void;
}

export const NotificationSkeleton = forwardRef<HTMLButtonElement, BaseProps>((props, ref) => {
  return (
    <StyledNotificationButton skeleton ref={ref} {...props}>
      <Skeleton width={25} height={25} />
      <NotificationContainer>
        <NotificationContent>
          <NotificationTitle>
            <Skeleton width={180} height={18} />
          </NotificationTitle>
          <NotificationTimestamp>
            <Skeleton width={70} height={14} />
          </NotificationTimestamp>
        </NotificationContent>
        <NotificationParagraph>
          <Skeleton width={100} height={14} />
        </NotificationParagraph>
        <NotificationParagraph>
          <Skeleton width={250} height={14} />
        </NotificationParagraph>
      </NotificationContainer>
    </StyledNotificationButton>
  );
});

export const Notification = forwardRef<HTMLButtonElement, NotificationProps>((props, ref) => {
  const { data, itemType = defaultNotificationProps.itemType, onClick, ...restProps } = props;

  const unread = useMemo(() => {
    return data.status === NotificationStatus.NEW || data.status === NotificationStatus.UNREAD;
  }, [data.status]);

  const useButton = useMemo(() => {
    return (
      itemType === 'button' ||
      (itemType === 'menu-item' && data.type !== NotificationType.ACTION && !data.url) ||
      (itemType === 'menu-item' && data.type === NotificationType.ACTION && !data.projectId)
    );
  }, [data, itemType]);

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(data);
    }
  }, [data, onClick]);

  if (useButton) {
    return (
      <StyledNotificationButton ref={ref} unread={unread} onClick={handleClick} {...restProps}>
        <UnreadIndicator enable={unread} />
        <NotificationContainer>
          <NotificationContent>
            <NotificationTitle>{data.message}</NotificationTitle>
            <NotificationTimestamp>{utc2Mel(data.createdAt).fromNow()}</NotificationTimestamp>
          </NotificationContent>
          <div>
            {data.description.split('\n').map((p, i) => (
              <NotificationParagraph key={i}>{p}</NotificationParagraph>
            ))}
          </div>
        </NotificationContainer>
      </StyledNotificationButton>
    );
  }

  return (
    <StyledNotificationMenuItem
      ref={ref as ForwardedRef<HTMLDivElement>}
      unread={unread}
      onClick={handleClick}
      {...restProps}
    >
      <UnreadIndicator enable={unread} />
      <NotificationContainer>
        <NotificationContent>
          <NotificationTitle>{data.message}</NotificationTitle>
          <NotificationTimestamp>{utc2Mel(data.createdAt).fromNow()}</NotificationTimestamp>
        </NotificationContent>
        <div>
          {data.description.split('\n').map((p, i) => (
            <NotificationParagraph key={i}>{p}</NotificationParagraph>
          ))}
        </div>
      </NotificationContainer>
    </StyledNotificationMenuItem>
  );
});

NotificationSkeleton.displayName = 'NotificationSkeleton';
Notification.displayName = 'Notification';
