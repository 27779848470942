import { type Dispatch, forwardRef, useCallback } from 'react';

import { Button, type ButtonProps } from '../button';
import { Flex } from '../flex';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { StyledStepFormFooter } from './_Base';
import type { FormStepper } from './types';

export interface StepFormFooterProps extends BaseProps {
  currentStep?: number;
  steps?: FormStepper[];
  isSticky?: boolean;
  isReview?: boolean;
  prevButton?: ButtonProps;
  nextButton?: ButtonProps;
  hidden?: boolean;
  dispatch?: Dispatch<any>;
}

export const StepFormFooter = forwardRef<HTMLDivElement, StepFormFooterProps>((props, ref) => {
  const {
    currentStep: currentStepIndex = 0,
    steps = [],
    isSticky,
    isReview,
    hidden,
    prevButton,
    nextButton,
    dispatch,
    ...restProps
  } = props;

  const handlePrevious = useCallback<React.MouseEventHandler<Element>>(
    (event) => {
      const { loading, disabled, onClick } = prevButton ?? {};

      if (loading || disabled) {
        return;
      }

      if (typeof onClick === 'function') {
        onClick(event);
      } else if (dispatch) {
        dispatch({
          type: 'prevStep',
        });
      }
    },
    [dispatch, prevButton],
  );

  const handleNext = useCallback<React.MouseEventHandler<Element>>(
    (event) => {
      const { loading, disabled, onClick } = nextButton ?? {};

      if (loading || disabled) {
        return;
      }

      if (typeof onClick === 'function') {
        onClick(event);
      } else if (dispatch) {
        dispatch({
          type: 'nextStep',
        });
      }
    },
    [dispatch, nextButton],
  );

  if (hidden) {
    return null;
  }

  return (
    <StyledStepFormFooter sticky={isSticky} {...restProps}>
      <Flex align="center" justify="end">
        <Space size={10} align="center">
          <Button
            size="large"
            height={44}
            variant="transparent"
            leadingIcon={prevButton?.loading ? undefined : 'previous-2'}
            loading={prevButton?.loading}
            disabled={prevButton?.disabled}
            hidden={currentStepIndex === 0 || prevButton?.hidden}
            onClick={handlePrevious}
          >
            {prevButton?.children}
          </Button>
          <Button
            height={44}
            size="large"
            color="primary"
            trailingIcon={nextButton?.loading || isReview ? undefined : 'next-2'}
            loading={nextButton?.loading}
            disabled={nextButton?.disabled}
            hidden={nextButton?.hidden}
            onClick={handleNext}
          >
            {nextButton?.children}
          </Button>
        </Space>
      </Flex>
    </StyledStepFormFooter>
  );
});

StepFormFooter.displayName = 'StepFormFooter';
