import { useEffect, useRef, useState } from 'react';

export interface UseMediaQueryOptions {
  getInitialValueInEffect: boolean;
}

type MediaQueryCallback = (event: { matches: boolean; media: string }) => void;

/**
 * Get initial value with media query string.
 *
 * @param query - Media query string.
 * @param initialValue - Initial value.
 * @returns Media query matches.
 */
function getInitialValue(query: string, initialValue?: boolean) {
  if (typeof initialValue === 'boolean') {
    return initialValue;
  }
  if (typeof window !== 'undefined' && 'matchMedia' in window) {
    return window.matchMedia(query).matches;
  }
  return false;
}

/**
 * Get media query matches.
 *
 * @author Ritchie Zhu <fengfu.zhu@paid.inc>
 * @date 2023-05-06
 * @param query - Media query string.
 * @param initialValue - Initial value.
 * @param options - Options of useMediaQuery.
 * @returns Media query matches.
 */
export function useMediaQuery(
  query: string,
  initialValue?: boolean,
  options?: UseMediaQueryOptions,
) {
  const { getInitialValueInEffect } = options ?? { getInitialValueInEffect: true };
  const [matches, setMatches] = useState(
    getInitialValueInEffect ? initialValue : getInitialValue(query, initialValue),
  );
  const queryRef = useRef<MediaQueryList>();

  useEffect(() => {
    if ('matchMedia' in window) {
      queryRef.current = window.matchMedia(query);
      setMatches(queryRef.current.matches);
      const handler: MediaQueryCallback = (event) => setMatches(event.matches);
      queryRef.current.addEventListener('change', handler);
      return () => queryRef.current?.removeEventListener('change', handler);
    }
  }, [query]);

  return matches;
}
