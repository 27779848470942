import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StyledCard } from './_Base';

export const defaultCardProps = {
  interactive: true,
  padding: false,
};

export interface CardProps extends BasePropsWithChildren {
  interactive?: boolean;
  padding?: boolean;
  onClick?: () => void;
}

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const {
    interactive = defaultCardProps.interactive,
    padding = defaultCardProps.padding,
    children,
    onClick,
    ...restProps
  } = props;

  return (
    <StyledCard
      ref={ref}
      interactive={interactive}
      padding={padding}
      onClick={interactive ? onClick : undefined}
      {...restProps}
    >
      {children}
    </StyledCard>
  );
});

Card.displayName = 'Card';
