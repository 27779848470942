import { blue, slate } from '@radix-ui/colors';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { styled } from '../design-tokens';

export const RadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',

  variants: {
    empty: {
      true: {
        my: '24px',
      },
    },
  },
});

export const RadioItemRow = styled('div', {
  'width': '100%',
  'display': 'flex',
  'gap': '8px',
  'padding': '14px 10px',
  'borderBottom': '1px solid $colors$fgFaint',
  'transition': 'backgroundColor 100ms',

  '&:last-of-type': {
    padding: '14px 10px 24px',
    borderBottom: 'none',
  },

  'variants': {
    noPadding: {
      true: {
        'padding': '14px 0',

        '&:last-of-type': {
          padding: '14px 0',
          borderBottom: 'none',
        },
      },
    },

    selected: {
      true: {
        backgroundColor: '$bgGroup',
      },
    },

    align: {
      start: {
        alignItems: 'flex-start',
      },

      center: {
        alignItems: 'center',
      },
    },
  },

  'defaultVariants': {
    align: 'center',
  },
});

export const RadioItemLastRow = styled('div', {
  'width': '100%',
  'display': 'flex',
  'gap': '8px',
  'padding': '14px 0',
  'borderBottom': '1px solid $colors$fgFaint',
  'transition': 'backgroundColor 100ms',

  '&:last-of-type': {
    padding: '14px 0',
    borderBottom: 'none',
  },

  'variants': {
    selected: {
      true: {
        backgroundColor: '$bgGroup',
      },
    },

    align: {
      start: {
        alignItems: 'flex-start',
      },

      center: {
        alignItems: 'center',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  'defaultVariants': {
    align: 'center',
  },
});

export const RadioItem = styled(RadioGroupPrimitive.Item, {
  'all': 'unset',
  'backgroundColor': '$bg',
  'size': '20px',
  'borderRadius': '50%',
  'border': `2px solid #5a636e`,
  'flexShrink': 0,
  'transition': 'borderColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: blue.blue4,
    },
  },

  '&:focus': {
    borderColor: '$blue',
  },

  '&[data-state="checked"]': {
    borderColor: '$blue',
  },

  '&:disabled': {
    'backgroundColor': slate.slate4,

    '@hover': {
      '&:hover': {
        backgroundColor: slate.slate4,
      },
    },
  },
});

export const RadioItemIndicator = styled(RadioGroupPrimitive.Indicator, {
  'position': 'relative',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'size': '100%',
  'backgroundColor': 'transparent',
  'transition': 'backgroundColor 100ms',

  '&::after': {
    display: 'block',
    borderRadius: '50%',
    backgroundColor: '$blue',
    content: '""',
    size: '10px',
  },
});

export const RadioItemLabel = styled('label', {
  display: 'grid',
  gridTemplateColumns: '1fr 40px 120px',
  alignItems: 'center',
  color: '$fg',
  width: '100%',
  gap: '14px',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '$medium',
  userSelect: 'none',

  variants: {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const RadioItemHeldLabel = styled('label', {
  display: 'grid',
  gridTemplateAreas: '"name price" "address address"',
  gridTemplateColumns: '1fr 120px',
  alignItems: 'center',
  color: '$fg',
  width: '100%',
  gap: '4px 14px',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '$medium',
  userSelect: 'none',

  variants: {
    lastRow: {
      true: {
        gridTemplateAreas: 'none',
        gridTemplateColumns: 'none',
      },
    },

    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const RadioLabelCell = styled('span', {
  fontWeight: '$normal',

  variants: {
    bold: {
      true: {
        fontWeight: '$medium',
      },
    },

    align: {
      left: {
        textAlign: 'left',
      },

      center: {
        textAlign: 'center',
      },

      right: {
        textAlign: 'right',
      },
    },

    size: {
      small: {
        fontSize: '16px',
      },
    },
  },
});

export const RadioLabelAddress = styled('div', {
  'fontSize': '14px',
  'lineHeight': '21px',
  'fontWeight': '$normal',
  'gridArea': 'address',

  '&:empty': {
    display: 'none',
  },
});

export const ShowMoreButton = styled('button', {
  all: 'unset',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '-14px',
  padding: '4px 10px',
  color: '$blue',
  border: '1px solid $colors$blue',
  fontWeight: '$medium',
  fontSize: '14px',
  lineHeight: '18px',
  borderRadius: '4px',
  backgroundColor: '$bg',
  cursor: 'pointer',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
