import { forwardRef, useMemo } from 'react';

import type { BasePropsWithChildren, FontSize } from '../interfaces';
import { StyledSpace } from './_Base';

export type SpaceAlign = 'start' | 'end' | 'center' | 'baseline';
export type SpaceDirection = 'vertical' | 'horizontal';

const spaceSizeMap = {
  small: 8,
  medium: 14,
  large: 24,
};

export const defaultSpaceProps = {
  direction: 'horizontal' as SpaceDirection,
  align: 'start' as SpaceAlign,
  size: 'medium' as FontSize,
  inline: true,
};

export interface SpaceProps extends BasePropsWithChildren {
  /** Space direction */
  direction?: SpaceDirection;
  /** Item align style */
  align?: SpaceAlign;
  /** Gap size between items */
  size?: FontSize | FontSize[];
  /** Flex or inline flex */
  inline?: boolean;
  /** Wrap or not, only when direction is horizontal */
  wrap?: boolean;
  /** Set height to container height */
  fullHeight?: boolean;
  /** Reverse label and switch */
  reversed?: boolean;
  /** Return null */
  hidden?: boolean;
}

export const Space = forwardRef<HTMLDivElement, SpaceProps>((props, ref) => {
  const {
    align = defaultSpaceProps.align,
    direction = defaultSpaceProps.direction,
    size = defaultSpaceProps.size,
    inline = defaultSpaceProps.inline,
    wrap,
    fullHeight,
    reversed,
    hidden,
    children,
    css,
    ...restProps
  } = props;

  const flexWrap = useMemo(() => {
    return wrap && direction !== 'vertical' ? 'wrap' : 'nowrap';
  }, [direction, wrap]);

  const [columnGap, rowGap] = useMemo(() => {
    if (Array.isArray(size)) {
      const [columnSize = 8, rowSize = 8] = size;

      return [
        typeof columnSize === 'string' ? spaceSizeMap[columnSize] : columnSize,
        typeof rowSize === 'string' ? spaceSizeMap[rowSize] : rowSize,
      ];
    }

    const gap = typeof size === 'string' ? spaceSizeMap[size] : size;

    return [gap, gap];
  }, [size]);

  if (hidden) {
    return null;
  }

  return (
    <StyledSpace
      ref={ref}
      direction={direction}
      align={align}
      inline={inline}
      fullHeight={fullHeight}
      reversed={reversed}
      css={{
        ...css,
        columnGap,
        rowGap,
        flexWrap,
      }}
      {...restProps}
    >
      {children}
    </StyledSpace>
  );
});

Space.displayName = 'Space';
