import { slate } from '@radix-ui/colors';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { styled } from '../design-tokens';

export const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  zIndex: '$tabs',
});

export const TabContainer = styled('div', {
  py: '24px',
});

export const TabTrigger = styled(TabsPrimitive.Trigger, {
  'all': 'unset',
  'fontFamily': 'inherit',
  'backgroundColor': '$bg',
  'px': '16px',
  'height': '48px',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'fontSize': '16px',
  'lineHeight': 1.25,
  'color': '$fg',
  'userSelect': 'none',
  'cursor': 'pointer',
  'fontWeight': '$medium',
  'whiteSpace': 'nowrap',

  '&:hover': {
    color: '$blue',
  },

  '&[data-state="active"]': {
    boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
    color: '$blue',
    cursor: 'default',
  },

  '&[disabled]': {
    'color': slate.slate8,
    'pointerEvents': 'none',

    '&:hover': {
      color: slate.slate8,
    },
  },

  'variants': {
    border: {
      true: {
        boxShadow: 'inset 0 -1px 0 0 $colors$fgFaint, 0 1px 0 0 $colors$fgFaint',
      },

      false: {
        boxShadow: 'none',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const Tabs = styled(TabsPrimitive.Root, {
  display: 'block',
  width: '100%',
  zIndex: '$tabs',

  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: 0,
      },
    },
  },
});

export const TabsList = styled(TabsPrimitive.List, {
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  overflowX: 'auto',

  variants: {
    fullWidth: {
      true: {
        [`& > ${TabTrigger}`]: {
          flex: 1,
        },
      },

      false: {
        [`& > ${TabTrigger}`]: {
          flexGrow: 0,
        },
      },
    },

    bordered: {
      true: {
        borderBottom: '1px solid $colors$fgFaint',
      },
    },
  },

  defaultVariants: {
    fullWidth: false,
  },
});

export const TabsContent = styled(TabsPrimitive.Content, {
  py: '24px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
