import { CountryCode, type MonthKey, MonthOfDate, PaymentTermsType } from '@paid-ui/constants';
import { numberPattern } from '@paid-ui/regexps';
import { type Address } from '@paid-ui/schemas/zod/address';
import { type Attachment, type NullableAttachment } from '@paid-ui/schemas/zod/attachment';
import type { Evidence, NullableEvidence, PaymentTerms } from '@paid-ui/types';
import { isNil } from 'lodash';
import pick from 'lodash/pick';

import { isNotNil } from './filters';

export const currencyFormatter = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  maximumFractionDigits: 2,
});

export const noSignCurrencyFormatter = Intl.NumberFormat('en-AU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const dateFormatter = Intl.DateTimeFormat('en-AU', {
  dateStyle: 'short',
});

export const dateTimeFormatter = Intl.DateTimeFormat('en-AU', {
  dateStyle: 'short',
  timeStyle: 'short',
});

/**
 * Format money number to currency in AUD.
 *
 * @param input - Input raw money number
 * @returns Formatted money in AUD currency
 */
export function formatCurrency(input: number): string {
  return currencyFormatter.format(input);
}

/**
 * Format utc date input to local date string.
 *
 * @param input - Input utc date
 * @returns Formatted local date string
 */
export function formatDate(input?: string | number | Date): string {
  switch (typeof input) {
    case 'undefined': {
      return dateFormatter.format();
    }

    case 'string': {
      if (!input) {
        return dateFormatter.format();
      }

      const localDate = input.endsWith('Z') ? new Date(input) : new Date(`${input} UTC`);
      return dateFormatter.format(localDate);
    }

    case 'number': {
      const timeZoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
      return dateFormatter.format(new Date(input + timeZoneOffsetInMs));
    }

    default: {
      return dateFormatter.format(input);
    }
  }
}

/**
 * Format utc date time input to local date time string.
 *
 * @param input - Input utc date time
 * @returns Formatted local date time string
 */
export function formatDateTime(input?: string | number | Date): string {
  switch (typeof input) {
    case 'undefined': {
      return dateTimeFormatter.format();
    }

    case 'string': {
      if (!input) {
        return dateTimeFormatter.format();
      }

      const localDate = input.endsWith('Z') ? new Date(input) : new Date(`${input} UTC`);
      return dateTimeFormatter.format(localDate);
    }

    case 'number': {
      const timeZoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000;
      return dateTimeFormatter.format(new Date(input + timeZoneOffsetInMs));
    }

    default: {
      return dateTimeFormatter.format(input);
    }
  }
}

/**
 * Format address object to string.
 *
 * @param address - Address object.
 * @param noCountryAndCodeIncluded - Whether to include country and postcode in the address string.
 * @returns Address string.
 */
export function formatAddress(address?: Address | '', noCountryAndCodeIncluded = false): string {
  if (!address || !address.country || !address.state || !address.suburb || !address.streetName) {
    return '';
  }

  const { unitNumber, streetNumber, streetName, suburb, postcode, state, country } = address;

  const fullStreetNumber = [unitNumber, streetNumber].filter(Boolean).join('-');
  const fullStreetName = [fullStreetNumber, streetName].filter(Boolean).join(' ');

  if (!fullStreetName) {
    return '';
  }

  if (noCountryAndCodeIncluded) {
    return `${fullStreetName}, ${suburb} ${state}`;
  }

  if (country === CountryCode.AU) {
    return `${fullStreetName}, ${suburb} ${state} ${postcode}, Australia`;
  }

  return `${fullStreetName}, ${suburb} ${state} ${postcode}, ${country}`;
}

/**
 * Format utc date input to local date string.
 *
 * @param input - Input utc date
 * @returns Formatted local date string
 */
export function formatDateWithLetter(input?: string): string {
  if (!input) {
    return '';
  }
  const [day, month, year] = input.split('/'); // day/month/year
  return `${day} ${MonthOfDate[String(Number(month)) as MonthKey]} 20${year}`;
}

/**
 * Format file size
 *
 * @param size - Numeric file size
 * @param precise - Precise file size or not
 * @returns Formatted file size string
 */
export function formatFileSize(size?: number, precise?: boolean): string {
  if (size === undefined) {
    return 'unknown';
  }

  if (size < 1000) {
    return precise ? `${size} bytes` : '< 1 KB';
  }

  if (size < 1_000_000) {
    return `${Math.round(size / 100) / 10} KB`;
  }

  if (size < 1_000_000_000) {
    return `${Math.round(size / 100_000) / 10} MB`;
  }

  return `${Math.round(size / 100_000_000) / 10} GB`;
}

/**
 * Format numeric string based on format template.
 *
 * @param value - Numeric string.
 * @param format - Format template.
 * @param separator - Separator of format template.
 * @returns Formatted numeric string.
 */
export function formatNumericString(value?: string, format?: string, separator?: RegExp): string {
  if (!value) {
    return '';
  }

  if (!format || !numberPattern.test(value)) {
    return value;
  }

  let lastIndex = 0;
  const result = [...format];

  for (const char of value.replace(separator ?? / /g, '')) {
    const index = result.indexOf('#');

    if (index !== -1) {
      result[index] = char;
      lastIndex = index;
    }
  }

  return result.join('').slice(0, Math.max(0, lastIndex + 1));
}

/**
 * Format ABN.
 *
 * @param value - ABN string.
 * @returns Formatted ABN string.
 */
export function formatABN(value?: string): string {
  return formatNumericString(value, '## ### ### ###');
}

/**
 * Format ACN.
 *
 * @param value - ACN string.
 * @returns Formatted ACN string.
 */
export function formatACN(value?: string): string {
  return formatNumericString(value, '### ### ###');
}

/**
 * Format BSB.
 *
 * @param value - BSB string.
 * @returns Formatted BSB string.
 */
export function formatBSB(value?: string): string {
  return formatNumericString(value, '###-###');
}

/**
 * Format account number.
 *
 * @param value - Account number string.
 * @returns Formatted account number string.
 */
export function formatAccountNumber(value?: string): string {
  if (!value) {
    return '';
  }
  if (value.length === 6) {
    return formatNumericString(value, '### ###');
  }
  if (value.length === 7) {
    return formatNumericString(value, '# ### ###');
  }
  if (value.length === 8) {
    return formatNumericString(value, '## ### ###');
  }
  return formatNumericString(value, '### ### ###');
}

/**
 * Clean attachments with null values
 *
 * @param attachments - Nullable attachments
 * @returns Cleaned attachments
 */
export function cleanAttachments(attachments: NullableAttachment[]): Attachment[] {
  return attachments
    .filter(isNotNil<Attachment>)
    .map((attachment) =>
      pick(attachment, ['category', 'fileName', 'fileAddress', 'fileSize', 'fileType']),
    );
}

/**
 * Clean evidences with null values
 *
 * @param evidences - Nullable evidences
 * @returns Cleaned evidences
 */
export function cleanEvidences(evidences: NullableEvidence[]): Evidence[] {
  return evidences
    .filter(isNotNil<Evidence>)
    .map((evidence) =>
      pick(evidence, [
        'category',
        'fileName',
        'fileAddress',
        'fileSize',
        'fileType',
        'geolocation',
      ]),
    );
}

/**
 * Format payment terms object to string.
 *
 * @param paymentTerms - Payment terms object.
 * @returns Formatted payment terms string.
 */
export function formatPaymentTerms(paymentTerms?: PaymentTerms) {
  if (paymentTerms === undefined) {
    return '-';
  }

  const { days, type } = paymentTerms;

  return type === PaymentTermsType.EOM
    ? `${days} calendar days (End of month)`
    : `${days} calendar days`;
}

/**
 * Format string currency to number
 *
 * @param amount - Currency string or number
 * @returns Numeric currency
 */
export const formatStrCurrency = (amount?: string | number) => {
  if (isNil(amount) || amount === '') {
    return '';
  } else if (typeof amount === 'number') {
    return amount;
  } else {
    return Number(amount.replaceAll(/[^\d.-]+/g, ''));
  }
};
