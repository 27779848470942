import type { SVGIconName } from '@paid-ui/icons';
import clsx from 'clsx';
import InputNumber from 'rc-input-number';
import { forwardRef, useCallback, useMemo } from 'react';

import type { CSS } from '../design-tokens';
import { FormControl, LeadingIconButton, StyledSVGIcon, TrailingIconButton } from '../form';
import { HelperText } from '../help-text';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import { TrailingUnit } from './_Base';

export type RcAlignType = 'left' | 'center' | 'right';
export type RcInputNumberType = 'number' | 'percentage' | 'currency';

export interface RcInputNumberProps extends BaseProps {
  name: string;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  block?: boolean;
  size?: FontSize | 'xlarge';
  height?: number;
  pattern?: string;
  hidden?: boolean;
  simple?: boolean;
  prefixCls?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  controls?: boolean;
  precision?: number;
  className?: string;
  autoFocus?: boolean;
  align?: RcAlignType;
  labelSize?: FontSize;
  placeholder?: string;
  leadingText?: string;
  trailingText?: string;
  leadingStyle?: CSS;
  trailingStyle?: CSS;
  helperTextStyle?: CSS;
  defaultValue?: number;
  autoComplete?: boolean;
  paddingLeft?: number;
  paddingRight?: number;
  leadingBordered?: boolean;
  trailingBordered?: boolean;
  extra?: React.ReactNode;
  label?: React.ReactNode;
  labelWeight?: FontWeight;
  mode?: RcInputNumberType;
  leadingIcon?: SVGIconName;
  trailingIcon?: SVGIconName;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  inputStyle?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  formatter?: (value?: number) => string;
  onChange?: (value?: number | string) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const defaultRcInputNumberProps = {
  type: 'text' as React.HTMLInputTypeAttribute,
  mode: 'number' as RcInputNumberType,
  precision: 0,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  leadingText: '',
  trailingText: '',
  align: 'left' as RcAlignType,
  controls: false,
};

export const RcInputNumber = forwardRef<HTMLInputElement, RcInputNumberProps>((props, ref) => {
  const {
    min = defaultRcInputNumberProps.min,
    max = defaultRcInputNumberProps.max,
    type = defaultRcInputNumberProps.type,
    name,
    step,
    mode = defaultRcInputNumberProps.mode,
    size,
    align = defaultRcInputNumberProps.align,
    block,
    label,
    extra,
    value,
    height,
    hidden,
    simple,
    controls,
    disabled,
    readOnly,
    required,
    className,
    labelSize,
    autoFocus,
    prefixCls,
    precision = defaultRcInputNumberProps.precision,
    helperText,
    inputStyle,
    labelWeight,
    leadingIcon,
    leadingText = defaultRcInputNumberProps.leadingText,
    placeholder,
    paddingLeft,
    paddingRight,
    trailingIcon,
    trailingText = defaultRcInputNumberProps.trailingText,
    errorMessage,
    autoComplete = 'off',
    defaultValue,
    leadingStyle,
    trailingStyle,
    helperTextStyle,
    leadingBordered,
    trailingBordered,
    onBlur,
    onClick,
    onFocus,
    onChange,
    onKeyDown,
    formatter,
    ...restProps
  } = props;

  const hasError = !!errorMessage;

  const inputStep = useMemo(() => {
    if (step) {
      return step;
    }
    if (precision === 1) {
      return 0.1;
    }
    if (precision === 2) {
      return 0.01;
    }
  }, [precision, step]);

  const leadingElement = useMemo(() => {
    if (leadingIcon) {
      return <StyledSVGIcon name={leadingIcon} />;
    }
    if (leadingText) {
      return <TrailingUnit css={leadingStyle}>{leadingText}</TrailingUnit>;
    }
    return null;
  }, [leadingIcon, leadingStyle, leadingText]);

  const trailingElement = useMemo(() => {
    if (trailingIcon) {
      return <StyledSVGIcon name={trailingIcon} />;
    }
    if (trailingText) {
      return <TrailingUnit css={trailingStyle}>{trailingText}</TrailingUnit>;
    }
    return null;
  }, [trailingIcon, trailingStyle, trailingText]);

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const inputClass = useMemo(() => {
    const leadingCls = leadingElement ? 'hasLeadingElement' : '';
    const trailingCls = trailingElement ? 'hasTrailingElement' : '';
    const paddingLeftCls = paddingLeft ? `paddingLeft_${paddingLeft}` : '';
    const paddingRightCls = paddingRight ? `paddingRight_${paddingRight}` : '';
    return `${clsx('rcInputNumber', className, block ? 'block' : '', size)} ${clsx(
      `rcInputNumber_align`,
      align,
    )} ${leadingCls} ${trailingCls} ${paddingLeftCls} ${paddingRightCls}`;
  }, [align, block, className, leadingElement, paddingLeft, paddingRight, size, trailingElement]);

  const handleFormat = useCallback(
    (val?: number) => {
      if (typeof formatter === 'function') {
        return formatter(val);
      }
      if (!val) {
        return (val ?? '') as unknown as string;
      }
      if (mode === 'currency') {
        return val.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return val.toString();
    },
    [formatter, mode],
  );

  const handleChange = useCallback(
    (val: number | null) => {
      onChange?.(val ?? '');
    },
    [onChange],
  );

  if (hidden) {
    return null;
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label && !simple ? (
        <Label
          htmlFor={name}
          asterisk={required}
          extra={extra}
          size={labelSize}
          weight={labelWeight}
        >
          {label}
        </Label>
      ) : null}
      <FormControl block={block}>
        <LeadingIconButton type="button" tabIndex={-1} bordered={leadingBordered}>
          {leadingElement}
        </LeadingIconButton>
        <InputNumber
          ref={ref}
          id={name}
          min={min}
          max={max}
          name={name}
          value={value}
          step={inputStep}
          stringMode={false}
          controls={controls}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
          prefixCls={prefixCls}
          precision={precision}
          autoFocus={autoFocus}
          className={inputClass}
          aria-invalid={hasError}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          formatter={handleFormat}
          style={inputStyle}
          aria-describedby={hasError ? `${name}-error` : `${name}-description`}
          {...restProps}
        />
        <TrailingIconButton type="button" tabIndex={-1} bordered={trailingBordered}>
          {trailingElement}
        </TrailingIconButton>
      </FormControl>
      {simple ? null : (
        <HelperText
          error={hasError}
          id={hasError ? `${name}-error` : `${name}-description`}
          css={helperTextStyle}
        >
          {hasError ? errorMessage : helperText}
        </HelperText>
      )}
    </Space>
  );
});

RcInputNumber.displayName = 'RcInputNumber';
