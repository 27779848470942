import { forwardRef, useCallback, useState } from 'react';

import type { BaseProps } from '../interfaces';
import {
  AlertContainer,
  AlertContent,
  AlertContentContainer,
  AlertFooter,
  AlertHeader,
  CloseButton,
  CloseIcon,
} from './_Base';

export type AlertColor = 'light' | 'dark';

export const defaultAlertProps = {
  closable: true,
  compact: false,
  color: 'light' as AlertColor,
};

export interface AlertProps extends BaseProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  color?: AlertColor;
  closable?: boolean;
  compact?: boolean;
  isVertical?: boolean;
  hidden?: boolean;
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const {
    title,
    content,
    footer,
    color = defaultAlertProps.color,
    closable = defaultAlertProps.closable,
    compact = defaultAlertProps.compact,
    isVertical,
    hidden,
    ...restProps
  } = props;
  const [closed, setClosed] = useState(false);

  const handleClose = useCallback(() => {
    setClosed(true);
  }, []);

  if (hidden || closed) {
    return null;
  }

  return (
    <AlertContainer ref={ref} color={color} compact={compact} {...restProps}>
      <AlertHeader closable={closable}>{title}</AlertHeader>
      <AlertContentContainer isVertical={isVertical}>
        <AlertContent closable={closable && !title}>{content}</AlertContent>
        <AlertFooter>{footer}</AlertFooter>
      </AlertContentContainer>
      <CloseButton hidden={!closable} type="button" aria-label="Close" onClick={handleClose}>
        <CloseIcon name="close" />
      </CloseButton>
    </AlertContainer>
  );
});

Alert.displayName = 'Alert';
