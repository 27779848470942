import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import {
  DropdownMenuArrow,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLink,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  RightSlot,
} from './_Base';

export const defaultDropdownMenuProps = {};

export type DropdownMenuItemProps =
  | {
      text: React.ReactNode;
      href?: string;
      target?: React.HTMLAttributeAnchorTarget;
      extra?: React.ReactNode;
      disabled?: boolean;
      hidden?: boolean;
      danger?: boolean;
      readonly?: boolean;
      onSelect?: (event: Event) => void;
    }
  | '--';

export interface DropdownMenuProps extends BasePropsWithChildren {
  menu?: DropdownMenuItemProps[];
  open?: boolean;
  side?: 'left' | 'right' | 'bottom' | 'top';
  align?: 'start' | 'end' | 'center';
  trigger?: React.ReactNode;
  defaultOpen?: boolean;
  width?: number | string;
  size?: 'sm' | 'md' | 'lg';
  hidden?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>((props, ref) => {
  const {
    menu,
    open,
    side,
    align,
    defaultOpen,
    trigger,
    width,
    size = 'sm',
    children,
    hidden,
    css,
    onOpenChange,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <DropdownMenuRoot open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent
        ref={ref}
        side={side}
        align={align}
        sideOffset={8}
        css={{
          ...css,
          width,
        }}
        {...restProps}
      >
        {menu
          ?.filter((item) => item === '--' || !item.hidden)
          .map((item, index) =>
            item === '--' ? (
              <DropdownMenuSeparator key={index} />
            ) : item.href ? (
              <DropdownMenuLink
                key={index}
                disabled={item.disabled}
                danger={item.danger}
                size={size}
                href={item.href}
                target={item.target}
              >
                {item.text} <RightSlot>{item.extra}</RightSlot>
              </DropdownMenuLink>
            ) : (
              <DropdownMenuItem
                key={index}
                disabled={item.disabled}
                danger={item.danger}
                size={size}
                onSelect={item.onSelect}
                onFocus={(event) => event.stopPropagation()}
                onClick={(event) => event.stopPropagation()}
                readonly={item.readonly}
              >
                {item.text} <RightSlot>{item.extra}</RightSlot>
              </DropdownMenuItem>
            ),
          )}
        {children}
        <DropdownMenuArrow offset={12} />
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
});

DropdownMenu.displayName = 'DropdownMenu';
