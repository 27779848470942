import { uriPattern } from '@paid-ui/regexps';
import { type LinkProps as NextLinkProps } from 'next/link';
import { forwardRef } from 'react';

import type { CSS } from '../design-tokens';
import { Anchor, NextLink } from './_Base';

export type LinkColor = 'default' | 'blue' | 'red';
export type LinkUnderline = 'none' | 'hover' | 'always';

export const defaultLinkProps = {
  color: 'default' as LinkColor,
  underline: 'hover' as LinkUnderline,
};

export interface LinkProps extends Omit<NextLinkProps, 'href' | 'passHref'> {
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  tabIndex?: number;
  color?: LinkColor;
  bold?: boolean;
  underline?: LinkUnderline;
  id?: string;
  className?: string;
  css?: CSS;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  hidden?: boolean;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    href,
    target,
    tabIndex,
    color = defaultLinkProps.color,
    bold,
    underline = defaultLinkProps.underline,
    shallow,
    id,
    className,
    css,
    style,
    children,
    hidden,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  if (!href || uriPattern.test(href)) {
    return (
      <Anchor
        ref={ref}
        href={href}
        target={target}
        tabIndex={tabIndex}
        color={color}
        bold={bold}
        underline={underline}
        id={id}
        className={className}
        css={css}
        style={style}
      >
        {children}
      </Anchor>
    );
  }

  return (
    <NextLink
      ref={ref}
      href={href}
      shallow={shallow}
      tabIndex={tabIndex}
      color={color}
      bold={bold}
      underline={underline}
      id={id}
      className={className}
      css={css}
      style={style}
      passHref
      {...restProps}
    >
      {children}
    </NextLink>
  );
});

Link.displayName = 'Link';
