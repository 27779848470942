import { forwardRef, useMemo } from 'react';

import type { Align, BasePropsWithChildren, PointerDownOutsideEvent, Side } from '../interfaces';
import { TooltipArrow, TooltipContent, TooltipPortal, TooltipRoot, TooltipTrigger } from './_Base';

const defaultProps = {
  delayDuration: 400,
  side: 'top' as Side,
  sideOffset: 5,
  align: 'center' as Align,
  collisionPadding: 4,
};

export interface TooltipProps extends BasePropsWithChildren {
  title?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  delayDuration?: number;
  disableHoverableContent?: boolean;
  side?: Side;
  sideOffset?: number;
  align?: Align;
  alignOffset?: number;
  arrowPadding?: number;
  collisionBoundary?: Element | null;
  collisionPadding?: number;
  sticky?: 'partial' | 'always';
  hideWhenDetached?: boolean;
  avoidCollisions?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onOpenChange?: (open: boolean) => void;
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  onPointerDownOutside?: (event: PointerDownOutsideEvent) => void;
}

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const {
    title,
    open,
    defaultOpen,
    delayDuration = defaultProps.delayDuration,
    disableHoverableContent,
    side = defaultProps.side,
    sideOffset = defaultProps.sideOffset,
    align = defaultProps.align,
    alignOffset,
    arrowPadding,
    collisionBoundary,
    collisionPadding = defaultProps.collisionPadding,
    sticky,
    hideWhenDetached,
    avoidCollisions,
    disabled,
    multiline: multilineProp,
    hidden,
    children,
    onOpenChange,
    onEscapeKeyDown,
    onPointerDownOutside,
    ...restProps
  } = props;

  const multiline = useMemo(() => {
    if (multilineProp !== undefined) {
      return multilineProp;
    }

    if (typeof title === 'string') {
      return title.length >= 32;
    }

    return true;
  }, [multilineProp, title]);

  if (hidden) {
    return null;
  }

  if (disabled || !title || !window) {
    return children as JSX.Element;
  }

  return (
    <TooltipRoot
      open={title ? open : false}
      defaultOpen={defaultOpen}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
      onOpenChange={onOpenChange}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          ref={ref}
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          arrowPadding={arrowPadding}
          collisionBoundary={collisionBoundary}
          collisionPadding={collisionPadding}
          sticky={sticky}
          hideWhenDetached={hideWhenDetached}
          avoidCollisions={avoidCollisions}
          onEscapeKeyDown={onEscapeKeyDown}
          onPointerDownOutside={onPointerDownOutside}
          multiline={multiline}
          {...restProps}
        >
          {title}
          {multiline ? <TooltipArrow /> : null}
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  );
});

Tooltip.displayName = 'Tooltip';

export { TooltipProvider } from './_Base';
