import * as ProgressPrimitive from '@radix-ui/react-progress';

import { styled } from '../design-tokens';
import { Grid } from '../grid';

export const ProgressRoot = styled(ProgressPrimitive.Root, {
  position: 'relative',
  width: '100%',
  height: '8px',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: 'transparent',

  variants: {
    compact: {
      true: {
        height: '4px',
      },
      false: {},
    },
  },
});

export const ProgressIndicator = styled(ProgressPrimitive.Indicator, {
  size: '100%',
  backgroundColor: '$blue',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
  variants: {
    color: {
      blue: {
        backgroundColor: '$blue',
      },

      pink: {
        backgroundColor: '$pink',
      },

      green: {
        backgroundColor: '$green',
      },

      grey: {
        backgroundColor: '$fgTertiary',
      },

      midGrey: {
        backgroundColor: '$midGrey',
      },
    },

    rounded: {
      true: {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    },

    hoverable: {
      true: {
        '&:hover': {
          backgroundColor: '$fg',
        },
      },
    },

    disabled: {
      true: {
        backgroundColor: '$fgFaint',
      },
    },
  },

  compoundVariants: [
    {
      color: 'blue',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'pink',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'green',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
    {
      color: 'grey',
      disabled: true,
      css: {
        backgroundColor: '$fgFaint',
      },
    },
  ],

  defaultVariants: {
    color: 'blue',
    rounded: true,
  },
});

export const ProgressLabel = styled('div', {
  variants: {
    hidden: {
      true: {
        visibility: 'hidden',
      },
    },
    isLast: {
      true: {
        position: 'absolute',
        right: 0,
      },
    },
    hover: {
      true: {
        zIndex: 10,
        backgroundColor: '$lightGrey',
        border: '1px solid $colors$midGrey',
        borderRadius: '4px',
        padding: '0 4px',
      },
      false: {
        backgroundColor: 'unset',
      },
    },
  },
});
