export enum WorksCategory {
  'JOINERY' = 'JOINERY',
  'CARPENTRY' = 'CARPENTRY',
  'CLEANING' = 'CLEANING',
  'CONCRETE' = 'CONCRETE',
  'DEMOLITION' = 'DEMOLITION',
  'ELECTRICAL' = 'ELECTRICAL',
  'EXCAVATION' = 'EXCAVATION',
  'FENCING' = 'FENCING',
  'FLOORING' = 'FLOORING',
  'FRAMING' = 'FRAMING',
  'GLAZING' = 'GLAZING',
  'HVAC' = 'HVAC',
  'INSULATION' = 'INSULATION',
  'LANDSCAPING' = 'LANDSCAPING',
  'PAINTING' = 'PAINTING',
  'PEST_CONTROL' = 'PEST_CONTROL',
  'PLASTERING' = 'PLASTERING',
  'PLUMBING' = 'PLUMBING',
  'RENDERING' = 'RENDERING',
  'ROOFING' = 'ROOFING',
  'RUBBISH_REMOVAL' = 'RUBBISH_REMOVAL',
  'SCAFFOLDING' = 'SCAFFOLDING',
  'STEEL_FABRICATION' = 'STEEL_FABRICATION',
  'TILING' = 'TILING',
  'CAULKING' = 'CAULKING',
  'SHOWER_SCREENING' = 'SHOWER_SCREENING',
  'OTHER' = 'OTHER',
}

export const worksCategories = {
  [WorksCategory.JOINERY]: 'Joinery',
  [WorksCategory.CARPENTRY]: 'Carpentry',
  [WorksCategory.CLEANING]: 'Cleaning',
  [WorksCategory.CONCRETE]: 'Concrete',
  [WorksCategory.DEMOLITION]: 'Demolition',
  [WorksCategory.ELECTRICAL]: 'Electrical',
  [WorksCategory.EXCAVATION]: 'Excavation',
  [WorksCategory.FENCING]: 'Fencing',
  [WorksCategory.FLOORING]: 'Flooring',
  [WorksCategory.FRAMING]: 'Framing',
  [WorksCategory.GLAZING]: 'Glazing',
  [WorksCategory.HVAC]: 'HVAC',
  [WorksCategory.INSULATION]: 'Insulation',
  [WorksCategory.LANDSCAPING]: 'Landscaping',
  [WorksCategory.PAINTING]: 'Painting',
  [WorksCategory.PEST_CONTROL]: 'Pest control',
  [WorksCategory.PLASTERING]: 'Plastering',
  [WorksCategory.PLUMBING]: 'Plumbing',
  [WorksCategory.RENDERING]: 'Rendering',
  [WorksCategory.ROOFING]: 'Roofing',
  [WorksCategory.RUBBISH_REMOVAL]: 'Rubbish removal',
  [WorksCategory.SCAFFOLDING]: 'Scaffolding',
  [WorksCategory.STEEL_FABRICATION]: 'Steel fabrication',
  [WorksCategory.TILING]: 'Tiling',
  [WorksCategory.CAULKING]: 'Caulking',
  [WorksCategory.SHOWER_SCREENING]: 'Shower screening',
  [WorksCategory.OTHER]: 'Other',
};
