import * as LabelPrimitive from '@radix-ui/react-label';

import { styled } from '../design-tokens';

export const LabelContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  userSelect: 'none',
});

export const StyledLabel = styled(LabelPrimitive.Root, {
  'display': 'inline-block',
  'alignItems': 'center',
  'color': '$label',
  'lineHeight': 1.375,

  '&:empty': {
    display: 'none',
  },

  'variants': {
    size: {
      small: {
        fontSize: '12px',
      },

      medium: {
        fontSize: '14px',
      },

      large: {
        fontSize: '16px',
      },
    },

    weight: {
      normal: {
        fontWeight: '$normal',
      },

      medium: {
        fontWeight: '$medium',
      },

      semibold: {
        fontWeight: '$semibold',
      },
    },

    asterisk: {
      true: {
        '&::after': {
          fontSize: '10px',
          content: '∗',
          color: '$pink',
          verticalAlign: 'text-top',
          marginLeft: '2px',
        },
      },
    },
  },

  'defaultvariants': {
    asterisk: false,
    size: 'medium',
    weight: 'semibold',
  },
});
