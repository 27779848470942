import * as Portal from '@radix-ui/react-portal';
import { forwardRef, Fragment, useCallback, useState } from 'react';

import { Alert, type AlertProps } from '../alert';
import { Button, type ButtonProps } from '../button';
import type { BaseProps } from '../interfaces';
import { Separator } from '../separator';
import { Space } from '../space';
import {
  QuickActionsActionSheet,
  QuickActionsContainer,
  QuickActionsDesktop,
  QuickActionsMobile,
  QuickActionsOverlay,
} from './_Base';

export const defaultQuickActionsProps = {};

export interface QuickActionsProps extends BaseProps {
  actions: ButtonProps[];
  alert?: AlertProps;
  stickyNavbar?: boolean;
  hidden?: boolean;
  advertisement?: React.ReactNode;
}

export const QuickActions = forwardRef<HTMLDivElement, QuickActionsProps>((props, ref) => {
  const { actions, alert, stickyNavbar, hidden, advertisement, ...restProps } = props;
  const [showQuickActions, setQuickActionsShow] = useState(false);

  const handleQuickActionsShow = useCallback(() => {
    setQuickActionsShow(true);
  }, []);

  const handleQuickActionsClose = useCallback(() => {
    setQuickActionsShow(false);
  }, []);

  const handleQuickActionClick = useCallback((event: React.MouseEvent, action: ButtonProps) => {
    if (typeof action.onClick === 'function') {
      action.onClick(event);
    }
    setQuickActionsShow(false);
  }, []);

  if (hidden || actions.filter((action) => !action.hidden).length === 0) {
    return null;
  }

  return (
    <>
      <QuickActionsDesktop ref={ref} {...restProps}>
        <QuickActionsContainer stickyNavbar={stickyNavbar}>
          {actions.map((action, index) => (
            <Button
              key={index}
              color="primary"
              size="xlarge"
              onClick={(event) => handleQuickActionClick(event, action)}
              animated
              {...action}
            />
          ))}
          {alert ? (
            <Alert
              title={alert.title}
              content={alert.content}
              footer={alert.footer}
              css={{ mt: 8 }}
              isVertical
            />
          ) : null}
          {advertisement}
        </QuickActionsContainer>
      </QuickActionsDesktop>
      <Portal.Root>
        <QuickActionsMobile hidden={!showQuickActions}>
          <QuickActionsOverlay onClick={handleQuickActionsClose} hidden={!showQuickActions} />
          <QuickActionsActionSheet>
            {showQuickActions ? (
              <Space direction="vertical" inline={false}>
                {actions.map((action, index) => (
                  <Fragment key={index}>
                    <Button
                      color={action.color}
                      onClick={(event) => handleQuickActionClick(event, action)}
                      block
                      variant="transparent"
                      size="large"
                      hidden={action.hidden}
                    >
                      {action.children}
                    </Button>
                    <Separator decorative hidden={action.hidden} />
                  </Fragment>
                ))}
                <Button
                  key="close"
                  variant="transparent"
                  size="large"
                  onClick={handleQuickActionsClose}
                  block
                >
                  Close quick actions
                </Button>
              </Space>
            ) : (
              <Button
                color="primary"
                size="xlarge"
                leadingIcon="quick-actions"
                onClick={handleQuickActionsShow}
                animated
                block
              >
                Quick actions
              </Button>
            )}
          </QuickActionsActionSheet>
        </QuickActionsMobile>
      </Portal.Root>
    </>
  );
});

QuickActions.displayName = 'QuickActions';
