import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';

export const StyledSVGIcon = styled(SVGIcon, {
  flexShrink: 0,
  width: '16px',
  height: '16px',
  marginRight: '4px',
  fill: 'currentColor',
});

export const StyledTag = styled('span', {
  'display': 'inline-flex',
  'flexShrink': 0,
  'alignItems': 'center',
  'justifyContent': 'center',
  'width': 'fit-content',
  'height': '24px',
  'minWidth': '32px',
  'padding': '4px 6px',
  'borderRadius': '4px',
  'fontSize': '12px',
  'lineHeight': '16px',
  'fontWeight': '$semibold',
  'border': '1px solid $colors$bg',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    variant: {
      solid: {
        borderColor: '$bg',
        backgroundColor: '$fgTertiary',
        color: '$bg',
      },

      outline: {
        borderColor: '$fgTertiary',
        backgroundColor: 'transparent',
        color: '$fgTertiary',
      },
    },

    color: {
      default: {
        backgroundColor: '$fgTertiary',
      },

      primary: {
        backgroundColor: '$blue',
      },

      success: {
        backgroundColor: '$payInGreen',
      },

      danger: {
        backgroundColor: '$pink',
      },
    },
  },

  'compoundVariants': [
    {
      variant: 'solid',
      color: 'primary',
      css: {
        backgroundColor: '$blue',
      },
    },
    {
      variant: 'solid',
      color: 'success',
      css: {
        backgroundColor: '$payInGreen',
      },
    },
    {
      variant: 'solid',
      color: 'danger',
      css: {
        backgroundColor: '$pink',
      },
    },
    {
      variant: 'outline',
      color: 'default',
      css: {
        backgroundColor: 'transparent',
      },
    },
    {
      variant: 'outline',
      color: 'primary',
      css: {
        borderColor: '$blue',
        color: '$blue',
        backgroundColor: 'transparent',
      },
    },
    {
      variant: 'outline',
      color: 'success',
      css: {
        borderColor: '$payInGreen',
        color: '$payInGreen',
        backgroundColor: 'transparent',
      },
    },
    {
      variant: 'outline',
      color: 'danger',
      css: {
        borderColor: '$pink',
        color: '$pink',
        backgroundColor: 'transparent',
      },
    },
  ],

  'defaultVariants': {
    color: 'default',
    variant: 'solid',
  },
});
