import { type LinkedItem as ILinkedItem } from '@paid-ui/types';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { LinkedItem } from '../linked-item';
import { EmptyText } from './_Base';

export const defaultLinkedListProps = {
  emptyText: 'No linked item',
};

export interface LinkedListProps extends BaseProps {
  data: ILinkedItem[];
  emptyText?: string;
}

export const LinkedList = forwardRef<HTMLDivElement, LinkedListProps>((props, ref) => {
  const { data, emptyText = defaultLinkedListProps.emptyText, ...restProps } = props;

  if (data.length === 0) {
    return <EmptyText>{emptyText}</EmptyText>;
  }

  return (
    <div ref={ref} {...restProps}>
      {data.map((item) => (
        <LinkedItem key={item.id} data={item} />
      ))}
    </div>
  );
});

LinkedList.displayName = 'LinkedList';
