import { SVGIcon } from '@paid-ui/icons';

import { breakpoints, styled } from '../design-tokens';

export const StyledNavbar = styled('header', {
  'display': 'flex',
  'width': '100%',
  'alignItems': 'center',
  'justifyContent': 'space-between',
  'padding': '12px 16px',
  'height': '54px',
  'backgroundColor': '$fg',
  'boxSizing': 'border-box',
  '&::before': {
    boxSizing: 'border-box',
  },

  '&::after': {
    boxSizing: 'border-box',
  },

  'variants': {
    rounded: {
      true: {
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
    },

    sticky: {
      true: {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: '$navbar',
      },
    },

    glass: {
      true: {
        opacity: 0.9,
        backdropFilter: 'blur(12px) saturate(160%)',
      },
    },
  },
});

export const StyledContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: breakpoints.lg,
  margin: '0 auto',
});

export const StyledNavbarIcon = styled('div', {
  'position': 'relative',
  'display': 'inline-block',
  'color': 'rgb(255 255 255 / 60%)',
  'transition': 'color 100ms',
  'padding': '3px',
  'cursor': 'pointer',

  '&:focus': {
    color: 'rgb(255 255 255 / 80%)',
  },

  '@hover': {
    '&:hover': {
      backgroundColor: 'rgb(12 37 76)',
      color: 'white',
    },
  },
});

export const StyledNavbarMenuIcon = styled(StyledNavbarIcon, {
  '@sm': {
    display: 'none',
  },
});

export const StyledNavbarNotificationIcon = styled(StyledNavbarIcon, {
  variants: {
    underscore: {
      true: {
        '&::after': {
          position: 'absolute',
          right: '10px',
          bottom: '-3px',
          left: '10px',
          width: '10px',
          height: '3px',
          borderRadius: '2px',
          backgroundColor: '$pink',
          content: '',
        },
      },
    },
  },
});

export const StyledNavbarLogo = styled('button', {
  all: 'unset',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  fontWeight: '$semibold',
  lineHeight: '20px',
  color: 'rgb(255 255 255 / 60%)',
  transition: 'color 100ms',
  cursor: 'pointer',
});

export const StyledNavbarMenu = styled('nav', {
  'display': 'none',
  'marginRight': 'auto',
  'marginLeft': '8px',
  'height': '32px',
  'flex': 1,
  'alignItems': 'center',
  'columnGap': '2px',

  '&:empty': {
    display: 'none',
  },

  '@sm': {
    display: 'block',
  },
});

export const StyledNavbarMenuItem = styled('button', {
  'all': 'unset',
  'position': 'relative',
  'display': 'inline-block',
  'whiteSpace': 'nowrap',
  'fontWeight': '$semibold',
  'lineHeight': '20px',
  'color': 'rgb(255 255 255 / 60%)',
  'transition': 'color 100ms',
  'padding': '5px 10px',
  'cursor': 'pointer',

  '&:focus': {
    color: 'rgb(255 255 255 / 80%)',
  },

  '@hover': {
    '&:hover': {
      color: 'white',
    },
  },

  'variants': {
    active: {
      true: {
        'color': 'white',

        '&::after': {
          position: 'absolute',
          right: 0,
          bottom: '-13px',
          left: 0,
          height: '4px',
          backgroundColor: '$blue',
          content: '',
        },
      },
    },

    disabled: {
      true: {
        color: 'rgb(255 255 255 / 40%)',
        cursor: 'not-allowed',
      },
    },
  },

  'defaultVariants': {
    active: false,
    disabled: false,
  },
});

export const NavbarSideContent = styled('div', {
  display: 'flex',
  height: '30px',
  alignItems: 'center',
  columnGap: '12px',
});

export const StyledSVGIcon = styled(SVGIcon, {
  size: '24px',
});

export const CountIndicator = styled('div', {
  padding: '0 6px 2px 5px',
  position: 'absolute',
  top: '-2px',
  right: '0px',
  backgroundColor: '$pink',
  color: '$bg',
  fontSize: '10px',
  borderRadius: '8px',
  fontWeight: '$medium',
  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
