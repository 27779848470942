import { type SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { StyledNavbarIcon, StyledSVGIcon } from './_Base';

interface NavbarIconProps extends BaseProps {
  name: SVGIconName;
  hidden?: boolean;
  onClick?: () => void;
}

export const NavbarIcon = forwardRef<HTMLDivElement, NavbarIconProps>((props, ref) => {
  const { name, hidden, onClick, ...restProps } = props;

  if (hidden) {
    return null;
  }

  return (
    <StyledNavbarIcon ref={ref} onClick={onClick} {...restProps}>
      <StyledSVGIcon name={name} />
    </StyledNavbarIcon>
  );
});

NavbarIcon.displayName = 'NavbarIcon';
