import { numberPattern } from '@paid-ui/regexps';
import { formatNumericString } from '@paid-ui/utils';
import { forwardRef, useCallback, useMemo, useRef } from 'react';

import type { CSS } from '../design-tokens';
import { FormControl, LeadingIconButton, TrailingIconButton } from '../form';
import { HelperText } from '../help-text';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import { DateIcon, StyledInput } from './_Base';

export type IconPosition = 'leading' | 'trailing';
export type InputMode =
  | 'text'
  | 'email'
  | 'search'
  | 'tel'
  | 'url'
  | 'none'
  | 'numeric'
  | 'decimal';

export const defaultInputProps = {
  type: 'text' as React.HTMLInputTypeAttribute,
  inputMode: 'text' as InputMode,
  paddingLeft: 40,
  paddingRight: 40,
  formatSeparator: / /g,
};

export interface InputProps extends BaseProps {
  name: string;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: InputMode;
  min?: number | string;
  max?: number | string;
  maxLength?: number;
  size?: FontSize;
  placeholder?: string;
  value?: string | number;
  defaultValue?: string | number;
  label?: React.ReactNode;
  labelSize?: FontSize;
  labelWeight?: FontWeight;
  extra?: React.ReactNode;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  helperTextStyle?: CSS;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  leadingBordered?: boolean;
  trailingBordered?: boolean;
  block?: boolean;
  ghost?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  hidden?: boolean;
  simple?: boolean;
  center?: boolean;
  autoComplete?: string;
  autoCapitalize?: string;
  autoCorrect?: string;
  width?: number | string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  /**
   * How to format value to display value
   * ABN example: ## ### ### ###
   */
  format?: string;
  formatSeparator?: RegExp;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  setFieldValue?: (name: string, value?: string | number) => void;
  onIconButtonClick?: (position: IconPosition) => void;
  onIconButtonPress?: (position: IconPosition) => void;
  onIconButtonRelease?: (position: IconPosition) => void;
  onIconButtonLeave?: (position: IconPosition) => void;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    name,
    type = defaultInputProps.type,
    inputMode = defaultInputProps.inputMode,
    min,
    max,
    placeholder,
    value,
    defaultValue,
    label,
    labelSize,
    labelWeight,
    extra,
    helperText,
    errorMessage,
    leadingIcon,
    trailingIcon,
    block,
    ghost,
    required,
    disabled,
    readOnly,
    autoFocus,
    hidden,
    simple,
    center,
    autoComplete = 'off',
    autoCorrect,
    autoCapitalize,
    width,
    paddingLeft = defaultInputProps.paddingLeft,
    paddingRight = defaultInputProps.paddingRight,
    size,
    maxLength,
    helperTextStyle,
    format,
    formatSeparator = defaultInputProps.formatSeparator,
    trailingBordered,
    leadingBordered,
    onPaste,
    onChange,
    onBlur,
    setFieldValue,
    onIconButtonClick,
    onIconButtonPress,
    onIconButtonRelease,
    onIconButtonLeave,
    onPressEnter,
    ...restProps
  } = props;
  const inputDateRef = useRef<HTMLInputElement | null>(null);

  const inputRef = useMemo(() => {
    if (type === 'date') {
      return inputDateRef;
    }
    return ref;
  }, [ref, type]);
  const hasError = !!errorMessage;

  const displayValue = useMemo(() => {
    if (typeof value === 'number') {
      return value;
    }

    return formatNumericString(value, format);
  }, [format, value]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (typeof onChange === 'function') {
        if (event.target.value && format) {
          if (numberPattern.test(event.target.value)) {
            event.target.value = event.target.value
              .slice(0, Math.max(0, format.length))
              .replace(formatSeparator, '');
          }

          if (numberPattern.test(event.currentTarget.value)) {
            event.currentTarget.value = event.currentTarget.value
              .slice(0, Math.max(0, format.length))
              .replace(formatSeparator, '');
          }
        }

        onChange(event);
      }
    },
    [format, formatSeparator, onChange],
  );

  const handleIconButtonClick = useCallback(
    (position: IconPosition) => {
      if (typeof onIconButtonClick === 'function') {
        onIconButtonClick(position);
      }
    },
    [onIconButtonClick],
  );

  const handleIconButtonPress = useCallback(
    (position: IconPosition) => {
      if (typeof onIconButtonPress === 'function') {
        onIconButtonPress(position);
      }
    },
    [onIconButtonPress],
  );

  const handleIconButtonRelease = useCallback(
    (position: IconPosition) => {
      if (typeof onIconButtonRelease === 'function') {
        onIconButtonRelease(position);
      }
    },
    [onIconButtonRelease],
  );

  const handleIconButtonLeave = useCallback(
    (position: IconPosition) => {
      if (typeof onIconButtonLeave === 'function') {
        onIconButtonLeave(position);
      }
    },
    [onIconButtonLeave],
  );

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      if (event.key === 'Enter' && typeof onPressEnter === 'function') {
        onPressEnter(event);
      }
    },
    [onPressEnter],
  );

  const handleShowCalendar = useCallback(() => {
    if (
      inputDateRef?.current &&
      !inputDateRef?.current?.disabled &&
      !inputDateRef?.current?.readOnly
    ) {
      inputDateRef?.current?.showPicker();
    }
  }, [inputDateRef]);

  const trailingIconWithDate = useMemo(() => {
    if (type === 'date') {
      return <DateIcon name="calendar" onClick={handleShowCalendar} readonly={readOnly} />;
    }
    return trailingIcon;
  }, [handleShowCalendar, readOnly, trailingIcon, type]);

  if (hidden) {
    return null;
  }

  if (type === 'hidden') {
    return (
      <StyledInput
        ref={ref}
        id={name}
        name={name}
        type={type}
        inputMode={inputMode}
        min={min}
        max={max}
        maxLength={maxLength}
        size={typeof size === 'string' ? size : undefined}
        placeholder={placeholder}
        value={displayValue}
        block={block}
        ghost={ghost}
        disabled={disabled}
        readOnly={readOnly}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        autoCorrect={autoCorrect}
        invalid={hasError}
        onChange={handleChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        css={{
          display: 'none',
        }}
      />
    );
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label && !simple ? (
        <Label
          htmlFor={name}
          asterisk={required}
          extra={extra}
          size={labelSize}
          weight={labelWeight}
        >
          {label}
        </Label>
      ) : null}

      <FormControl block={block}>
        <LeadingIconButton
          type="button"
          tabIndex={-1}
          bordered={leadingBordered}
          onClick={() => handleIconButtonClick('leading')}
          onMouseDown={() => handleIconButtonPress('leading')}
          onMouseUp={() => handleIconButtonRelease('leading')}
          onMouseLeave={() => handleIconButtonLeave('leading')}
        >
          {leadingIcon}
        </LeadingIconButton>
        <StyledInput
          ref={inputRef}
          id={name}
          name={name}
          type={type}
          inputMode={inputMode}
          min={min}
          max={max}
          maxLength={maxLength}
          size={typeof size === 'string' ? size : undefined}
          placeholder={placeholder}
          value={displayValue}
          block={block}
          ghost={ghost}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          center={center}
          autoComplete={autoComplete}
          invalid={hasError}
          onChange={handleChange}
          onBlur={onBlur}
          onPaste={onPaste}
          onKeyDown={handleKeyDown}
          className={type === 'date' && !!displayValue ? 'date-input--has-value' : ''}
          aria-invalid={hasError ? 'true' : 'false'}
          aria-describedby={hasError ? `${name}-error` : `${name}-description`}
          css={{
            width,
            paddingLeft: leadingIcon ? paddingLeft : undefined,
            paddingRight: trailingIcon ? paddingRight : undefined,
          }}
        />
        <TrailingIconButton
          type="button"
          tabIndex={-1}
          bordered={trailingBordered || type === 'date'}
          onClick={() => handleIconButtonClick('trailing')}
          onMouseDown={() => handleIconButtonPress('trailing')}
          onMouseUp={() => handleIconButtonRelease('trailing')}
          onMouseLeave={() => handleIconButtonLeave('trailing')}
        >
          {trailingIconWithDate}
        </TrailingIconButton>
      </FormControl>

      {simple ? null : (
        <HelperText
          error={hasError}
          id={hasError ? `${name}-error` : `${name}-description`}
          css={helperTextStyle}
        >
          {hasError ? errorMessage : helperText}
        </HelperText>
      )}
    </Space>
  );
});

Input.displayName = 'Input';
