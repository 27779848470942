import { forwardRef } from 'react';

import type { BasePropsWithChildren, FontSize } from '../interfaces';
import { StyledHelperText } from './_Base';

export const defaultHelperTextProps = {
  size: 'medium' as FontSize,
};

export type HelperTextColor = 'default' | 'primary' | 'success' | 'danger' | 'grey';

export interface HelperTextProps extends BasePropsWithChildren {
  /** Size of helper text */
  size?: FontSize;
  /** If it is a error message */
  error?: boolean;
  /** Color of helper text */
  color?: HelperTextColor;
  /** Hide helper text or not */
  hidden?: boolean;
}

export const HelperText = forwardRef<HTMLParagraphElement, HelperTextProps>((props, ref) => {
  const {
    size = defaultHelperTextProps.size,
    error,
    children,
    css,
    color,
    hidden,
    ...restProps
  } = props;

  if (hidden || !children) {
    return null;
  }

  return (
    <StyledHelperText
      ref={ref}
      size={typeof size === 'number' ? undefined : size}
      error={error}
      color={color}
      css={{
        ...css,
        fontSize: typeof size === 'number' ? size : undefined,
      }}
      {...restProps}
    >
      {children}
    </StyledHelperText>
  );
});

HelperText.displayName = 'HelperText';
