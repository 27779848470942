import { SVGIcon } from '@paid-ui/icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { dialogContentShow, dialogOverlayShow, styled } from '../design-tokens';

export const DialogRoot = DialogPrimitive.Root;
export const DialogPortal = DialogPrimitive.Portal;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;

export const DialogOverlay = styled(DialogPrimitive.Overlay, {
  'position': 'fixed',
  'inset': 0,
  'backgroundColor': '$bgOverlay',
  'animation': `${dialogOverlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  'zIndex': '$overlay',

  '@motion': {
    animation: 'none',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
    nested: {
      true: {
        zIndex: '$overlay2',
      },
    },

    fullScreen: {
      true: {
        backgroundColor: '$bg',
        backdropFilter: 'none',
      },
    },
  },
});

export const DialogContent = styled(DialogPrimitive.Content, {
  'position': 'fixed',
  'top': '50%',
  'left': '50%',
  'transform': 'translate(-50%, -50%)',
  'width': '100vw',
  'height': '100vh',
  'maxWidth': '100vw',
  'maxHeight': '100vh',
  'zIndex': '$dialog',
  'overflow': 'hidden auto',
  'backgroundColor': 'white',
  'borderRadius': 0,
  'outline': 'none',
  'animation': `${dialogContentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  '@motion': {
    animation: 'none',
  },

  '@sm': {
    borderRadius: '0px 16px 16px',
  },

  'variants': {
    fixMobileSafari: {
      true: {
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
        },
      },

      false: {
        '@supports (-webkit-touch-callout: none)': {
          height: '100vh',
        },
      },
    },

    nested: {
      true: {
        zIndex: '$dialog2',
      },
    },

    size: {
      small: {
        'maxWidth': 'calc(100vw - 10px)',
        'maxHeight': 'calc(100vh - 10px)',
        'borderRadius': '0px 16px 16px',
        'size': 'auto',

        '@sm': {
          width: '400px',
        },
      },

      medium: {
        '@sm': {
          width: '700px',
          height: '760px',
        },
      },

      large: {
        '@sm': {
          width: '840px',
          height: '820px',
        },
      },
    },

    fullScreen: {
      true: {
        '@sm': {
          width: '100vw',
          height: '100vh',
          maxHeight: '100vh',
          borderRadius: 0,
        },
      },
    },
  },

  'defaultVariants': {
    size: 'medium',
  },
});

export const DialogCloseButton = styled('button', {
  'all': 'unset',
  'cursor': 'pointer',
  'position': 'absolute',
  'top': '28px',
  'right': '32px',
  'zIndex': 1,
  '@sm': {
    top: '38px',
    right: '44px',
  },
});

export const DialogCloseIcon = styled(SVGIcon, {
  'size': '20px',
  'color': '$fgSecondary',

  '&:hover': {
    color: '$blue',
  },
});
