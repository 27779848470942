import { PartyType } from '@paid-ui/constants';
import { type ContractSummary } from '@paid-ui/queries/project-summary';
import type { DraftContract } from '@paid-ui/types';
import { getFullContractName } from '@paid-ui/utils';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import {
  CheckboxIcon,
  DropIcon,
  PrimaryText,
  SecondaryText,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTitle,
  SelectTrigger,
  SelectValue,
  SelectViewport,
} from './_Base';

export type ContractListInProject = {
  drafts: DraftContract[];
  headContract: ContractSummary | null;
  subContracts: ContractSummary[];
  supplyContracts: ContractSummary[];
};
export type ContractSelectSize = 'small' | 'large';

export const defaultContractSelectProps = {
  contracts: {} as ContractListInProject,
  size: 'large' as ContractSelectSize,
  viewAs: PartyType.PAYER,
};

export interface ContractSelectProps extends BaseProps {
  name: string;
  /** Selected contract id */
  value: string;
  /** Contract change handler */
  onChange: (value: string) => void;
  /** Label of select */
  label?: React.ReactNode;
  /** Contract list in project */
  contracts?: ContractListInProject;
  /** Size of contract select */
  size?: ContractSelectSize;
  /** If select disabled */
  disabled?: boolean;
  /** View as party type */
  viewAs?: PartyType;
}

export const ContractSelect = forwardRef<HTMLButtonElement, ContractSelectProps>((props, ref) => {
  const {
    name,
    value,
    label,
    contracts = defaultContractSelectProps.contracts,
    size = defaultContractSelectProps.size,
    viewAs = defaultContractSelectProps.viewAs,
    disabled,
    onChange,
    ...restProps
  } = props;
  const { headContract, subContracts, supplyContracts } = contracts;

  return (
    <Space size={8} direction="vertical" inline={false}>
      <SelectRoot value={value} onValueChange={onChange}>
        <SelectTitle>{label}</SelectTitle>
        <SelectTrigger name={name} ref={ref} size={size} disabled={disabled} {...restProps}>
          <SelectValue />
          <SelectIcon>
            <DropIcon name="drop" />
          </SelectIcon>
        </SelectTrigger>
        <SelectContent hidden={disabled}>
          <SelectViewport>
            {headContract ? (
              <SelectItem value={headContract?.id ?? ''} size={size}>
                <SelectItemText>
                  <PrimaryText hidden={!value}>
                    {getFullContractName(headContract.theOtherPartyName, headContract.address)}
                  </PrimaryText>
                  <SecondaryText>
                    {value ? 'Building / Head contract' : 'Select contract'}
                  </SecondaryText>
                </SelectItemText>
                <SelectItemIndicator hidden={!value}>
                  <CheckboxIcon name="checkbox" />
                </SelectItemIndicator>
              </SelectItem>
            ) : null}
            {subContracts.map((contract) => (
              <SelectItem key={contract.id} value={contract.id} size={size}>
                <SelectItemText>
                  <PrimaryText>
                    {getFullContractName(contract.theOtherPartyName, contract.address)}
                  </PrimaryText>
                  <SecondaryText>Subcontract</SecondaryText>
                </SelectItemText>
                <SelectItemIndicator>
                  <CheckboxIcon name="checkbox" />
                </SelectItemIndicator>
              </SelectItem>
            ))}
            {supplyContracts.map((contract) => (
              <SelectItem key={contract.id} value={contract.id} size={size}>
                <SelectItemText>
                  <PrimaryText>
                    {getFullContractName(contract.theOtherPartyName, contract.address)}
                  </PrimaryText>
                  <SecondaryText>Supply contract</SecondaryText>
                </SelectItemText>
                <SelectItemIndicator>
                  <CheckboxIcon name="checkbox" />
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </SelectRoot>
    </Space>
  );
});

ContractSelect.displayName = 'ContractSelect';
