import { type Dispatch, forwardRef, useCallback, useMemo, useState } from 'react';

import type { BaseProps } from '../interfaces';
import { Tooltip } from '../tooltip';
import {
  StepFormClose,
  StepFormDescription,
  StepFormIconButton,
  StepFormLogo,
  StepFormProgress,
  StepFormProgressItem,
  StepFormSVGIcon,
  StepFormTitle,
  StepFormTitleAndDescription,
  StyledStepFormHeader,
} from './_Base';
import type { FormStepper } from './types';

export interface StepFormHeaderProps extends BaseProps {
  currentStep?: number;
  steps?: FormStepper[];
  isSticky?: boolean;
  readOnly?: boolean;
  hidden?: boolean;
  disableAutoClose?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  hideCloseButton?: boolean;
  dispatch?: Dispatch<any>;
  goToStep?: (step: number) => void;
}

export const StepFormHeader = forwardRef<HTMLDivElement, StepFormHeaderProps>((props, ref) => {
  const {
    currentStep: currentStepIndex = 0,
    steps = [],
    isSticky,
    readOnly,
    hidden,
    disableAutoClose,
    hideCloseButton,
    onClose,
    dispatch,
    goToStep,
    ...restProps
  } = props;
  const [header, setHeader] = useState<HTMLElement | null>(null);

  const callbackRef = useCallback(
    (node: HTMLDivElement | null) => {
      setHeader(node);
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    [ref],
  );

  const currentStep = useMemo(() => {
    return steps[currentStepIndex];
  }, [currentStepIndex, steps]);

  const handleGoToStep = useCallback(
    (step: number) => {
      if (dispatch) {
        dispatch({
          type: 'goToStep',
          payload: step,
        });
      }
      if (goToStep) {
        goToStep(step);
      }
    },
    [dispatch, goToStep],
  );

  if (hidden) {
    return null;
  }

  return (
    <StyledStepFormHeader sticky={isSticky} ref={callbackRef} {...restProps}>
      <StepFormLogo symbolOnly />
      <StepFormTitleAndDescription size={2} direction="vertical">
        <StepFormTitle>{currentStep?.title}</StepFormTitle>
        <StepFormDescription>{currentStep?.description}</StepFormDescription>
      </StepFormTitleAndDescription>
      <StepFormProgress align="center" size={1}>
        {steps
          .filter((step) => step.included)
          .map((step) => (
            <Tooltip key={step.index} title={step.description} collisionBoundary={header}>
              <StepFormProgressItem
                type="button"
                readonly={readOnly}
                active={step.index <= currentStepIndex}
                onClick={readOnly ? undefined : () => handleGoToStep(step.index)}
              />
            </Tooltip>
          ))}
      </StepFormProgress>
      {hideCloseButton ? null : disableAutoClose ? (
        <StepFormIconButton type="button" onClick={onClose}>
          <StepFormSVGIcon name="close" />
        </StepFormIconButton>
      ) : (
        <StepFormClose asChild>
          <StepFormIconButton type="button">
            <StepFormSVGIcon name="close" />
          </StepFormIconButton>
        </StepFormClose>
      )}
    </StyledStepFormHeader>
  );
});

StepFormHeader.displayName = 'StepFormHeader';
