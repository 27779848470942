import { styled } from '@paid-ui/ui';

export const LeftHub = styled('div', {
  position: 'fixed',
  left: 0,
  bottom: '96px',
  padding: '10px 8px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '$semibold',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  backgroundColor: '$bgGroup',
  writingMode: 'vertical-lr',
  transform: 'rotate(180deg)',
  borderRadius: '4px 0 0 4px',
  userSelect: 'none',
  zIndex: '$navbar',

  variants: {
    env: {
      local: {
        color: '$fgTertiary',
      },
      dev: {
        color: '$fgTertiary',
      },
      sit: {
        color: '$blue',
      },
      uat: {
        color: '$payInGreen',
      },
      prod: {
        display: 'none',
      },
    },
  },
});
