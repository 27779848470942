import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { SCROLLBAR_SIZE, styled } from '../design-tokens';

export const ScrollArea = styled(ScrollAreaPrimitive.Root, {
  height: '100%',
  width: '100%',
  overflow: 'hidden',

  variants: {
    rounded: {
      true: {
        borderRadius: 4,
      },
    },

    shadow: {
      true: {
        boxShadow: `0 2px 10px rgb(0 0 0 / 14%)`,
      },
    },
  },
});

export const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

export const Scrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  'display': 'flex',
  'userSelect': 'none',
  'touchAction': 'none',
  'padding': 2,
  'background': '$bg',

  '&[data-orientation="vertical"]': {
    width: SCROLLBAR_SIZE,
  },

  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
});

export const ScrollbarThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: 'rgb(134 132 141)',
  borderRadius: SCROLLBAR_SIZE / 2,
  position: 'relative',
});

export const ScrollAreaCorner = styled(ScrollAreaPrimitive.Corner, {
  background: 'rgb(0 0 0 / 22%)',
});
