import camelCase from 'lodash/camelCase';
import { forwardRef, Fragment, useCallback } from 'react';

import type { BaseProps } from '../interfaces';
import { Separator } from '../separator';
import { DescriptionsItems } from './_Base';
import { Description, type ValueType } from './Description';

export const defaultDescriptionsProps = {};

export interface DescriptionColumn {
  dataIndex: string;
  label?: React.ReactNode;
  type?: ValueType;
  span?: 1 | 2;
  separator?: boolean;
  hidden?: boolean;
  render?: (value: any) => React.ReactNode;
}

export interface DescriptionsProps extends BaseProps {
  data: Record<string, any>;
  errors: any;
  columns: DescriptionColumn[];
}

export const Descriptions = forwardRef<HTMLDivElement, DescriptionsProps>((props, ref) => {
  const { data, errors, columns, ...restProps } = props;

  const getNestedData = useCallback((obj: Record<string, any>, dataIndex: string) => {
    return dataIndex.split('.').reduce((o, k) => (o ? o[k] : null), obj as any);
  }, []);

  const getNestedError = useCallback((obj: Record<string, any>, dataIndex: string) => {
    return dataIndex.split('.').reduce((o, k) => (o ? o[k] : null), obj as any);
  }, []);

  return (
    <DescriptionsItems ref={ref} {...restProps}>
      {columns
        .filter((column) => !column.hidden)
        .map((column) => (
          <Fragment key={column.dataIndex}>
            <Description
              type={column.type}
              label={
                column.label ??
                column.dataIndex
                  .split('.')
                  .map((part) => camelCase(part))
                  .join(' ')
              }
              value={getNestedData(data, column.dataIndex)}
              renderValue={column.render}
              span={column.span}
              errorMessage={getNestedError(errors, column.dataIndex)}
            />
            {column.separator ? (
              <Separator
                css={{
                  gridColumn: 'span 2 / span 2',
                }}
              />
            ) : null}
          </Fragment>
        ))}
    </DescriptionsItems>
  );
});

Descriptions.displayName = 'Descriptions';
