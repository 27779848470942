import { SVGIcon } from '@paid-ui/icons';
import { blue, gray, slate } from '@radix-ui/colors';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { styled } from '../design-tokens';

export const RadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '14px',
  'width': '100%',

  '@sm': {
    flexDirection: 'row',
  },

  'variants': {
    direction: {
      vertical: {
        'flexDirection': 'column',
        '@sm': {
          flexDirection: 'column',
        },
      },

      horizontal: {},
    },

    display: {
      grid: {
        display: 'grid',
      },
      flex: {},
    },
  },
});

export const ClassicRadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '14px',
  'width': '100%',

  '@sm': {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '24px',
  },

  'variants': {
    direction: {
      vertical: {
        'flexDirection': 'column',
        '@sm': {
          flexDirection: 'column',
        },
      },

      horizontal: {},
    },
  },
});

export const RadioContainer = styled('label', {
  'display': 'flex',
  'justifyContent': 'space-between',
  'flexWrap': 'wrap',
  'gap': '10px',
  'flex': 1,
  'padding': '14px 20px',
  'borderWidth': '2px',
  'borderStyle': 'solid',
  'borderRadius': '0px 16px 16px',
  'backgroundColor': '$bg',
  'overflow': 'hidden',
  'cursor': 'pointer',

  '&:hover': {
    borderColor: '$fgTertiary',
  },

  'variants': {
    color: {
      default: {},
      primary: {},
      success: {},
      danger: {},
    },

    selected: {
      true: {
        'borderColor': '$fgSecondary',
        'color': '$fgSecondary',
        'cursor': 'unset',

        '&:hover': {
          borderColor: '$fgSecondary',
          color: '$fgSecondary',
        },
      },

      false: {
        borderColor: '$fgFaint',
        color: '$fgFaint',
      },
    },

    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  'compoundVariants': [
    {
      color: 'default',
      selected: true,
      css: {
        'borderColor': '$fgSecondary',
        'color': '$fgSecondary',
        '&:hover': {
          borderColor: '$fgSecondary',
          color: '$fgSecondary',
        },
      },
    },
    {
      color: 'primary',
      selected: true,
      css: {
        'borderColor': '$blue',
        'color': '$blue',
        '&:hover': {
          borderColor: '$blue',
          color: '$blue',
        },
      },
    },
    {
      color: 'success',
      selected: true,
      css: {
        'borderColor': '$payInGreen',
        'color': '$payInGreen',
        '&:hover': {
          borderColor: '$payInGreen',
          color: '$payInGreen',
        },
      },
    },
    {
      color: 'danger',
      selected: true,
      css: {
        'borderColor': '$pink',
        'color': '$pink',
        '&:hover': {
          borderColor: '$pink',
          color: '$pink',
        },
      },
    },
    {
      color: 'default',
      disabled: true,
      css: {
        'borderColor': '$fgFaint',
        'color': '$fgFaint',
        '&:hover': {
          borderColor: '$fgFaint',
          color: '$fgFaint',
        },
      },
    },
    {
      color: 'primary',
      disabled: true,
      css: {
        'borderColor': '$fgFaint',
        'color': '$fgFaint',
        '&:hover': {
          borderColor: '$fgFaint',
          color: '$fgFaint',
        },
      },
    },
    {
      color: 'success',
      disabled: true,
      css: {
        'borderColor': '$fgFaint',
        'color': '$fgFaint',
        '&:hover': {
          borderColor: '$fgFaint',
          color: '$fgFaint',
        },
      },
    },
    {
      color: 'danger',
      disabled: true,
      css: {
        'borderColor': '$fgFaint',
        'color': '$fgFaint',
        '&:hover': {
          borderColor: '$fgFaint',
          color: '$fgFaint',
        },
      },
    },
  ],

  'defaultVariants': {
    color: 'default',
    selected: false,
  },
});

export const RadioItem = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  boxSizing: 'border-box',
  flexShrink: 0,
});

export const ClassicRadioItem = styled(RadioGroupPrimitive.Item, {
  'all': 'unset',
  'backgroundColor': '$bg',
  'size': '20px',
  'borderRadius': '50%',
  'border': `2px solid ${gray.gray8}`,
  'flexShrink': 0,
  'transition': 'borderColor 100ms',

  '@hover': {
    '&:hover': {
      backgroundColor: blue.blue3,
    },
  },

  '&:focus': {
    borderColor: '$blue',
  },

  '&[data-state="checked"]': {
    borderColor: '$blue',
  },

  '&:disabled': {
    'backgroundColor': slate.slate4,

    '@hover': {
      '&:hover': {
        backgroundColor: slate.slate4,
      },
    },
  },
});

export const ClassicRadioItemIndicator = styled(RadioGroupPrimitive.Indicator, {
  'position': 'relative',
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'size': '100%',
  'backgroundColor': 'transparent',
  'transition': 'backgroundColor 100ms',

  '&::after': {
    display: 'block',
    borderRadius: '50%',
    backgroundColor: '$blue',
    content: '""',
    size: '10px',
  },
});

export const RadioItemLabel = styled('span', {
  color: '$fg',
  fontSize: '16px',
  lineHeight: '22px',
  userSelect: 'none',
  fontWeight: '$normal',
  flex: 1,
  wordBreak: 'break-word',

  variants: {
    selected: {
      true: {
        fontWeight: '$medium',
        letterSpacing: 0,
      },
    },

    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },

  compoundVariants: [
    {
      selected: true,
      disabled: true,
      css: {
        color: '$fg',
      },
    },
  ],
});

export const ClassicRadioItemLabel = styled('label', {
  color: '$fg',
  fontSize: '16px',
  fontWeight: '$normal',
  lineHeight: '24px',
  userSelect: 'none',
});

export const CheckboxSVGIcon = styled(SVGIcon, {
  size: '24px',
  color: 'currentColor',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const EmptySpan = styled('span', {
  display: 'inline-block',
  width: '24px',
  height: '24px',
});
