import reactStringReplace from 'react-string-replace';

import { HighlightMatched } from './_Base';

export const defaultHighlightProps = {};

export interface HighlightProps {
  highlight?: string | RegExp;
  children?: React.ReactNode;
}

export const Highlight: React.FC<HighlightProps> = (props) => {
  const { highlight, children } = props;

  if (typeof children === 'string' && highlight) {
    return (
      <>
        {reactStringReplace(children, highlight, (match, index) => (
          <HighlightMatched key={index}>{match}</HighlightMatched>
        ))}
      </>
    );
  }

  return children ?? null;
};

Highlight.displayName = 'Highlight';
