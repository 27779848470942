import { forwardRef, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { CountIndicator, StyledNavbarNotificationIcon, StyledSVGIcon } from './_Base';

interface NavbarNotificationIconProps extends BaseProps {
  underscore?: boolean;
  hidden?: boolean;
  amount?: number;
  maxAmount?: number;
  onClick?: () => void;
}

export const NavbarNotificationIcon = forwardRef<HTMLDivElement, NavbarNotificationIconProps>(
  (props, ref) => {
    const { underscore, hidden, amount, maxAmount = 99, onClick, ...restProps } = props;

    const amountComponent = useMemo(() => {
      if (!amount) {
        return null;
      }
      return amount > maxAmount ? `${maxAmount}+` : amount;
    }, [amount, maxAmount]);

    if (hidden) {
      return null;
    }

    return (
      <StyledNavbarNotificationIcon
        ref={ref}
        underscore={underscore}
        onClick={onClick}
        {...restProps}
      >
        <StyledSVGIcon name="notification" />
        <CountIndicator hidden={!amount}>{amountComponent}</CountIndicator>
      </StyledNavbarNotificationIcon>
    );
  },
);

NavbarNotificationIcon.displayName = 'NavbarNotificationIcon';
