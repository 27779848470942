import { VIEWPORT_PADDING } from './constants';
import { keyframes } from './stitches.config';

export const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export const pulse = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: '100%' },
});

export const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

export const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const dialogOverlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

export const dialogContentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const previewContentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.96)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

export const drawerOverlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 0.35 },
});

export const toastHide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

export const toastSlideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

export const toastSwipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

export const slideDown = keyframes({
  from: {
    height: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  to: {
    height: 'var(--radix-accordion-content-height)',
    paddingTop: 20,
    paddingBottom: 20,
  },
});

export const slideUp = keyframes({
  from: {
    height: 'var(--radix-accordion-content-height)',
    paddingTop: 20,
    paddingBottom: 20,
  },
  to: {
    height: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
});
