import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StyledContainer } from './_Base';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export const defaultContainerProps = {
  maxWidth: 'lg' as Breakpoint,
};

export interface ContainerProps extends BasePropsWithChildren {
  maxWidth?: Breakpoint;
  disableMargin?: boolean;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
  const {
    maxWidth = defaultContainerProps.maxWidth,
    disableMargin,
    children,
    ...restProps
  } = props;

  return (
    <StyledContainer ref={ref} maxWidth={maxWidth} disableMargin={disableMargin} {...restProps}>
      {children}
    </StyledContainer>
  );
});

Container.displayName = 'Container';
