import { type VariationItemLinkedToClaim } from '@paid-ui/types';
import { forwardRef, useMemo } from 'react';

import { Flex } from '../flex';
import { Grid } from '../grid';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Space } from '../space';
import {
  ArrowIcon,
  CheckboxIndicator,
  CheckboxLabel,
  CheckboxRoot,
  ComplexContainer,
  VariationContainer,
  VariationGrid,
} from './_Base';

export const defaultCheckboxProps = {
  showCheckbox: true,
  shape: 'round' as CheckboxShape,
  mode: 'simple' as CheckboxMode,
  labelWeight: 'normal' as FontWeight,
};

export type CheckboxSize = 'small' | 'medium';
export type CheckboxShape = 'round' | 'square';
export type CheckboxMode = 'simple' | 'complex';
export type CheckboxType = 'variation';

export interface CheckboxProps extends BaseProps {
  name: string;
  value?: boolean;
  mode?: CheckboxMode;
  shape?: CheckboxShape;
  defaultValue?: boolean;
  label?: React.ReactNode;
  labelSize?: FontSize;
  labelWeight?: FontWeight;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  size?: CheckboxSize;
  type?: CheckboxType;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  showCheckbox?: boolean;
  checkboxStyle?: React.CSSProperties;
  onChange?: (value: boolean) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  variationItem?: VariationItemLinkedToClaim;
  onClickVariation?: () => void;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => {
  const {
    name,
    mode = defaultCheckboxProps.mode,
    type,
    value,
    shape = defaultCheckboxProps.shape,
    label,
    labelSize,
    required,
    disabled,
    labelWeight = defaultCheckboxProps.labelWeight,
    defaultValue,
    size,
    readOnly,
    showCheckbox = defaultCheckboxProps.showCheckbox,
    checkboxStyle,
    variationItem,
    labelStyle,
    onChange,
    onBlur,
    onClickVariation,
    css,
    ...restProps
  } = props;

  const CheckboxComponent = useMemo(
    () => (
      <CheckboxRoot
        ref={ref}
        id={name}
        name={name}
        size={size}
        checked={value}
        readOnly={readOnly}
        defaultChecked={defaultValue}
        required={required}
        disabled={disabled}
        onCheckedChange={onChange}
        onBlur={onBlur}
        style={checkboxStyle}
        shape={shape}
      >
        <CheckboxIndicator />
      </CheckboxRoot>
    ),
    [
      checkboxStyle,
      defaultValue,
      disabled,
      name,
      onBlur,
      onChange,
      ref,
      required,
      shape,
      size,
      value,
      readOnly,
    ],
  );

  if (type === 'variation') {
    return (
      <VariationContainer
        htmlFor={name}
        selected={value}
        disabled={disabled}
        readOnly={readOnly}
        css={css}
        {...restProps}
      >
        <VariationGrid showCheckbox={showCheckbox}>
          {showCheckbox ? CheckboxComponent : null}
          <Grid gap="1_5">
            <Flex align="center" justify="between">
              <CheckboxLabel as="div" style={labelStyle}>
                {variationItem?.label}
              </CheckboxLabel>
              <Flex
                align="center"
                justify={'end'}
                gap={2}
                css={{ width: 'auto' }}
                onClick={onClickVariation}
              >
                <CheckboxLabel css={{ cursor: 'pointer' }}>{variationItem?.value}</CheckboxLabel>
                <ArrowIcon name="next-2" />
              </Flex>
            </Flex>
            <CheckboxLabel size="small">{variationItem?.description || 'no details'}</CheckboxLabel>
          </Grid>
        </VariationGrid>
      </VariationContainer>
    );
  }

  if (mode === 'complex') {
    return (
      <ComplexContainer htmlFor={name} selected={value} css={css} {...restProps}>
        {label ? (
          <CheckboxLabel as="div" style={labelStyle}>
            {label}
          </CheckboxLabel>
        ) : null}
        {CheckboxComponent}
      </ComplexContainer>
    );
  }

  return (
    <Space align="center" css={{ position: 'relative', ...css }} {...restProps}>
      {CheckboxComponent}
      {label ? (
        <CheckboxLabel htmlFor={name} style={labelStyle}>
          {label}
        </CheckboxLabel>
      ) : null}
    </Space>
  );
});

Checkbox.displayName = 'Checkbox';
