export enum SubType {
  WITHHOLD = 'WITHHOLD',
  RELEASE = 'RELEASE',
  DISPENSE = 'DISPENSE',
  SECURE = 'SECURE',
  MANUAL_SECURE = 'MANUAL_SECURE',
  UNSECURE = 'UNSECURE',
  RETAIN = 'RETAIN',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  EARLY_RELEASE_FEE = 'EARLY_RELEASE_FEE',
  RELEASE_RETENTION = 'RELEASE_RETENTION',
}

export enum InternalPaymentMethod {
  AUTO_DISPENSE = 'AUTO_DISPENSE',
  EARLY_RELEASE_DISPENSE = 'EARLY_RELEASE_DISPENSE',
}
