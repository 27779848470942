import { type SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import { Button, type ButtonProps } from '../button';
import type { BasePropsWithChildren } from '../interfaces';
import {
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertSVGIcon,
  CustomAlertIcon,
  DialogClose,
  DialogCloseButton,
  DialogCloseIcon,
} from './_Base';

type TextAlign = 'left' | 'center' | 'right';

const preventDefault = (event: KeyboardEvent) => event.preventDefault();

export interface AlertDialogProps extends BasePropsWithChildren {
  /** Dialog trigger element */
  trigger?: React.ReactNode;
  /** Text align of title and description */
  align?: TextAlign;
  /** Title of alert dialog */
  title: React.ReactNode;
  /** Description of alert dialog */
  description?: React.ReactNode;
  /** Text of confirm button */
  buttonText?: string;
  /** Props of confirm button */
  buttonProps?: ButtonProps;
  /** Loading state of confirm button */
  confirmLoading?: boolean;
  /** If dialog nested in another dialog */
  nested?: boolean;
  /** The controlled open state of the dialog. Must be used in conjunction with onOpenChange. */
  open?: boolean;
  /** The open state of the dialog when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean;
  /** Show alert icon */
  showAlertIcon?: boolean;
  /** Show close icon for user to turn off alert dialog */
  showCloseIcon?: boolean;
  /** Custom icon for alert dialog */
  customIcon?: SVGIconName;
  customIconStyle?: React.CSSProperties;
  /** If dialog should close on escape down */
  closeOnEscapeKeyDown?: boolean;
  /** Event handler called when submit button is clicked */
  onOk?: () => void;
  /** Event handler called when user click close alert dialog */
  onClose?: React.MouseEventHandler<Element>;
  /** Event handler called when the open state of the dialog changes. */
  onOpenChange?: (open: boolean) => void;
  /** Event handler called when focus moves into the component after opening. */
  onOpenAutoFocus?: (event: Event) => void;
  /** Event handler called when focus moves to the trigger after closing. */
  onCloseAutoFocus?: (event: Event) => void;
  /** Event handler called when the escape key is down.  */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
}

export const AlertDialog = forwardRef<HTMLDivElement, AlertDialogProps>((props, ref) => {
  const {
    trigger,
    align = 'center',
    title,
    description,
    buttonText = 'Done',
    buttonProps,
    confirmLoading,
    open,
    defaultOpen,
    nested = true,
    showAlertIcon = true,
    showCloseIcon = true,
    customIcon,
    closeOnEscapeKeyDown = true,
    children,
    customIconStyle,
    onOk,
    onClose,
    onOpenChange,
    onOpenAutoFocus,
    onCloseAutoFocus,
    onEscapeKeyDown,
    ...restProps
  } = props;

  return (
    <AlertDialogRoot open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay nested={nested} />
        <AlertDialogContent
          ref={ref}
          align={align}
          nested={nested}
          onOpenAutoFocus={onOpenAutoFocus}
          onCloseAutoFocus={onCloseAutoFocus}
          onEscapeKeyDown={closeOnEscapeKeyDown ? onEscapeKeyDown : preventDefault}
          {...restProps}
        >
          <DialogClose hidden={!showCloseIcon} asChild>
            <DialogCloseButton type="button" onClick={onClose}>
              <DialogCloseIcon name="close" />
            </DialogCloseButton>
          </DialogClose>
          {customIcon ? (
            <CustomAlertIcon name={customIcon} hidden={!showAlertIcon} style={customIconStyle} />
          ) : (
            <AlertSVGIcon name="success" hidden={!showAlertIcon} />
          )}
          <AlertDialogTitle as="div">{title}</AlertDialogTitle>
          <AlertDialogDescription
            as="div"
            dangerouslySetInnerHTML={
              typeof description === 'string'
                ? {
                    __html: description.replaceAll(
                      /\*{2}(\S[^*]*\S)\*{2}/gm,
                      `<span style="font-weight:500">$1</span>`,
                    ),
                  }
                : undefined
            }
          >
            {typeof description === 'string' ? null : description}
          </AlertDialogDescription>
          {children}
          <AlertDialogAction asChild>
            <Button
              height={44}
              size="large"
              color="primary"
              onClick={onOk}
              loading={confirmLoading}
              disabled={confirmLoading}
              {...buttonProps}
            >
              {confirmLoading ? 'Submitting...' : buttonText}
            </Button>
          </AlertDialogAction>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialogRoot>
  );
});

AlertDialog.displayName = 'AlertDialog';
