import { useEffect } from 'react';

/**
 * Updates the height of a <textarea> when the value changes.
 *
 * @param autosize - Auto size or not
 * @param textAreaRef - Ref to textarea element
 * @param value - Value of textarea
 */
function useAutosize(autosize: boolean, textAreaRef: HTMLTextAreaElement | null, value: string) {
  useEffect(() => {
    if (!autosize || !textAreaRef) {
      return;
    }
    if (textAreaRef && autosize) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px';

      const { scrollHeight } = textAreaRef;
      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosize, value]);
}

export default useAutosize;
