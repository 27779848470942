import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { StyledNavbarMenuIcon, StyledSVGIcon } from './_Base';

interface NavbarMenuIconProps extends BaseProps {
  onClick?: () => void;
}

export const NavbarMenuIcon = forwardRef<HTMLDivElement, NavbarMenuIconProps>((props, ref) => {
  const { onClick, ...restProps } = props;

  return (
    <StyledNavbarMenuIcon ref={ref} onClick={onClick} {...restProps}>
      <StyledSVGIcon name="menu" />
    </StyledNavbarMenuIcon>
  );
});

NavbarMenuIcon.displayName = 'NavbarMenuIcon';
