import { styled } from '../design-tokens';

export const StyledHelperText = styled('div', {
  'margin': 0,
  'textAlign': 'left',
  'lineHeight': 1.375,

  '&:empty': {
    display: 'none',
  },

  'variants': {
    size: {
      small: {
        fontSize: '12px',
      },
      medium: {
        fontSize: '14px',
      },
      large: {
        fontSize: '16px',
      },
    },

    error: {
      true: {
        color: '$pink',
      },
    },

    color: {
      default: {
        color: '$fgSecondary',
      },
      primary: {
        color: '$blue',
      },
      success: {
        color: '$green',
      },
      danger: {
        color: '$pink',
      },
      grey: {
        color: '$darkGrey',
      },
    },
  },

  'defaultVariants': {
    error: false,
    color: 'default',
    size: 'medium',
  },
});
