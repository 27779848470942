import type { SVGIconName } from '@paid-ui/icons';
import { forwardRef, useCallback, useMemo } from 'react';

import { type CSS } from '../design-tokens';
import { FormControl, LeadingIconButton, StyledSVGIcon, TrailingIconButton } from '../form';
import { HelperText } from '../help-text';
import type { InputProps } from '../input';
import { Label } from '../label';
import { Space } from '../space';
import { StyledInput, TrailingUnit } from './_Base';

export type AlignType = 'left' | 'center' | 'right';
export type InputNumberType = 'number' | 'percentage' | 'currency';

export interface InputNumberProps
  extends Omit<
    InputProps,
    | 'min'
    | 'max'
    | 'value'
    | 'step'
    | 'inputMode'
    | 'leadingIcon'
    | 'trailingIcon'
    | 'onChange'
    | 'onBlur'
    | 'onKeyUp'
  > {
  precision?: number;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  width?: number;
  height?: number;
  align?: AlignType;
  mode?: InputNumberType;
  leadingText?: string;
  trailingText?: string;
  leadingIcon?: SVGIconName;
  trailingIcon?: SVGIconName;
  inputStyle?: CSS;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: (value?: number) => void;
}

export const defaultInputNumberProps = {
  type: 'text' as React.HTMLInputTypeAttribute,
  mode: 'number' as InputNumberType,
  precision: 0,
  paddingLeft: 40,
  paddingRight: 40,
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  leadingText: '',
  trailingText: '',
  align: 'left' as AlignType,
};

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const {
    min = defaultInputNumberProps.min,
    max = defaultInputNumberProps.max,
    type = defaultInputNumberProps.type,
    name,
    step,
    mode = defaultInputNumberProps.mode,
    size,
    align = defaultInputNumberProps.align,
    ghost,
    block,
    label,
    extra,
    width,
    value,
    height,
    hidden,
    simple,
    disabled,
    readOnly,
    required,
    labelSize,
    autoFocus,
    precision = defaultInputNumberProps.precision,
    helperText,
    inputStyle,
    labelWeight,
    leadingIcon,
    leadingText = defaultInputNumberProps.leadingText,
    placeholder,
    paddingLeft = defaultInputNumberProps.paddingLeft,
    paddingRight = defaultInputNumberProps.paddingRight,
    trailingIcon,
    trailingText = defaultInputNumberProps.trailingText,
    errorMessage,
    autoComplete = 'off',
    defaultValue,
    helperTextStyle,
    leadingBordered,
    trailingBordered,
    onBlur,
    onClick,
    onFocus,
    onChange,
    onKeyDown,
    onIconButtonClick,
    ...restProps
  } = props;

  const hasError = !!errorMessage;

  const leadingElement = useMemo(() => {
    if (leadingIcon) {
      return <StyledSVGIcon name={leadingIcon} />;
    }
    if (leadingText) {
      return <TrailingUnit>{leadingText}</TrailingUnit>;
    }
    return null;
  }, [leadingIcon, leadingText]);

  const trailingElement = useMemo(() => {
    if (trailingIcon) {
      return <StyledSVGIcon name={trailingIcon} />;
    }
    if (trailingText) {
      return <TrailingUnit>{trailingText}</TrailingUnit>;
    }
    return null;
  }, [trailingIcon, trailingText]);

  const formatValue = useCallback(
    (inputVal: number | undefined) => {
      if (!inputVal && inputVal !== 0) {
        return defaultValue ?? 0;
      }
      const num = String(inputVal).replaceAll(',', '');
      if (Number.isNaN(Number(num))) {
        return Number.parseFloat(num);
      }
      if (['currency', 'percentage'].includes(mode)) {
        const val = new Intl.NumberFormat('en-AU', {
          style: 'decimal',
          maximumFractionDigits: precision,
        }).format(num as unknown as number);
        if (precision) {
          const digits = String(num).split('.');
          if (digits?.[1] !== undefined) {
            const integerPart = String(val).split('.')[0];
            const decimalPart = digits[1]?.slice(0, precision);
            const finalVal = `${integerPart}.${decimalPart}`;
            return decimalPart.length === precision && Number(finalVal) === 0 ? 0 : finalVal;
          }
        }
        return val;
      }
      return precision ? inputVal : Number(inputVal);
    },
    [defaultValue, mode, precision],
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (!event.target.value && typeof onChange === 'function') {
        onChange('' as unknown as number);
      }
      let inputValue = event.target.value.replaceAll(',', '');
      if (!/^(\d+)(.\d{0,2})?$/.test(inputValue) || Number.isNaN(Number(inputValue))) {
        return;
      }
      if (
        (min && min > Number.parseFloat(inputValue)) ||
        (max && max < Number.parseFloat(inputValue))
      ) {
        return;
      }
      if (precision) {
        const digits = inputValue.split('.');
        if (digits?.[1] !== undefined) {
          const integerPart = Number.parseInt(inputValue, 10);
          const decimalPart = digits[1]?.slice(0, precision);
          inputValue = `${integerPart}.${decimalPart}`;
        }
      }
      if (typeof onChange === 'function') {
        onChange(inputValue as unknown as number);
      }
    },
    [onChange, min, max, precision],
  );

  const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      const {
        target: { value: inputValue },
      } = event;
      const val = ((inputValue.replaceAll(',', '') || defaultValue) ?? '0') as unknown as string;

      if (typeof onChange === 'function') {
        if (mode === 'currency') {
          const _value = val ? Number.parseFloat(val).toFixed(precision) : defaultValue;
          onChange(_value as unknown as number);
        } else if (val) {
          onChange(Number.parseFloat(val));
        }
      }
      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
    [defaultValue, onChange, onBlur, mode, precision],
  );

  if (hidden) {
    return null;
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label && !simple ? (
        <Label
          htmlFor={name}
          asterisk={required}
          extra={extra}
          size={labelSize}
          weight={labelWeight}
        >
          {label}
        </Label>
      ) : null}

      <FormControl block={block}>
        <LeadingIconButton type="button" tabIndex={-1} bordered={leadingBordered}>
          {leadingElement}
        </LeadingIconButton>
        <StyledInput
          ref={ref}
          id={name}
          name={name}
          type={type}
          inputMode={precision === 0 ? 'numeric' : 'decimal'}
          min={min}
          max={max}
          step={step}
          align={align}
          size={typeof size === 'string' ? size : undefined}
          placeholder={placeholder}
          value={formatValue(value) as unknown as number}
          block={block}
          ghost={ghost}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          invalid={hasError}
          onClick={onClick}
          onChange={handleChange}
          autoComplete={autoComplete ?? 'off'}
          aria-invalid={hasError ? 'true' : 'false'}
          aria-describedby={hasError ? `${name}-error` : `${name}-description`}
          onBlur={handleBlur}
          onFocus={onFocus}
          css={{
            ...inputStyle,
            fontSize: typeof size === 'number' ? size : undefined,
            width,
            height,
            paddingLeft: leadingElement ? paddingLeft : undefined,
            paddingRight: trailingElement ? paddingRight : undefined,
          }}
          onKeyDown={onKeyDown}
        />
        <TrailingIconButton type="button" tabIndex={-1} bordered={trailingBordered}>
          {trailingElement}
        </TrailingIconButton>
      </FormControl>

      {simple ? null : (
        <HelperText
          error={hasError}
          id={hasError ? `${name}-error` : `${name}-description`}
          css={helperTextStyle}
        >
          {hasError ? errorMessage : helperText}
        </HelperText>
      )}
    </Space>
  );
});

InputNumber.displayName = 'InputNumber';
