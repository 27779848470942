import { useDebounceEffect } from 'ahooks';
import { useFormikContext } from 'formik';
import { type Dispatch, useRef } from 'react';

export interface AutoSaverProps {
  dispatch?: Dispatch<any>;
}

export const AutoSaver: React.FC<AutoSaverProps> = (props) => {
  const { dispatch } = props;
  const isMountedRef = useRef(false);
  const { values } = useFormikContext();

  useDebounceEffect(
    () => {
      if (!isMountedRef.current) {
        isMountedRef.current = true;
        return;
      }

      if (typeof dispatch === 'function') {
        dispatch({
          type: 'toggleSkipSaveDraft',
          payload: false,
        });
      }
    },
    [JSON.stringify(values)],
    {
      wait: 500,
    },
  );

  return null;
};
