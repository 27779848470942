import type { SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { StyledSVGIcon, StyledTag } from './_Base';

export type TagVariant = 'solid' | 'outline';
export type TagColor = 'default' | 'primary' | 'success' | 'danger';

export const defaultTagProps = {
  variant: 'solid' as TagVariant,
  color: 'default' as TagColor,
};

export interface TagProps extends BaseProps {
  variant?: TagVariant;
  color?: TagColor;
  icon?: SVGIconName;
  hidden?: boolean;
  children?: number | string | null;
}

export const Tag = forwardRef<HTMLSpanElement, TagProps>((props, ref) => {
  const {
    variant = defaultTagProps.variant,
    color = defaultTagProps.color,
    icon,
    hidden,
    children,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <StyledTag variant={variant} color={color} ref={ref} {...restProps}>
      {icon ? <StyledSVGIcon name={icon} /> : null}
      {children}
    </StyledTag>
  );
});

Tag.displayName = 'Tag';
