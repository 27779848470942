import type { PaymentStage } from '@paid-ui/types';
import { formatCurrency, getStageName } from '@paid-ui/utils';
import { forwardRef, useCallback, useMemo, useState } from 'react';

import type { BaseProps } from '../interfaces';
import { Separator } from '../separator';
import {
  RadioGroupRoot,
  RadioItem,
  RadioItemIndicator,
  RadioItemLabel,
  RadioItemRow,
  RadioLabelCell,
  ShowMoreButton,
} from './_Base';

export const defaultPaymentSelectProps = {
  payments: [] as PaymentStage[],
};

export interface PaymentSelectProps extends BaseProps {
  name?: string;
  value: string;
  defaultValue?: string;
  payments?: PaymentStage[];
  disableShowMore?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange: (value: string) => void;
}

export const PaymentSelect = forwardRef<HTMLDivElement, PaymentSelectProps>((props, ref) => {
  const {
    name,
    value,
    defaultValue,
    payments = defaultPaymentSelectProps.payments,
    disableShowMore,
    disabled,
    hidden,
    onChange,
    ...restProps
  } = props;
  const [showMore, setShowMore] = useState(false);

  const filteredPayments = useMemo(() => {
    return showMore || disableShowMore
      ? payments
      : payments.filter((payment) => payment.claim === undefined && !payment.disabled);
  }, [disableShowMore, payments, showMore]);

  const handleShowMoreToggle = useCallback(() => {
    setShowMore((prevState) => !prevState);
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <RadioGroupRoot
      ref={ref}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onValueChange={onChange}
      empty={filteredPayments.length === 0}
      {...restProps}
    >
      {filteredPayments.length === 0 ? (
        <Separator orientation="horizontal" decorative />
      ) : (
        filteredPayments.map((payment, index) => (
          <RadioItemRow key={payment.id} selected={payment.id === value}>
            <RadioItem
              id={`${name}-${index}`}
              value={payment.id ?? ''}
              disabled={disabled || payment.claim !== undefined || payment.disabled}
            >
              <RadioItemIndicator />
            </RadioItem>
            <RadioItemLabel
              htmlFor={`${name}-${index}`}
              disabled={disabled || payment.claim !== undefined || payment.disabled}
            >
              <RadioLabelCell bold={payment.id === value}>{getStageName(payment)}</RadioLabelCell>
              <RadioLabelCell
                bold
                align="right"
              >{`${payment.percentOfContractSum}%`}</RadioLabelCell>
              <RadioLabelCell bold align="right">
                {formatCurrency(payment.amount ?? 0)}
              </RadioLabelCell>
            </RadioItemLabel>
          </RadioItemRow>
        ))
      )}
      {disableShowMore ? null : (
        <ShowMoreButton
          type="button"
          hidden={!showMore && filteredPayments.length === payments.length}
          onClick={handleShowMoreToggle}
        >
          {showMore ? 'View less' : 'Other claims'}
        </ShowMoreButton>
      )}
    </RadioGroupRoot>
  );
});

PaymentSelect.displayName = 'PaymentSelect';
