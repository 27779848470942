import type { PaymentClaimApproved } from '@paid-ui/types';
import { formatAddress, formatCurrency, getStageName } from '@paid-ui/utils';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import {
  RadioGroupRoot,
  RadioItem,
  RadioItemHeldLabel,
  RadioItemIndicator,
  RadioItemLastRow,
  RadioItemRow,
  RadioLabelAddress,
  RadioLabelCell,
} from './_Base';

const defaultPaymentClaimSelectProps = {
  claims: [] as PaymentClaimApproved[],
};

export interface PaymentClaimSelectProps extends BaseProps {
  name?: string;
  value?: string;
  defaultValue?: string;
  claims?: PaymentClaimApproved[];
  showLastRow?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onChange: (value: string) => void;
}

export const PaymentClaimSelect = forwardRef<HTMLDivElement, PaymentClaimSelectProps>(
  (props, ref) => {
    const {
      name,
      value,
      defaultValue,
      claims = defaultPaymentClaimSelectProps.claims,
      showLastRow,
      disabled,
      hidden,
      onChange,
      ...restProps
    } = props;

    if (hidden) {
      return null;
    }

    return (
      <RadioGroupRoot
        ref={ref}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onValueChange={onChange}
        empty={claims.length === 0}
        {...restProps}
      >
        {claims.map((claim, index) => (
          <RadioItemRow key={claim.id} align="start" noPadding selected={claim.id === value}>
            <RadioItem
              id={`${name}-${index}`}
              value={claim.id ?? ''}
              disabled={disabled || claim.disabled}
            >
              <RadioItemIndicator />
            </RadioItem>
            <RadioItemHeldLabel htmlFor={`${name}-${index}`} disabled={disabled || claim.disabled}>
              <RadioLabelCell bold={claim.id === value}>
                {getStageName(claim.progressPayment)}
              </RadioLabelCell>
              <RadioLabelCell bold align="right">
                {formatCurrency(claim?.progressPayment?.amount ?? 0)}
              </RadioLabelCell>
              <RadioLabelAddress>
                {[formatAddress(claim.contractAddress, true), claim.progressPayment?.otherPartyName]
                  .filter(Boolean)
                  .join(' · ')}
              </RadioLabelAddress>
            </RadioItemHeldLabel>
          </RadioItemRow>
        ))}
        {showLastRow ? (
          <RadioItemLastRow key="no-claim" align="start" selected={value === ''}>
            <RadioItem id={`${name}-${claims.length}`} value="" disabled={disabled}>
              <RadioItemIndicator />
            </RadioItem>
            <RadioItemHeldLabel htmlFor={`${name}-${claims.length}`} lastRow disabled={disabled}>
              <RadioLabelCell bold={value === ''}>
                Held funds do not relate to any progress payment.
              </RadioLabelCell>
            </RadioItemHeldLabel>
          </RadioItemLastRow>
        ) : null}
      </RadioGroupRoot>
    );
  },
);

PaymentClaimSelect.displayName = 'PaymentClaimSelect';
