import {
  ContractType,
  contractTypes,
  RequiringActionFeatureType,
  requiringActionLabelMap,
  RequiringActionType,
} from '@paid-ui/constants';
import type { RequiringAction as IRequiringAction, RequiringActionName } from '@paid-ui/types';
import { utc2Mel } from '@paid-ui/utils/datetime';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { Skeleton } from '../skeleton';
import {
  ActionContainer,
  ActionContent,
  ActionExtraContent,
  ActionIcon,
  ActionParagraph,
  ActionTimestamp,
  ActionTitle,
  DismissButton,
  RequiringActionMenuItem,
  StyledActionButton,
} from './_Base';

export interface RequiringActionProps extends BaseProps {
  data: IRequiringAction;
  disabled?: boolean;
  onClick?: (data: IRequiringAction) => void;
  onDismiss?: (data: IRequiringAction) => void;
}

export const RequiringActionSkeleton = forwardRef<HTMLButtonElement, BaseProps>((props, ref) => {
  return (
    <StyledActionButton skeleton ref={ref} {...props}>
      <Skeleton width={24} height={24} />
      <ActionContainer>
        <ActionContent>
          <ActionTitle>
            <Skeleton width={180} height={18} />
          </ActionTitle>
          <ActionTimestamp>
            <Skeleton width={70} height={14} />
          </ActionTimestamp>
        </ActionContent>
        <ActionParagraph>
          <Skeleton width={250} height={14} />
        </ActionParagraph>
      </ActionContainer>
    </StyledActionButton>
  );
});

RequiringActionSkeleton.displayName = 'RequiringActionSkeleton';

export const RequiringAction = forwardRef<HTMLDivElement, RequiringActionProps>((props, ref) => {
  const { data: requiringAction, disabled, onClick, onDismiss, ...restProps } = props;

  const handleClick = useCallback(() => {
    if (disabled) return;
    if (typeof onClick === 'function') {
      onClick(requiringAction);
    }
  }, [requiringAction, disabled, onClick]);

  const handleDismiss = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();
      onDismiss?.(requiringAction);
    },
    [requiringAction, onDismiss],
  );

  const title = useMemo(() => {
    const { action, featureItemType } = requiringAction;
    const actionName = `${action}_${featureItemType}` as RequiringActionName;
    return requiringActionLabelMap[actionName] ?? 'Unknown';
  }, [requiringAction]);

  const description = useMemo(() => {
    const { action, featureItemType, data, otherParties } = requiringAction;
    const str = `${contractTypes[data?.contractType ?? ContractType.HEAD_CONTRACT]}`;
    const fullStr = otherParties ? `${str} · ${otherParties}` : str;

    if (
      action === RequiringActionType.PAY ||
      (action === RequiringActionType.REVIEW &&
        featureItemType === RequiringActionFeatureType.EARLY_RELEASE_REQUEST)
    ) {
      const dueDate =
        data?.dueDate && dayjs(data?.dueDate).isValid()
          ? utc2Mel(data.dueDate).format('DD MMM YYYY')
          : '';
      if (dueDate) {
        return `${fullStr} · due ${dueDate}`;
      }
    }

    return fullStr;
  }, [requiringAction]);

  return (
    <RequiringActionMenuItem
      ref={ref}
      onClick={handleClick}
      data-cy="requiring-action"
      {...restProps}
    >
      <ActionIcon name="next" />
      <ActionContainer>
        <ActionContent>
          <ActionTitle>{title}</ActionTitle>
          <ActionParagraph>{description}</ActionParagraph>
        </ActionContent>
        <ActionExtraContent>
          <ActionTimestamp>
            {dayjs(requiringAction.createdTimestamp).tz().fromNow()}
          </ActionTimestamp>
          {requiringAction.data?.dismissible ? (
            <DismissButton type="button" disabled={disabled} onClick={handleDismiss}>
              Dismiss
            </DismissButton>
          ) : null}
        </ActionExtraContent>
      </ActionContainer>
    </RequiringActionMenuItem>
  );
});

RequiringAction.displayName = 'RequiringAction';
