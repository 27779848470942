import { slate } from '@radix-ui/colors';

import { pulse, styled } from '../design-tokens';

export const StyledSkeleton = styled('div', {
  'backgroundColor': slate.slate4,
  'position': 'relative',
  'overflow': 'hidden',
  'borderRadius': '2px',

  '&::after': {
    position: 'absolute',
    inset: 0,
    borderRadius: 'inherit',
    backgroundColor: slate.slate6,
    content: '""',
    animationName: `${pulse}`,
    animationDuration: '500ms',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },

  'variants': {
    variant: {
      square: {
        borderRadius: 0,
      },

      rounded: {
        borderRadius: '4px',
      },

      circle: {
        borderRadius: '50%',
      },
    },

    size: {
      xSmall: {
        size: '24px',
      },

      small: {
        size: '32px',
      },

      medium: {
        size: '48px',
      },

      large: {
        size: '64px',
      },

      xlarge: {
        size: '72px',
      },
    },
  },
});
