export enum ClaimStatus {
  'SUBMITTED' = 'SUBMITTED',
  'REJECTED' = 'REJECTED',
  'RESUBMITTED' = 'RESUBMITTED',
  'APPROVED' = 'APPROVED',
  'PAID' = 'PAID',
  'PROVISIONAL' = 'PROVISIONAL',
}

export enum ClaimSource {
  ON_PLATFORM = 'ON_PLATFORM',
  OFF_PLATFORM = 'OFF_PLATFORM',
}

export enum ClaimMethod {
  STAGED = 'STAGED',
  PERIODIC = 'PERIODIC',
}

export enum PropertyUsage {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
}

export enum DerivedClaimStatus {
  NOT_YET_APPROVED = 'not-yet-approved',
  PART_SECURED = 'part-secured',
  SECURED = 'secured',
  NOT_YET_PAID = 'not-yet-paid',
  PART_PAID = 'part-paid',
  PAID = 'paid',
  OVERDUE = 'overdue',
}

export enum ReviewClaimAction {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum ClaimActionType {
  CREATE = 'CREATE',
  AMEND = 'AMEND',
  REVIEW = 'REVIEW',
  EDIT = 'EDIT',
}
