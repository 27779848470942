import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { SVGIcon } from '@paid-ui/icons';
import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { styled } from '../design-tokens';
import { HelperText } from '../help-text';
import type { BaseProps } from '../interfaces';

const LabelContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  userSelect: 'none',
});

const InputContainer = styled('div', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  position: 'relative',
  borderRadius: '4px',

  variants: {
    size: {
      small: {
        height: '42px',
      },
      large: {
        height: '56px',
      },
    },
  },
});

const DropIcon = styled(SVGIcon, {
  size: '18px',
  color: '$fgSecondary',
});

const NotFound = styled('div', {
  display: 'block',
  position: 'relative',
  color: '$fgSecondary',
  listStyle: 'none',
  cursor: 'default',
  padding: '8px 36px 8px 12px',
});

export type ComboboxOption<T = string> =
  | T
  | {
      label: string;
      value: T;
      disabled?: boolean;
    };

export interface ComboboxProps<T = string> extends Omit<BaseProps, 'css'> {
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: 'none' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
  label?: React.ReactNode;
  extra?: React.ReactNode;
  placeholder?: string;
  value?: T;
  defaultValue?: T;
  options?: ComboboxOption<T>[];
  renderOption?: (option: ComboboxOption<T>) => React.ReactNode;
  renderCustomOption?: (query: string) => React.ReactNode;
  searchMode?: 'includes' | 'startsWith';
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  notFoundText?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  userInput?: boolean;
  size?: 'small' | 'large';
  maxLength?: number;
  maxHeight?: string | number;
  hidden?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onValueChange?: (value: T) => void;
  onPressEnter?: () => void;
}

const Combobox = forwardRef(
  <T extends string | number>(props: ComboboxProps<T>, ref: React.Ref<HTMLInputElement>) => {
    const {
      name,
      type,
      inputMode,
      label,
      extra,
      placeholder,
      value,
      defaultValue,
      options = [],
      renderOption,
      renderCustomOption,
      searchMode = 'includes',
      helperText,
      errorMessage,
      notFoundText = 'Nothing found.',
      autoFocus,
      disabled,
      readOnly,
      required,
      userInput,
      size = 'large',
      maxLength,
      maxHeight = 236,
      hidden,
      onBlur,
      onChange,
      onFocus,
      onValueChange,
      onPressEnter,
      id,
      className,
      style,
    } = props;
    const hasError = !!errorMessage;
    const [query, setQuery] = useState('');

    const getOptionValue = useCallback((option: ComboboxOption<T>) => {
      if (typeof option === 'string' || typeof option === 'number') {
        return option;
      }

      return option.value;
    }, []);

    const getOptionLabel = useCallback(
      (option: ComboboxOption<T>) => {
        if (typeof renderOption === 'function') {
          return renderOption(option);
        }

        if (typeof option === 'string' || typeof option === 'number') {
          return option;
        }

        return option.label;
      },
      [renderOption],
    );

    const getOptionDisabled = useCallback((option: ComboboxOption<T>) => {
      if (typeof option === 'string' || typeof option === 'number') {
        return;
      }

      return option.disabled;
    }, []);

    const filteredOptions =
      query === ''
        ? options
        : options.filter((option) => {
            if (typeof option === 'string' || typeof option === 'number') {
              return option.toString().toLowerCase()[searchMode](query.toLowerCase());
            }
            return option.label.toLowerCase()[searchMode](query.toLowerCase());
          });

    const handleQueryChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        const value = event.target.value?.trim();
        setQuery(value);
        if (typeof onChange === 'function') {
          onChange(event);
        }
      },
      [onChange],
    );

    const formatDisplayValue = useCallback(
      (v?: T) => {
        if (v === undefined) {
          return '';
        }

        const filteredOption = options.find((option) => {
          return typeof option === 'string' || typeof option === 'number'
            ? option === v
            : option.value === v;
        });

        if (filteredOption) {
          return typeof filteredOption === 'string' || typeof filteredOption === 'number'
            ? filteredOption.toString()
            : filteredOption.label.toString();
        }

        return v.toString();
      },
      [options],
    );

    const handleOptionSelect = useCallback(
      (v: T) => {
        if (typeof onValueChange === 'function') {
          onValueChange(type === 'number' ? (Number(v) as T) : v);
        }
      },
      [onValueChange, type],
    );

    const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
      (event) => {
        if (event.key === 'Enter' && typeof onPressEnter === 'function') {
          onPressEnter();
        }
      },
      [onPressEnter],
    );

    const userInputOptions = useMemo(() => {
      const isQueryInOptions = filteredOptions.some((option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return option.toString().toLowerCase() === query.toLowerCase();
        }
        return option.value.toString().toLowerCase() === query.toLowerCase();
      });

      return (
        <>
          {query && !isQueryInOptions ? (
            <HeadlessCombobox.Option
              key="custom-option"
              value={query}
              className={(_option) =>
                clsx('combobox-option', {
                  active: _option.active,
                  selected: _option.selected,
                })
              }
            >
              {typeof renderCustomOption === 'function'
                ? renderCustomOption(query)
                : `Enter "${query}"`}
            </HeadlessCombobox.Option>
          ) : null}
          {filteredOptions.map((option) => (
            <HeadlessCombobox.Option
              key={getOptionValue(option)}
              value={getOptionValue(option)}
              disabled={getOptionDisabled(option)}
              className={(_option) =>
                clsx('combobox-option', {
                  active: _option.active,
                  disabled: _option.disabled,
                  selected: _option.selected,
                })
              }
            >
              {getOptionLabel(option)}
            </HeadlessCombobox.Option>
          ))}
        </>
      );
    }, [
      filteredOptions,
      getOptionDisabled,
      getOptionLabel,
      getOptionValue,
      query,
      renderCustomOption,
    ]);

    useEffect(() => {
      setQuery(formatDisplayValue(value));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (hidden) {
      return null;
    }

    return (
      <HeadlessCombobox
        id={id}
        as="div"
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleOptionSelect}
        onFocus={onFocus}
        onBlur={onBlur}
        className={clsx('combobox', className)}
        style={style}
      >
        {label ? (
          <LabelContainer>
            <HeadlessCombobox.Label
              htmlFor={name}
              className={clsx('combobox-label', {
                required,
              })}
            >
              {label}
            </HeadlessCombobox.Label>
            {typeof extra === 'string' ? <span>{extra}</span> : extra}
          </LabelContainer>
        ) : null}
        <HeadlessCombobox.Button as={InputContainer} size={size}>
          <HeadlessCombobox.Input
            name={name}
            type={type}
            displayValue={formatDisplayValue}
            inputMode={inputMode}
            placeholder={placeholder}
            autoFocus={autoFocus}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            maxLength={maxLength}
            autoCapitalize="none"
            autoCorrect="off"
            autoComplete="off"
            aria-invalid={hasError ? 'true' : 'false'}
            aria-describedby={hasError ? `${name}-error` : `${name}-description`}
            onChange={handleQueryChange}
            onKeyDown={handleKeyDown}
            className={clsx('combobox-input', size)}
          />
          {readOnly || disabled ? null : (
            <HeadlessCombobox.Button className="combobox-button">
              <DropIcon name="drop" />
            </HeadlessCombobox.Button>
          )}
          <HeadlessCombobox.Options
            className="combobox-options"
            style={{
              maxHeight,
            }}
          >
            {userInput ? (
              userInputOptions
            ) : filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <HeadlessCombobox.Option
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  disabled={getOptionDisabled(option)}
                  className={(_option) =>
                    clsx('combobox-option', {
                      active: _option.active,
                      disabled: _option.disabled,
                      selected: _option.selected,
                    })
                  }
                >
                  {getOptionLabel(option)}
                </HeadlessCombobox.Option>
              ))
            ) : (
              <NotFound>{notFoundText}</NotFound>
            )}
          </HeadlessCombobox.Options>
        </HeadlessCombobox.Button>
        <HelperText id={hasError ? `${name}-error` : `${name}-description`} error={hasError}>
          {hasError ? errorMessage : helperText}
        </HelperText>
      </HeadlessCombobox>
    );
  },
);

Combobox.displayName = 'Combobox';

export default Combobox as <T extends string | number>(
  props: ComboboxProps<T> & { ref?: React.Ref<HTMLInputElement> },
) => JSX.Element;
