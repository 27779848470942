import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
  styled,
} from '../design-tokens';

export const TooltipRoot = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipPortal = TooltipPrimitive.Portal;
export const TooltipProvider = TooltipPrimitive.Provider;

export const TooltipContent = styled(TooltipPrimitive.Content, {
  'maxWidth': '300px',
  'padding': '6px 14px',
  'borderRadius': '$lg',
  'animationDirection': '400ms',
  'animationTimingFunction': 'cubic-bezier(0.16, 1, 0.3, 1)',
  'backgroundColor': '$fg',
  'boxShadow': '$md',
  'color': '$bg',
  'fontSize': '16px',
  'lineHeight': '22px',
  'transformOrigin': 'var(--radix-tooltip-content-transform-origin)',
  'willChange': 'opacity, transform',
  'zIndex': '$tooltip',

  '&:[data-state="delayed-open"][data-side="top"]': {
    animationName: slideDownAndFade,
  },

  '&:[data-state="delayed-open"][data-side="right"]': {
    animationName: slideLeftAndFade,
  },

  '&:[data-state="delayed-open"][data-side="bottom"]': {
    animationName: slideUpAndFade,
  },

  '&:[data-state="delayed-open"][data-side="left"]': {
    animationName: slideRightAndFade,
  },

  '@motion': {
    animation: 'none',
    willChange: 'unset',
  },

  'variants': {
    multiline: {
      true: {
        padding: '14px',
      },
    },
  },
});

export const TooltipArrow = styled(TooltipPrimitive.Arrow, {
  fill: '$fg',
});
