import type { NotificationItem } from '@paid-ui/types';
import { nowMel, utc2Mel } from '@paid-ui/utils/datetime';
import { forwardRef, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { Notification, NotificationSkeleton } from '../notification';
import { Skeleton } from '../skeleton';
import { Space } from '../space';
import { EmptyText, NotificationsLabel } from './_Base';

export const defaultNotificationsProps = {
  emptyText: 'No requiring actions',
  itemType: 'button' as 'button' | 'menu-item',
};

export interface NotificationsProps extends BaseProps {
  data: NotificationItem[];
  loading?: boolean;
  groupByDate?: boolean;
  emptyText?: string;
  itemType?: 'button' | 'menu-item';
  onClick?: (data: NotificationItem) => void;
}

export const Notifications = forwardRef<HTMLDivElement, NotificationsProps>((props, ref) => {
  const {
    data,
    loading,
    groupByDate,
    emptyText = defaultNotificationsProps.emptyText,
    itemType = defaultNotificationsProps.itemType,
    onClick,
    ...restProps
  } = props;

  const [todayData, yesterdayData, olderData] = useMemo(() => {
    const today = nowMel().startOf('day');
    const yesterday = nowMel().subtract(1, 'day').startOf('day');
    const _todayData: NotificationItem[] = [];
    const _yesterdayData: NotificationItem[] = [];
    const _olderData: NotificationItem[] = [];

    data.forEach((item) => {
      if (utc2Mel(item.createdAt).isBefore(yesterday)) {
        _olderData.push(item);
      } else if (utc2Mel(item.createdAt).isBefore(today)) {
        _yesterdayData.push(item);
      } else {
        _todayData.push(item);
      }
    });

    return [_todayData, _yesterdayData, _olderData];
  }, [data]);

  if (loading) {
    return groupByDate ? (
      <Space ref={ref} direction="vertical" inline={false} {...restProps}>
        <NotificationsLabel>
          <Skeleton width={60} height={22} />
        </NotificationsLabel>
        {Array.from({ length: 2 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
        <NotificationsLabel>
          <Skeleton width={100} height={22} />
        </NotificationsLabel>
        {Array.from({ length: 2 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
        <NotificationsLabel>
          <Skeleton width={60} height={22} />
        </NotificationsLabel>
        {Array.from({ length: 4 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
      </Space>
    ) : (
      <Space size={10} direction="vertical" inline={false} {...restProps}>
        {Array.from({ length: 8 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
      </Space>
    );
  }

  if (data.length === 0) {
    return <EmptyText>{emptyText}</EmptyText>;
  }

  if (groupByDate) {
    return (
      <Space ref={ref} direction="vertical" inline={false} {...restProps}>
        <NotificationsLabel hidden={todayData.length === 0}>Today</NotificationsLabel>
        <Space size={10} direction="vertical" inline={false}>
          {todayData.map((item) => (
            <Notification key={item.messageId} data={item} itemType={itemType} onClick={onClick} />
          ))}
        </Space>
        <NotificationsLabel hidden={yesterdayData.length === 0}>Yesterday</NotificationsLabel>
        <Space size={10} direction="vertical" inline={false}>
          {yesterdayData.map((item) => (
            <Notification key={item.messageId} data={item} itemType={itemType} onClick={onClick} />
          ))}
        </Space>
        <NotificationsLabel hidden={olderData.length === 0}>Older</NotificationsLabel>
        <Space size={10} direction="vertical" inline={false}>
          {olderData.map((item) => (
            <Notification key={item.messageId} data={item} itemType={itemType} onClick={onClick} />
          ))}
        </Space>
      </Space>
    );
  }

  return (
    <Space size={10} direction="vertical" inline={false} {...restProps}>
      {data.map((item) => (
        <Notification key={item.messageId} data={item} itemType={itemType} onClick={onClick} />
      ))}
    </Space>
  );
});

Notifications.displayName = 'Notifications';
