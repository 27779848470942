import {
  plusOrMinus,
  PriceVariationMethod,
  VariationStage,
  type VariationType,
  variationTypeLabel,
} from '@paid-ui/constants';
import type {
  Adjustment,
  CostItem,
  PaymentStage,
  VariationItemLinkedToClaim,
} from '@paid-ui/types';
import sumBy from 'lodash/sumBy';

import { formatCurrency } from './formatter';

export type AdjustmentFilter = 'all' | 'dangling' | 'claimed';

/**
 * Format adjustment item description.
 *
 * @param value - Adjustment item.
 * @returns Formatted adjustment item description.
 */
export function formatAdjustmentDescription(value?: Adjustment | null): string {
  if (!value) {
    return '-';
  }

  const [firstAdjustment, ...restAdjustments] = value.items;

  if (!firstAdjustment?.description) {
    return '-';
  }

  switch (restAdjustments.length) {
    case 0: {
      return firstAdjustment.description;
    }
    case 1: {
      return `${firstAdjustment.description} + 1 other item`;
    }
    default: {
      return `${firstAdjustment.description} + ${restAdjustments.length} other items`;
    }
  }
}

/**
 *  Get total adjustment price
 *
 * @param items - Cost items
 * @returns Total adjustment price
 */
export function getTotalAdjustmentPrice(items: CostItem[] = []): number {
  return (
    Math.round(
      sumBy(
        items,
        (item) =>
          (item.netAdjustment ?? 0) *
          plusOrMinus[item.netAdjustmentImpact ?? PriceVariationMethod.NONE],
      ) * 100,
    ) / 100
  );
}

/**
 * Format adjustment item linked to claim
 *
 * @param adjustment - Original adjustment item
 * @param index - index
 * @returns Adjustment item
 */
export function formatAdjustmentItem(
  adjustment: Adjustment,
  index: number,
): VariationItemLinkedToClaim {
  const type = adjustment.type as VariationType;
  const valueAsNumber = getTotalAdjustmentPrice(adjustment.items);

  return {
    id: adjustment.id,
    disabled: !!adjustment?.claimId,
    label: adjustment.displayName || `${variationTypeLabel[type]} ${index + 1}`,
    value: formatCurrency(valueAsNumber),
    valueAsNumber,
    description: formatAdjustmentDescription(adjustment),
  };
}

/**
 * Get filtered adjustment items
 *
 * @param adjustments - All adjustment items
 * @param payment - Payment stage to filter
 * @param filter - Filter type, default to 'all'
 * @returns Filtered adjustment items
 */
export function getAdjustmentItems(
  adjustments: Adjustment[] = [],
  payment: PaymentStage | null = null,
  filter: AdjustmentFilter = 'all',
): VariationItemLinkedToClaim[] {
  const approvedAdjustments = adjustments.filter(
    (adjustment) => adjustment.adjustmentStage === VariationStage.APPROVED,
  );

  switch (filter) {
    case 'all': {
      return approvedAdjustments
        .filter((adjustment) => !adjustment.claimId || adjustment.claimId === payment?.claim?.id)
        .map(formatAdjustmentItem);
    }

    case 'dangling': {
      return approvedAdjustments
        .filter((adjustment) => !adjustment.claimId)
        .map(formatAdjustmentItem);
    }

    case 'claimed': {
      return approvedAdjustments
        .filter((adjustment) => adjustment.claimId && adjustment.claimId === payment?.claim?.id)
        .map(formatAdjustmentItem);
    }

    default: {
      return approvedAdjustments.map(formatAdjustmentItem);
    }
  }
}
