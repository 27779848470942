import { blue, slate } from '@radix-ui/colors';
import * as SelectPrimitive from '@radix-ui/react-select';

import { styled } from '../design-tokens';

export const SelectRoot = SelectPrimitive.Root;
export const SelectGroup = SelectPrimitive.Group;
export const SelectLabel = SelectPrimitive.Label;

export const SelectTrigger = styled(SelectPrimitive.Trigger, {
  'all': 'unset',
  'boxSizing': 'border-box',
  'position': 'relative',
  'width': '100%',
  'padding': '10px 12px',
  'borderWidth': 0,
  'height': '42px',
  'minHeight': '42px',
  'borderRadius': '4px',
  'fontFamily': 'inherit',
  'fontSize': '16px',
  'lineHeight': 1.35,
  'letterSpacing': '0.025em',
  'color': '$fg',
  'backgroundColor': '$bg',
  'boxShadow': `inset 0 0 0 1px ${slate.slate7}`,
  'cursor': 'default',
  'outline': 'none',

  '&[data-placeholder]': {
    color: slate.slate9,
    lineHeight: 1.35,
  },

  '@hover': {
    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${blue.blue6}, inset 0 0 0 100px ${blue.blue3}`,
    },
  },

  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
  },

  '&:disabled': {
    'backgroundColor': slate.slate2,
    'color': slate.slate8,
    'cursor': 'not-allowed',
    'pointerEvents': 'none',

    '&::placeholder': {
      color: slate.slate7,
    },
  },

  'variants': {
    readOnly: {
      true: {
        backgroundColor: slate.slate2,
      },
    },

    size: {
      small: {
        height: '42px',
        minHeight: '42px',
      },

      large: {
        height: '56px',
        minHeight: '56px',
      },
    },

    ghost: {
      true: {
        'backgroundColor': slate.slate2,
        'boxShadow': 'none',

        '@hover': {
          '&:hover': {
            backgroundColor: '$bg',
            boxShadow: 'inset 0 0 0 1px $colors$blue6, inset 0 0 0 100px $colors$blue3',
          },
        },

        '&:focus': {
          backgroundColor: '$bg',
          boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
        },

        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
    },

    invalid: {
      true: {
        'boxShadow': 'inset 0 0 0 1px $colors$pink',

        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$pink, 0 0 0 1px $colors$pink',
        },
      },
    },
  },

  'defaultVariants': {
    ghost: false,
    invalid: false,
    size: 'small',
  },
});

export const SelectValueContainer = styled('div', {
  display: 'block',
  paddingRight: '30px',
  textAlign: 'left',
  fontSize: '16px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const SelectValue = styled(SelectPrimitive.Value, {
  color: '$bg',
});

export const SelectIcon = styled(SelectPrimitive.Icon, {
  marginLeft: 'auto',
});

export const SelectContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: '$bg',
  borderRadius: '4px',
  boxShadow: '0px 10px 38px -10px rgb(22 23 24 / 35%), 0px 10px 20px -15px rgb(22 23 24 / 20%)',
  zIndex: '$dropdown',
});

export const SelectViewport = styled(SelectPrimitive.Viewport, {
  padding: '5px',
});

export const SelectItem = styled(SelectPrimitive.Item, {
  'all': 'unset',
  'fontSize': '16px',
  'lineHeight': 1.35,
  'color': '$fg',
  'borderRadius': '4px',
  'display': 'flex',
  'alignItems': 'center',
  'padding': '5px 35px 5px 25px',
  'position': 'relative',
  'userSelect': 'none',

  '&[data-disabled]': {
    color: '$fgTertiary',
    pointerEvents: 'none',
  },

  '&[data-highlighted]': {
    color: '$fg',
    backgroundColor: '$bgBlue',
  },

  'variants': {
    selected: {
      true: {
        color: '$blue',
      },
    },
  },
});

export const SelectItemText = styled(SelectPrimitive.ItemText, {});

export const SelectItemExtra = styled('span', {
  'marginLeft': '8px',
  'color': '$fgSecondary',
  'fontSize': '14px',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const SelectItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: '25px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SelectSeparator = styled(SelectPrimitive.Separator, {
  height: '1px',
  backgroundColor: '$fgTertiary',
  margin: '5px',
});
