import { SVGIcon } from '@paid-ui/icons';
import { slate } from '@radix-ui/colors';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { styled } from '../design-tokens';

export const AvatarRoot = styled(AvatarPrimitive.Root, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'middle',
  overflow: 'hidden',
  userSelect: 'none',
  backgroundColor: slate.slate6,
  cursor: 'inherit',
  flexShrink: 0,

  variants: {
    bordered: {
      true: {
        boxShadow: '0 0 0 2px white',
      },
    },

    pointer: {
      true: {
        cursor: 'pointer',
      },
    },

    size: {
      xSmall: {
        size: '28px',
      },

      small: {
        size: '36px',
      },

      medium: {
        size: '48px',
      },

      large: {
        size: '64px',
      },

      xlarge: {
        size: '72px',
      },
    },

    variant: {
      square: {
        borderRadius: 0,
      },

      rounded: {
        borderRadius: '4px',
      },

      circle: {
        borderRadius: '50%',
      },
    },
  },

  defaultVariants: {
    bordered: false,
    pointer: false,
    size: 'medium',
    variant: 'circle',
  },
});

export const AvatarImage = styled(AvatarPrimitive.Image, {
  size: '100%',
  objectFit: 'cover',
  borderRadius: 'inherit',
});

export const AvatarFallback = styled(AvatarPrimitive.Fallback, {
  size: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: slate.slate6,
  color: slate.slate10,
  lineHeight: 1,
  fontWeight: '$semibold',
  letterSpacing: '0.025em',

  variants: {
    color: {
      white: {
        color: 'white',
      },
    },

    size: {
      xSmall: {
        fontSize: '12px',
      },

      small: {
        fontSize: '14px',
      },

      medium: {
        fontSize: '18px',
      },

      large: {
        fontSize: '24px',
      },

      xlarge: {
        fontSize: '28px',
      },
    },

    background: {
      transparent: {
        backgroundColor: 'transparent',
      },

      blue: {
        'backgroundColor': '$blue',

        '&:hover': {
          backgroundColor: '$hoverBlue',
        },
      },

      white: {
        backgroundColor: '$bg',
      },
      lightGrey: {
        backgroundColor: '$lightGrey',
      },
    },
  },
});

export const StyledSVGIcon = styled(SVGIcon, {
  variants: {
    size: {
      xSmall: {
        size: '20px',
      },

      small: {
        size: '20px',
      },

      medium: {
        size: '32px',
      },

      large: {
        size: '52px',
      },

      xlarge: {
        size: '60px',
      },
    },
  },

  defaultVariants: {
    size: 'medium',
  },
});
