import { type Dispatch, forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import {
  StepFormClose,
  StepFormDescription,
  StepFormIconButton,
  StepFormLogo,
  StepFormSVGIcon,
  StepFormTitle,
  StepFormTitleAndDescription,
  StyledReviewFormHeader,
} from './_Base';
import { type FormStepper } from './types';

export interface ReviewFormHeaderProps extends BaseProps {
  title: string;
  description: string;
  hidden?: boolean;
  disableAutoClose?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  hideCloseButton?: boolean;

  currentStep?: number;
  steps?: FormStepper[];
  isSticky?: boolean;
  dispatch?: Dispatch<any>;
}

export const ReviewFormHeader = forwardRef<HTMLDivElement, ReviewFormHeaderProps>((props, ref) => {
  const {
    title,
    description,
    hidden,
    disableAutoClose,
    hideCloseButton,
    onClose,
    currentStep,
    steps,
    isSticky,
    dispatch,
    ...restProps
  } = props;
  if (hidden) {
    return null;
  }

  return (
    <StyledReviewFormHeader sticky ref={ref} {...restProps}>
      <StepFormLogo symbolOnly />
      <StepFormTitleAndDescription size={2} direction="vertical">
        <StepFormTitle>{title}</StepFormTitle>
        <StepFormDescription>{description}</StepFormDescription>
      </StepFormTitleAndDescription>
      {hideCloseButton ? null : disableAutoClose ? (
        <StepFormIconButton type="button" onClick={onClose}>
          <StepFormSVGIcon name="close" />
        </StepFormIconButton>
      ) : (
        <StepFormClose asChild>
          <StepFormIconButton type="button">
            <StepFormSVGIcon name="close" />
          </StepFormIconButton>
        </StepFormClose>
      )}
    </StyledReviewFormHeader>
  );
});

ReviewFormHeader.displayName = 'ReviewFormHeader';
