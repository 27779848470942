export enum ClaimAmountType {
  PERCENTAGE_OF_TOTAL = 'PERCENTAGE_OF_TOTAL',
  FIX_AMOUNTS = 'FIX_AMOUNTS',
}

export enum ClaimMethod {
  SINGLE_PROGRESS_CLAIM = 'SINGLE_PROGRESS_CLAIM',
  MULTIPLE_PROGRESS_CLAIMS = 'MULTIPLE_PROGRESS_CLAIMS',
}

/**
 * See details
 * https://paid-inc.atlassian.net/wiki/spaces/AD/pages/16580783/Progress+payment
 */
export enum PaymentStatus {
  SCHEDULED = 'SCHEDULED',
  LINKED = 'LINKED',
  BREACHED = 'BREACHED',
  SECURED = 'SECURED',
  COMPLETED = 'COMPLETED',
  SUBMITTED = 'SUBMITTED',
}

export enum ClaimStatusWithDetails {
  PAID = 'Paid',
  SECURED = 'Secured',
  OVERDUE = 'Overdue',
  PART_PAID = 'Part paid',
  NOT_YET_PAID = 'Not yet paid',
  PART_SECURED = 'Part secured',
  NOT_YET_CLAIMED = 'Not yet claimed',
  NOT_YET_SECURED = 'Not yet secured',
  NOT_YET_APPROVED = 'Not yet approved',
  APPROVED = 'Approved',
}

export enum PaymentSecuredStatus {
  SECURED = 'SECURED',
  PARTIALLY_SECURED = 'PARTIALLY_SECURED',
  NOT_SECURED = 'NOT_SECURED',
  TRANSFERRED = 'TRANSFERRED',
}

export enum PaymentStageName {
  DEPOSIT = 'DEPOSIT',
  BASE = 'BASE',
  FRAME = 'FRAME',
  LOCK_UP = 'LOCK_UP',
  FIXING = 'FIXING',
  PRACTICAL_COMPLETION = 'PRACTICAL_COMPLETION',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
}

export const paymentStageNames = {
  [PaymentStageName.DEPOSIT]: 'Deposit',
  [PaymentStageName.BASE]: 'Base',
  [PaymentStageName.FRAME]: 'Frame',
  [PaymentStageName.LOCK_UP]: 'Lock-up',
  [PaymentStageName.FIXING]: 'Fixing',
  [PaymentStageName.PRACTICAL_COMPLETION]: 'Final',
  [PaymentStageName.OTHER]: 'Other',
  [PaymentStageName.UNKNOWN]: 'Unknown',
};

export const standardPayments: Record<string, any>[] = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 10,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 15,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 35,
  },
  {
    stage: PaymentStageName.FIXING,
    percentOfContractSum: 25,
  },
  {
    stage: PaymentStageName.PRACTICAL_COMPLETION,
    percentOfContractSum: 10,
  },
];

export const standardPayments2: Record<string, any>[] = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 12,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 18,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 40,
  },
  {
    stage: PaymentStageName.FIXING,
    percentOfContractSum: 25,
  },
];

export const standardPayments3: Record<string, any>[] = [
  {
    stage: PaymentStageName.DEPOSIT,
    percentOfContractSum: 5,
  },
  {
    stage: PaymentStageName.BASE,
    percentOfContractSum: 20,
  },
  {
    stage: PaymentStageName.FRAME,
    percentOfContractSum: 25,
  },
  {
    stage: PaymentStageName.LOCK_UP,
    percentOfContractSum: 50,
  },
];

export enum TransactionHistoryType {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  INNER = 'INNER',
  REVERT = 'REVERT',
  FEE = 'FEE',
  UNKNOWN = 'UNKNOWN',
}

export type MonthKey = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

export const MonthOfDate = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Spt',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export enum PaymentDialogName {
  RESERVED = 'RESERVED',
  HELD = 'HELD',
}

export enum PaymentTermsType {
  DEFAULT = 'DEFAULT',
  EOM = 'EOM',
}

export enum ScheduledJobStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum LinkingStatus {
  SCHEDULED = 'SCHEDULED',
  LINKED = 'LINKED',
  SECURED = 'SECURED',
  PAID = 'PAID',
}

export enum LinkedFundsStatus {
  NOT_RESERVED = 'NOT_RESERVED',
  PARTIALLY_RESERVED = 'PARTIALLY_RESERVED',
  RESERVED = 'RESERVED',
}

export enum SecuredType {
  SECURED = 'SECURED',
  RELEASED = 'RELEASED',
}

export enum SecureFundsType {
  PROGRESS_PAYMENT = 'PROGRESS_PAYMENT',
  PERIODIC_CLAIM = 'PERIODIC_CLAIM',
  VARIATION = 'VARIATION',
}
