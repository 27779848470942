import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';
import { Flex } from '../flex';

export const PaymentsProgressContainer = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2px',
});

export const PaymentsProgressStage = styled('span', {
  'display': 'block',
  'position': 'relative',
  'height': '4px',

  '&:first-of-type': {
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
  },

  '&:last-of-type': {
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },

  'variants': {
    paid: {
      true: {
        backgroundColor: '$payInGreen',
      },

      false: {
        backgroundColor: '$fgFaint',
      },
    },
  },

  'defaultVariants': {
    paid: false,
  },
});

export const TargetIcon = styled(SVGIcon, {
  position: 'absolute',
  left: 'calc(50% - 6px)',
  width: '12px',
  height: '12px',
  color: '$fgSecondary',

  variants: {
    position: {
      top: {
        top: -12,
      },

      bottom: {
        bottom: -12,
        transform: 'rotate(180deg)',
      },
    },
  },
});

export const ContractsAmountContainer = styled(Flex, {
  position: 'absolute',
  padding: '4px 5px',
  border: '1px solid $colors$fgTertiary',
  borderRadius: '4px',
  left: '50%',
  transform: 'translateX(-50%)',
  gap: '5px',
  alignItems: 'center',
  top: '-6px',
});

export const ContractsAmountText = styled('span', {
  fontSize: '12px',
  display: 'inline-block',
});
