import { SVGIcon } from '@paid-ui/icons';
import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';
import { Flex } from '../flex';
import { Grid } from '../grid';

export const TransactionHistoryContainer = styled('section', {
  width: '100%',
});

export const TransactionHistoryGrid = styled(Grid, {
  gap: '14px',
});

export const TransactionHistoryDateTitle = styled('div', {
  fontSize: '14px',
  color: '#darkGrey',
});

export const TransactionHistoryItemContainer = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'gap': '8px',
  'width': '100%',
  'padding': '30px',
  'borderBottom': '1px solid #f2f2f2',
  'backgroundColor': '$bg',
  'cursor': 'pointer',
  'transition': 'background-color 250ms ease',

  '&:last-child': {
    borderBottom: 'none',
  },

  '@hover': {
    '&:hover': {
      backgroundColor: '#fcfcfc',
    },
  },

  'variants': {
    clickable: {
      false: {
        cursor: 'default',
      },
    },
  },
});

export const TransactionHistoryItemTitleContainer = styled(Flex, {
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: '$semibold',

  variants: {
    bold: {
      false: {
        fontWeight: '$normal',
      },
    },
  },
});

export const TransactionHistoryItemTitle = styled('div', {
  fontSize: '16px',
  lineHeight: '22px',
  flexShrink: 0,
  maxWidth: 'calc(100% - 100px)',
  lineClamp: 2,
});

export const TransactionHistoryItemPaymentContainer = styled(Flex, {
  alignItems: 'center',
  gap: '6px',
  width: 'auto',
});

export const TransactionHistoryItemPayment = styled('div', {
  fontSize: '16px',
  color: 'fg',
  variants: {
    revert: {
      true: {
        color: '$darkGrey',
      },
    },
  },
});

export const TransactionHistoryItemPaymentIcon = styled(SVGIcon, {
  size: '18px',
});

export const TransactionHistoryItemAddress = styled('div', {
  marginTop: '2px',
  fontSize: '14px',
  lineHeight: '18px',
  color: '$darkGrey',
  lineClamp: 2,
});

export const TransactionHistoryItemExtraArea = styled(Grid, {
  color: '$darkGrey',
  gap: '4px',
});

export const TransactionHistoryItemUnreferencedArea = styled(TransactionHistoryItemTitleContainer, {
  color: '$darkGrey',
});

export const TransactionHistoryItemLinkedContractsTitle = styled('div', {
  flexShrink: 0,
  fontSize: '14px',
  lineHeight: '18px',
  color: '$darkGrey',
  variants: {
    held: {
      true: {
        color: '$pink',
      },
    },
  },
});

export const TransactionHistoryItemLinkedContractsValueContainer = styled(
  TransactionHistoryItemPaymentContainer,
  {
    fontSize: '16px',
    flexShrink: 0,
  },
);

export const TransactionHistoryItemLinkedReserved = styled(Flex, {
  cursor: 'pointer',
  alignItems: 'center',
  gap: '8px',
});

export const GroupedLabel = styled('div', {
  width: '100%',
  fontSize: '14px',
  fontWeight: '$normal',
  lineHeight: '21px',
  color: 'rgb(90 99 110 / 100%)',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const AutoPayment = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '6px',
  color: '$fgTertiary',
});

export const DispenseIcon = styled(SVGIcon, {
  width: '15px',
  height: '15px',
  color: 'currentColor',
  flexShrink: 0,
});

export const TransactionHistoryIcon = styled(SVGIcon, {
  width: '15px',
  height: '15px',
  color: '$darkGrey',
  flexShrink: 0,
});

export const EarlyReleaseDiscount = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  color: '$darkGrey',
  fontSize: '14px',
  fontWeight: '$normal',
  lineHeight: '14px',
});
