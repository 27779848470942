import { SVGIcon } from '@paid-ui/icons';
import { blue, gray } from '@radix-ui/colors';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';

import { styled } from '../design-tokens';
import { Grid } from '../grid';

export const CheckboxRoot = styled(CheckboxPrimitive.Root, {
  'all': 'unset',
  'display': 'inline-flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'backgroundColor': 'white',
  'size': '20px',
  'border': `2px solid ${gray.gray8}`,
  'flexShrink': 0,
  'transition': 'borderColor 100ms',
  'cursor': 'pointer',
  '@hover': {
    '&:hover': {
      backgroundColor: blue.blue3,
    },
  },

  '&:focus': {
    borderColor: '$blue',
  },

  '&[data-state="checked"]': {
    borderColor: '$blue',
    backgroundColor: '$blue',
  },

  '&:disabled': {
    opacity: 0.4,
    cursor: 'unset',
  },

  'variants': {
    size: {
      small: {
        size: '16px',
      },

      medium: {
        size: '18px',
      },
    },

    shape: {
      round: {
        borderRadius: '50%',
      },

      square: {
        borderRadius: '6px',
      },
    },

    readOnly: {
      true: {
        opacity: 0.9,
        cursor: 'unset',
      },
    },
  },

  'defaultVariants': {
    shape: 'round',
  },
});

export const CheckboxIndicator = styled(CheckboxPrimitive.Indicator, {
  color: '$bg',
  content:
    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyIDEuNDIgMTAuNTkgMCA0IDYuNTkgMS40MiA0LjAyIDAgNS40M2w0IDMuOTkgOC04WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==')",
});

export const CheckboxLabel = styled('label', {
  'color': '$fg',
  'fontSize': '16px',
  'fontWeight': '$normal',
  'lineHeight': '24px',
  'userSelect': 'none',
  '&:empty': {
    display: 'none',
  },

  'variants': {
    size: {
      small: {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  },
});

export const ComplexContainer = styled(LabelPrimitive.Root, {
  'display': 'flex',
  'gap': '8px',
  'minHeight': '88px',
  'padding': '14px 20px',
  'alignItems': 'flex-start',
  'justifyContent': 'space-between',
  'cursor': 'pointer',
  'border': '2px solid $colors$fgFaint',
  'borderStyle': 'solid',
  'borderRadius': '0px 16px 16px',
  'transition': 'borderColor 100ms',

  '&:hover': {
    borderColor: '$fgTertiary',
  },

  'variants': {
    selected: {
      true: {
        'borderColor': '$blue',
        'color': '$blue',

        '&:hover': {
          borderColor: '$blue',
          color: '$blue',
        },
      },

      false: {
        borderColor: '$fgFaint',
        color: '$fgFaint',
      },
    },

    disabled: {
      true: {
        borderColor: '$fgFaint',
        cursor: 'default',
      },
    },
  },
});

export const VariationContainer = styled(ComplexContainer, {
  border: 'none',
  borderRadius: 'unset',

  variants: {
    readOnly: {
      true: {
        cursor: 'default',
      },
    },
  },
});

export const VariationGrid = styled(Grid, {
  gridTemplateColumns: '20px 1fr',
  gap: '9px',

  variants: {
    showCheckbox: {
      false: {
        gridTemplateColumns: 'unset',
      },
    },
  },
});

export const ArrowIcon = styled(SVGIcon, {
  color: '$blue',
});
