import type { ProjectSummary } from '@paid-ui/types';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { ProjectCard } from '../project-card';
import {
  ActionButtons,
  CardContent,
  CardHeader,
  ContractInfo,
  StyledCard,
} from '../project-card/_Base';
import { Skeleton } from '../skeleton';
import { StyledProjectGrid } from './_Base';

export const defaultProjectCardListProps = {};

export interface ProjectCardListProps extends BaseProps {
  data: ProjectSummary[];
  highlight?: string;
  loading?: boolean;
  hideActions?: boolean;
  disableProjectCreation?: boolean;
  requiringActions?: Record<string, number>;
}

export const ProjectCardList = forwardRef<HTMLDivElement, ProjectCardListProps>((props, ref) => {
  const {
    data,
    highlight,
    loading,
    hideActions,
    disableProjectCreation,
    requiringActions = {},
    ...restProps
  } = props;

  if (loading) {
    return (
      <StyledProjectGrid ref={ref} {...restProps}>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <StyledCard key={item}>
            <CardHeader>
              <Skeleton type="avatar" size="large" />
              <Skeleton width={20} height={20} />
            </CardHeader>
            <CardContent>
              <Skeleton height={66} />
              <ContractInfo align="center" justify="between">
                <Skeleton width={200} height={14} />
                <Skeleton width={150} height={14} />
              </ContractInfo>
              <Skeleton height={4} />
              <ActionButtons justify="between" gap={2} hidden={hideActions}>
                <Skeleton height={32} />
                <Skeleton height={32} />
              </ActionButtons>
            </CardContent>
          </StyledCard>
        ))}
      </StyledProjectGrid>
    );
  }

  if (data.length === 0) {
    if (hideActions) {
      return <span style={{ color: '#a6acb3' }}>No projects</span>;
    }
    return (
      <StyledProjectGrid ref={ref} {...restProps}>
        <ProjectCard isEmpty disableProjectCreation={disableProjectCreation} />
      </StyledProjectGrid>
    );
  }

  return (
    <StyledProjectGrid ref={ref} {...restProps}>
      {data.map((project, index) => (
        <ProjectCard
          key={project.id}
          data={project}
          highlight={highlight}
          hideActions={hideActions}
          requiringActions={requiringActions[project.id]}
          id={`project-card-${index}`}
        />
      ))}
    </StyledProjectGrid>
  );
});

ProjectCardList.displayName = 'ProjectCardList';
