import 'dayjs/locale/en-au';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

/**
 * Init dayjs base on language code.
 *
 * @author Ritchie Zhu <fengfu.zhu@paid.inc>
 * @date 2023-04-20
 * @param language - Language code.
 */
export function initDayjs(language = 'en-AU') {
  dayjs.locale(language.toLowerCase());
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);
  dayjs.extend(localeData);
  dayjs.extend(relativeTime);
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(timezone);
  dayjs.localeData();
  dayjs.tz.setDefault('Australia/Melbourne');
}

/**
 * Format UTC date to Melbourne local date time
 *
 * @param date - UTC date string
 * @returns Melbourne locale date
 */
export function utc2Mel(date?: string | number | dayjs.Dayjs | Date | null) {
  return dayjs(date).tz('Australia/Melbourne');
}

/**
 * Format UTC date to Melbourne local date time (string)
 *
 * @param date - UTC date string
 * @param format - Date format, default: YYYY-MM-DD
 * @returns Melbourne locale date
 */
export function utc2MelStr(
  date?: string | number | dayjs.Dayjs | Date | null,
  format = 'YYYY-MM-DD',
) {
  return utc2Mel(date).format(format);
}

/**
 * Melbourne now
 *
 * @returns Melbourne now date
 */
export function nowMel() {
  return dayjs().tz('Australia/Melbourne');
}

/**
 * Get deadline in days from submitted by date.
 *
 * @param submittedBy - Submitted by date in month
 * @returns Deadline in days
 */
export function getDeadlineInDays(submittedBy: number = 28) {
  const now = nowMel();
  const day = now.date();
  if (submittedBy >= day) {
    return submittedBy - day;
  } else {
    const year = now.year();
    const month = now.month();
    const today = dayjs(`${year}-${month}-${day}`);
    const submitted = dayjs(`${year}-${month + 1}-${submittedBy}`);
    return submitted.diff(today, 'day');
  }
}

/**
 * Get next claim month
 *
 * @param submittedBy - Submitted by date in month
 * @returns Get next claim month
 */
export function getNextClaimMonth(submittedBy: number = 28) {
  const now = nowMel();
  const day = now.date();
  const year = now.year();
  const month = now.month();
  return submittedBy >= day ? now.format('MMMM') : now.add(1, 'M').format('MMMM');
}
