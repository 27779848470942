import { SVGIcon } from '@paid-ui/icons';
import { gray, slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledSVGIcon = styled(SVGIcon, {
  size: '18px',
  color: gray.gray10,
});

export const ComboboxOption = styled('span', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  variants: {
    selected: {
      true: {
        fontWeight: '$semibold',
      },

      false: {
        fontWeight: '$normal',
      },
    },

    size: {
      small: {},
      medium: {},

      large: {
        minHeight: 40,
      },
    },
  },

  defaultVariants: {
    selected: false,
  },
});

export const CheckIconContainer = styled('span', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  alignItems: 'center',
  paddingRight: '16px',

  variants: {
    active: {
      true: {
        color: 'white',
      },

      false: {
        color: '$blue',
      },
    },

    selected: {
      true: {
        display: 'flex',
      },

      false: {
        display: 'none',
      },
    },
  },

  defaultVariants: {
    active: false,
    selected: false,
  },
});

export const CheckIcon = styled(SVGIcon, {
  size: '20px',
});

export const AspectRatioRoot = styled('div', {
  width: '100%',
  height: '200px',
  borderRadius: '6px',
  backgroundColor: slate.slate6,
  border: '1px solid $colors$midGrey',
});
