import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';

export const CarouselContainer = styled('div', {
  display: 'grid',
  alignItems: 'stretch',
  width: '100%',
  gap: '14px',
  borderRadius: '0 16px 16px',

  variants: {
    theme: {
      dark: {
        backgroundColor: '$fg',
        color: '$bg',
      },

      light: {
        border: '1px solid $colors$fgFaint',
        backgroundColor: '$bg',
        color: '$fg',
      },
    },

    showArrows: {
      true: {
        'gridTemplateColumns': 'none',
        'padding': '30px',

        '@sm': {
          gridTemplateColumns: '32px 1fr 32px',
          padding: '30px 10px',
        },
      },

      false: {
        gridTemplateColumns: 'none',
        padding: '30px',
      },
    },
  },

  defaultVariants: {
    showArrows: true,
    theme: 'dark',
  },
});

export const CarouselViewport = styled('div', {
  display: 'block',
  overflow: 'hidden',
  width: '100%',
});

export const CarouselItems = styled('ul', {
  all: 'unset',
  boxSizing: 'border-box',
  display: 'flex',
  userSelect: 'none',
  marginLeft: '-30px',
});

export const CarouselItem = styled('li', {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'relative',
  minWidth: '100%',
  paddingLeft: '30px',
});

export const Arrow = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'none',

  '@sm': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '100%',
    borderRadius: '4px',
    transition: 'opacity 200ms',
    cursor: 'pointer',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },

    enabled: {
      true: {
        opacity: 1,
        pointerEvents: 'auto',
      },

      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

export const ArrowIcon = styled(SVGIcon, {
  width: '24px',
  height: '24px',

  variants: {
    theme: {
      light: {
        color: '$fgSecondary',
      },

      dark: {
        color: '$fgTertiary',
      },
    },
  },

  defaultVariants: {
    theme: 'dark',
  },
});

export const Indicators = styled('div', {
  display: 'inline-grid',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '4px',
  maxWidth: '100%',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const Indicator = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'height': '6px',
  'maxWidth': '48px',
  'transition': 'backgroundColor 200ms',
  'backgroundColor': '$fgTertiary',
  'cursor': 'pointer',

  '&:first-of-type': {
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
  },

  '&:last-of-type': {
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
  },

  'variants': {
    selected: {
      true: {
        backgroundColor: '$blue',
      },
    },
  },
});
