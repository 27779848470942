import { CheckIcon } from '@radix-ui/react-icons';
import { forwardRef } from 'react';

import type { CSS } from '../design-tokens';
import { LeadingIconButton, StyledSVGIcon, TrailingIconButton } from '../form';
import { HelperText } from '../help-text';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemExtra,
  SelectItemIndicator,
  SelectItemText,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectValueContainer,
  SelectViewport,
} from './_Base';

export const defaultSelectProps = {};

export type SelectOptionItem = {
  label: string;
  value: string;
  extra?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  border?: boolean;
};

export interface SelectProps extends BaseProps {
  name: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  options: SelectOptionItem[];
  label?: React.ReactNode;
  labelSize?: FontSize;
  labelWeight?: FontWeight;
  helperText?: React.ReactNode;
  helperTextStyle?: CSS;
  errorMessage?: React.ReactNode;
  icon?: React.ReactNode;
  valueStyle?: CSS;
  size?: 'small' | 'large';
  ghost?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  hidden?: boolean;

  onChange?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  renderValue?: (option?: SelectOptionItem) => React.ReactNode;
}

export const Select = forwardRef<HTMLButtonElement, SelectProps>((props, ref) => {
  const {
    name,
    placeholder,
    value,
    defaultValue,
    labelWeight,
    options,
    label,
    labelSize,
    helperText,
    errorMessage,
    icon,
    ghost,
    required,
    disabled,
    readOnly,
    autoFocus,
    hidden,
    valueStyle,
    size,
    onChange,
    onBlur,
    renderValue,
    helperTextStyle,
    ...restProps
  } = props;

  const hasError = !!errorMessage;

  if (hidden) {
    return null;
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label ? (
        <Label htmlFor={name} asterisk={required} size={labelSize} weight={labelWeight}>
          {label}
        </Label>
      ) : null}

      <SelectRoot
        name={name}
        value={value || undefined}
        defaultValue={defaultValue || undefined}
        onValueChange={onChange}
        open={readOnly ? false : undefined}
      >
        <SelectTrigger
          ref={ref}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          ghost={ghost}
          invalid={hasError}
          size={size}
          readOnly={readOnly}
        >
          <LeadingIconButton as="span">{icon}</LeadingIconButton>
          <SelectValueContainer
            css={{
              paddingLeft: icon ? 30 : undefined,
              ...valueStyle,
            }}
          >
            <SelectValue placeholder={placeholder} />
          </SelectValueContainer>
          <SelectIcon>
            <TrailingIconButton as="span">
              <StyledSVGIcon name="drop" />
            </TrailingIconButton>
          </SelectIcon>
        </SelectTrigger>
        <SelectContent
          position="popper"
          css={{
            width: 'var(--radix-select-trigger-width)',
            maxHeight: 'var(--radix-select-content-available-height)',
          }}
        >
          <SelectViewport>
            {options.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
                selected={Boolean(value) && option.value === value}
                disabled={option.disabled}
              >
                <SelectItemText>{option.label}</SelectItemText>
                <SelectItemExtra disabled={option.disabled}>{option.extra}</SelectItemExtra>
                <SelectItemIndicator>
                  <CheckIcon />
                </SelectItemIndicator>
              </SelectItem>
            ))}
          </SelectViewport>
        </SelectContent>
      </SelectRoot>

      <HelperText error={hasError} id={hasError ? `${name}-error` : `${name}-description`}>
        {hasError ? errorMessage : helperText}
      </HelperText>
    </Space>
  );
});

Select.displayName = 'Select';
