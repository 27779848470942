import { SVGIcon } from '@paid-ui/icons';
import { styled } from '@stitches/react';

import { Flex } from '../flex';

export const BannerContainer = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$fgTertiary',
  padding: '10px',
});

export const BannerWrap = styled(Flex, {
  position: 'relative',
  maxWidth: '1220px',
  alignItems: 'center',
});

export const ContentContainer = styled(Flex, {
  gap: '18px',
  color: 'white',
  alignItems: 'center',
});

export const Text = styled('div', {
  fontSize: '16px',
});

export const SubText = styled('div', {
  fontSize: '14px',
});

export const CloseIcon = styled(SVGIcon, {
  cursor: 'pointer',
  color: 'white',
  size: '15px',
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
});
