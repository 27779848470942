import { styled } from '../design-tokens';

export const EmptyText = styled('p', {
  my: '16px',
  color: '$fgSecondary',
  textAlign: 'center',
});

export const ActionContainer = styled('div', {
  width: '100%',
});
