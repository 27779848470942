export enum PartyType {
  PAYER = 'PAYER',
  PAYEE = 'PAYEE',
  ADDITIONAL_PAYER = 'ADDITIONAL_PAYER',
  ADDITIONAL_PAYEE = 'ADDITIONAL_PAYEE',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export enum PartyRole {
  INVITER = 'INVITER',
  INVITEE = 'INVITEE',
}

export enum PartyLinkingStatus {
  'UNLINKED' = 'UNLINKED',
  'LINKED' = 'LINKED',
  'FILLED_INFO' = 'FILLED_INFO',
  'READY_TO_SIGN' = 'READY_TO_SIGN',
  'SIGNED' = 'SIGNED',
  'SIGNING' = 'SIGNING',
  'DECLINED_ON_PROVIDER' = 'DECLINED_ON_PROVIDER',
  'JOINED' = 'JOINED',
}

export enum PayType {
  PAYER = 'PAYER',
  PAYEE = 'PAYEE',
  INTERMEDIARY = 'INTERMEDIARY',
}

export enum WitnessForSubType {
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_TRUST = 'INDIVIDUAL_TRUST',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
}
