import {
  ToastProvider as BaseToastProvider,
  type ToastProviderProps as BaseToastProviderProps,
} from '@radix-ui/react-toast';
import {
  createContext,
  type Dispatch,
  forwardRef,
  useCallback,
  useContext,
  useReducer,
} from 'react';

import {
  ToastActionIcon,
  ToastClose,
  ToastDescription,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from './_Base';

const initialState = {
  open: false,
  title: '',
  content: '',
  variant: 'info' as ToastVariant,
};

export type ToastPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

export interface ToastProviderProps extends BaseToastProviderProps {
  position?: ToastPosition;
}

export type ToastState = typeof initialState;
export type ToastContent = string | { title: string; content?: string };
export type ToastVariant = 'info' | 'success' | 'error';
export type ToastAction =
  | {
      type: 'openToast';
      payload?: {
        content: ToastContent;
        variant?: ToastVariant;
      };
    }
  | { type: 'closeToast' }
  | { type: 'reset' };

const ToastStateContext = createContext<ToastState | undefined>(undefined);
const ToastDispatchContext = createContext<Dispatch<ToastAction> | undefined>(undefined);

const reducer = (state: ToastState, action: ToastAction): ToastState => {
  switch (action.type) {
    case 'openToast': {
      switch (typeof action.payload?.content) {
        case 'undefined': {
          return {
            ...state,
            open: true,
          };
        }

        case 'string': {
          return {
            ...state,
            open: true,
            content: action.payload.content,
            variant: action.payload.variant ?? 'info',
          };
        }

        default: {
          const { title, content } = action.payload?.content ?? {};

          return {
            ...state,
            open: true,
            title: title ?? '',
            content: content ?? '',
            variant: action.payload?.variant ?? 'info',
          };
        }
      }
    }

    case 'closeToast': {
      return {
        ...state,
        open: false,
      };
    }

    case 'reset': {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export const ToastProvider = forwardRef<HTMLLIElement, ToastProviderProps>((props, ref) => {
  const { position, children, ...restProps } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleToastOpen = useCallback((open: boolean) => {
    dispatch({
      type: open ? 'openToast' : 'closeToast',
    });
  }, []);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        <BaseToastProvider {...restProps}>
          {children}
          <ToastRoot
            ref={ref}
            type="foreground"
            open={state.open}
            onOpenChange={handleToastOpen}
            variant={state.variant}
          >
            {state.title ? <ToastTitle>{state.title}</ToastTitle> : null}
            <ToastDescription>{state.content}</ToastDescription>
            <ToastClose aria-label="Close">
              <ToastActionIcon name="close" />
            </ToastClose>
          </ToastRoot>
          <ToastViewport position={position} />
        </BaseToastProvider>
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
});

export const useToast = () => {
  const dispatch = useContext(ToastDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useToast should be used within a ToastProvider');
  }

  const toast = (content: ToastContent, variant: ToastVariant) => {
    dispatch({
      type: 'closeToast',
    });

    setTimeout(() => {
      dispatch({
        type: 'openToast',
        payload: {
          content,
          variant,
        },
      });
    }, 100);
  };

  return {
    info: (content: ToastContent) => toast(content, 'info'),
    success: (content: ToastContent) => toast(content, 'success'),
    error: (content: ToastContent) => toast(content, 'error'),
  };
};

ToastProvider.displayName = 'ToastProvider';
