import { defaultAddress } from '@paid-ui/constants';
import { type AddressState } from '@paid-ui/enums/address';
import type { Address } from '@paid-ui/types';
import { getGeocode } from 'use-places-autocomplete';

/**
 * Decode a string address into an address object.
 *
 * @param address - Address string.
 * @returns Address object.
 */
export async function decodeAddress(address?: string): Promise<Address> {
  const addressObject: Address = { ...defaultAddress };

  if (!address) {
    return addressObject;
  }

  try {
    const geoCode = await getGeocode({ address });

    geoCode[0]?.address_components.forEach((component) => {
      switch (component.types[0]) {
        case 'postal_code': {
          addressObject.postcode = component.short_name;
          break;
        }

        case 'country': {
          addressObject.country = component.long_name;
          break;
        }

        case 'administrative_area_level_2':
        case 'administrative_area_level_1': {
          addressObject.state = component.short_name as AddressState;
          break;
        }

        case 'colloquial_area':
        case 'locality': {
          addressObject.suburb = component.long_name;
          break;
        }

        case 'route': {
          addressObject.streetName = component.long_name;
          break;
        }

        case 'street_number': {
          addressObject.streetNumber = component.short_name;
          break;
        }

        default: {
          break;
        }
      }
    });

    return addressObject;
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : 'Decode project address failed.');
  }
}
