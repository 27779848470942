import { SVGIcon } from '@paid-ui/icons';
import * as Accordion from '@radix-ui/react-accordion';

import { slideDown, styled } from '../design-tokens';

export const UserGuideContainer = styled('div', {
  display: 'none',
  padding: '10px',
  backgroundColor: '$lightGrey',
  borderRadius: '$panel',

  variants: {
    breakpoint: {
      md: {
        '@md': {
          display: 'block',
        },
      },
      sm: {
        '@sm': {
          display: 'block',
        },
      },
    },
  },
});

export const UserGuideWrapper = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '$bg',
  borderRadius: '0px 8px 8px 8px',
  overflow: 'auto',
  overscrollBehavior: 'contain',
  filter:
    'drop-shadow(0px 15px 29px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 3.35045px 6.47753px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 0.997515px 1.92853px rgba(0, 0, 0, 0.07))',
});

export const UserGuideHeader = styled('div', {
  position: 'sticky',
  top: 0,
  bottom: 0,
  backgroundColor: '$bg',
  padding: '18px 20px',
  borderBottom: '1px solid $colors$midGrey',
  zIndex: '$navbar',
});

export const UserGuideTitle = styled('div', {
  color: '$fg',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '$medium',
  width: 'calc(100% - 20px)',
});

export const UserGuideSubtitle = styled('div', {
  color: '$darkGrey',
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '$normal',
  marginTop: '2px',
});

export const UserGuideMinimizeButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'absolute',
  top: '18px',
  right: '20px',
  width: '18px',
  height: '18px',
});

export const MinimizeIcon = styled(SVGIcon, {
  'width': '18px',
  'height': '18px',
  'color': '$darkGrey',
  'cursor': 'pointer',
  'transition': 'color 150ms ease-in-out',

  '@hover': {
    '&:hover': {
      color: '$fg',
    },
  },
});

export const UserGuideContent = styled(Accordion.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const UserGuideItem = styled(Accordion.Item, {
  'overflow': 'hidden',
  '&:not(:first-child)': {
    borderTop: '1px solid $colors$midGrey',
  },
});

export const UserGuideItemTrigger = styled(Accordion.Trigger, {
  'all': 'unset',
  'boxSizing': 'border-box',
  'padding': '18px 20px',
  'display': 'flex',
  'alignItems': 'center',
  'width': '100%',
  'gap': 0,

  '&[data-state="open"]': {
    boxShadow:
      '0px 15px 29px rgba(0, 0, 0, 0.0282725), 0px 3.35045px 6.47753px rgba(0, 0, 0, 0.0417275), 0px 0.997515px 1.92853px rgba(0, 0, 0, 0.07)',
  },
});

export const UserGuideItemContent = styled(Accordion.Content, {
  'display': 'flex',
  'flexDirection': 'column',
  'width': '100%',
  'gap': '24px',
  'overflow': 'hidden',

  '&[data-state="open"]': {
    padding: '20px',
    animation: `${slideDown} 200ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    padding: '0 20px',
    // animation: `${slideUp} 200ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const CheckIcon = styled(SVGIcon, {
  flexShrink: 0,
  width: '21px',
  height: '21px',
  color: '$midGrey',

  variants: {
    checked: {
      true: {
        color: '$payInGreen',
      },
    },
  },
});

export const UserGuideItemTitle = styled('div', {
  flex: 1,
  marginLeft: '16px',
  marginRight: '6px',
  color: '$fg',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '$medium',
});

export const ExpandButton = styled('span', {
  'flexShrink': 0,
  'width': '20px',
  'height': '20px',
  'display': 'inline-flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'cursor': 'pointer',
  'transition': 'color 150ms ease-in-out, transform 200ms cubic-bezier(0.87, 0, 0.13, 1)',
  'color': '$darkGrey',

  '@hover': {
    '&:hover': {
      color: '$fg',
    },
  },

  '[data-state="open"] &': {
    transform: 'rotate(180deg)',
  },
});

export const ExpandIcon = styled(SVGIcon, {
  width: '21px',
  height: '21px',
});

export const UserGuideBenefits = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '10px',
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

export const UserGuideBenefit = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '$medium',
});

export const BulletSpan = styled('span', {
  display: 'inline-block',
  fontSize: '22px',
  lineHeight: '22px',
});

export const UserGuideItemVideo = styled('video', {
  display: 'block',
  width: '100%',
  aspectRatio: '16 / 9',
  objectFit: 'cover',
  borderRadius: '6px',
  margin: 0,
});

export const UserGuideItemDisableReason = styled('p', {
  width: '100%',
  color: '$darkGrey',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '21px',
  fontWeight: '$normal',
});

export const UserGuideFab = styled('button', {
  'all': 'unset',
  'boxSizing': 'border-box',
  'display': 'none',
  'position': 'fixed',
  'bottom': '20px',
  'right': '20px',
  'width': '66px',
  'height': '66px',
  'backgroundColor': '$blue',
  'borderRadius': '33px',
  'alignItems': 'center',
  'justifyContent': 'center',
  'cursor': 'pointer',
  'transition': 'background-color 150ms ease-in-out',

  '@hover': {
    '&:hover': {
      backgroundColor: '$hoverBlue',
    },
  },

  '&:focus': {
    backgroundColor: '$hoverBlue',
  },

  'variants': {
    breakpoint: {
      md: {
        '@md': {
          display: 'flex',
        },
      },
      sm: {
        '@sm': {
          display: 'flex',
        },
      },
    },
  },
});

export const QuickStartIcon = styled(SVGIcon, {
  color: '$bg',
  width: '24px',
  height: '24px',
});
