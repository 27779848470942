import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StyledKbd } from './_Base';

export type KbdSize = 'small' | 'large';
export type KbdWidth = 'space' | 'shift' | 'command';

export const defaultKbdProps = {
  size: 'large' as KbdSize,
};

export interface KbdProps extends BasePropsWithChildren {
  size?: 'small' | 'large';
  width?: 'space' | 'shift' | 'command';
}

export const Kbd = forwardRef<HTMLElement, KbdProps>((props, ref) => {
  const { size = defaultKbdProps.size, width, children, ...restProps } = props;

  return (
    <StyledKbd ref={ref} size={size} width={width} {...restProps}>
      {children}
    </StyledKbd>
  );
});

Kbd.displayName = 'Kbd';
