import { SVGIcon } from '@paid-ui/icons';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { styled } from '../design-tokens';
import { Logo } from '../logo';
import { Space } from '../space';

export const DialogClose = DialogPrimitive.Close;

export const VerifyEmailFormMainContent = styled(Space, {
  padding: '30px',
});

export const VerifyEmailFormHeader = styled(Space, {
  width: '100%',
});

export const VerifyEmailFormTitle = styled(DialogPrimitive.Title, {
  color: '$fg',
  fontSize: '22px',
  fontWeight: '$semibold',
  lineHeight: '27px',
  userSelect: 'none',
  marginRight: '32px',
});

export const VerifyEmailFormDescription = styled(DialogPrimitive.Description, {
  color: '$fg',
  fontSize: '16px',
  fontWeight: '$normal',
  lineHeight: '22px',
  userSelect: 'none',
});

export const VerifyEmailFormIconButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  padding: '6px',
  size: '32px',
  cursor: 'pointer',
});

export const VerifyEmailFormSVGIcon = styled(SVGIcon, {
  'size': '20px',

  '@hover': {
    'color': '$fgSecondary',
    'transition': 'color 100ms',

    '&:hover': {
      color: '$blue',
    },
  },
});

export const VerifyEmailFormClose = styled(DialogPrimitive.Close, {
  position: 'absolute',
  top: '30px',
  right: '30px',
});

export const VerifyEmailFormFooter = styled('footer', {
  width: '100%',
});

export const VerifyEmailFormLogo = styled(Logo, {
  height: '27px',
});

export const ResendButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  color: '$blue',
  cursor: 'pointer',
});
