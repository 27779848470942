import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';
import { Flex } from '../flex';
import { Grid } from '../grid';

export const LinkedItemContainer = styled(Grid, {
  'gap': '4px',
  'fontSize': '16px',
  'lineHeight': '22px',
  'padding': '14px 0',
  'borderBottom': `1px solid ${slate.slate5}`,

  '&:last-child': {
    borderBottom: 'none',
  },
});

export const LinkedItemRow = styled(Flex, {
  alignItems: 'center',
  color: '$fg',
});

export const LinkedItemLabel = styled('div', {
  width: '105px',
  fontWeight: '$semibold',
});

export const LinkedItemValue = styled('div', {});
