import { styled } from '../design-tokens';

export const NotificationsPanelContainer = styled('div', {
  'background': '$bg',
  'padding': '15px 5px 15px 15px',
  'minWidth': '100vw',
  '@sm': {
    minWidth: '410px',
  },
});
