import { forwardRef } from 'react';

import type { BaseProps, Orientation } from '../interfaces';
import { StyledSeparator } from './_Base';

export const defaultSeparatorProps = {
  gap: 0,
  decorative: true,
  orientation: 'horizontal' as Orientation,
};

export interface SeparatorProps extends BaseProps {
  gap?: number;
  decorative?: boolean;
  orientation?: Orientation;
  hidden?: boolean;
}

export const Separator = forwardRef<HTMLDivElement, SeparatorProps>((props, ref) => {
  const {
    gap = defaultSeparatorProps.gap,
    decorative = defaultSeparatorProps.decorative,
    orientation = defaultSeparatorProps.orientation,
    hidden,
    css,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <StyledSeparator
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      css={{
        ...css,
        margin: orientation === 'horizontal' ? `${gap}px 0` : `0 ${gap}px`,
      }}
      {...restProps}
    />
  );
});

Separator.displayName = 'Separator';
