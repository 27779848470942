import { dialogOverlayShow, styled } from '../design-tokens';

export const QuickActionsDesktop = styled('div', {
  'display': 'none',

  '@sm': {
    display: 'block',
  },
});

export const QuickActionsMobile = styled('div', {
  'position': 'fixed',
  'inset': 0,
  'zIndex': '$overlay',

  '@sm': {
    display: 'none',
  },

  'variants': {
    hidden: {
      true: {
        top: 'unset',
      },
    },
  },
});

export const QuickActionsOverlay = styled('div', {
  'display': 'block',
  'position': 'absolute',
  'inset': 0,
  'backgroundColor': '$bgOverlay',
  'backdropFilter': 'blur(8px)',
  'animation': `${dialogOverlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  'zIndex': '$overlay',

  '@md': {
    display: 'none',
  },

  '@motion': {
    animation: 'none',
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  'defaultVariants': {
    hidden: true,
  },
});

export const QuickActionsActionSheet = styled('div', {
  'zIndex': '$dialog',
  'position': 'absolute',
  'bottom': 0,
  'left': 0,
  'right': 0,
  'padding': '15px 20px',
  'backgroundColor': '$bg',
  'borderTop': '1px solid $colors$midGrey',
  'boxShadow':
    '0px -9px 25px rgb(0 0 0 / 2.83%), 0px -2.01027px 5.58408px rgb(0 0 0 / 4.17%), 0px -0.598509px 1.66253px rgb(0 0 0 / 7%)',

  '@md': {
    display: 'none',
  },
});

export const QuickActionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  position: 'sticky',
  top: '24px',

  variants: {
    stickyNavbar: {
      true: {
        top: '78px',
      },
    },
  },
});
