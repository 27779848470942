import {
  ClaimStatus,
  ClaimStatusWithDetails,
  PartyType,
  PaymentSecuredStatus,
} from '@paid-ui/constants';
import { type PaymentStage } from '@paid-ui/types';
import { nowMel, utc2Mel } from '@paid-ui/utils/datetime';

/**
 * Get claim status for payment
 *
 * @param payment payment stage
 * @returns Claim status
 */
export function getClaimStatusAfterApproved(payment: PaymentStage) {
  const { dueDate, amount = 0, paymentBreakdown } = payment.claim ?? {};
  if (dueDate && utc2Mel(dueDate).isBefore(nowMel()) && !utc2Mel(dueDate).isToday()) {
    return ClaimStatusWithDetails.OVERDUE;
  }
  const {
    securedUnreleased = 0,
    releasedUnpaid = 0,
    totalHistoryPaid = 0,
    discount = 0,
  } = paymentBreakdown ?? {};
  const totalPaid = releasedUnpaid + totalHistoryPaid + discount;
  if (totalPaid >= amount) {
    return ClaimStatusWithDetails.PAID;
  }
  if (totalPaid > 0 && totalPaid < amount) {
    return ClaimStatusWithDetails.PART_PAID;
  }
  if (securedUnreleased + releasedUnpaid >= amount) {
    return ClaimStatusWithDetails.SECURED;
  }
  if (securedUnreleased > 0) {
    return ClaimStatusWithDetails.PART_SECURED;
  }
  if (
    payment.linked &&
    payment.linked?.length > 0 &&
    payment.secured !== PaymentSecuredStatus.TRANSFERRED &&
    payment.claim?.scheduledClaimPayments?.length === 0
  ) {
    return ClaimStatusWithDetails.NOT_YET_SECURED;
  }
  return ClaimStatusWithDetails.NOT_YET_PAID;
}
/**
 *
 * @param payment PaymentStage
 * @param hideMoreStatus hide more status
 * @returns ClaimStatusWithDetails
 */
export function getClaimStatusWithDetails(payment: PaymentStage, hideMoreStatus = false) {
  if (!payment.claim) {
    return ClaimStatusWithDetails.NOT_YET_CLAIMED;
  }
  if (payment.claim.state === ClaimStatus.PAID) {
    return ClaimStatusWithDetails.PAID;
  }
  const { state } = payment?.claim || {};
  if (state === ClaimStatus.APPROVED) {
    if (hideMoreStatus) {
      return ClaimStatusWithDetails.APPROVED;
    }
    return getClaimStatusAfterApproved(payment);
  }
  return ClaimStatusWithDetails.NOT_YET_APPROVED;
}

/**
 *
 * @param dueDate date
 * @returns days
 */
export function getDiffDays(dueDate?: string) {
  if (!dueDate) {
    return 0;
  }
  const diffHours = nowMel().diff(utc2Mel(dueDate), 'seconds') / 3600;
  return Math.ceil(Math.abs(diffHours) / 24);
}

/**
 *
 * @param status status
 * @param payment payment
 * @returns string
 */
export function getDescriptionForClaim(status: ClaimStatusWithDetails, payment: PaymentStage) {
  const { dueDate } = payment?.claim ?? {};
  const days = getDiffDays(dueDate);
  if (status === ClaimStatusWithDetails.SECURED) {
    if (utc2Mel(dueDate).isToday()) {
      return 'Auto transfer today';
    }
    return `Auto transfer in ${days}d`;
  }
  if (
    [
      ClaimStatusWithDetails.PART_PAID,
      ClaimStatusWithDetails.PART_SECURED,
      ClaimStatusWithDetails.SECURED,
      ClaimStatusWithDetails.NOT_YET_PAID,
      ClaimStatusWithDetails.NOT_YET_SECURED,
    ].includes(status)
  ) {
    if (utc2Mel(dueDate).isToday()) {
      return 'Due today';
    }
    return `Due in ${days}d`;
  }
  return '';
}

/**
 *
 * @param claimStatusToDisplay claim status
 * @returns color
 */
export function getClaimStatusColor(claimStatusToDisplay: ClaimStatusWithDetails) {
  if (claimStatusToDisplay === ClaimStatusWithDetails.PAID) {
    return 'success';
  }
  if (claimStatusToDisplay === ClaimStatusWithDetails.OVERDUE) {
    return 'danger';
  }
  if (claimStatusToDisplay === ClaimStatusWithDetails.SECURED) {
    return 'primary';
  }
  return 'default';
}

/**
 *
 * @param claimStatusToDisplay claim status
 * @param payment payment
 * @returns string
 */
export function getOverdueDays(
  claimStatusToDisplay: ClaimStatusWithDetails,
  payment: PaymentStage,
) {
  if (claimStatusToDisplay === ClaimStatusWithDetails.OVERDUE) {
    const { dueDate } = payment?.claim ?? {};
    const days = Math.max(getDiffDays(dueDate) - 1, 1);
    return ` ${days}d`;
  }
  return '';
}
