import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledKbd = styled('kbd', {
  boxSizing: 'border-box',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$bg',
  flexShrink: 0,
  color: '$fg',
  userSelect: 'none',
  cursor: 'default',
  whiteSpace: 'nowrap',
  boxShadow: `
    inset 0 0.5px rgb(255 255 255 / 10%),
    inset 0 1px 5px ${slate.slate2},
    0px 0px 0px 0.5px ${slate.slate8},
    0px 2px 1px -1px ${slate.slate8},
    0 1px  ${slate.slate8}`,
  textShadow: '0 0 1px rgb(255 255 255 / 50%)',
  fontFamily: 'inherit',
  fontWeight: '$normal',
  lineHeight: 1.5,

  variants: {
    size: {
      small: {
        minWidth: '1.6em',
        height: '16px',
        paddingRight: '0.3em',
        paddingLeft: '0.3em',
        borderRadius: '4px',
        fontSize: '12px',
        lineHeight: '16px',
      },

      large: {
        minWidth: '2em',
        height: '24px',
        paddingRight: '0.5em',
        paddingLeft: '0.5em',
        borderRadius: '6px',
        fontSize: '14px',
        lineHeight: '24px',
      },
    },

    width: {
      shift: {
        justifyContent: 'flex-start',
        width: '4em',
      },

      command: {
        justifyContent: 'flex-end',
        width: '3em',
      },

      space: {
        width: '8em',
      },
    },
  },

  compoundVariants: [
    {
      size: 'small',
      width: 'shift',
      css: {
        width: '3em',
      },
    },
    {
      size: 'small',
      width: 'command',
      css: {
        width: '2.5em',
      },
    },
    {
      size: 'small',
      width: 'space',
      css: {
        width: '5em',
      },
    },
  ],

  defaultVariants: {
    size: 'large',
  },
});
