import { TransactionHistoryType } from '@paid-ui/constants';
import { type TransactionHistory as ITransactionHistory } from '@paid-ui/types';
import { nowMel, utc2Mel } from '@paid-ui/utils/datetime';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { forwardRef, useCallback, useMemo } from 'react';

import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { GroupedLabel, TransactionHistoryContainer, TransactionHistoryGrid } from './_Base';
import { TransactionHistoryItem } from './TransactionHistoryItem';

export const defaultTransactionHistoryProps = {};

export interface TransactionHistoryProps extends BaseProps {
  data: ITransactionHistory[];
  groupByDate?: boolean;
  onClick?: (item: ITransactionHistory) => void;
  onActionClick?: (item: ITransactionHistory) => void;
}

const transactionFeeTypes = new Set([TransactionHistoryType.FEE, TransactionHistoryType.REVERT]);

export const TransactionHistory = forwardRef<HTMLDivElement, TransactionHistoryProps>(
  (props, ref) => {
    const { data, groupByDate, onClick, onActionClick, ...restProps } = props;
    const groupedData = useMemo(() => {
      const transactionMap: any = {};
      const historyIdList: string[] = [];
      data.forEach((item) => {
        if (transactionFeeTypes.has(item.type)) {
          if (item.referenceTransactionId) {
            transactionMap[item.referenceTransactionId] = item;
          }
        } else if (item.id) {
          historyIdList.push(item.id);
        }
      });
      const newData = data
        .map((item) => {
          if (
            transactionFeeTypes.has(item.type) &&
            item.referenceTransactionId &&
            historyIdList.includes(item.referenceTransactionId)
          ) {
            return null;
          }
          const transactionItem = transactionMap[item.id as string];
          if (transactionItem) {
            item.transactionFee = transactionItem.amount;
            item.transactionFeeType = transactionItem.type;
            item.transactionFeeOrigin = transactionItem.origin;
            item.transactionFeeDescription = transactionItem.description;
          }
          return item;
        })
        .filter(Boolean);
      return sortBy(
        Object.entries(groupBy(newData, (item) => utc2Mel(item.date).format('YYYY-MM-DD'))),
        ([key]) => nowMel().valueOf() - utc2Mel(key).valueOf(),
      );
    }, [data]);

    const formatDate = useCallback((date: string) => {
      if (utc2Mel(date).isToday()) {
        return 'Today';
      }

      if (utc2Mel(date).isYesterday()) {
        return 'Yesterday';
      }

      return utc2Mel(date).format('DD MMM YYYY');
    }, []);

    const handleClick = useCallback(
      (item: ITransactionHistory) => {
        if (typeof onClick === 'function') {
          onClick(item);
        }
      },
      [onClick],
    );

    const handleActionClick = useCallback(
      (item: ITransactionHistory) => {
        if (typeof onActionClick === 'function') {
          onActionClick(item);
        }
      },
      [onActionClick],
    );

    if (groupByDate) {
      return (
        <Space ref={ref} direction="vertical" inline={false} {...restProps}>
          {groupedData.map(([date, items]) => (
            <TransactionHistoryContainer key={date}>
              <GroupedLabel hidden={items.length === 0}>{formatDate(date)}</GroupedLabel>
              <Space size={0} direction="vertical" inline={false}>
                <TransactionHistoryGrid>
                  {sortBy(items, (item) => nowMel().valueOf() - utc2Mel(item.date).valueOf()).map(
                    (item, index) => (
                      <TransactionHistoryItem
                        key={index}
                        {...item}
                        onClick={() => handleClick(item)}
                        onActionClick={() => handleActionClick(item)}
                      />
                    ),
                  )}
                </TransactionHistoryGrid>
              </Space>
            </TransactionHistoryContainer>
          ))}
        </Space>
      );
    }

    return (
      <TransactionHistoryContainer ref={ref} {...restProps}>
        <TransactionHistoryGrid>
          {data.map((item, index) => (
            <TransactionHistoryItem
              key={index}
              {...item}
              onClick={() => handleClick(item)}
              onActionClick={() => handleActionClick(item)}
            />
          ))}
        </TransactionHistoryGrid>
      </TransactionHistoryContainer>
    );
  },
);

TransactionHistory.displayName = 'TransactionHistory';
