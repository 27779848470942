import { styled } from '../design-tokens';
import { Input } from '../input';
import { Select } from '../select';

export const AddressInputGrid = styled('div', {
  'display': 'grid',
  'width': '100%',
  'gap': '14px 20px',
  'gridTemplateAreas':
    '"unitNumber streetNumber" "streetName streetName" "suburb suburb" "state postcode" "toggleButton toggleButton"',
  'gridTemplateColumns': '1fr 1fr',

  '@sm': {
    gridTemplateAreas:
      '"unitNumber streetNumber streetNumber" "streetName streetName streetName" "suburb state postcode" "toggleButton toggleButton toggleButton"',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

export const UnitNumberInput = styled(Input, {
  gridArea: 'unitNumber',
});

export const StreetNumberInput = styled(Input, {
  gridArea: 'streetNumber',
});

export const StreetNameInput = styled(Input, {
  gridArea: 'streetName',
});

export const SuburbInput = styled(Input, {
  gridArea: 'suburb',
});

export const StateSelect = styled(Select, {
  gridArea: 'state',
});

export const PostcodeInput = styled(Input, {
  gridArea: 'postcode',
});

export const ToggleButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  flexShrink: 'none',
  fontSize: '14px',
  color: '$blue',
  cursor: 'pointer',
  gridArea: 'toggleButton',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});
