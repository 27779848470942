import { forwardRef, useCallback, useMemo } from 'react';

import type { CSS } from '../design-tokens';
import { Flex } from '../flex';
import { Grid } from '../grid';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { Tooltip } from '../tooltip';
import {
  DefaultProgressFooter,
  DefaultProgressHeader,
  DefaultProgressLabel,
  ProgressIndicator,
  ProgressRoot,
  RestProgressIndicator,
} from './_Base';

export type ProgressColor = 'blue' | 'green' | 'pink' | 'grey' | 'midGrey' | 'darkGrey';

export interface ProgressProps extends BaseProps {
  compact?: boolean;
  /** Value of progress */
  value: number;
  /** Primary color for progress bar */
  color?: ProgressColor;
  /** Secondary color for progress bar */
  twoToneColor?: ProgressColor;
  /** Gap of two indicators */
  gap?: string | number;
  /** Additional label node */
  label?: React.ReactNode;
  /** Additional header node */
  header?: React.ReactNode;
  /** Additional footer node */
  footer?: React.ReactNode;
  /** If the process disabled or not */
  disabled?: boolean;
  /** Hide the progress or not */
  hidden?: boolean;
  /** For modify progress indicator styles */
  progressStyle?: CSS;
  /** linked value of progress */
  linkedValue?: number;
  linkedStyle?: CSS;
  linkedValueTips?: React.ReactNode;
  unlinkedValueTips?: React.ReactNode;
  restValueTips?: React.ReactNode;
  hideProgress?: boolean;
  twoToneRemainValue?: number;
}

export const Progress = forwardRef<HTMLDivElement, ProgressProps>((props, ref) => {
  const {
    css,
    value,
    color = 'blue',
    compact,
    twoToneColor,
    gap = '6px',
    label = null,
    header = null,
    footer = null,
    disabled,
    hidden,
    progressStyle,
    linkedValue,
    linkedStyle,
    linkedValueTips,
    unlinkedValueTips,
    restValueTips,
    hideProgress,
    twoToneRemainValue = 0,
    ...restProps
  } = props;

  const [leftLabel, rightLabel] = Array.isArray(label)
    ? (label as React.ReactNode[])
    : [label, null];

  const [leftHeader, rightHeader] = Array.isArray(header)
    ? (header as React.ReactNode[])
    : [header, null];

  const [leftFooter, rightFooter] = Array.isArray(footer)
    ? (footer as React.ReactNode[])
    : [footer, null];

  const getIndicatorComponent = useCallback(
    (color: ProgressColor, tips?: React.ReactNode) => {
      return restValueTips ? (
        <Tooltip title={restValueTips} delayDuration={0}>
          <ProgressIndicator
            color={color}
            rounded={false}
            disabled={disabled}
            css={progressStyle}
            hoverable
          />
        </Tooltip>
      ) : (
        <ProgressIndicator color={color} rounded={false} disabled={disabled} css={progressStyle} />
      );
    },
    [disabled, progressStyle, restValueTips],
  );

  const progressComponent = useMemo(() => {
    if (hideProgress) {
      return null;
    }

    if (linkedValue !== undefined && twoToneColor) {
      return (
        <ProgressRoot value={value} transparent={Boolean(twoToneColor)} compact={compact}>
          <Grid
            fullHeight
            columns={3}
            css={{
              columnGap: [0, 100].includes(value) ? 0 : gap,
              gridTemplateColumns: `${linkedValue}fr ${value - linkedValue}fr ${100 - value}fr`,
            }}
          >
            {getIndicatorComponent(color, linkedValueTips)}
            {unlinkedValueTips ? (
              <Tooltip title={unlinkedValueTips} delayDuration={0}>
                <RestProgressIndicator
                  color={color}
                  disabled={disabled}
                  hoverable
                  css={linkedStyle}
                />
              </Tooltip>
            ) : (
              <RestProgressIndicator color={color} css={linkedStyle} />
            )}
            {twoToneRemainValue ? (
              <Grid
                fullHeight
                columns={2}
                css={{
                  columnGap: '3px',
                  gridTemplateColumns: `${
                    100 - value - twoToneRemainValue
                  }fr ${twoToneRemainValue}fr`,
                }}
              >
                <ProgressIndicator
                  color={twoToneColor}
                  rounded={false}
                  disabled={disabled}
                  css={progressStyle}
                />
                <ProgressIndicator
                  rounded={false}
                  color={twoToneColor}
                  disabled={disabled}
                  css={progressStyle}
                />
              </Grid>
            ) : (
              getIndicatorComponent(twoToneColor, restValueTips)
            )}
          </Grid>
        </ProgressRoot>
      );
    }

    return (
      <ProgressRoot value={value} transparent={Boolean(twoToneColor)} compact={compact} css={css}>
        {twoToneColor ? (
          <Grid
            fullHeight
            columns={2}
            css={{
              columnGap: [0, 100].includes(value) ? 0 : gap,
              gridTemplateColumns: `${value}fr ${100 - value}fr`,
            }}
          >
            <ProgressIndicator
              color={color}
              rounded={false}
              disabled={disabled}
              css={progressStyle}
            />
            <ProgressIndicator
              rounded={false}
              color={twoToneColor}
              disabled={disabled}
              css={progressStyle}
            />
          </Grid>
        ) : (
          <ProgressIndicator
            color={color}
            disabled={disabled}
            css={{
              transform: `translateX(-${100 - value}%)`,
              ...progressStyle,
            }}
          />
        )}
      </ProgressRoot>
    );
  }, [
    hideProgress,
    linkedValue,
    twoToneColor,
    value,
    compact,
    css,
    gap,
    color,
    disabled,
    progressStyle,
    getIndicatorComponent,
    linkedValueTips,
    unlinkedValueTips,
    linkedStyle,
    twoToneRemainValue,
    restValueTips,
  ]);

  if (hidden) {
    return null;
  }

  return (
    <Space ref={ref} size={4} direction="vertical" inline={false} {...restProps}>
      {Array.isArray(label) ? (
        <Flex align="center" justify="between" fullWidth>
          <DefaultProgressLabel disabled={disabled}>{leftLabel}</DefaultProgressLabel>
          <DefaultProgressLabel disabled={disabled}>{rightLabel}</DefaultProgressLabel>
        </Flex>
      ) : (
        label
      )}
      <Space size={6} direction="vertical" inline={false}>
        {Array.isArray(header) ? (
          <Flex align="center" justify="between" fullWidth>
            <DefaultProgressHeader disabled={disabled}>{leftHeader}</DefaultProgressHeader>
            <DefaultProgressHeader disabled={disabled}>{rightHeader}</DefaultProgressHeader>
          </Flex>
        ) : (
          header
        )}
        {progressComponent}
        {Array.isArray(footer) ? (
          <Flex align="center" justify="between" fullWidth>
            <DefaultProgressFooter disabled={disabled}>{leftFooter}</DefaultProgressFooter>
            <DefaultProgressFooter disabled={disabled}>{rightFooter}</DefaultProgressFooter>
          </Flex>
        ) : (
          footer
        )}
      </Space>
    </Space>
  );
});

Progress.displayName = 'Progress';
