import isNil from 'lodash/isNil';

/**
 * Remove type error when using filter
 *
 * @param item - Item to filter
 * @returns Filtered item
 */
export function isNotNil<T>(item?: T | null): item is T {
  return !isNil(item);
}
