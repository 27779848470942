import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { SwitchLabel, SwitchRoot, SwitchThumb } from './_Base';

export const defaultSwitchProps = {};

export interface SwitchProps extends BaseProps {
  name: string;
  value?: boolean;
  defaultValue?: boolean;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  reversed?: boolean;
  error?: boolean;
  hidden?: boolean;
  onChange?: (checked: boolean) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
  const {
    name,
    value,
    defaultValue,
    label,
    disabled,
    required,
    reversed,
    error,
    hidden,
    onChange,
    onBlur,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <Space align="center" reversed={reversed} {...restProps}>
      <SwitchRoot
        ref={ref}
        id={name}
        name={name}
        checked={value}
        defaultChecked={defaultValue}
        disabled={disabled}
        required={required}
        onCheckedChange={onChange}
        onBlur={onBlur}
      >
        <SwitchThumb />
      </SwitchRoot>
      <SwitchLabel htmlFor={name}>{label}</SwitchLabel>
    </Space>
  );
});

Switch.displayName = 'Switch';
