import { SVGIcon } from '@paid-ui/icons';

import { spin, styled } from '../design-tokens';

export const PreviewGridLabel = styled('h4', {
  'color': '$fg',
  'fontSize': '18px',
  'lineHeight': '25px',
  'fontWeight': '$semibold',

  '&:empty': {
    display: 'none',
  },
});

export const ImageGrid = styled('div', {
  'display': 'grid',
  'boxSizing': 'border-box',
  'width': '100%',
  'gridTemplateColumns': 'repeat(2, minmax(0, 1fr))',
  'gridTemplateRows': '1fr',
  'gap': '14px',

  '@sm': {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: '16px',
  },
});

export const ImageContainer = styled('div', {
  position: 'relative',
  width: '100%',
  aspectRatio: '1 / 1',
  overflow: 'hidden',
  borderRadius: '6px',
  backgroundColor: '$fgFaint',
  userSelect: 'none',
  cursor: 'pointer',
});

export const Image = styled('img', {
  display: 'block',
  size: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  objectPosition: 'center',
  zIndex: -1,
});

export const Video = styled('video', {
  display: 'block',
  size: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
  objectPosition: 'center',
  zIndex: -1,
});

export const ActionButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'absolute',
  top: '4px',
  borderRadius: '4px',
  right: '4px',
  size: '32px',
  padding: '7px',
  backgroundColor: 'rgb(0 16 38 / 35%)',
  backdropFilter: 'blur(7px)',
  cursor: 'pointer',
  zIndex: 1,

  variants: {
    loading: {
      true: {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ActionIcon = styled(SVGIcon, {
  size: '18px',
  color: 'rgb(255 255 255 / 60%)',

  variants: {
    loading: {
      true: {
        animation: `${spin} 1s linear infinite`,
      },
    },
  },
});

export const VideoIcon = styled(SVGIcon, {
  position: 'absolute',
  width: '24px',
  height: '24px',
  color: '$bg',
  top: '8px',
  left: '8px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const GeoInfo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  padding: '6px 10px',
  backgroundColor: 'rgb(0 16 38 / 60%)',
  // Backdrop filter conflict with using Tooltip component
  // backdropFilter: 'blur(7px)',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const GeoInfoIcon = styled(SVGIcon, {
  size: '18px',
  flexShrink: 0,

  variants: {
    color: {
      pink: {
        color: '$pink',
      },

      amber: {
        color: '#ff9500',
      },

      green: {
        color: '$payInGreen',
      },
    },
  },

  defaultVariants: {
    color: 'pink',
  },
});

export const GeoInfoText = styled('span', {
  color: '$bg',
  fontSize: '12px',
  fontWeight: '$medium',
  lineHeight: '16px',
  ellipsis: 'auto',
});
