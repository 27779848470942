import '@paid-ui/styles/globals.css';

import { LeftHub } from '@paid-ui/blocks/left-hub';
import { ReactQueryProvider } from '@paid-ui/components/react-query';
import { CountryCode } from '@paid-ui/enums/address';
import { browserEnvs } from '@paid-ui/env';
import { SVGSpriteProvider } from '@paid-ui/icons';
import { saveBrowserEnv } from '@paid-ui/models/app-config';
import { useGetUserProfile } from '@paid-ui/queries/user-profile';
import { ToastProvider, TooltipProvider } from '@paid-ui/ui';
import { initDayjs } from '@paid-ui/utils/datetime';
import { APIProvider } from '@vis.gl/react-google-maps';
import { detect } from 'detect-browser';
import type { NextPageWithAddons } from 'next';
import type { AppProps as BaseAppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { useEffect } from 'react';

const { env, title, description, languageCode, googleMapsApiKey } = browserEnvs;

initDayjs(languageCode);

const Auth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data } = useGetUserProfile();
  return data ? <>{children}</> : null;
};

type AppProps = BaseAppProps & {
  Component: NextPageWithAddons;
};

const App = (props: AppProps) => {
  const { Component, ...pageProps } = props;
  const getLayout = Component.layout ?? ((page) => page);

  useEffect(() => {
    const { name, os } = detect() ?? { name: null, os: null };
    saveBrowserEnv({ name, os });
  }, []);

  return (
    <>
      <DefaultSeo defaultTitle={title} titleTemplate={`%s - ${title}`} description={description} />
      <ReactQueryProvider>
        <SVGSpriteProvider>
          <TooltipProvider>
            <ToastProvider duration={5000}>
              <APIProvider
                apiKey={googleMapsApiKey}
                region={CountryCode.AU}
                language="en-AU"
                libraries={['places']}
                onLoad={() => console.log('🌏 Google Maps Places API Initialized')}
              >
                <LeftHub env={env}>{env}</LeftHub>
                {Component.auth ? (
                  <Auth>{getLayout(<Component {...pageProps} />)}</Auth>
                ) : (
                  getLayout(<Component {...pageProps} />)
                )}
              </APIProvider>
            </ToastProvider>
          </TooltipProvider>
        </SVGSpriteProvider>
      </ReactQueryProvider>
    </>
  );
};

export default App;
