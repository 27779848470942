export enum BusinessType {
  OWNER = 'OWNER',
  BUILDER = 'BUILDER',
  SUPPLIER = 'SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPERINTENDENT = 'SUPERINTENDENT',
}

export enum TrustType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum BusinessStructure {
  COMPANY = 'COMPANY',
  SOLE_TRADER = 'SOLE_TRADER',
  PARTNERSHIP = 'PARTNERSHIP',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}
