import { forwardRef, useCallback } from 'react';

import type { BaseProps } from '../interfaces';
import { PaymentsProgressContainer, PaymentsProgressStage, TargetIcon } from './_Base';

export const defaultPaymentsProgressProps = {
  claimed: 0,
  total: 6,
  showArrow: true,
  paidIndexList: [],
};

export interface PaymentsProgressProps extends BaseProps {
  total: number;
  claimed?: number;
  showArrow?: boolean;
  paidIndexList?: number[];
}

export const PaymentsProgress = forwardRef<HTMLDivElement, PaymentsProgressProps>((props, ref) => {
  const {
    claimed = defaultPaymentsProgressProps.claimed,
    total = defaultPaymentsProgressProps.total,
    showArrow = defaultPaymentsProgressProps.showArrow,
    paidIndexList = defaultPaymentsProgressProps.paidIndexList,
    ...restProps
  } = props;

  const checkIsPaid = useCallback(
    (pos: number) => {
      if (claimed) {
        return claimed > pos;
      }
      return paidIndexList.includes(pos);
    },
    [claimed, paidIndexList],
  );

  return (
    <PaymentsProgressContainer ref={ref} {...restProps}>
      {Array.from({ length: total }).map((_, index) => (
        <PaymentsProgressStage
          key={index}
          paid={checkIsPaid(index)}
          css={{ width: `${(1 / total) * 100}%` }}
        >
          {claimed === index && showArrow ? (
            <>
              <TargetIcon name="target-stage" position="top" />
              <TargetIcon name="target-stage" position="bottom" />
            </>
          ) : null}
        </PaymentsProgressStage>
      ))}
    </PaymentsProgressContainer>
  );
});

PaymentsProgress.displayName = 'PaymentsProgress';
