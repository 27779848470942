import { EvidenceCategory } from '@paid-ui/constants';
import { type Attachment } from '@paid-ui/schemas/zod/attachment';
import type { Evidence } from '@paid-ui/types';
import { formatGeolocationInfo } from '@paid-ui/utils';
import { utc2Mel } from '@paid-ui/utils/datetime';
import Mousetrap from 'mousetrap';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PrismaZoom from 'react-prismazoom';
import { useImmer } from 'use-immer';

import { Dialog, type DialogProps } from '../dialog';
import { Space } from '../space';
import { Spinner } from '../spinner';
import {
  CalendarIcon,
  Caption,
  GeoIcon,
  Image,
  ImageItem,
  ImageList,
  PreviewClose,
  PreviewCloseButton,
  PreviewContainer,
  PreviewFooter,
  PreviewIcon,
  PreviewNext,
  PreviewPrev,
  PreviewZoomIn,
  PreviewZoomOut,
  Video,
} from './_Base';

interface PrismaZoomRefObject {
  getZoom: () => number;
  zoomIn: (zoom: number) => void;
  zoomOut: (zoom: number) => void;
  move: (shiftX: number, shiftY: number, transitionDuration?: number) => void;
  reset: () => void;
  zoomToZone: (relX: number, relY: number, relWidth: number, relHeight: number) => void;
}

export const defaultImagePreviewProps = {
  imageIndex: 0,
};

export interface ImagePreviewProps extends Omit<DialogProps, 'fullScreen' | 'nested'> {
  data: Attachment[] | Evidence[];
  imageIndex?: number;
}

export const ImagePreview = forwardRef<HTMLDivElement, ImagePreviewProps>((props, ref) => {
  const {
    data,
    imageIndex: defaultImageIndex = defaultImagePreviewProps.imageIndex,
    open,
    onOpenChange,
    ...restProps
  } = props;

  const evidences = (data as Evidence[]).filter((v) => v.category !== EvidenceCategory.DOCUMENT);
  const [loading, setLoading] = useImmer(evidences.map((v) => true));
  const [imageIndex, setImageIndex] = useState(defaultImageIndex);
  const videoRefs = useRef<Array<HTMLVideoElement | null>>(evidences.map((v) => null));
  const prismaZoomRefs = useRef<Array<PrismaZoomRefObject | null>>(evidences.map((v) => null));
  const scrollBehaviorRef = useRef<ScrollBehavior>('smooth');

  const isFirstImage = useMemo(() => {
    return imageIndex === 0;
  }, [imageIndex]);

  const isLastImage = useMemo(() => {
    return imageIndex === evidences.length - 1;
  }, [evidences.length, imageIndex]);

  const selectedEvidence = useMemo(() => {
    return evidences[imageIndex];
  }, [evidences, imageIndex]);

  const geoLocationInfo = useMemo(() => {
    return formatGeolocationInfo(selectedEvidence);
  }, [selectedEvidence]);

  const formattedDatetime = useMemo(() => {
    const timestamp = evidences[imageIndex]?.geolocation?.timestamp;
    return timestamp ? utc2Mel(timestamp).format('DD MMM YYYY · hh:mm a') : undefined;
  }, [evidences, imageIndex]);

  const handleImageLoaded = useCallback(
    (index: number) => {
      setLoading((draft) => {
        draft[index] = false;
      });
    },
    [setLoading],
  );

  const handlePrev = useCallback(() => {
    const prismaZoom = prismaZoomRefs.current?.[imageIndex];

    setImageIndex((prevState) => Math.max(0, prevState - 1));

    if (prismaZoom && prismaZoom.getZoom() > 1) {
      prismaZoom.reset();
    }
  }, [imageIndex]);

  const handleNext = useCallback(() => {
    const prismaZoom = prismaZoomRefs.current?.[imageIndex];

    setImageIndex((prevState) => Math.min(evidences.length - 1, prevState + 1));

    if (prismaZoom && prismaZoom.getZoom() > 1) {
      prismaZoom.reset();
    }
  }, [evidences.length, imageIndex]);

  const handleZoomIn = useCallback(() => {
    const prismaZoom = prismaZoomRefs.current?.[imageIndex];

    if (prismaZoom) {
      prismaZoom.zoomIn(1);
    }
  }, [imageIndex]);

  const handleZoomOut = useCallback(() => {
    const prismaZoom = prismaZoomRefs.current?.[imageIndex];

    if (prismaZoom) {
      prismaZoom.zoomOut(1);
    }
  }, [imageIndex]);

  const handlePlay = useCallback(() => {
    const currentVideo = videoRefs.current[imageIndex];

    if (currentVideo) {
      currentVideo.paused ? currentVideo.play() : currentVideo.pause();
    }
  }, [imageIndex]);

  const handleOpenChange = useCallback(
    (value: boolean) => {
      if (value) {
        setTimeout(() => {
          document.getElementById(`slide-${imageIndex}`)?.scrollIntoView();
        }, 10);
      } else {
        setImageIndex(defaultImageIndex);
        videoRefs.current.forEach((video) => video?.pause());
        prismaZoomRefs.current.forEach((image) => image?.reset());
      }

      if (typeof onOpenChange === 'function') {
        onOpenChange(value);
      }
    },
    [defaultImageIndex, imageIndex, onOpenChange],
  );

  useEffect(() => {
    Mousetrap.bind('space', handlePlay);
    Mousetrap.bind('left', handlePrev);
    Mousetrap.bind('right', handleNext);

    return () => {
      Mousetrap.unbind('space');
      Mousetrap.unbind('left');
      Mousetrap.unbind('right');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidences.length, imageIndex]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(`slide-${imageIndex}`)?.scrollIntoView({
        behavior: scrollBehaviorRef.current,
      });
      scrollBehaviorRef.current = 'smooth';
    }, 250);

    const currentVideo = videoRefs.current[imageIndex];

    if (!currentVideo) {
      videoRefs.current.forEach((video) => video?.pause());
    }
  }, [imageIndex]);

  useEffect(() => {
    if (open) {
      setImageIndex(defaultImageIndex);
      scrollBehaviorRef.current = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultImageIndex]);

  return (
    <Dialog {...restProps} ref={ref} open={open} onOpenChange={handleOpenChange} fullScreen nested>
      <PreviewContainer>
        <ImageList>
          {evidences.map((v, i) => (
            <ImageItem key={i} id={`slide-${i}`}>
              {v.category === 'VIDEO' ? (
                <Video
                  controls
                  preload="auto"
                  src={v.tempFileAddress}
                  onProgress={() => handleImageLoaded(i)}
                  hidden={loading[i]}
                  ref={(item) => {
                    videoRefs.current[i] = item;
                  }}
                />
              ) : (
                <PrismaZoom
                  ref={(item) => {
                    prismaZoomRefs.current[i] = item;
                  }}
                >
                  <Image
                    src={v.tempFileAddress}
                    alt={v.fileName}
                    onLoad={() => handleImageLoaded(i)}
                    hidden={loading[i]}
                  />
                </PrismaZoom>
              )}
              {loading[i] ? <Spinner size="lg" /> : null}
            </ImageItem>
          ))}
        </ImageList>
        <PreviewFooter>
          <Space align="center" size={10}>
            <GeoIcon name="location" color={geoLocationInfo.color} />
            <Caption>{geoLocationInfo.text}</Caption>
          </Space>
          {formattedDatetime ? (
            <Space align="center" size={10}>
              <CalendarIcon name="datetime" />
              <Caption>{formattedDatetime}</Caption>
            </Space>
          ) : null}
        </PreviewFooter>
      </PreviewContainer>
      <PreviewZoomIn type="button" onClick={handleZoomIn}>
        <PreviewIcon name="zoom-in" />
      </PreviewZoomIn>
      <PreviewZoomOut type="button" onClick={handleZoomOut}>
        <PreviewIcon name="zoom-out" />
      </PreviewZoomOut>
      <PreviewClose asChild>
        <PreviewCloseButton type="button">
          <PreviewIcon name="close" />
        </PreviewCloseButton>
      </PreviewClose>
      <PreviewPrev type="button" onClick={handlePrev} hidden={isFirstImage}>
        <PreviewIcon name="previous" />
      </PreviewPrev>
      <PreviewNext type="button" onClick={handleNext} hidden={isLastImage}>
        <PreviewIcon name="next" />
      </PreviewNext>
    </Dialog>
  );
});

ImagePreview.displayName = 'ImagePreview';
