export enum BackgroundCheckStatus {
  REQUESTED = 'REQUESTED',
  PASS = 'PASS',
  FAIL = 'FAIL',
  RESULT_READY = 'RESULT_READY',
  UNKNOWN = 'UNKNOWN',
}

export enum TransactionAccountMatcherType {
  MAIN = 'MAIN',
  RETENTION = 'RETENTION',
}
