import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useRouter } from 'next/router';
import { forwardRef, useCallback } from 'react';

import type { BaseProps } from '../interfaces';
import { StyledNavbarMenu, StyledNavbarMenuItem } from './_Base';

export interface NavbarMenuItem {
  key: string;
  text: string;
  href: string;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

interface NavbarMenuProps extends BaseProps {
  data?: NavbarMenuItem[];
}

export const NavbarMenu = forwardRef<HTMLElement, NavbarMenuProps>((props, ref) => {
  const router = useRouter();
  const { data = [], ...restProps } = props;

  const handleNavebarMenuClick = useCallback(
    (item: NavbarMenuItem) => {
      if (item.disabled) {
        return;
      }

      router.push(item.href);
    },
    [router],
  );

  return (
    <StyledNavbarMenu ref={ref} {...restProps}>
      <VisuallyHidden>Primary Navigation</VisuallyHidden>
      {data
        .filter((item) => !item.hidden)
        .map((item) => (
          <StyledNavbarMenuItem
            key={item.key}
            active={item.active}
            disabled={item.disabled}
            onClick={() => handleNavebarMenuClick(item)}
          >
            {item.text}
          </StyledNavbarMenuItem>
        ))}
    </StyledNavbarMenu>
  );
});

NavbarMenu.displayName = 'NavbarMenu';
