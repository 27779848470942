import { slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledInput = styled('input', {
  'appearance': 'none',
  'position': 'relative',
  'boxSizing': 'border-box',
  'padding': '10px 12px',
  'borderWidth': 0,
  'borderRadius': '4px',
  'fontFamily': 'inherit',
  'fontSize': '16px',
  'lineHeight': 1.35,
  'letterSpacing': '0.025em',
  'color': '$fg',
  'backgroundColor': '$bg',
  'boxShadow': `inset 0 0 0 1px $colors$midGrey`,
  'cursor': 'default',
  'outline': 'none',
  'fontVariantNumeric': 'tabular-nums',
  'webkitTapHighlightColor': 'transparent',

  '&:focus': {
    boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
    cursor: 'text',
  },

  '&::placeholder': {
    color: slate.slate9,
    lineHeight: 1.35,
  },

  '&:disabled': {
    'backgroundColor': slate.slate2,
    'color': slate.slate8,
    'cursor': 'not-allowed',
    'pointerEvents': 'none',

    '&::placeholder': {
      color: slate.slate7,
    },
  },

  '&:read-only': {
    'backgroundColor': slate.slate2,

    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${slate.slate7}`,
    },
  },

  'variants': {
    block: {
      true: {
        width: '100%',
      },

      false: {
        width: 'auto',
      },
    },

    ghost: {
      true: {
        'backgroundColor': slate.slate2,
        'boxShadow': 'none',

        '&:focus': {
          backgroundColor: '$bg',
          boxShadow: 'inset 0 0 0 1px $colors$blue, 0 0 0 1px $colors$blue',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
        },

        '&:read-only': {
          backgroundColor: 'transparent',
        },
      },
    },

    invalid: {
      true: {
        'boxShadow': 'inset 0 0 0 1px $colors$pink',

        '&:focus': {
          boxShadow: 'inset 0 0 0 1px $colors$pink, 0 0 0 1px $colors$pink',
        },
      },
    },

    size: {
      small: {
        fontSize: '14px',
      },

      medium: {
        height: '42px',
        fontSize: '16px',
      },

      large: {
        height: '56px',
        fontSize: '16px',
      },
    },

    align: {
      left: {
        textAlign: 'left',
      },

      center: {
        textAlign: 'center',
      },

      right: {
        textAlign: 'right',
      },
    },
  },

  'defaultvariants': {
    block: false,
    ghost: false,
    invalid: false,
  },
});

export const TrailingUnit = styled('span', {
  color: slate.slate10,
  userSelect: 'none',
});
