import { type AddressState } from '@paid-ui/enums/address';
import {
  type FeatureDeployStatus,
  type FeatureGroup,
  type FeatureItem,
  type FeatureToggleStatus,
} from '@paid-ui/enums/feature';
import { browserEnvs } from '@paid-ui/env';
import { utc2MelStr } from '@paid-ui/utils/datetime';
import type { Browser, OperatingSystem } from 'detect-browser';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

const { title, description, maxFileSize, env } = browserEnvs;

export type Announcement = {
  type: 'MAINTENANCE';
  message: string;
  startDate: string;
  endDate: string;
};

export type Feature = {
  name: FeatureItem;
  displayName: string;
  description?: string;
  featureGroup: FeatureGroup;
  toggleStatus: FeatureToggleStatus;
  deployStatus: FeatureDeployStatus;
  active: boolean;
  userSubscribable: boolean;
  subscribed?: boolean;
};

export type StateDepositInsuranceDisplay = {
  displayName: string;
};

export type RemoteConfig = {
  announcements: Announcement[];
  configs: {
    EARLY_RELEASE_MIN_AMOUNT: number;
    EARLY_RELEASE_MIN_DISCOUNT_AMOUNT: number;
  };
  features: Record<FeatureItem, Feature>;
  fees: {
    EARLY_RELEASE_DISCOUNT: number;
  };
  gstRate: number;
  stateDepositInsurances?: Record<AddressState, StateDepositInsuranceDisplay>;
};

export const appConfigManager = proxy({
  title,
  description,
  maxFileSize,
  os: null as OperatingSystem | NodeJS.Platform | null,
  browser: null as Browser | 'bot' | 'node' | 'react-native' | null,
  env,
  mapId: '7050deb153077aa7',

  // Remote app config
  announcements: [] as Announcement[],
  earlyRelease: {
    discountFeeRate: 0.2,
    discountRatePrecision: 10,
    minAmount: 100,
    minDiscountAmount: 1,
  },
  gstRate: 10,
  stateDepositInsurances: undefined as
    | Record<AddressState, StateDepositInsuranceDisplay>
    | undefined,
});

devtools(appConfigManager, {
  name: 'App Config',
  enabled: false,
});

type BrowserEnv = {
  name: Browser | 'bot' | 'node' | 'react-native' | null;
  os: OperatingSystem | NodeJS.Platform | null;
};

export const saveBrowserEnv = (context: BrowserEnv) => {
  appConfigManager.browser = context.name;
  appConfigManager.os = context.os;
};

export const saveRemoteConfig = (remoteConfig: RemoteConfig) => {
  const { announcements, configs, fees, gstRate, stateDepositInsurances } = remoteConfig;
  const { EARLY_RELEASE_MIN_AMOUNT, EARLY_RELEASE_MIN_DISCOUNT_AMOUNT } = configs;
  const { EARLY_RELEASE_DISCOUNT } = fees;

  appConfigManager.announcements = announcements;
  appConfigManager.gstRate = gstRate;
  appConfigManager.stateDepositInsurances = stateDepositInsurances;
  appConfigManager.earlyRelease.discountFeeRate = Number(EARLY_RELEASE_DISCOUNT);
  appConfigManager.earlyRelease.minAmount = Number(EARLY_RELEASE_MIN_AMOUNT);
  appConfigManager.earlyRelease.minDiscountAmount = Number(EARLY_RELEASE_MIN_DISCOUNT_AMOUNT);
};

export const getMaintenance = (announcements: Announcement[]) => {
  const maintenance = announcements.find(({ type }) => type === 'MAINTENANCE');
  return maintenance
    ? {
        type: 'MAINTENANCE',
        message: maintenance.message,
        startDate: utc2MelStr(maintenance.startDate, 'D MMM YYYY'),
        endDate: utc2MelStr(maintenance.endDate, 'D MMM YYYY'),
      }
    : null;
};
