import { forwardRef } from 'react';

import { type AvatarSize, type AvatarVariant } from '../avatar';
import type { BaseProps } from '../interfaces';
import { StyledSkeleton } from './_Base';

export type SkeletonType = 'avatar' | 'rect';

export const defaultSkeletonProps = {
  type: 'rect' as SkeletonType,
  size: 'medium' as AvatarSize,
  variant: 'rounded' as AvatarVariant,
  width: '100%',
  height: '4px',
};

export interface SkeletonProps extends BaseProps {
  type?: SkeletonType;
  size?: AvatarSize;
  variant?: AvatarVariant;
  width?: string | number;
  height?: string | number;
}
export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>((props, ref) => {
  const {
    type = defaultSkeletonProps.type,
    size = defaultSkeletonProps.size,
    variant = defaultSkeletonProps.variant,
    width = defaultSkeletonProps.width,
    height = defaultSkeletonProps.height,
    css,
    ...restProps
  } = props;

  if (type === 'avatar') {
    return <StyledSkeleton ref={ref} size={size} variant={variant} css={css} {...restProps} />;
  }

  return (
    <StyledSkeleton
      ref={ref}
      css={{
        ...css,
        width,
        height,
      }}
      {...restProps}
    />
  );
});

Skeleton.displayName = 'Skeleton';
