import { useEffect, useRef } from 'react';

import { Flex } from '../flex';
import { LoadingIndicator } from '../loading-indicator';
import { EmptyText } from './_Base';

export const defaultInfiniteScrollProps = {
  offset: 0,
  limit: 50,
  total: 0,
  loading: true,
  loadingText: 'Loading...',
  emptyText: 'No more data',
};

export interface InfiniteScrollProps {
  offset?: number;
  limit?: number;
  total?: number;
  isEnding?: boolean;
  loading?: boolean;
  loadingText?: string;
  emptyText?: string;
  setLoading?: (loading: boolean) => void;
  query: (value: { offset: number; limit: number }) => void;
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = (props) => {
  const loadingIndicatorRef = useRef<HTMLDivElement | null>(null);
  const {
    offset = defaultInfiniteScrollProps.offset,
    limit = defaultInfiniteScrollProps.limit,
    isEnding,
    loading = defaultInfiniteScrollProps.loading,
    loadingText = defaultInfiniteScrollProps.loadingText,
    emptyText = defaultInfiniteScrollProps.emptyText,
    setLoading,
    query,
  } = props;

  useEffect(() => {
    const loadingIndicator = loadingIndicatorRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (offset && !loading && entry && entry.isIntersecting) {
          setLoading?.(true);
          query({
            offset,
            limit,
          });
        }
      },
      {
        threshold: [1],
      },
    );

    if (loadingIndicator) {
      observer.observe(loadingIndicator);
    }

    return () => {
      if (loadingIndicator) {
        observer.unobserve(loadingIndicator);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  if (isEnding) {
    return (
      <Flex align="center" justify="center">
        <EmptyText>{emptyText}</EmptyText>
      </Flex>
    );
  }

  return (
    <Flex ref={loadingIndicatorRef} align="center" justify="center">
      {loading ? (
        <LoadingIndicator
          title={loadingText}
          size="sm"
          css={{
            color: '$blue',
          }}
        />
      ) : (
        <EmptyText>{emptyText}</EmptyText>
      )}
    </Flex>
  );
};

InfiniteScroll.displayName = 'InfiniteScroll';
