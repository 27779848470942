import type { Evidence as IEvidence } from '@paid-ui/schemas/zod/evidence';
import type { Evidence } from '@paid-ui/types/evidences';

export type GeolocationResult = {
  text: string;
  color: 'pink' | 'amber' | 'green';
};

/**
 * Format geolocation info from file.
 *
 * @param file - Evidence file.
 * @param threshold - Threshold distance (m) from project address.
 * @returns Formatted geolocation result.
 */
export function formatGeolocationInfo(file?: Evidence, threshold = 100): GeolocationResult {
  const { geolocatable, distanceFromProjectAddress } = file?.geolocation ?? {};

  if (!geolocatable) {
    return {
      text: 'No geolocation info',
      color: 'pink',
    };
  }

  if (!distanceFromProjectAddress) {
    return {
      text: 'Geolocated but site location unknown',
      color: 'pink',
    };
  }

  return {
    text:
      distanceFromProjectAddress >= 1000
        ? `Geolocated ${Math.round(distanceFromProjectAddress / 100) / 10}km from site`
        : `Geolocated ${Math.round(distanceFromProjectAddress)}m from site`,
    color: distanceFromProjectAddress > threshold ? 'amber' : 'green',
  };
}

/**
 * Get geolocation status text from evidence.
 *
 * @param file - Evidence file.
 * @returns Geolocation status text.
 */
export function getGeolocationStatusText(file?: IEvidence | null) {
  const {
    geolocatable,
    distanceFromProjectAddress,
    distanceThreshold = 50,
  } = file?.geolocation ?? {};
  const threshold = distanceThreshold ?? 50;

  if (!geolocatable || !distanceFromProjectAddress) {
    return {
      text: 'Unable to geolocate',
      color: 'text-pink',
    };
  }

  if (distanceFromProjectAddress > threshold) {
    const distance =
      distanceFromProjectAddress < 1000
        ? `${distanceFromProjectAddress}m`
        : `${Math.round(distanceFromProjectAddress / 100) / 10}km`;
    return {
      text: `${distance} from site`,
      color: 'text-amber',
    };
  }

  return {
    text: 'Geolocated at site',
    color: 'text-green',
  };
}
