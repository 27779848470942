import { SVGIcon } from '@paid-ui/icons';
import { slate } from '@radix-ui/colors';

import { Label } from '..';
import { Card } from '../card';
import { styled } from '../design-tokens';
import { Flex } from '../flex';

export const ActionButtons = styled(Flex, {
  'width': '100%',
  'gap': '4px',
  'opacity': 1,
  'transition': 'opacity 100ms',

  '@hover': {
    '&:hover': {
      opacity: 0,
    },
  },

  'variants': {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ActionWrap = styled('div', {
  'padding': '4px',
  '@hover': {
    '&:hover': {
      backgroundColor: '$bgGroup',
    },
  },
});

export const ActionIcon = styled(SVGIcon, {
  size: '24px',
  color: '$darkGrey',
});

export const StyledCard = styled(Card, {
  'display': 'flex',
  'flexDirection': 'column',
  'backgroundColor': '$bg',
  'border': 'none',
  '@hover': {
    [`&:hover ${ActionButtons}`]: {
      opacity: 1,
    },
  },
});

export const CardHeader = styled('header', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
  background: `$colors$lightGrey center no-repeat`,
  backgroundSize: 'cover',
  overflow: 'hidden',
  height: '105px',
});

export const CardContent = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'minHeight': '172px',
  'gap': '14px',
  'padding': '18px 15px',

  '@sm': {
    minHeight: '194px',
  },

  'variants': {
    isEmpty: {
      true: {
        justifyContent: 'space-between',
      },
    },
  },
});

export const HeaderLabel = styled('div', {
  position: 'absolute',
  bottom: '18px',
  left: '15px',
  fontSize: '22px',
  fontWeight: '$semibold',
  lineHeight: '27px',
  variants: {
    color: {
      grey: {
        color: '$darkGrey',
      },
      white: {
        color: '$bg',
      },
    },
  },
  defaultVariants: {
    color: 'white',
  },
});

export const CardTitle = styled('h3', {
  'fontSize': '16px',
  'fontWeight': '$medium',
  'lineHeight': '22px',
  'height': '44px',
  'lineClamp': 2,

  '@sm': {
    height: '66px',
    lineClamp: 3,
  },
});

export const ContractInfo = styled(Flex, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

export const ContractTypeText = styled('span', {
  flex: 1,
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: '$medium',
  color: slate.slate10,
});

export const CompletedOrRemainTime = styled('time', {
  flexShrink: 0,
  fontSize: '14px',
  fontWeight: '$medium',

  variants: {
    completed: {
      true: {
        color: '$bg',
        backgroundColor: '$payInGreen',
        padding: '4px 7px',
      },

      false: {
        color: slate.slate10,
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  defaultVariants: {
    completed: false,
  },
});

export const PaymentProgressContainer = styled('div', {
  diaply: 'contents',
  position: 'relative',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const ContractsAmountContainer = styled(Flex, {
  width: 'auto',
  position: 'absolute',
  padding: '2px 4px',
  border: '1px solid $colors$fgTertiary',
  borderRadius: '4px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  gap: '5px',
  alignItems: 'center',
  outline: '2px solid white',
  backgroundColor: 'white',

  variants: {
    completed: {
      true: {
        borderColor: '$payInGreen',
      },
    },
  },
});

export const ContractsAmountText = styled('span', {
  fontSize: '12px',
  display: 'inline-block',
  color: '$fgTertiary',

  variants: {
    completed: {
      true: {
        color: '$payInGreen',
      },
    },
  },
});
