import merge from 'lodash/merge';
import { forwardRef, useMemo } from 'react';

import { type CSS, spin, styled } from '../design-tokens';
import type { BaseProps } from '../interfaces';

const StyledSpinner = styled('div', {
  display: 'inline-block',
  animation: `${spin} 500ms linear infinite`,
  borderRadius: '$pill',
  fontSize: '1em',
  borderStyle: 'solid',
  userSelect: 'none',

  variants: {
    size: {
      xs: {
        width: '12px',
        height: '12px',
      },
      sm: {
        width: '16px',
        height: '16px',
      },
      md: {
        width: '24px',
        height: '24px',
      },
      lg: {
        width: '32px',
        height: '32px',
      },
      xl: {
        width: '48px',
        height: '48px',
      },
    },
  },
});

export interface SpinnerProps extends BaseProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number;
  color?: string;
  emptyColor?: string;
  thickness?: number | string;
  hidden?: boolean;
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  (
    {
      size = 'md',
      color = 'currentColor',
      emptyColor = 'transparent',
      thickness = '2px',
      hidden,
      css,
      ...restProps
    },
    ref,
  ) => {
    const spinnerCss = useMemo<CSS>(() => {
      return merge(css, {
        width: typeof size === 'number' ? `${size}px` : undefined,
        height: typeof size === 'number' ? `${size}px` : undefined,
        borderColor: color,
        borderBottomColor: emptyColor,
        borderLeftColor: emptyColor,
        borderWidth: thickness,
      });
    }, [color, css, emptyColor, size, thickness]);

    if (hidden) {
      return null;
    }

    return (
      <StyledSpinner
        ref={ref}
        size={typeof size === 'number' ? undefined : size}
        css={spinnerCss}
        {...restProps}
      />
    );
  },
);

Spinner.displayName = 'Spinner';
