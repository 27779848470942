import { SVGIcon } from '@paid-ui/icons';
import { red, slate } from '@radix-ui/colors';

import { styled } from '../design-tokens';

export const StyledCountIndicator = styled('span', {
  'display': 'inline-flex',
  'flexShrink': 0,
  'alignItems': 'center',
  'justifyContent': 'center',
  'width': 'fit-content',
  'height': '24px',
  'minWidth': '20px',
  'padding': '4px 6px',
  'borderRadius': '4px',
  'color': '$bg',
  'fontSize': '12px',
  'lineHeight': '16px',
  'fontWeight': '$medium',
  'boxShadow': '0 0 0 1px white',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    color: {
      primary: {
        backgroundColor: '$pink',
      },

      secondary: {
        backgroundColor: slate.slate9,
      },
    },
  },

  'defaultVariants': {
    color: 'primary',
  },
});

export const NotificationIcon = styled(SVGIcon, {
  size: '16px',
  flexShrink: 0,
  marginRight: '4px',
});
