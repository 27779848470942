import { type SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import { Button, type ButtonProps } from '../button';
import type { BaseProps } from '../interfaces';
import {
  ActionGroup,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogIcon,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
  DialogClose,
  DialogCloseButton,
  DialogCloseIcon,
} from './_Base';

const preventDefault = (event: any) => event.preventDefault();

export interface ConfirmDialogProps extends BaseProps {
  /** Dialog trigger element */
  trigger?: React.ReactNode;
  /** SVG used for dialog */
  icon?: SVGIconName;
  /** Title of alert dialog */
  title: React.ReactNode;
  /** Description of alert dialog */
  description?: React.ReactNode;
  /** Align center of content */
  center?: boolean;
  /** Text of confirm button */
  okButtonText?: string;
  /** Props of confirm button */
  okButtonProps?: ButtonProps;
  /** Text of cancel button */
  cancelButtonText?: string;
  /** Props of cancel button */
  cancelButtonProps?: ButtonProps;
  /** Loading state of confirm button */
  confirmLoading?: boolean;
  /** The controlled open state of the dialog. Must be used in conjunction with onOpenChange. */
  open?: boolean;
  /** The open state of the dialog when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean;
  /** If dialog nested in another dialog */
  nested?: boolean;
  /** Show close icon for user to turn off alert dialog */
  showCloseIcon?: boolean;
  /** If dialog should close on escape down */
  closeOnEscapeKeyDown?: boolean;
  /** If dialog should close on ok */
  closeOnOk?: boolean;
  /** Event handler called when user click confirm button  */
  onOk?: React.MouseEventHandler<Element>;
  /** Event handler called when user click cancel button */
  onCancel?: React.MouseEventHandler<Element>;
  /** Event handler called when user click close alert dialog */
  onClose?: React.MouseEventHandler<Element>;
  /** Event handler called when the open state of the dialog changes. */
  onOpenChange?: (open: boolean) => void;
  /** Event handler called when focus moves into the component after opening. */
  onOpenAutoFocus?: (event: Event) => void;
  /** Event handler called when focus moves to the trigger after closing. */
  onCloseAutoFocus?: (event: Event) => void;
  /** Event handler called when the escape key is down.  */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
}

export const ConfirmDialog = forwardRef<HTMLDivElement, ConfirmDialogProps>((props, ref) => {
  const {
    trigger,
    icon,
    title,
    description,
    center,
    okButtonText = 'Confirm',
    okButtonProps,
    cancelButtonText = 'Cancel',
    cancelButtonProps,
    confirmLoading,
    open,
    defaultOpen,
    nested,
    showCloseIcon = true,
    closeOnEscapeKeyDown = true,
    closeOnOk = true,
    onOk,
    onCancel,
    onClose,
    onOpenChange,
    onOpenAutoFocus,
    onCloseAutoFocus,
    onEscapeKeyDown,
    ...restProps
  } = props;

  return (
    <AlertDialogRoot open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay nested={nested} />
        <AlertDialogContent
          ref={ref}
          nested={nested}
          onOpenAutoFocus={onOpenAutoFocus}
          onCloseAutoFocus={onCloseAutoFocus}
          onEscapeKeyDown={closeOnEscapeKeyDown ? onEscapeKeyDown : preventDefault}
          onClick={(event) => event.stopPropagation()}
          {...restProps}
        >
          <DialogClose hidden={!showCloseIcon} asChild>
            <DialogCloseButton type="button" onClick={onClose}>
              <DialogCloseIcon name="close" />
            </DialogCloseButton>
          </DialogClose>
          {icon ? <AlertDialogIcon name={icon} /> : null}
          <AlertDialogTitle center={center}>{title}</AlertDialogTitle>
          <AlertDialogDescription
            as="div"
            center={center}
            dangerouslySetInnerHTML={
              typeof description === 'string'
                ? {
                    __html: description.replaceAll(
                      /\*{2}(\S[^*]*\S)\*{2}/gm,
                      `<span style="font-weight:500">$1</span>`,
                    ),
                  }
                : undefined
            }
          >
            {typeof description === 'string' ? null : description}
          </AlertDialogDescription>
          <ActionGroup center={center}>
            <AlertDialogAction asChild>
              <Button
                height={44}
                size="large"
                variant="light"
                onClick={onCancel}
                {...cancelButtonProps}
              >
                {cancelButtonText}
              </Button>
            </AlertDialogAction>
            {closeOnOk ? (
              <AlertDialogAction asChild>
                <Button
                  height={44}
                  size="large"
                  color="primary"
                  loading={confirmLoading}
                  onClick={onOk}
                  {...okButtonProps}
                >
                  {okButtonText}
                </Button>
              </AlertDialogAction>
            ) : (
              <Button
                height={44}
                size="large"
                color="primary"
                loading={confirmLoading}
                onClick={onOk}
                {...okButtonProps}
              >
                {okButtonText}
              </Button>
            )}
          </ActionGroup>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialogRoot>
  );
});

ConfirmDialog.displayName = 'ConfirmDialog';
