import { UploadStatus } from '@paid-ui/enums/attachment';
import type { SVGIconName } from '@paid-ui/icons';
import { type Attachment } from '@paid-ui/schemas/zod/attachment';
import { type Evidence } from '@paid-ui/types';
import {
  download,
  formatFileSize,
  isDocument,
  isPhoto,
  isVideo,
  preview,
  sleep,
} from '@paid-ui/utils';
import { nanoid } from 'nanoid';
import { forwardRef, Fragment, useCallback, useMemo } from 'react';

import { Button } from '../button';
import { Flex } from '../flex';
import type { BaseProps } from '../interfaces';
import { Separator } from '../separator';
import { Skeleton } from '../skeleton';
import { Space } from '../space';
import { useToast } from '../toast';
import {
  AttachmentContent,
  AttachmentIcon,
  AttachmentListLabel,
  AttachmentSubtitle,
  AttachmentTitle,
  ViewButton,
  ViewIcon,
} from './_Base';

export enum AttachmentActionType {
  NONE = 'NONE',
  PREVIEW = 'PREVIEW',
  REMOVE = 'REMOVE',
}

export const defaultAttachmentListProps = {
  action: AttachmentActionType.PREVIEW,
  emptyText: 'No attachments',
};

export interface AttachmentListProps extends BaseProps {
  data: Attachment[] | Evidence[];
  label?: React.ReactNode;
  bucketName: string;
  action?: AttachmentActionType;
  emptyText?: string;
  loading?: boolean;
  showDownload?: boolean;
  onRemove?: (index: number) => Promise<void> | void;
}

export const AttachmentList = forwardRef<HTMLDivElement, AttachmentListProps>((props, ref) => {
  const toast = useToast();
  const {
    data,
    label,
    bucketName,
    action = defaultAttachmentListProps.action,
    emptyText = defaultAttachmentListProps.emptyText,
    loading,
    onRemove,
    showDownload,
    ...restProps
  } = props;

  const dataWithId = useMemo(() => {
    return data.map((v) => ({ ...v, id: v.id || nanoid() }));
  }, [data]);

  const useRemoveAction = useMemo(() => {
    return action === AttachmentActionType.REMOVE && typeof onRemove === 'function';
  }, [action, onRemove]);

  const getIconName = useCallback((attachment: Attachment | Evidence): SVGIconName => {
    if (!attachment) {
      return 'document';
    }

    if (isDocument(attachment)) {
      return 'pdf';
    }

    if (isPhoto(attachment)) {
      return 'photo';
    }

    if (isVideo(attachment)) {
      return 'video';
    }

    return 'document';
  }, []);

  const getActionIconName = useCallback(
    (attachment: Attachment | Evidence) => {
      if (attachment.status === UploadStatus.UPLOADING) {
        return 'loading';
      }

      return useRemoveAction ? 'remove' : 'view';
    },
    [useRemoveAction],
  );

  const handlePreviewOrRemove = useCallback(
    async (attachment: Attachment | Evidence) => {
      if (!attachment) {
        return;
      }

      if (useRemoveAction) {
        const index = dataWithId.findIndex((v) => v.id === attachment.id);

        if (index !== -1 && typeof onRemove === 'function') {
          await onRemove(index);
        }

        return;
      }

      try {
        await preview(attachment, bucketName);
      } catch (error) {
        toast.error(error instanceof Error ? error.message : 'Preview attachment failed');
      }
    },
    [bucketName, dataWithId, onRemove, toast, useRemoveAction],
  );

  const handleDownloadAll = useCallback(async () => {
    for await (const attachment of data) {
      await sleep(0.3);
      await download(attachment, bucketName);
    }
  }, [bucketName, data]);

  if (loading) {
    return (
      <Space size={24} direction="vertical" inline={false} ref={ref} {...restProps}>
        {Array.from({ length: 5 }).map((_, index) => (
          <Fragment key={index}>
            {index === 0 ? null : <Separator gap={0} decorative />}
            <Flex align="center" justify="between" fullWidth gap={3}>
              <Skeleton type="avatar" width={50} height={50} />
              <AttachmentContent size={4} direction="vertical" inline={false}>
                <AttachmentTitle>
                  <Skeleton type="rect" width={90} height={22} />
                </AttachmentTitle>
                <AttachmentSubtitle>
                  <Skeleton type="rect" width={50} height={16} />
                </AttachmentSubtitle>
              </AttachmentContent>
              <ViewButton type="button" size="large" loading>
                <Skeleton type="rect" width={24} height={24} />
              </ViewButton>
            </Flex>
          </Fragment>
        ))}
      </Space>
    );
  }

  return (
    <Space size={14} direction="vertical" inline={false}>
      <AttachmentListLabel>{label}</AttachmentListLabel>
      {dataWithId.length === 0 ? (
        <p>{emptyText}</p>
      ) : (
        <Space size={24} direction="vertical" inline={false} ref={ref} {...restProps}>
          {dataWithId.map((attachment, index) => (
            <Fragment key={index}>
              {index === 0 ? null : <Separator gap={0} decorative />}
              <Flex align="center" justify="between" fullWidth gap={3}>
                <AttachmentIcon name={getIconName(attachment)} />
                <AttachmentContent size={0} direction="vertical" inline={false}>
                  <AttachmentTitle>{attachment.fileName}</AttachmentTitle>
                  <AttachmentSubtitle hidden={!attachment.fileSize}>
                    {formatFileSize(attachment.fileSize)}
                  </AttachmentSubtitle>
                </AttachmentContent>
                <ViewButton
                  size="large"
                  type="button"
                  loading={attachment.status === UploadStatus.UPLOADING}
                  onClick={() => handlePreviewOrRemove(attachment)}
                  hidden={
                    action === AttachmentActionType.NONE ||
                    (action === AttachmentActionType.REMOVE &&
                      attachment.status === UploadStatus.WAITING)
                  }
                >
                  <ViewIcon
                    name={getActionIconName(attachment)}
                    loading={attachment.status === UploadStatus.UPLOADING}
                  />
                </ViewButton>
              </Flex>
            </Fragment>
          ))}
        </Space>
      )}
      <Flex justify="end" fullWidth hidden={data.length === 0 || !showDownload}>
        <Button
          type="button"
          variant="transparent"
          size="large"
          color="primary"
          weight="normal"
          trailingIcon="download"
          onClick={handleDownloadAll}
        >
          Download all
        </Button>
      </Flex>
    </Space>
  );
});

AttachmentList.displayName = 'AttachmentList';
