import { SVGIcon } from '@paid-ui/icons';

import { spin, styled } from '../design-tokens';
import { Space } from '../space';

export const AttachmentListLabel = styled('h4', {
  'fontSize': '18px',
  'lineHeight': '25px',
  'fontWeight': '$medium',

  '&:empty': {
    display: 'none',
  },
});

export const ViewIcon = styled(SVGIcon, {
  'color': '$fg',
  'size': '24px',

  '@hover': {
    'opacity': 0.5,
    'transition': 'opacity 100ms',

    '&:hover': {
      opacity: 1,
    },
  },

  'variants': {
    loading: {
      true: {
        animation: `${spin} 1s linear infinite`,
      },
    },
  },
});

export const ViewButton = styled('button', {
  all: 'unset',
  flexShrink: 0,
  boxSizing: 'border-box',
  cursor: 'pointer',

  variants: {
    size: {
      small: {
        width: '24px',
        height: '24px',
        padding: '4px',

        [`& > ${ViewIcon}`]: {
          width: '16px',
          height: '16px',
        },
      },

      large: {
        width: '40px',
        height: '40px',
        padding: '8px',
      },
    },

    loading: {
      true: {
        cursor: 'default',
        pointerEvents: 'none',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  defaultVariants: {
    size: 'small',
  },
});

export const AttachmentContent = styled(Space, {
  flex: 1,
  ellipsis: 'auto',
});

export const AttachmentTitle = styled('div', {
  margin: 0,
  color: '$fg',
  fontSize: '16px',
  whiteSpace: 'pre-wrap',
  fontWeight: '$medium',
  wordBreak: 'break-word',
  lineHeight: '22px',
});

export const AttachmentSubtitle = styled('div', {
  margin: 0,
  color: '$fg',
  fontSize: '14px',
  fontWeight: '$normal',
  lineHeight: '21px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const AttachmentIcon = styled(SVGIcon, {
  color: '$fg',
  width: '44px',
  height: '44px',
});
