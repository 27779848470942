export type HotKeyName =
  | 'quick-actions'
  | 'search'
  | 'next'
  | 'previous'
  | 'save-draft'
  | 'submit'
  | 'help';

export type HotKey = {
  name: HotKeyName;
  keys: string | string[];
  description: string;
};

export const hotkeys: Record<HotKeyName, HotKey> = {
  'quick-actions': {
    name: 'quick-actions',
    keys: ['ctrl+n'],
    description: 'Open quick actions',
  },

  'search': {
    name: 'search',
    keys: ['ctrl+k'],
    description: 'Search project list',
  },

  'next': {
    name: 'next',
    keys: ['ctrl+right'],
    description: 'Next step of form',
  },

  'previous': {
    name: 'previous',
    keys: ['ctrl+left'],
    description: 'Previous step of form',
  },

  'save-draft': {
    name: 'save-draft',
    keys: ['ctrl+s'],
    description: 'Save draft',
  },

  'submit': {
    name: 'submit',
    keys: ['ctrl+enter'],
    description: 'Submit form',
  },

  'help': {
    name: 'help',
    keys: ['?'],
    description: 'Show help',
  },
};
