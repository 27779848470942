import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StyledContainer, StyledNavbar } from './_Base';

export const defaultNavbarProps = {};

export interface NavbarProps extends BasePropsWithChildren {
  /** Should navbar have rounded bottom corners */
  rounded?: boolean;
  /** Should navbar sticky to container top */
  sticky?: boolean;
  /** Use glass filter */
  glass?: boolean;
}

export const Navbar = forwardRef<HTMLElement, NavbarProps>((props, ref) => {
  const { rounded, sticky, glass, children, ...restProps } = props;

  return (
    <StyledNavbar ref={ref} rounded={rounded} sticky={sticky} glass={glass} {...restProps}>
      <StyledContainer>{children}</StyledContainer>
    </StyledNavbar>
  );
});

Navbar.displayName = 'Navbar';
