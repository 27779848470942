import { styled } from '../design-tokens';
import { Space } from '../space';

export const Subtitle = styled('h3', {
  '&:empty': {
    display: 'none',
  },

  'variants': {
    level: {
      '0': {
        fontSize: '22px',
        fontWeight: '$semibold',
        lineHeight: '27px',
      },
      '1': {
        fontSize: '20px',
        fontWeight: '$semibold',
        lineHeight: '26px',
      },

      '2': {
        fontSize: '16px',
        fontWeight: '$medium',
        lineHeight: '22px',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },

  'defaultVariants': {
    level: '1',
  },
});

export const Container = styled('ul', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  border: '1px solid $colors$midGrey',
  backgroundColor: '$lightGrey',
  borderRadius: '6px',

  variants: {
    compact: {
      true: {
        padding: '20px',
      },

      false: {
        padding: 0,
      },
    },
  },
});

export const SpaceItem = styled(Space, {
  variants: {
    bordered: {
      true: {
        'padding': '14px 20px',
        'borderBottom': '1px solid $colors$midGrey',

        '&:last-of-type': {
          borderBottom: 'none',
        },
      },

      false: {
        borderBottom: 'none',
      },
    },
    compact: {
      true: {
        padding: '10px 20px',
      },
    },
  },
});

export const Item = styled('li', {
  position: 'relative',
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  fontSize: '16px',
  textAlign: 'left',
  lineHeight: '22px',
  color: '$fg',

  variants: {
    responsive: {
      true: {
        'flexDirection': 'column',
        'gap': '2px',

        '@sm': {
          flexDirection: 'row',
          gap: '24px',
        },
      },

      false: {
        flexDirection: 'row',
        gap: '24px',
      },
    },
  },

  defaultVariants: {
    responsive: true,
  },
});

export const ItemLabel = styled('div', {
  '&::after': {
    content: ':',
  },

  'variants': {
    bold: {
      true: {
        fontWeight: '$medium',
      },

      false: {
        fontWeight: '$normal',
      },
    },

    responsive: {
      true: {
        'width': '100%',

        '@sm': {
          flexShrink: 0,
          width: 'auto',
        },
      },

      false: {
        'flexShrink': 0,
        'width': '100px',

        '@xs': {
          width: '120px',
        },
      },
    },

    noAutoColon: {
      true: {
        '&::after': {
          display: 'none',
          content: '',
        },
      },
    },

    longLabel: {
      true: {},
    },
  },

  'compoundVariants': [
    {
      responsive: true,
      longLabel: true,
      css: {
        '@sm': {
          flex: 1,
        },
      },
    },
  ],

  'defaultVariants': {
    bold: true,
    responsive: true,
  },
});

export const ItemValue = styled('div', {
  flex: 1,

  variants: {
    bold: {
      true: {
        fontWeight: '$medium',
      },

      false: {
        fontWeight: '$normal',
      },
    },

    align: {
      left: {
        textAlign: 'left',
      },

      right: {
        'textAlign': 'unset',
        '@sm': {
          textAlign: 'right',
        },
      },
    },

    longLabel: {
      true: {
        flex: 'none',
      },
    },
  },

  defaultVariants: {
    align: 'left',
    bold: false,
  },
});
