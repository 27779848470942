import { forwardRef, useCallback, useReducer, useState } from 'react';

import { StyledEyeNoneIcon, StyledEyeOpenIcon } from './_Base';
import { type IconPosition, Input, type InputProps } from './Input';

export interface InputPasswordProps
  extends Omit<InputProps, 'type' | 'trailingIcon' | 'autoComplete'> {
  autoComplete: 'off' | 'new-password' | 'current-password';
}

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordShow = useCallback((position: IconPosition) => {
    if (position === 'trailing') {
      setShowPassword(true);
    }
  }, []);

  const handlePasswordHide = useCallback((position: IconPosition) => {
    if (position === 'trailing') {
      setTimeout(() => {
        setShowPassword(false);
      }, 300);
    }
  }, []);

  return (
    <Input
      ref={ref}
      type={showPassword ? 'text' : 'password'}
      trailingIcon={showPassword ? <StyledEyeOpenIcon /> : <StyledEyeNoneIcon />}
      onIconButtonPress={handlePasswordShow}
      onIconButtonRelease={handlePasswordHide}
      onIconButtonLeave={handlePasswordHide}
      {...props}
    />
  );
});

InputPassword.displayName = 'InputPassword';
