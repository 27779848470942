import { CountryCode } from '@paid-ui/constants';
import type { Address } from '@paid-ui/types';
import { formatAddress } from '@paid-ui/utils';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import type { BaseProps } from '../interfaces';
import { useToast } from '../toast';
import { AspectRatioRoot } from './_Base';
import { mapOptionsCountryCodeMap } from './_utils';

export type MapTheme = 'light' | 'dark' | 'grey';

const defaultGoogleMapsMarkerWidgetProps = {
  country: CountryCode.AU,
  zoom: 14,
  mapOptions: {
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    controlSize: 24,
  } as google.maps.MapOptions,
  markerOptions: {} as google.maps.MarkerOptions,
};

export interface GoogleMapsMarkerWidgetProps extends BaseProps {
  mapId?: string;
  address?: Address | string;
  country?: CountryCode;
  zoom?: number;
  mapOptions?: google.maps.MapOptions;
  markerOptions?: google.maps.MarkerOptions;
}

export const GoogleMapsMarkerWidget: React.FC<GoogleMapsMarkerWidgetProps> = (props) => {
  const {
    mapId,
    address,
    country = defaultGoogleMapsMarkerWidgetProps.country,
    zoom = defaultGoogleMapsMarkerWidgetProps.zoom,
    mapOptions: defaultMapOptions = defaultGoogleMapsMarkerWidgetProps.mapOptions,
    markerOptions = defaultGoogleMapsMarkerWidgetProps.markerOptions,
    ...restProps
  } = props;

  const toast = useToast();
  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const mapOptions = useMemo(() => {
    return {
      ...defaultMapOptions,
      ...mapOptionsCountryCodeMap[country],
      mapId,
      animation: google.maps.Animation.DROP,
    };
  }, [country, defaultMapOptions, mapId]);

  const decodeAddressAsMarker = useCallback(async (): Promise<void> => {
    const map = mapRef.current;
    const marker = markerRef.current;
    const addressStr = typeof address === 'string' ? address : formatAddress(address);

    if (!address || !addressStr) {
      marker?.setMap(null);
      markerRef.current = null;
      map?.setOptions(mapOptions);
      return;
    }

    try {
      const [geoCode] = await getGeocode({
        address: addressStr,
      });

      if (geoCode) {
        markerRef.current?.setMap(null);
        const latLng = getLatLng(geoCode);

        markerRef.current = new google.maps.Marker({
          ...markerOptions,
          position: latLng,
          icon: '/map-pin-blue.png',
        });

        map?.setZoom(zoom);
        map?.setCenter(latLng);
        markerRef.current.setMap(map);
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Google Maps API request failed.');
    }
  }, [address, mapOptions, markerOptions, toast, zoom]);

  useEffect(() => {
    const map = mapRef.current;

    if (mapContainerRef.current) {
      if (map) {
        map.setOptions(mapOptions);
      } else {
        mapRef.current = new google.maps.Map(mapContainerRef.current, mapOptions);
      }
    }
  }, [mapOptions]);

  useEffect(() => {
    decodeAddressAsMarker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return <AspectRatioRoot ref={mapContainerRef} {...restProps} />;
};

GoogleMapsMarkerWidget.displayName = 'GoogleMapsMarkerWidget';
