import { type SVGIconName } from '@paid-ui/icons';
import type * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { Children, cloneElement, forwardRef, isValidElement } from 'react';

import { HelperText } from '../help-text';
import { type BaseProps } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import {
  StyledIcon,
  StyledItemContent,
  StyledToggleGroupItem,
  StyledToggleGroupRoot,
} from './_Base';

export interface ToggleGroupProps
  extends Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, 'type'>,
    BaseProps {
  name?: string;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  errorMessage?: React.ReactNode;
  required?: boolean;
  bordered?: boolean;
  hideIconWhenOff?: boolean;
}

export const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>((props, ref) => {
  const {
    name,
    label,
    value,
    defaultValue,
    orientation,
    helperText,
    errorMessage,
    required,
    bordered,
    hideIconWhenOff,
    disabled,
    hidden,
    children,
    onValueChange,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  const hasError = !!errorMessage;

  return (
    <Space size={8} direction="vertical" inline={false}>
      <Label htmlFor={name} size={16} weight="medium" asterisk={required}>
        {label}
      </Label>
      <StyledToggleGroupRoot
        ref={ref}
        type="single"
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        orientation="horizontal"
        onValueChange={onValueChange}
        aria-describedby={hasError ? `${name}-error` : `${name}-description`}
        {...restProps}
      >
        {Children.map(children, (child) =>
          isValidElement<ToggleGroupItemProps>(child)
            ? cloneElement(child, { name, bordered, hideIconWhenOff, ...child.props })
            : null,
        )}
      </StyledToggleGroupRoot>
      <HelperText error={hasError} id={hasError ? `${name}-error` : `${name}-description`}>
        {hasError ? errorMessage : helperText}
      </HelperText>
    </Space>
  );
});

export interface ToggleGroupItemProps extends ToggleGroupPrimitive.ToggleGroupItemProps, BaseProps {
  icon?: SVGIconName;
  iconColor?: 'blue' | 'pink' | 'green';
  bordered?: boolean;
  hideIconWhenOff?: boolean;
}

export const ToggleGroupItem = forwardRef<HTMLButtonElement, ToggleGroupItemProps>((props, ref) => {
  const {
    name,
    value,
    icon,
    iconColor,
    bordered,
    hideIconWhenOff,
    disabled,
    hidden,
    children,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  return (
    <StyledToggleGroupItem
      ref={ref}
      id={`${name}-${value}`}
      value={value}
      disabled={disabled}
      hasIcon={Boolean(icon)}
      iconColor={iconColor}
      bordered={bordered}
      hideIconWhenOff={hideIconWhenOff}
      {...restProps}
    >
      {icon ? (
        <StyledItemContent>
          <StyledIcon name={icon} />
          <span style={{ flex: 1 }}>{children}</span>
        </StyledItemContent>
      ) : (
        children
      )}
    </StyledToggleGroupItem>
  );
});

ToggleGroup.displayName = 'ToggleGroup';
ToggleGroupItem.displayName = 'ToggleGroupItem';
