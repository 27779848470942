import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { Logo } from '../logo';
import { StyledNavbarLogo } from './_Base';

interface NavbarLogoProps extends BaseProps {
  onClick?: () => void;
}

export const NavbarLogo = forwardRef<HTMLButtonElement, NavbarLogoProps>((props, ref) => {
  const { onClick, ...restProps } = props;

  return (
    <StyledNavbarLogo type="button" ref={ref} onClick={onClick} {...restProps}>
      <Logo width={30} symbolOnly />
    </StyledNavbarLogo>
  );
});

NavbarLogo.displayName = 'NavbarLogo';
