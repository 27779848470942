import { useMediaQuery } from '@paid-ui/hooks/use-media-query';
import { type SVGIconName } from '@paid-ui/icons';
import { useCallback, useMemo } from 'react';

import { type Breakpoint, Container } from '../container';
import { breakpoints } from '../design-tokens';
import type { BaseProps } from '../interfaces';
import { Select, type SelectOptionItem } from '../select';
import { TabContainer, Tabs, TabsContainer, TabsContent, TabsList, TabTrigger } from './_Base';

export const defaultResponsiveTabs = {};

export type TabItemOption = {
  key: string;
  title: React.ReactNode;
  content?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  icon?: SVGIconName;
};

export interface ResponsiveTabsProps extends BaseProps {
  name: string;
  value?: string;
  defaultValue?: string;
  data: TabItemOption[];
  bordered?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint;
  hidden?: boolean;
  onChange?: (value: string) => void;
}

export const ResponsiveTabs: React.FC<ResponsiveTabsProps> = (props) => {
  const {
    name,
    value,
    defaultValue,
    data,
    bordered,
    maxWidth,
    fullWidth,
    hidden,
    onChange,
    ...restProps
  } = props;
  const smallScreen = useMediaQuery(`(max-width: ${breakpoints.sm}px)`);

  const options = useMemo<SelectOptionItem[]>(() => {
    return data
      .filter((item) => !item.hidden)
      .map((item) => ({
        label: item.title as string,
        value: item.key,
        disabled: item.disabled,
      }));
  }, [data]);

  const tabContent = useMemo(() => {
    const selectedTab = value
      ? data.find((item) => item.key === value)
      : data.find((item) => item.key === defaultValue);

    if (!selectedTab || selectedTab.hidden) {
      return null;
    }

    return selectedTab?.content ?? selectedTab.title;
  }, [data, defaultValue, value]);

  const handleSelectChange = useCallback(
    (_value: string) => {
      if (typeof onChange === 'function') {
        onChange(_value);
      }
    },
    [onChange],
  );

  if (hidden) {
    return null;
  }

  return smallScreen ? (
    <TabsContainer>
      <Select
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={handleSelectChange}
        options={options}
        size="large"
      />
      <TabContainer>{tabContent}</TabContainer>
    </TabsContainer>
  ) : (
    <Tabs value={value} defaultValue={defaultValue} {...restProps}>
      {maxWidth ? (
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth={maxWidth}>
            <TabsList bordered={bordered} fullWidth={fullWidth}>
              {data.map((tab) => (
                <TabTrigger
                  key={tab.key}
                  value={tab.key}
                  hidden={tab.hidden}
                  onClick={() => handleSelectChange(tab.key)}
                >
                  {tab.title}
                </TabTrigger>
              ))}
            </TabsList>
          </Container>
        </div>
      ) : (
        <TabsList bordered={bordered} fullWidth={fullWidth}>
          {data.map((tab) => (
            <TabTrigger
              key={tab.key}
              value={tab.key}
              hidden={tab.hidden}
              onClick={() => handleSelectChange(tab.key)}
            >
              {tab.title}
            </TabTrigger>
          ))}
        </TabsList>
      )}
      {data.map((tab) => (
        <TabsContent key={tab.key} value={tab.key} hidden={tab.hidden}>
          {tab.content ?? tab.title}
        </TabsContent>
      ))}
    </Tabs>
  );
};

ResponsiveTabs.displayName = 'ResponsiveTabs';
