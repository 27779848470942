import Link from 'next/link';

import { css, styled } from '../design-tokens';

const styles = css({
  cursor: 'pointer',
  textUnderlineOffset: 2,

  variants: {
    color: {
      default: {
        color: '$fg',
      },

      blue: {
        color: '$blue',
      },

      red: {
        color: '$pink',
      },
    },

    bold: {
      true: {
        fontWeight: '$semibold',
      },
    },

    underline: {
      none: {
        textDecoration: 'none',
      },

      hover: {
        'textDecoration': 'none',

        '@hover': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },

      always: {
        textDecoration: 'underline',
      },
    },
  },

  defaultVariants: {
    color: 'default',
    underline: 'none',
  },
});

export const Anchor = styled('a', styles);
export const NextLink = styled(Link, styles);
