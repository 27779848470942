import type { VariationItemLinkedToClaim } from '@paid-ui/types';
import React from 'react';

import { Checkbox } from '../checkbox';
import { Grid } from '../grid';
import type { BaseProps, FontSize } from '../interfaces';
import { Label } from '../label';
import { VariationSectionContainer } from './_Base';

export interface VariationViewListProps extends BaseProps {
  label: string;
  items: VariationItemLinkedToClaim[];
  labelSize?: FontSize;
  onClickVariation?: (id: string) => void;
}

export const VariationViewList: React.FC<VariationViewListProps> = (props) => {
  const { label, items, labelSize = 16, onClickVariation } = props;

  return (
    <Grid gap="3_5">
      <Label size={labelSize}>{label}</Label>
      <VariationSectionContainer>
        {items.length > 0
          ? items.map((item) => (
              <Checkbox
                key={item.id}
                name={`variations-${item.id}`}
                size="medium"
                mode="complex"
                labelSize={labelSize}
                shape="square"
                type="variation"
                value
                variationItem={item}
                onClickVariation={() => onClickVariation?.(item.id)}
                showCheckbox={false}
                css={{
                  backgroundColor: '$lightGrey',
                  borderRadius: '6px',
                }}
              />
            ))
          : '-'}
      </VariationSectionContainer>
    </Grid>
  );
};

VariationViewList.displayName = 'VariationViewList';
