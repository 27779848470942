import { forwardRef } from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import {
  DialogClose,
  DialogCloseButton,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTrigger,
} from './_Base';

const preventDefault = (event: Event) => event.preventDefault();

export const defaultDialogProps = {
  trigger: null as React.ReactNode,
  size: 'medium' as DialogSize,
  showOverlay: true,
  fixMobileSafari: true,
  closeOnEscapeKeyDown: true,
  closeOnClickOutside: true,
  onOpenAutoFocus: preventDefault,
  onCloseAutoFocus: preventDefault,
};

export type DialogSize = 'small' | 'medium' | 'large';

export interface DialogProps extends BasePropsWithChildren {
  /** Dialog trigger element */
  trigger?: React.ReactNode;
  /** Size of dialog */
  size?: DialogSize;
  /** The controlled open state of the dialog. Must be used in conjunction with onOpenChange. */
  open?: boolean;
  /** The open state of the dialog when it is initially rendered. Use when you do not need to control its open state. */
  defaultOpen?: boolean;
  /** If dialog nested in another dialog */
  nested?: boolean;
  /** If dialog is fullscreen */
  fullScreen?: boolean;
  /** If show overlay */
  showOverlay?: boolean;
  /** If fix mobile dialog height */
  fixMobileSafari?: boolean;
  /** If has a close button */
  hasCloseButton?: boolean;
  /** If dialog should close on escape down */
  closeOnEscapeKeyDown?: boolean;
  /** If dialog should close on click outside */
  closeOnClickOutside?: boolean;
  /** Event handler called when the open state of the dialog changes. */
  onOpenChange?: (open: boolean) => void;
  /** Event handler called when focus moves into the component after opening. */
  onOpenAutoFocus?: (event: Event) => void;
  /** Event handler called when focus moves to the trigger after closing. */
  onCloseAutoFocus?: (event: Event) => void;
  /** Event handler called when the escape key is down.  */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  /** Event handler called when a pointer event occurs outside the bounds of the component. */
  onPointerDownOutside?: (event: CustomEvent<{ originalEvent: Event }>) => void;
  /** Event handler called when an interaction (pointer or focus event) happens outside the bounds of the component. */
  onInteractOutside?: (event: CustomEvent<{ originalEvent: Event }>) => void;
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const {
    trigger = defaultDialogProps.trigger,
    size = defaultDialogProps.size,
    open,
    defaultOpen,
    nested,
    fullScreen,
    hasCloseButton,
    fixMobileSafari = defaultDialogProps.fixMobileSafari,
    closeOnEscapeKeyDown = defaultDialogProps.closeOnEscapeKeyDown,
    closeOnClickOutside = defaultDialogProps.closeOnClickOutside,
    children,
    showOverlay = defaultDialogProps.showOverlay,
    onOpenChange,
    onOpenAutoFocus = defaultDialogProps.onOpenAutoFocus,
    onCloseAutoFocus = defaultDialogProps.onCloseAutoFocus,
    onEscapeKeyDown,
    onPointerDownOutside,
    onInteractOutside,
    ...restProps
  } = props;

  return (
    <DialogRoot open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay nested={nested} fullScreen={fullScreen} hidden={!showOverlay} />
        <DialogContent
          ref={ref}
          size={size}
          nested={nested}
          fullScreen={fullScreen}
          fixMobileSafari={fixMobileSafari}
          onOpenAutoFocus={onOpenAutoFocus}
          onCloseAutoFocus={onCloseAutoFocus}
          onEscapeKeyDown={closeOnEscapeKeyDown ? onEscapeKeyDown : preventDefault}
          onPointerDownOutside={closeOnClickOutside ? onPointerDownOutside : preventDefault}
          onInteractOutside={closeOnClickOutside ? onInteractOutside : preventDefault}
          {...restProps}
        >
          {hasCloseButton ? (
            <DialogClose asChild>
              <DialogCloseButton type="button">
                <DialogCloseIcon name="close" />
              </DialogCloseButton>
            </DialogClose>
          ) : null}
          {children}
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
});

Dialog.displayName = 'Dialog';

export { DialogClose } from './_Base';
