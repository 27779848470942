import { styled } from '../design-tokens';

export const StyledCurrency = styled('span', {
  width: '100%',

  variants: {
    inline: {
      true: {
        width: 'auto',
      },
    },

    align: {
      left: {
        textAlign: 'left',
      },

      center: {
        textAlign: 'center',
      },

      right: {
        textAlign: 'right',
      },
    },

    size: {
      small: {
        fontSize: '16px',
        lineHeight: '22px',
      },

      medium: {
        fontSize: '18px',
        lineHeight: '25px',
      },

      large: {
        fontSize: '22px',
        lineHeight: '27px',
      },

      xlarge: {
        fontSize: '38px',
        lineHeight: '40px',
      },
    },

    bold: {
      true: {
        fontWeight: '$semibold',
      },

      false: {
        fontWeight: '$normal',
      },
    },

    color: {
      default: {
        color: '$fg',
      },

      primary: {
        color: '$blue',
      },

      success: {
        color: '$payInGreen',
      },

      danger: {
        color: '$pink',
      },

      secondary: {
        color: '$fgSecondary',
      },
    },
    abandoned: {
      true: {
        color: '$fgSecondary',
        textDecoration: 'line-through',
      },
    },
  },

  defaultVariants: {
    color: 'default',
    align: 'left',
    bold: true,
    size: 'medium',
  },
});
