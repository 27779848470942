import { worksCategories } from '@paid-ui/constants';
import { type LinkedItem as ILinkedItem } from '@paid-ui/types';
import { formatCurrency } from '@paid-ui/utils';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { forwardRef } from 'react';

import type { BaseProps } from '../interfaces';
import { LinkedItemContainer, LinkedItemLabel, LinkedItemRow, LinkedItemValue } from './_Base';

export const defaultLinkedItemProps = {};

export interface LinkedItemProps extends BaseProps {
  data: ILinkedItem;
}

export const LinkedItem = forwardRef<HTMLDivElement, LinkedItemProps>((props, ref) => {
  const { data, ...restProps } = props;

  return (
    <LinkedItemContainer ref={ref} {...restProps}>
      <LinkedItemRow>
        <LinkedItemLabel>Works:</LinkedItemLabel>
        <LinkedItemValue>
          {data.id}: {worksCategories[data.worksCategory]}
        </LinkedItemValue>
      </LinkedItemRow>
      <LinkedItemRow>
        <LinkedItemLabel>Contractor:</LinkedItemLabel>
        <LinkedItemValue>{data.contractor}</LinkedItemValue>
      </LinkedItemRow>
      <LinkedItemRow>
        <LinkedItemLabel>Payment:</LinkedItemLabel>
        <LinkedItemValue>{formatCurrency(data.payment)}</LinkedItemValue>
      </LinkedItemRow>
      <LinkedItemRow>
        <LinkedItemLabel>Paid:</LinkedItemLabel>
        <LinkedItemValue>
          {utc2Mel(data.paidAt).format('DD MMM YYYY')} ({utc2Mel(data.paidAt).fromNow(true)})
        </LinkedItemValue>
      </LinkedItemRow>
    </LinkedItemContainer>
  );
});

LinkedItem.displayName = 'LinkedItem';
