import { SVGIcon } from '@paid-ui/icons';
import { Formik, type FormikHelpers, type FormikProps, type FormikValues } from 'formik';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import {
  type Dispatch,
  forwardRef,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '../button';
import { type CSS } from '../design-tokens';
import { Flex } from '../flex';
import type { BasePropsWithChildren } from '../interfaces';
import { Space } from '../space';
import { Tooltip } from '../tooltip';
import {
  ReturnToReviewButton,
  StepFormClose,
  StepFormContentContainer,
  StepFormDescription,
  StepFormHelp,
  StepFormIconButton,
  StepFormLogo,
  StepFormMainContent,
  StepFormProgress,
  StepFormProgressItem,
  StepFormSVGIcon,
  StepFormTitle,
  StepFormTitleAndDescription,
  StyledStepFormFooter,
  StyledStepFormHeader,
} from './_Base';
import { AutoSaver } from './AutoSaver';
import type { FormStepper } from './types';

export const defaultStepFormProps = {
  currentStep: 0,
  steps: [] as FormStepper[],
  showHelpButton: false,
  showCloseButton: true,
  showSaveDraftButton: false,
  showReturnToReviewButton: true,
  initialValues: {} as FormikValues,
  prevText: 'Previous',
  nextText: 'Next',
  submitText: 'Send',
  submittingText: 'Sending...',
};

export interface StepFormProps<T = FormikValues> extends BasePropsWithChildren {
  /** Dispatch utils */
  dispatch?: Dispatch<any>;
  /** State of step form */
  state?: Record<string, any>;
  /** Current step of form */
  currentStep: number;
  /** step form stepper */
  steps: FormStepper[];
  /** Style of step form header */
  headerStyle?: CSS;
  /** Style of step form content */
  contentStyle?: CSS;
  /** Style of step form footer */
  footerStyle?: CSS;
  /** If help icon button show or not */
  showHelpButton?: boolean;
  /** Help url */
  helpUrl?: string;
  /** If close icon button show or not */
  showCloseButton?: boolean;
  /** If save draft button show or not */
  showSaveDraftButton?: boolean;
  /** If return to review button show or not */
  showReturnToReviewButton?: boolean;
  /** Initial values of StepForm form */
  initialValues?: T;
  /** Validation schema of StepForm form */
  validationSchema?: any;
  /** Reference of form instance */
  formRef?: React.MutableRefObject<FormikProps<T>>;
  /** Text of previous button */
  prevText?: string;
  /** Text of next button */
  nextText?: string;
  /** Style of button */
  buttonText?: string;
  /** Disable or not */
  disableBtn?: boolean;
  /** Id of submit button */
  submitId?: string;
  /** Text of submit button */
  submitText?: string;
  /** Text of submitting button */
  submittingText?: string;
  /** If submit button is disabled */
  disabled?: boolean;
  /** If draft is saving */
  saving?: boolean;
  /** If form is submitting */
  submitting?: boolean;
  /** Hide previous button or not */
  hidePrevious?: boolean;
  /** Hide next button or not */
  hideNext?: boolean;
  /** Hide footer or not */
  hideFooter?: boolean;
  /** Disable next button or not */
  disableNext?: boolean;
  /** Disable prev button or not */
  disablePrev?: boolean;
  /** Disable save button or not */
  disableSave?: boolean;
  /** Show submit icon or not */
  showSubmitIcon?: boolean;
  /** Remove padding of content */
  noPadding?: boolean;
  /** If progress readonly or not */
  progressReadonly?: boolean;
  /** Hide progress or not */
  hideProgress?: boolean;
  /** Progress should be hidden */
  hiddenProgresses?: number[];
  /** Should form reset when new initialValues change  */
  enableReinitialize?: boolean;
  /** Button click event */
  onClick?: (step: number) => void;
  /** Handler when previous step */
  onPreviousStep?: (step: number) => void;
  /** Handler when next step */
  onNextStep?: (step: number) => void;
  /** Handler draft saving */
  onSaveDraft?: (step: number) => void;
  /** Event handler called when form submit */
  onSubmit?: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
}

const StepForm = forwardRef(
  <T extends FormikValues>(props: StepFormProps<T>, ref: React.Ref<HTMLDivElement>) => {
    const {
      state,
      dispatch,
      currentStep: currentStepIndex = defaultStepFormProps.currentStep,
      steps = defaultStepFormProps.steps,
      headerStyle,
      contentStyle,
      footerStyle,
      showHelpButton = defaultStepFormProps.showHelpButton,
      helpUrl,
      showCloseButton = defaultStepFormProps.showCloseButton,
      showSaveDraftButton = defaultStepFormProps.showSaveDraftButton,
      showReturnToReviewButton = defaultStepFormProps.showReturnToReviewButton,
      initialValues = defaultStepFormProps.initialValues,
      validationSchema,
      formRef,
      prevText = defaultStepFormProps.prevText,
      nextText = defaultStepFormProps.nextText,
      submitText = defaultStepFormProps.submitText,
      submittingText = defaultStepFormProps.submittingText,
      disabled,
      saving,
      submitId,
      submitting,
      hidePrevious,
      hideNext,
      hideFooter,
      disableNext,
      disablePrev,
      disableSave,
      noPadding,
      buttonText,
      disableBtn,
      showSubmitIcon,
      progressReadonly,
      hideProgress,
      hiddenProgresses = [],
      enableReinitialize,
      onClick,
      onNextStep,
      onPreviousStep,
      onSaveDraft,
      onSubmit,
      children,
      ...restProps
    } = props;

    const innerRef = useRef<HTMLDivElement | null>(null);
    const [hasReviewed, setHasReviewed] = useState(false);
    const [header, setHeader] = useState<HTMLElement | null>(null);
    const [isHeaderSticky, setHeaderSticky] = useState(false);
    const [isFooterSticky, setFooterSticky] = useState(false);
    const footerRef = useRef<HTMLDivElement | null>(null);

    const currentStep = steps[currentStepIndex];
    const lastStep = steps.length - 1;
    const isReviewStep = currentStepIndex === steps.length - 1;
    const showReturnButton =
      showReturnToReviewButton && steps.length > 1 && !isReviewStep && hasReviewed;

    const callbackRef = useCallback((node: HTMLDivElement | null) => {
      if (node) {
        setFooterSticky(node.scrollHeight > node.clientHeight);
      }
      innerRef.current = node;
    }, []);

    const debouncedScrollHandler = useMemo<React.UIEventHandler<HTMLDivElement>>(() => {
      return debounce((event) => {
        const dialogContainer = innerRef.current;

        if (dialogContainer) {
          const { scrollHeight, clientHeight, scrollTop } = dialogContainer;
          const scrollBottom = scrollHeight - clientHeight - scrollTop;

          startTransition(() => {
            setHeaderSticky(scrollTop > 1);
            setFooterSticky(scrollBottom > 1);
          });
        }
      }, 60);
    }, []);

    const handleDialogContainerScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
      (event) => {
        debouncedScrollHandler(event);
      },
      [debouncedScrollHandler],
    );

    const handleGoToStep = useCallback(
      (step: number) => {
        if (typeof dispatch === 'function') {
          dispatch({
            type: 'goToStep',
            payload: step,
          });
        }
      },
      [dispatch],
    );

    const handleGoToReviewStep = useCallback(() => {
      if (typeof dispatch === 'function') {
        dispatch({
          type: 'goToStep',
          payload: lastStep,
        });
      }
    }, [dispatch, lastStep]);

    const handlePrevious = useCallback(() => {
      if (typeof onPreviousStep === 'function') {
        onPreviousStep(currentStepIndex);
      } else if (typeof dispatch === 'function') {
        dispatch({
          type: 'prevStep',
        });
      }
    }, [currentStepIndex, dispatch, onPreviousStep]);

    const handleNext = useCallback(() => {
      if (typeof onNextStep === 'function') {
        onNextStep(currentStepIndex);
      } else if (typeof dispatch === 'function') {
        dispatch({
          type: 'nextStep',
        });
      }
    }, [currentStepIndex, dispatch, onNextStep]);

    const handleSubmit = useCallback(
      (values: T, formikHelpers: FormikHelpers<T>) => {
        if (formRef?.current && !isEmpty(formRef.current.errors)) {
          console.log(formRef.current.errors);
          return;
        }

        if (typeof onSubmit === 'function') {
          onSubmit(values, formikHelpers);
        }
      },
      [formRef, onSubmit],
    );

    const handleSubmitTrigger = useCallback(() => {
      if (document.getElementById('sectionsToComplete')) {
        document.getElementById('sectionsToComplete')?.scrollIntoView({
          behavior: 'smooth',
        });
      }
      formRef?.current.submitForm();
    }, [formRef]);

    const handleSaveDraft = useCallback(() => {
      if (typeof onSaveDraft === 'function') {
        onSaveDraft(currentStepIndex);
      }
    }, [currentStepIndex, onSaveDraft]);

    useEffect(() => {
      if (currentStepIndex === lastStep && !hasReviewed) {
        setHasReviewed(true);
      }

      const scrollElement = innerRef.current;
      const footerElement = footerRef.current;

      if (scrollElement) {
        scrollElement.scroll({
          top: 0,
          behavior: 'smooth',
        });

        setFooterSticky(scrollElement.scrollHeight > scrollElement.clientHeight);
      }

      if (footerElement) {
        footerElement.scrollLeft = 200;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStepIndex]);

    return (
      <Formik
        ref={ref}
        innerRef={formRef}
        onSubmit={handleSubmit}
        initialValues={initialValues as T}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
        {...restProps}
      >
        <StepFormMainContent ref={callbackRef} onScroll={handleDialogContainerScroll}>
          <StyledStepFormHeader
            css={headerStyle}
            sticky={isHeaderSticky}
            ref={(instance: HTMLElement | null) => setHeader(instance)}
          >
            <StepFormLogo symbolOnly />
            <StepFormTitleAndDescription size={2} direction="vertical">
              <StepFormTitle>{currentStep?.title}</StepFormTitle>
              <StepFormDescription>{currentStep?.description}</StepFormDescription>
            </StepFormTitleAndDescription>
            <StepFormProgress align="center" size={1} hidden={hideProgress}>
              {steps
                .filter((step) => step.included)
                .map((step) => (
                  <Tooltip key={step.index} title={step.description} collisionBoundary={header}>
                    <StepFormProgressItem
                      type="button"
                      readonly={progressReadonly}
                      active={step.index <= currentStepIndex}
                      hidden={hiddenProgresses.includes(step.index)}
                      onClick={progressReadonly ? undefined : () => handleGoToStep(step.index)}
                    />
                  </Tooltip>
                ))}
            </StepFormProgress>
            <Tooltip title="Get support">
              <StepFormHelp href={helpUrl} target="_blank" hidden={!showHelpButton}>
                <StepFormSVGIcon name="help" />
              </StepFormHelp>
            </Tooltip>
            <StepFormClose hidden={!showCloseButton} asChild>
              <StepFormIconButton type="button">
                <StepFormSVGIcon name="close" />
              </StepFormIconButton>
            </StepFormClose>
          </StyledStepFormHeader>
          <StepFormContentContainer noPadding={noPadding} css={contentStyle}>
            {children}
          </StepFormContentContainer>
          <StyledStepFormFooter hidden={hideFooter} sticky={isFooterSticky} css={footerStyle}>
            <Button
              size="large"
              height={44}
              color="primary"
              width="100%"
              hidden={!buttonText}
              disabled={disableBtn}
              onClick={() => onClick?.(currentStepIndex)}
            >
              {buttonText}
            </Button>
            <Flex
              align="center"
              justify="end"
              ref={footerRef}
              css={{
                overflowX: 'auto',
                p: '4px',
              }}
            >
              <ReturnToReviewButton
                size="large"
                height={44}
                variant="transparent"
                onClick={handleGoToReviewStep}
                hidden={!showReturnButton}
              >
                Return to review
              </ReturnToReviewButton>
              <Space size={10} align="center">
                <AutoSaver dispatch={dispatch} />
                <Button
                  size="large"
                  height={44}
                  variant="transparent"
                  leadingIcon="previous-2"
                  onClick={handlePrevious}
                  disabled={disablePrev || submitting}
                  hidden={currentStepIndex === 0 || hidePrevious}
                >
                  {prevText}
                </Button>
                <Button
                  height={44}
                  size="large"
                  variant="outline"
                  color="primary"
                  loading={saving}
                  disabled={disableSave || submitting}
                  hidden={!showSaveDraftButton}
                  onClick={handleSaveDraft}
                >
                  {saving ? 'Saving...' : ' Save draft'}
                </Button>
                {isReviewStep ? (
                  <Button
                    height={44}
                    size="large"
                    color="primary"
                    loading={submitting}
                    disabled={disabled || submitting}
                    onClick={handleSubmitTrigger}
                    id={submitId}
                  >
                    <Flex align="center" gap={2}>
                      {showSubmitIcon ? <SVGIcon name="send" /> : null}
                      {submitting ? submittingText : submitText}
                    </Flex>
                  </Button>
                ) : (
                  <Button
                    height={44}
                    size="large"
                    color="primary"
                    trailingIcon="next-2"
                    onClick={handleNext}
                    hidden={hideNext}
                    disabled={disableNext}
                  >
                    {nextText}
                  </Button>
                )}
              </Space>
            </Flex>
          </StyledStepFormFooter>
        </StepFormMainContent>
      </Formik>
    );
  },
);

StepForm.displayName = 'StepForm';

export default StepForm as <T extends FormikValues>(
  props: StepFormProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => JSX.Element;
