export const streetTypes = [
  'Access',
  'Alley',
  'Alleyway',
  'Amble',
  'Approach',
  'Arcade',
  'Arterial',
  'Artery',
  'Avenue',
  'Banan',
  'Bend',
  'Boardwalk',
  'Boulevard',
  'Brace',
  'Brae',
  'Break',
  'Brow',
  'Bypass',
  'Byway',
  'Causeway',
  'Centre',
  'Chase',
  'Circle',
  'Circuit',
  'Circus',
  'Close',
  'Concourse',
  'Copse',
  'Corner',
  'Court',
  'Courtyard',
  'Cove',
  'Crescent',
  'Crest',
  'Cross',
  'Cul-de-sac',
  'Cutting',
  'Dale',
  'Dip',
  'Drive',
  'Driveway',
  'Edge',
  'Elbow',
  'End',
  'Entrance',
  'Esplanade',
  'Expressway',
  'Fairway',
  'Follow',
  'Footway',
  'Formation',
  'Freeway',
  'Frontage',
  'Gap',
  'Gardens',
  'Gate',
  'Glade',
  'Glen',
  'Grange',
  'Green',
  'Grove',
  'Heights',
  'Highroad',
  'Highway',
  'Hill',
  'Interchange',
  'Junction',
  'Key',
  'Lane',
  'Laneway',
  'Line',
  'Link',
  'Lookout',
  'Loop',
  'Mall',
  'Meander',
  'Mews',
  'Motorway',
  'Nook',
  'Outlook',
  'Parade',
  'Parkway',
  'Pass',
  'Passage',
  'Path',
  'Pathway',
  'Piazza',
  'Place',
  'Plaza',
  'Pocket',
  'Point',
  'Port',
  'Promenade',
  'Quadrant',
  'Quay',
  'Quays',
  'Ramble',
  'Rest',
  'Retreat',
  'Ridge',
  'Rise',
  'Road',
  'Rotary',
  'Route',
  'Row',
  'Rue',
  'Serviceway',
  'Shunt',
  'Spur',
  'Square',
  'Street',
  'Subway',
  'Tarn',
  'Terrace',
  'Thoroughfare',
  'Tollway',
  'Top',
  'Tor',
  'Track',
  'Trail',
  'Turn',
  'Underpass',
  'Vale',
  'Viaduct',
  'View',
  'Vista',
  'Walk',
  'Way',
  'Walkway',
  'Wharf',
  'Wynd',
];
