import { SVGIcon } from '@paid-ui/icons';

import { css, styled } from '../design-tokens';
import { Flex } from '../flex';

export const MenuContainer = styled('div', {
  variants: {
    theme: {
      light: {
        color: '',
      },

      dark: {
        color: 'white',
      },
    },
  },
});

export const ChildContainer = styled('div', {
  'borderBottom': '1px solid $fgTertiary',
  'padding': '14px 0 10px',

  '&:first-of-type': {
    paddingTop: 0,
  },

  '&:last-child': {
    border: 'none',
  },
});

export const MenuItemContainer = styled('div', {});

export const MenuItemTitleContainer = styled(Flex, {
  position: 'relative',
  gap: '8px',
  marginBottom: '4px',
  padding: '11px 12px',

  variants: {
    disable: {
      true: {
        cursor: 'default',
      },

      false: {
        cursor: 'pointer',
      },
    },

    active: {
      true: {
        borderRadius: '6px',
        background: '$hoverBlue',
        color: 'white',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const StyledMenuItemTitle = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.5px',
  paddingLeft: '37px',
});

export const MenuItemTitle = styled('div', {
  fontSize: '16px',
  fontWeight: '$semibold',
});

export const MenuItemTitleIconWithDefault = styled('div', {
  position: 'absolute',
  left: '12px',
});

export const MenuItemTitleIcon = styled(SVGIcon, {
  position: 'absolute',
  left: '12px',
  top: 'calc(50% - 12px)',
  size: '24px',
  color: '$fgSecondary',
});

export const MenuItemSubtitle = styled('div', {
  display: 'block',
  fontSize: '14px',
  fontWeight: '$medium',
});

export const MenuItemListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '37px',
  lineHeight: 1,
});

export const menuItemStyles = css({
  all: 'unset',
  fontSize: '16px',
  fontWeight: '$medium',
  cursor: 'pointer',
  padding: '11px 0 11px 10px',

  variants: {
    disable: {
      true: {
        cursor: 'default',
      },
    },

    active: {
      true: {
        borderRadius: '6px',
        background: '$hoverBlue',
        color: 'white',
      },
    },

    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const MenuItemListItem = styled('div', menuItemStyles);
export const MenuItemListItemLink = styled('a', menuItemStyles);
