import { forwardRef, useCallback, useMemo, useState } from 'react';

import type { CSS } from '../design-tokens';
import { Grid } from '../grid';
import type { BaseProps } from '../interfaces';
import { Space } from '../space';
import { ProgressIndicator, ProgressLabel, ProgressRoot } from './_Base';

export type MultiProgressColor = 'blue' | 'green' | 'pink' | 'grey' | 'midGrey';

export const defaultMultiColorProgressProps = {
  color: 'blue' as MultiProgressColor,
  gap: '4px' as string | number,
  label: null as React.ReactNode,
  header: null as React.ReactNode,
  footer: null as React.ReactNode,
};

export interface ProgressListItemProps {
  name?: string;
  label?: React.ReactNode;
  color: MultiProgressColor;
  value: number;
  progressStyle?: CSS;
}

export interface MultiColorProgressProps extends BaseProps {
  label?: React.ReactNode;
  progressList: ProgressListItemProps[];
  hidden?: boolean;
  gap?: string | number;
  highlightProgress?: number;
}

export const MultiColorProgress = forwardRef<HTMLDivElement, MultiColorProgressProps>(
  (props, ref) => {
    const { label, progressList, hidden, gap, highlightProgress, ...restProps } = props;

    const [hoverIndex, setHoverIndex] = useState(-1);

    const gridColumnList = useMemo(() => {
      return progressList.map((progress) => progress.value);
    }, [progressList]);

    const totalValue = useMemo(() => {
      return gridColumnList.reduce((result, value) => result + value, 0);
    }, [gridColumnList]);

    const highlightStyle = useMemo<React.CSSProperties>(() => {
      if (!highlightProgress) {
        return {
          display: 'none',
        };
      }

      const style: React.CSSProperties = {
        position: 'absolute',
        right: 0,
        width: `${highlightProgress}%`,
        height: '18px',
        backgroundColor: 'transparent',
        border: '2px dashed #5A636E',
        borderRadius: '6px',
      };

      return progressList.length > 1
        ? {
            ...style,
            top: '50%',
            transform: 'translateY(-50%)',
          }
        : {
            ...style,
            top: '-6px',
          };
    }, [highlightProgress, progressList.length]);

    const gridColumns = useMemo(() => {
      const values = gridColumnList;
      const firstValue = values[0];
      if (!firstValue) {
        return '1fr';
      }
      if (gridColumnList.length === 4) {
        return `${firstValue}fr ${values[1] ?? (0 / firstValue) * 100}% ${
          values[2] ?? (0 / firstValue) * 100
        }% ${values[3] ?? (0 / firstValue) * 100}%`;
      }
      if (gridColumnList.length === 3) {
        return `${firstValue}fr ${values[1] ?? (0 / firstValue) * 100}% ${
          values[2] ?? (0 / firstValue) * 100
        }%`;
      }
      if (gridColumnList.length === 2) {
        return `${firstValue}fr ${values[1] ?? (0 / firstValue) * 100}%`;
      }
      return '1fr';
    }, [gridColumnList]);

    const isLastOne = useCallback(
      (index: number) => {
        return index > 0 && progressList.length - 1 === index;
      },
      [progressList.length],
    );

    const needAdjustStyle = useCallback(
      (index: number, value: number) => {
        if (isLastOne(index)) {
          return false;
        }
        if (index === 1) {
          // the rest is wide enough, align from left
          return value <= 20 && (gridColumnList?.[0] ?? 0) > 80;
        }
        return index > 0;
      },
      [gridColumnList, isLastOne],
    );

    const getLabelStyle = useCallback(
      (index: number) => {
        if (!totalValue) {
          return 0;
        }
        if (index === 1) {
          return `${
            100 - (((gridColumnList[0] ?? 0) + (gridColumnList[1] ?? 0)) / totalValue) * 100
          }%`;
        }
        if (index === 2) {
          return `${((gridColumnList.at(-1) ?? 0) / totalValue) * 100}%`;
        }
        return 0;
      },
      [gridColumnList, totalValue],
    );

    const handleHover = useCallback((index: number) => {
      setHoverIndex(index);
    }, []);

    const handleRemoveHover = useCallback(() => {
      setHoverIndex(-1);
    }, []);

    if (hidden) {
      return null;
    }

    return (
      <Space ref={ref} size={4} direction="vertical" inline={false} {...restProps}>
        {label}
        <Space size={6} direction="vertical" inline={false} style={{ position: 'relative' }}>
          <Grid
            columns={progressList.length as any}
            hidden={progressList.length <= 1}
            css={{
              gap,
              position: 'relative',
              gridTemplateColumns: gridColumns,
            }}
          >
            {progressList.map((progress, index) => (
              <ProgressLabel
                key={index}
                hidden={index % 2 === 0}
                isLast={isLastOne(index)}
                hover={hoverIndex === index}
                style={
                  needAdjustStyle(index, progress.value)
                    ? {
                        position: 'absolute',
                        right: getLabelStyle(index),
                      }
                    : undefined
                }
              >
                {progress.label}
              </ProgressLabel>
            ))}
          </Grid>
          <ProgressRoot>
            <Grid
              fullHeight
              columns={progressList.length as any}
              css={{
                gap,
                gridTemplateColumns: gridColumns,
              }}
            >
              {progressList.map((progress, index) => (
                <ProgressIndicator
                  key={index}
                  color={progress.color}
                  rounded={false}
                  css={progress.progressStyle}
                  onMouseOver={() => handleHover(index)}
                  onMouseOut={handleRemoveHover}
                />
              ))}
            </Grid>
          </ProgressRoot>
          <Grid
            columns={progressList.length as any}
            css={{
              gap,
              position: 'relative',
              gridTemplateColumns: gridColumns,
            }}
            hidden={progressList.length === 0}
          >
            {progressList.map((progress, index) => (
              <ProgressLabel
                key={index}
                hidden={index % 2 !== 0}
                isLast={isLastOne(index)}
                hover={hoverIndex === index}
                style={
                  needAdjustStyle(index, progress.value)
                    ? {
                        position: 'absolute',
                        right: getLabelStyle(index),
                      }
                    : undefined
                }
              >
                {progress.label}
              </ProgressLabel>
            ))}
          </Grid>
          <span style={highlightStyle} />
        </Space>
      </Space>
    );
  },
);

MultiColorProgress.displayName = 'MultiColorProgress';
