import debounce from 'lodash/debounce';
import {
  cloneElement,
  type Dispatch,
  forwardRef,
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { BasePropsWithChildren } from '../interfaces';
import { StepFormContentContainer, StepFormMainContent } from './_Base';
import type { FormStepper } from './types';

export interface StepFormContentProps extends BasePropsWithChildren {
  /** Current step of form */
  currentStep: number;
  /** Step form stepper */
  steps: FormStepper[];
  /** Header of step form */
  header?: JSX.Element;
  /** Footer of step form */
  footer?: JSX.Element;
  /** Dispatch util */
  dispatch?: Dispatch<any>;
}

export const StepFormContent = forwardRef(
  (props: StepFormContentProps, ref: React.Ref<HTMLDivElement>) => {
    const { currentStep = 0, steps = [], children, header, footer, dispatch, ...restProps } = props;

    const innerRef = useRef<HTMLDivElement | null>(null);
    const [isHeaderSticky, setHeaderSticky] = useState(false);
    const [isFooterSticky, setFooterSticky] = useState(false);

    const callbackRef = useCallback((node: HTMLDivElement | null) => {
      if (node) {
        setFooterSticky(node.scrollHeight > node.clientHeight);
      }
      innerRef.current = node;
    }, []);

    const debouncedScrollHandler = useMemo<React.UIEventHandler<HTMLDivElement>>(() => {
      return debounce((event) => {
        const dialogContainer = innerRef.current;

        if (dialogContainer) {
          const { scrollHeight, clientHeight, scrollTop } = dialogContainer;
          const scrollBottom = scrollHeight - clientHeight - scrollTop;

          startTransition(() => {
            setHeaderSticky(scrollTop > 1);
            setFooterSticky(scrollBottom > 1);
          });
        }
      }, 60);
    }, []);

    const handleDialogContainerScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
      (event) => {
        debouncedScrollHandler(event);
      },
      [debouncedScrollHandler],
    );

    useEffect(() => {
      const scrollElement = innerRef.current;

      if (scrollElement) {
        scrollElement.scroll({
          top: 0,
          behavior: 'smooth',
        });

        setFooterSticky(scrollElement.scrollHeight > scrollElement.clientHeight);
      }
    }, [currentStep]);

    return (
      <StepFormMainContent ref={callbackRef} onScroll={handleDialogContainerScroll} {...restProps}>
        {header
          ? cloneElement(header, {
              currentStep,
              steps,
              isSticky: isHeaderSticky,
              dispatch,
            })
          : null}
        <StepFormContentContainer>{children}</StepFormContentContainer>
        {footer
          ? cloneElement(footer, {
              currentStep,
              steps,
              isSticky: isFooterSticky,
              dispatch,
            })
          : null}
      </StepFormMainContent>
    );
  },
);

StepFormContent.displayName = 'StepFormContent';
