export enum CountryCode {
  AU = 'AU',
}

export enum AddressState {
  VIC = 'VIC',
  NSW = 'NSW',
  WA = 'WA',
  SA = 'SA',
  NT = 'NT',
  ACT = 'ACT',
  QLD = 'QLD',
  TAS = 'TAS',
}
