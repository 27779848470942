import { SVGIcon } from '@paid-ui/icons';

import { drawerOverlayShow, styled } from '../design-tokens';

export const DrawerTemplate = styled('div', {
  height: '1000px',
});

export const DrawerContainer = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  variants: {
    visible: {
      true: {
        transition: 'transform .3s cubic-bezier(.23,1,.32,1)',
      },

      false: {
        transition: 'width 0s ease .3s,height 0s ease .3s',
      },
    },
  },
});

export const StyledDrawer = styled('div', {
  position: 'fixed',
  padding: '18px 16px',
  transition: 'transform .3s cubic-bezier(.23,1,.32,1),box-shadow .3s cubic-bezier(.23,1,.32,1)',
  boxShadow: '6px 0 16px -8px #00000014, 9px 0 28px #0000000d, 12px 0 48px 16px #00000008',

  variants: {
    theme: {
      light: {
        backgroundColor: '$bg',
      },

      dark: {
        backgroundColor: '$fg',
      },
    },

    visible: {
      true: {
        transform: 'none',
      },
    },

    placement: {
      top: {
        top: 0,
        right: 0,
        left: 0,
        height: '322px',
      },

      right: {
        top: 0,
        right: 0,
        bottom: 0,
        width: '322px',
      },

      bottom: {
        right: 0,
        bottom: 0,
        left: 0,
        height: '322px',
      },

      left: {
        top: 0,
        bottom: 0,
        left: 0,
        width: '322px',
      },
    },
  },
  compoundVariants: [
    {
      visible: false,
      placement: 'top',
      css: {
        transform: 'translateY(-100%)',
      },
    },
    {
      visible: false,
      placement: 'right',
      css: {
        transform: 'translateX(100%)',
      },
    },
    {
      visible: false,

      placement: 'bottom',
      css: {
        transform: 'translateY(100%)',
      },
    },
    {
      visible: false,
      placement: 'left',
      css: {
        width: '322px',
        transform: 'translateX(-100%)',
      },
    },
  ],

  defaultVariants: {
    placement: 'left',
  },
});

export const DrawerOverlay = styled('div', {
  position: 'fixed',
  inset: 0,
  backgroundColor: '$bgOverlay',
  backdropFilter: 'blur(8px)',
  zIndex: '$overlay',
  transition: 'none',
  pointerEvents: 'auto',

  variants: {
    visible: {
      true: {
        animation: `${drawerOverlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
      },

      false: {
        transition: 'opacity .3s linear, height 0s ease .3s',
      },
    },
  },
});

export const DrawerHeader = styled('header', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '20px',
});

export const DrawerTitle = styled('h2', {
  fontWeight: '$normal',
});

export const DrawerCloseButton = styled('button', {
  all: 'unset',
  cursor: 'pointer',

  variants: {
    placement: {
      top: {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },

      right: {
        position: 'absolute',
        left: 0,
      },

      bottom: {
        position: 'absolute',
        top: 0,
        right: 0,
      },

      left: {
        position: 'absolute',
        right: 0,
      },
    },
  },

  defaultVariants: {
    placement: 'left',
  },
});

export const DrawerCloseIcon = styled(SVGIcon, {
  width: '24px',
  height: '24px',

  variants: {
    theme: {
      light: {
        color: '$fgSecondary',
      },

      dark: {
        color: 'white',
      },
    },
  },
});

export const DrawerBody = styled('div', {
  marginTop: '24px',
  paddingBottom: '24px',
  overflowY: 'auto',
  overscrollBehavior: 'none',

  variants: {
    placement: {
      top: {
        height: '254px',
      },

      right: {
        height: '100%',
      },

      bottom: {
        height: '254px',
      },

      left: {
        height: '100%',
      },
    },
  },
});

export const DrawerFooter = styled('footer', {});
