import { forwardRef, useCallback, useState } from 'react';

import type { BaseProps } from '../interfaces';
import { BannerContainer, BannerWrap, CloseIcon, ContentContainer, SubText, Text } from './_Base';

export const defaultBannerProps = {
  width: '100%',
  height: 'auto',
  closable: true,
};

export interface BannerProps extends Omit<BaseProps, 'children'> {
  /** Custom logo width */
  width?: string | number;
  /** Custom logo height */
  height?: string | number;
  text?: React.ReactNode;
  subtext?: React.ReactNode;
  closable?: boolean;
  handleClose?: () => void;
  closeIconStyle?: React.CSSProperties;
  hidden?: boolean;
}

export const Banner = forwardRef<SVGSVGElement, BannerProps>((props, ref) => {
  const {
    width = defaultBannerProps.width,
    height = defaultBannerProps.height,
    style,
    text,
    hidden,
    subtext,
    closable = defaultBannerProps.closable,
    handleClose,
    closeIconStyle,
  } = props;

  const [containerStyle, setContainerStyle] = useState({
    width,
    height,
    ...style,
  });

  const handleClickClose = useCallback(() => {
    setContainerStyle((prevState) => ({
      ...prevState,
      display: 'none',
    }));
    if (typeof handleClose === 'function') {
      handleClose();
    }
  }, [handleClose]);

  if (hidden) {
    return null;
  }

  return (
    <BannerContainer style={containerStyle}>
      <BannerWrap>
        <ContentContainer>
          <Text>{text}</Text>
          <SubText>{subtext}</SubText>
        </ContentContainer>
        {closable ? (
          <CloseIcon name="close" style={closeIconStyle} onClick={handleClickClose} />
        ) : null}
      </BannerWrap>
    </BannerContainer>
  );
});

Banner.displayName = 'Banner';
