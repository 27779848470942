import { SVGIcon } from '@paid-ui/icons';

import { styled } from '../design-tokens';

export const PanelContainer = styled('section', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
  background: '$bg',
  radius: '16px',

  variants: {
    noBorder: {
      true: {
        border: 'none',
        boxShadow: 'none',
      },

      false: {
        border: '1px solid $colors$lightGrey',
        boxShadow:
          '0px 15px 29px rgb(0 0 0 / 2.83%), 0px 3.35045px 6.47753px rgb(0 0 0 / 4.17%), 0px 0.997515px 1.92853px rgb(0 0 0 / 7%)',
      },
    },

    noPadding: {
      true: {
        'padding': 0,

        '@sm': {
          padding: 0,
        },
      },

      false: {
        'padding': '20px',

        '@sm': {
          padding: '30px',
        },
      },
    },

    compact: {
      true: {
        gap: '10px',
      },
    },
  },

  defaultVariants: {
    noBorder: false,
    noPadding: false,
  },
});

export const PanelHeader = styled('header', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '8px',

  variants: {
    hidden: {
      true: {
        display: 'none',
      },
    },
  },
});

export const PanelHeaderTitle = styled('h3', {
  'flex': 1,
  'color': '$fg',
  'fontSize': '22px',
  'fontWeight': '$semibold',
  'lineHeight': '27px',

  '&:empty': {
    display: 'none',
  },

  'variants': {
    compact: {
      true: {
        fontSize: '18px',
        lineHeight: '25px',
      },
    },
  },
});

export const CollapseButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  boxSizing: 'border-box',
  cursor: 'pointer',

  variants: {
    compact: {
      true: {
        gap: '8px',
      },
    },
    disabled: {
      true: {
        color: '$fgTertiary',
        cursor: 'unset',
      },
    },
  },
});

export const StyledSVGIcon = styled(SVGIcon, {
  flexShrink: 0,
  width: '24px',
  height: '24px',

  transformOrigin: 'center',
  transition: 'transform 100ms',
  willChange: 'transform',

  variants: {
    expanded: {
      true: {
        transform: 'rotate(0)',
      },

      false: {
        transform: 'rotate(-90deg)',
      },
    },
  },

  defaultVariants: {
    expanded: true,
  },
});

export const ClampSpan = styled('span', {
  lineClamp: 2,
  variants: {
    disabled: {
      true: {
        color: '$fgTertiary',
      },
    },
  },
});

export const PanelHeaderExtra = styled('div', {
  'flexShrink': 0,
  'color': '$blue',

  '&:empty': {
    display: 'none',
  },
});

export const PanelContent = styled('div', {
  'display': 'flex',
  'flexDirection': 'column',
  'width': '100%',
  'gap': '14px',
  '&:empty': {
    display: 'none',
  },
});
