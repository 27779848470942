import * as SeparatorPrimitive from '@radix-ui/react-separator';

import { styled } from '../design-tokens';

export const StyledSeparator = styled(SeparatorPrimitive.Root, {
  'backgroundColor': '$fgFaint',

  '&[data-orientation=horizontal]': {
    width: '100%',
    height: 1,
  },

  '&[data-orientation=vertical]': {
    width: 1,
    height: '100%',
  },
});
