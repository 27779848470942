import type { SVGIconName } from '@paid-ui/icons';
import { forwardRef } from 'react';

import type { CSS } from '../design-tokens';
import { Flex } from '../flex';
import type { BaseProps, FontSize, FontWeight } from '../interfaces';
import { Label } from '../label';
import { Space } from '../space';
import {
  CheckboxSVGIcon,
  ClassicRadioGroupRoot,
  ClassicRadioItem,
  ClassicRadioItemIndicator,
  ClassicRadioItemLabel,
  EmptySpan,
  RadioContainer,
  RadioGroupRoot,
  RadioItem,
  RadioItemLabel,
} from './_Base';

export type RadioGroupDisplay = 'flex' | 'grid';
export type RadioGroupDirection = 'vertical' | 'horizontal';
export type RadioItemColor = 'default' | 'primary' | 'success' | 'danger';

export interface RadioGroupOption {
  label?: React.ReactNode;
  value: string;
  icon?: SVGIconName;
  color?: RadioItemColor;
  disabled?: boolean;
  extra?: React.ReactNode;
  hidden?: boolean;
}

export const defaultRadioGroupProps = {
  display: 'flex' as RadioGroupDisplay,
  direction: 'horizontal' as RadioGroupDirection,
  classic: true,
  options: [
    {
      value: '1',
      label: 'Yes',
      color: 'primary',
    },
    {
      value: '0',
      label: 'No',
      color: 'primary',
    },
  ] as RadioGroupOption[],
};

export interface RadioGroupProps extends BaseProps {
  name: string;
  value?: string;
  defaultValue?: string;
  label?: React.ReactNode;
  labelSize?: FontSize;
  labelWeight?: FontWeight;
  options?: RadioGroupOption[];
  required?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  display?: RadioGroupDisplay;
  direction?: RadioGroupDirection;
  classic?: boolean;
  showIcon?: boolean;
  showIconStyle?: CSS;
  radioStyle?: CSS;
  radioGroupStyle?: CSS;
  radioLabelStyle?: CSS;
  onChange?: (value: string) => void;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>((props, ref) => {
  const {
    name,
    value,
    label,
    options = defaultRadioGroupProps.options,
    required,
    disabled,
    hidden,
    display = defaultRadioGroupProps.display,
    direction = defaultRadioGroupProps.direction,
    classic = defaultRadioGroupProps.classic,
    labelSize,
    radioStyle,
    labelWeight,
    defaultValue,
    radioGroupStyle,
    radioLabelStyle,
    showIcon,
    showIconStyle,
    onChange,
    ...restProps
  } = props;

  if (hidden) {
    return null;
  }

  if (classic) {
    return (
      <Space size={8} direction="vertical" inline={false} {...restProps}>
        {label ? (
          <Label htmlFor={name} asterisk={required} size={labelSize} weight={labelWeight}>
            {label}
          </Label>
        ) : null}
        <ClassicRadioGroupRoot
          ref={ref}
          name={name}
          value={value}
          defaultValue={defaultValue}
          required={required}
          direction={direction}
          onValueChange={onChange}
        >
          {options.map((option) => (
            <Space key={option.value}>
              <ClassicRadioItem
                id={`${name}-${option.value}`}
                value={option.value}
                disabled={disabled || option.disabled}
              >
                <ClassicRadioItemIndicator />
              </ClassicRadioItem>
              <ClassicRadioItemLabel htmlFor={`${name}-${option.value}`}>
                {option.label}
              </ClassicRadioItemLabel>
            </Space>
          ))}
        </ClassicRadioGroupRoot>
      </Space>
    );
  }

  return (
    <Space size={8} direction="vertical" inline={false} {...restProps}>
      {label ? (
        <Label htmlFor={name} asterisk={required} size={labelSize} weight={labelWeight}>
          {label}
        </Label>
      ) : null}

      <RadioGroupRoot
        ref={ref}
        name={name}
        value={value}
        display={display}
        required={required}
        direction={direction}
        css={radioGroupStyle}
        defaultValue={defaultValue}
        onValueChange={onChange}
      >
        {options.map((option) => (
          <RadioContainer
            key={option.value}
            css={radioStyle}
            htmlFor={`${name}-${option.value}`}
            selected={value === option.value}
            disabled={disabled || option.disabled}
            color={option.color}
            hidden={option.hidden}
          >
            <Flex css={{ width: '100%' }}>
              <RadioItemLabel
                selected={value === option.value}
                disabled={disabled || option.disabled}
                css={radioLabelStyle}
              >
                {option.label}
              </RadioItemLabel>
              <RadioItem
                id={`${name}-${option.value}`}
                value={option.value}
                disabled={disabled || option.disabled}
              >
                {option.icon ? <CheckboxSVGIcon name={option.icon} /> : null}
                {showIcon && value === option.value ? (
                  <CheckboxSVGIcon name="checkbox" css={showIconStyle} />
                ) : (
                  <EmptySpan />
                )}
              </RadioItem>
            </Flex>
          </RadioContainer>
        ))}
      </RadioGroupRoot>
    </Space>
  );
});

RadioGroup.displayName = 'RadioGroup';
