export enum RequiringActionType {
  SUBMIT_CLAIM = 'SUBMIT_CLAIM',
  UPDATE = 'UPDATE',
  REVIEW = 'REVIEW',
  PAY = 'PAY',
  SIGN = 'SIGN',
  JOIN = 'JOIN',
}

export enum RequiringActionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum RequiringActionFeatureType {
  CONTRACT = 'CONTRACT',
  VARIATION = 'VARIATION',
  ADJUSTMENT = 'ADJUSTMENT',
  CLAIM = 'CLAIM',
  EARLY_RELEASE_REQUEST = 'EARLY_RELEASE_REQUEST',
}

export const requiringActionLabelMap = {
  JOIN_CONTRACT: 'Join contract',
  UPDATE_CONTRACT: 'Resubmit contract',
  REVIEW_CONTRACT: 'Review contract',
  SIGN_CONTRACT: 'Sign contract',
  SUBMIT_CLAIM_CONTRACT: 'Create claim',
  SUBMIT_CLAIM: 'Submit claim',

  REVIEW_CLAIM: 'Review claim',
  UPDATE_CLAIM: 'Resubmit claim',
  PAY_CLAIM: 'Pay claim',

  REVIEW_VARIATION: 'Review variation',
  UPDATE_VARIATION: 'Resubmit variation',

  REVIEW_ADJUSTMENT: 'Review adjustment',
  UPDATE_ADJUSTMENT: 'Resubmit adjustment',

  REVIEW_EARLY_RELEASE_REQUEST: 'Review InstaPaid counter offer.',
};

export const requiringActionSearchParamMap = {
  JOIN_CONTRACT: 'complete-contract',
  UPDATE_CONTRACT: 'amend-contract',
  REVIEW_CONTRACT: 'review-contract',
  SIGN_CONTRACT: 'sign-contract',
  SUBMIT_CLAIM_CONTRACT: 'new-claim',
  SUBMIT_CLAIM: 'new-claim',

  REVIEW_CLAIM: 'review-claim',
  UPDATE_CLAIM: 'amend-claim',
  PAY_CLAIM: 'pay-claim',

  NEW_VARIATION: 'new-variation',
  REVIEW_VARIATION: 'review-variation',
  UPDATE_VARIATION: 'amend-variation',

  NEW_ADJUSTMENT: 'new-adjustment',
  REVIEW_ADJUSTMENT: 'review-adjustment',
  UPDATE_ADJUSTMENT: 'amend-adjustment',

  REVIEW_EARLY_RELEASE_REQUEST: 'earlyrelease-review-offer',
};
