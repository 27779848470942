import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { BaseProps } from '../interfaces';
import { Container, Input, InputChar, InputChars } from './_Base';

type ValidChars = 'alphanumeric' | 'alpha' | 'numeric';
export type InputVerificationSize = 'sm' | 'md' | 'lg' | 'xl';

export const defaultInputVerificationProps = {
  placeholder: '',
  size: 'md' as InputVerificationSize,
  length: 6,
  validChars: 'numeric' as ValidChars,
  autoComplete: 'off',
  capitalize: true,
};

export interface InputVerificationProps extends BaseProps {
  name?: string;
  formId?: string;
  value?: string;
  placeholder?: string;
  size?: InputVerificationSize;
  length?: number;
  validChars?: ValidChars;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
  ghost?: boolean;
  readOnly?: boolean;
  capitalize?: boolean;
  hidden?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const InputVerification = forwardRef<HTMLInputElement, InputVerificationProps>(
  (props, ref) => {
    const {
      name,
      formId,
      value: valueProp,
      placeholder = defaultInputVerificationProps.placeholder,
      size = defaultInputVerificationProps.size,
      length = defaultInputVerificationProps.length,
      validChars = defaultInputVerificationProps.validChars,
      autoFocus,
      autoComplete = defaultInputVerificationProps.autoComplete,
      disabled,
      ghost,
      readOnly,
      capitalize = defaultInputVerificationProps.capitalize,
      hidden,
      onChange,
      onBlur,
      onFocus,
      onPressEnter,
      ...restProps
    } = props;
    const [isActive, setActive] = useState(false);
    const [localValue, setLocalValue] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const callbackRef = useCallback(
      (node: HTMLInputElement) => {
        inputRef.current = node;

        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      },
      [ref],
    );

    const value = useMemo(() => {
      return valueProp ?? localValue;
    }, [localValue, valueProp]);

    const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        let newValue = event.target.value.replaceAll(/\s/g, '');

        switch (validChars) {
          case 'alpha': {
            const re = new RegExp(`^[a-zA-Z]{0,${length}}$`);
            if (re.test(newValue)) {
              if (capitalize) {
                newValue = [...newValue].map((v) => v.toUpperCase()).join('');
              }
              setLocalValue(newValue);
              if (typeof onChange === 'function') {
                event.target.value = newValue;
                event.currentTarget.value = newValue;
                onChange(event);
              }
            }
            break;
          }

          case 'numeric': {
            const re = new RegExp(`^\\d{0,${length}}$`);
            if (re.test(newValue)) {
              setLocalValue(newValue);
              if (typeof onChange === 'function') {
                event.target.value = newValue;
                event.currentTarget.value = newValue;
                onChange(event);
              }
            }
            break;
          }

          case 'alphanumeric': {
            const re = new RegExp(`^[0-9a-zA-Z]{0,${length}}$`);
            if (re.test(newValue)) {
              if (capitalize) {
                newValue = [...newValue].map((v) => v.toUpperCase()).join('');
              }
              setLocalValue(newValue);
              if (typeof onChange === 'function') {
                event.target.value = newValue;
                event.currentTarget.value = newValue;
                onChange(event);
              }
            }
            break;
          }

          default: {
            break;
          }
        }
      },
      [capitalize, length, onChange, validChars],
    );

    const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
      inputRef.current?.focus();
    }, []);

    const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (event) => {
        setActive(false);
        if (typeof onBlur === 'function') {
          onBlur(event);
        }
      },
      [onBlur],
    );

    const handleFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
      (event) => {
        setActive(true);
        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      },
      [onFocus],
    );

    const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
      (event) => {
        if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.preventDefault();
        }

        if (event.key === 'Enter' && typeof onPressEnter === 'function') {
          onPressEnter(event);
        }
      },
      [onPressEnter],
    );

    const handleSelect = useCallback<React.ReactEventHandler<HTMLInputElement>>((event) => {
      const currentValue = event.currentTarget.value;
      event.currentTarget.setSelectionRange(currentValue.length, currentValue.length);
    }, []);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    if (hidden) {
      return null;
    }

    return (
      <Container {...restProps}>
        <Input
          ref={callbackRef}
          id={name}
          name={name}
          form={formId}
          type="text"
          inputMode="numeric"
          value={value}
          minLength={length}
          maxLength={length}
          autoCapitalize={capitalize ? 'characters' : 'none'}
          autoCorrect="off"
          autoComplete={autoComplete}
          spellCheck="false"
          disabled={disabled}
          readOnly={readOnly}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onSelect={handleSelect}
        />
        <InputChars size={size}>
          {Array.from({ length }).map((_, index) => (
            <InputChar
              key={index}
              onClick={handleClick}
              size={size}
              disabled={disabled}
              selected={
                value.length === index ||
                (value.length === index + 1 && index + 1 === length && isActive)
              }
              inactive={value.length < index}
              ghost={ghost}
            >
              {value?.at(index) ?? placeholder}
            </InputChar>
          ))}
        </InputChars>
      </Container>
    );
  },
);

InputVerification.displayName = 'InputVerification';
