export enum NotificationStatus {
  NEW = 'NEW',
  UNREAD = 'UNREAD',
  READ = 'READ',
  DELETED = 'DELETED',
}

export enum NotificationSource {
  PROJECT = 'PROJECT',
  USER_PROFILE = 'USER_PROFILE',
  TXN_ACCOUNT = 'TXN_ACCOUNT',
}

export enum NotificationType {
  ACTION = 'ACTION',
  INFO = 'INFO',
  SYSTEM = 'SYSTEM',
}
