import { type AccountSummary } from '@paid-ui/types';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

import { userManager } from './user';

const initialState = {
  // This id is account matcher ID
  id: '',
  accountId: '',
  availableBalance: 0,
  reservedBalance: 0,
  totalBalance: 0,
  bsb: '',
  accountNumber: '',
  accountCreationTime: '',
  /** Force update flag */
  forceUpdate: 0,
  /** Loading state */
  loading: false,
};

export const transactionAccountManager = proxy(initialState);

devtools(transactionAccountManager, {
  name: 'Main Transaction Account',
  enabled: false,
});

export const resetTransactionAccount = () => {
  Object.assign(transactionAccountManager, initialState);
};

export const saveAccountSummary = (data: AccountSummary) => {
  transactionAccountManager.availableBalance = data.availableBalance;
  transactionAccountManager.reservedBalance = data.reservedBalance;
  transactionAccountManager.totalBalance = data.totalBalance;
  transactionAccountManager.bsb = data.bsb;
  transactionAccountManager.accountNumber = data.accountNumber;
  if (data.accountCreationTime) {
    transactionAccountManager.accountCreationTime = data.accountCreationTime;
  }
};

export const startLoading = () => {
  transactionAccountManager.loading = true;
};

export const stopLoading = () => {
  transactionAccountManager.loading = false;
};

export const reloadTransactionAccount = () => {
  const { forceUpdate } = transactionAccountManager;
  const { isAccountReady } = userManager;
  if (!isAccountReady) return;
  transactionAccountManager.forceUpdate = forceUpdate > 100 ? 1 : forceUpdate + 1;
};
