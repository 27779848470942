import { type UploadStatus } from '@paid-ui/enums/attachment';
import { type Geolocation } from '@paid-ui/schemas/zod/evidence';

import { PaymentStageName } from './payments';

export type Evidence = {
  fileName: string;
  fileAddress: string;
  fileSize?: number;
  fileType?: string;
  category?: string;
  geolocation?: Geolocation;
  workItems?: Record<string, any>;

  /** Used for unique upload task (wont be saved to DB) */
  id?: string | null;
  /** Used for tracking upload status (wont be saved to DB) */
  status?: UploadStatus;
  /** Used for presigned url (wont be saved to DB) */
  tempFileAddress?: string;
};

export enum EvidenceCategory {
  'PHOTOGRAPH' = 'PHOTOGRAPH',
  'VIDEO' = 'VIDEO',
  'DOCUMENT' = 'DOCUMENT',
}

export const externalEvidenceNotes: Record<PaymentStageName, string[]> = {
  [PaymentStageName.DEPOSIT]: [],
  [PaymentStageName.BASE]: [],
  [PaymentStageName.FRAME]: ['Walls, joist, flooring and roof structure'],
  [PaymentStageName.LOCK_UP]: [
    'All walls including alcoves and those that are setback',
    'Eaves or soffits',
    'External doors and windows',
    'Roof cladding and roof plumbing',
  ],
  [PaymentStageName.FIXING]: [],
  [PaymentStageName.PRACTICAL_COMPLETION]: [
    'Everything outside',
    'Hard and/or soft landscaping (if in contract)',
  ],
  [PaymentStageName.OTHER]: [],
  [PaymentStageName.UNKNOWN]: [],
};

export const internalEvidenceNotes: Record<PaymentStageName, string[]> = {
  [PaymentStageName.DEPOSIT]: [],
  [PaymentStageName.BASE]: ['Penetrations'],
  [PaymentStageName.FRAME]: ['Walls, joist, flooring and roof structure'],
  [PaymentStageName.LOCK_UP]: ['Internal walls', 'Underside of roof cladding'],
  [PaymentStageName.FIXING]: [
    'Internal cladding, architraves, staircases, skirting, doors',
    'Built-in shelves, baths, basins, troughs, sinks, cabinets and cupboards ',
    'All fitted and fixed in position',
  ],
  [PaymentStageName.PRACTICAL_COMPLETION]: ['Everything inside'],
  [PaymentStageName.OTHER]: [],
  [PaymentStageName.UNKNOWN]: [],
};

export const documentEvidenceNotes: Record<PaymentStageName, string[]> = {
  [PaymentStageName.DEPOSIT]: ['Any other supporting documentation'],
  [PaymentStageName.BASE]: [
    'Building Surveyor/Certifier report',
    'Any other supporting documentation',
  ],
  [PaymentStageName.FRAME]: [
    'Building Surveyor/Certifier report',
    'Any other supporting documentation',
  ],
  [PaymentStageName.LOCK_UP]: ['Any other supporting documentation'],
  [PaymentStageName.FIXING]: ['Any other supporting documentation'],
  [PaymentStageName.PRACTICAL_COMPLETION]: [
    'Occupancy certificate',
    'Building Surveyor/Certifier report',
    'Any other supporting documentation',
  ],
  [PaymentStageName.OTHER]: ['Any other supporting documentation'],
  [PaymentStageName.UNKNOWN]: [],
};
