import type { UserDetail } from '@paid-ui/types';
import { forwardRef } from 'react';

import { Avatar } from '../avatar';
import type { BaseProps } from '../interfaces';

interface NavbarAvatarProps extends BaseProps {
  user?: UserDetail;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const NavbarAvatar = forwardRef<HTMLSpanElement, NavbarAvatarProps>((props, ref) => {
  const { user, onClick, ...restProps } = props;

  return (
    <Avatar
      ref={ref}
      pointer
      bordered
      size="xSmall"
      iconColor="white"
      backgroundColor="blue"
      alt="User Avatar"
      {...restProps}
    >
      {user ? `${user.firstName.at(0)}${user.lastName.at(0)}` : null}
    </Avatar>
  );
});

NavbarAvatar.displayName = 'NavbarAvatar';
